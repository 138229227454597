<template>
  <div class="main">
    <div class="content">
      <div class="content-card">
        <div class="content-card-common content-card-left">
          <div class="plate-title">
            场景模板
          </div>
          <el-scrollbar class="left-scrollbar">
            <div class="left-content">
              <div v-for="(sItem,i) in secondLevelMenuList" :key="i" class="content-item">
                <div class="item-second-level">
                  {{ sItem.label }}
                </div>
                <div :class="['item-third-level', tItem.id == selectTLMId ? 'item-third-level-select' : '']"
                     v-for="(tItem,j) in sItem.applicationList"
                     :key="j"
                     @click="handleClickThirdLevelMenu(tItem)">
                  <div class="third-level-img">
                    <el-image :src="tItem.icon" fit="cover"/>
                  </div>
                  <div class="third-level-font">
                    <div class="font-label">
                      {{ tItem.label }}
                    </div>
                    <div class="font-introduce">
                      {{ tItem.introduce }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="content-card-common content-card-middle">
          <div class="plate-title">
            开始创作
          </div>
          <el-scrollbar class="middle-scrollbar">
            <div class="middle-content">
              <el-form ref="form" :model="form" :rules="rules" label-position="top" :validate-on-rule-change="false">
                <div v-for="(item,i) in paramConfigure" :key="i">
                  <el-form-item :label="item.label" :prop="item.id">
                    <template>
                      <el-input v-if="item.type == 'input'" v-model="form[item.id]" :placeholder="item.prompt"/>
                      <el-input :rows="4" v-else-if="item.type == 'textarea'" type="textarea" v-model="form[item.id]"
                                :placeholder="item.prompt"/>
                      <el-select style="width: 100%" v-else-if="item.type == 'select'"
                                 v-model="form[item.id]" :placeholder="item.prompt">
                        <el-option
                            v-for="(s,i) in item.selectContent"
                            :key="i"
                            :label="s"
                            :value="s"
                        ></el-option>
                      </el-select>
                      <el-select style="width: 100%" v-else-if="item.type == 'multiSelect'"
                                 v-model="form[item.id]" :placeholder="item.prompt">
                        <el-option
                            v-for="(s,i) in item.selectContent"
                            :key="i"
                            :label="s"
                            :value="s"
                        ></el-option>
                      </el-select>
                      <div v-else-if="item.type == 'showImage'" style="width: 240px">
                        <el-image style="width: 100%" :src="item.showImage" :preview-src-list="[item.showImage]"></el-image>
                      </div>
                      <el-input v-else v-model="form[item.id]" :placeholder="item.prompt"/>
                    </template>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </el-scrollbar>
          <div class="middle-submit-wrap">
            <div class="middle-submit">
              <div class="submit-button-wrap">
                <el-button :loading="btnLoading" class="submit-button" icon="el-icon-edit" @click="submitToAi">智能创作文案</el-button>
                <el-tooltip class="submit-tip"
                            effect="dark"
                            placement="top">
                  <div slot="content" style="font-size: 14px">
                    该服务处于前沿探索阶段，您应当合<br/>
                    法合规使用本服务，并承担由此产生<br/>
                    的所有责任。我们对您的使用不做保<br/>
                    证且不承担任何责任。
                  </div>
                  <div><i class="el-icon-warning-outline"/>免责声明</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="content-card-common content-card-right">
          <div class="right-top">
            <div class="top-tool">
              <el-tabs v-model="activeName" @tab-click="handleClickTabTool">
                <el-tab-pane name="create">
                  <span slot="label"><i class="el-icon-plus"></i> 新创作的</span>
                </el-tab-pane>
                <el-tab-pane name="collect" >
                  <span slot="label"><i class="el-icon-star-off"></i> 已收藏的</span>
                </el-tab-pane>
                <el-tab-pane name="history" >
                  <span slot="label"><i class="el-icon-time"></i> 历史记录</span>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="top-btn">
              <el-button type="text" :loading="btnLoading" @click="handleClickSaveCreate">保存</el-button>
            </div>
          </div>
          <div class="right-content">
            <div class="right-content-create" v-if="activeName === 'create'">
              <wang-editor ref="wangEditor" style="height: 100%" parentFun="aiCreateNew"/>
            </div>
            <!--            <div class="right-content-collect-wrap" v-if="activeName === 'collect'">-->
            <!--              <div style="height: 100%">-->
            <!--                <el-empty style="height: 100%" description="暂无数据"></el-empty>-->
            <!--              </div>-->
            <!--            </div>-->
            <div v-loading="loading" class="right-content-history-wrap" v-if="activeName === 'history' || activeName === 'collect'">
              <div style="height: 100%" v-if="historyList.length === 0">
                <el-empty style="height: 100%" description="暂无数据"></el-empty>
              </div>
              <div class="right-content-history-card" v-if="historyList.length !== 0">
                <el-scrollbar class="right-scrollbar">
                  <div class="card-content-wrap">
                    <div class="content-title">
                      {{historyList[0].messageQTime}}
                    </div>
                    <div v-show="!showEditor" id="aiCreateHistory" class="content-introduce">
                    </div>
                    <div v-show="showEditor" class="content-introduce">
                      <wang-editor ref="wangEditorHistory" parentFun="aiCreateHistory"></wang-editor>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="content-tool">
                  <el-button class="content-tool-button" @click="handleClickCopyHistory"><i class="el-icon-document-copy" />{{" 复制"}}</el-button>
                  <el-button class="content-tool-button" @click="handleClickEditHistory" v-if="!showEditor"><i class="el-icon-edit" />{{" 编辑"}}</el-button>
                  <el-button :loading="btnLoading"
                             class="content-tool-button"
                             @click="handleClickSaveHistory"
                             v-if="showEditor">
                    <i class="el-icon-finished" />{{" 保存"}}
                  </el-button>
                  <el-button class="content-tool-button" v-if="!isCollect" @click="handleClickCollect"><i class="el-icon-star-off" />{{" 收藏"}}</el-button>
                  <el-button class="content-tool-button button-primary" v-if="isCollect" @click="handleClickCollect"><i class="el-icon-star-on" />{{" 已收藏"}}</el-button>
                  <el-button class="content-tool-button" @click="handleClickCancelHistory"><i class="el-icon-refresh-left" />{{" 取消"}}</el-button>
                </div>
              </div>
              <div class="right-content-pagination" v-if="historyList.length !== 0">
                <el-pagination
                    background
                    :disabled="showEditor"
                    @current-change="handleCurrentChange"
                    :pager-count="5"
                    :page-size="1"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next, jumper"
                    :total="historyListTotal">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAiToolTemplateApplicationStar,
  getAiToolTemplateMenuEleByPid
} from "@/api/chat/fun";
import WangEditor from "@/components/wang-editor";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import website from "@/config/website";
import {getStorage} from "@/utils/storage";
import {mapActions, mapGetters} from "vuex";
import {changeCollectStatus, listUserChatLogMessageFun, updateChatLogMessageFun} from "@/api/chat/funLog";
import {main, mainApp} from "@/test/test";


export default {
  name: "toolTemplate-detail",
  components: {WangEditor},
  data() {
    return {
      loading: false,
      menuId: 0,
      pid: 0,
      elementList: [],
      secondLevelMenuList: [],
      thirdLevelMenuList: [],
      selectTLMId: undefined,
      paramConfigure: [],
      form: {},
      rules: {},
      activeName: "create",
      currentActiveName: "create",
      sseMessage: "",
      messageEnd: false,
      editorContent: undefined,
      messageId: undefined,
      btnLoading: false,
      // 查询历史记录参数列表
      historyParam:{
        pageSize: 1,
        pageNum: 1,
        funDataId: undefined,
      },
      // 查询收藏列表参数列表
      collectParam:{
        pageSize: 1,
        pageNum: 1,
        funDataId: undefined,
        storeStatus: 1,
      },
      historyList:[],
      historyListTotal: 0,
      currentPage: 1,
      showEditor: false,
      isCollect: false,
      orgId: undefined,
      starList: [],
    }
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  mounted() {
    this.menuId = this.$route.query.menuId
    this.selectTLMId = this.menuId
    this.pid = this.$route.query.pid
    this.orgId = this.$route.query.o
    this.$emit("toolTemplateChild", this.orgId)
    this.getElementByPid()
  },
  methods: {
    ...mapActions(['getUserInfoActions']),
    async getElementByPid() {
      this.editorContent = this.$refs.wangEditor.html;
      if (this.elementList && this.elementList.length){
        this.getFirstLevelMenu();
        return;
      }
      await getAiToolTemplateMenuEleByPid(this.orgId).then(res => {
        this.elementList = res.data;

      })
      this.getFirstLevelMenu();
      await this.getStarList();
      await this.getSecondLevelMenu();
      this.getTLMParamConfigure();
    },
    getStarList() {
      if (this.isLogin){
        return new Promise((resolve, reject) => {
          getAiToolTemplateApplicationStar().then(res => {
            this.starList = res.data;
            this.starList.forEach(item => {
              item.label = item.name;
            })
            let a = {
              id : -1,
              label: "我的收藏",
              children: [{
                id: -11,
                label: "我的收藏",
                applicationList: this.starList
              }]
            }
            this.elementList.push(a)
            resolve(true)
          })
        });
      }
    },
    getFirstLevelMenu() {
      let data = this.elementList;
      let firstLevelMenuList = [];
      firstLevelMenuList.push({
        id: 0,
        label: "全部",
        icon: ""
      })
      firstLevelMenuList.push({
        id: -1,
        label: "我的收藏",
        icon: ""
      })
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == this.pid) {
          this.$emit('showFirstLevelMenu', i+2 + '')
        }
        firstLevelMenuList.push({
          id: data[i].id,
          label: data[i].label,
          icon: data[i].icon,
        })
      }
      this.$emit('getList', firstLevelMenuList)
    },
    getSecondLevelMenu() {
      this.elementList.forEach(item => {
        if (item.id == this.pid){
          if (item.children && item.children.length){
            this.secondLevelMenuList.push(...item.children)
          }
        }
      })
      this.getThirdLevelMenu()
    },
    // 点击应用
    handleClickThirdLevelMenu(row) {
      if (row.id === this.selectTLMId){
        return
      }
      this.clearEditor()
      this.form = {};
      this.$refs.form.clearValidate();
      this.messageId = null;
      this.selectTLMId = row.id;
      this.menuId = row.id;
      // 需要将右侧标签页变为创作
      this.activeName = 'create';
      this.currentActiveName = 'create';
      this.getTLMParamConfigure()
    },
    // 获取三级菜单
    getThirdLevelMenu() {
      this.thirdLevelMenuList = [];
      this.secondLevelMenuList.forEach(item => {
        if (item.applicationList && item.applicationList.length) {
          this.thirdLevelMenuList.push(...item.applicationList)
        }
      })
    },
    // 获取三级菜单动态配置
    getTLMParamConfigure() {
      if (this.selectTLMId) {
        this.paramConfigure = [];
        let a = this.thirdLevelMenuList.filter(item => {
          return item.id == this.selectTLMId
        })

        let b = JSON.parse(a[0].paramConfigure);
        this.paramConfigure.push(...b);
        this.setTLMParamConfigureRules();
      }
    },
    // 设置表单校验规则
    setTLMParamConfigureRules() {
      this.rules = {}
      this.paramConfigure.forEach(item => {
        if (item.isRequire && item.isRequire == "1") {
          this.rules[item.id] = [{
            required: true,
            message: `${item.label}"不能为空"`,
            trigger: item.type == "select" ? "change" : "blur"
          }]
        }
      })
    },
    // 点击右侧标签页
    async handleClickTabTool() {
      if (this.activeName === this.currentActiveName){
        return
      }
      this.showEditor = false;
      this.currentActiveName = this.activeName
      if (this.activeName === 'create'){
        this.messageId = null;
      }
      if (this.activeName === 'history' || this.activeName === 'collect'){
        this.historyParam.pageNum = 1;
        this.collectParam.pageNum = 1;
        this.currentPage = 1;
        this.getHistoryList();
      }
    },
    // 对ai进行提问
    submitToAi() {
      this.sseMessage = "";
      // 判断是否登录
      if (!this.isLogin) {
        return this.$router.push({name: 'login'})
      }
      this.btnLoading = true;
      if (this.activeName !== "create"){
        this.activeName = "create"
      }
      this.$nextTick(() => {
        this.$refs.wangEditor.html = "AI生成中..."
      })
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (!this.selectTLMId) {
            this.$message.error("菜单id为空")
            return
          }
          let a = {
            param: this.form,
            menuId: this.selectTLMId
          };
          let that = this;
          const ctrl = new AbortController();
          fetchEventSource(`/api/${website.apiRequestHead}/chat/aiToolTemplateApplication/createSSE`, {
            method: 'POST',
            headers: {
              'Content-Type': 'text/event-stream',
              Authorization: `Basic ${website.Authorization}`,
              'Blade-Auth': `bearer ${getStorage({name: 'token'})}`,
              Connection: 'keep-alive',
              'Cache-Control': 'no-cache',
            },
            openWhenHidden: true,
            signal: ctrl.signal,
            body: JSON.stringify(a),
            onopen(res) {
              that.getUserInfoActions()
              let scrollTimerFlag = false;
              that.timer = setInterval(() => {
                if (that.messageEnd) {
                  that.messageEnd = false;
                  scrollTimerFlag = true;
                  clearInterval(that.timer);
                }
              }, 50)
              let scrollTimer = setInterval(() => {
                if (scrollTimerFlag){
                  scrollTimerFlag = false;
                  clearInterval(scrollTimer);
                  setTimeout(function () {
                    that.$refs.wangEditor.editor.focus();
                    that.$refs.wangEditor.editor.move(that.sseMessage.length);
                    let c = document.getElementsByClassName('w-e-scroll')[0]
                    c.scrollTop = c.scrollHeight
                  },500)
                }
                if (!scrollTimerFlag){
                  that.$nextTick(() => {
                    let c = document.getElementsByClassName('w-e-scroll')[0]
                    c.scrollTop = c.scrollHeight
                  })
                }
              }, 100)
            },
            onmessage(event) {
              let data = JSON.parse(event.data)
              if (event.id === '[DONE]'){
                console.log('结束')
              }
              if (event.id === 'error'){
                that.sseMessage += data.message_content;
                that.setAiReply();
                return;
              }
              if (event.id != '[DONE]' && event.id != 'param' && data.content != undefined){
                that.setMessageId(event.id);
                if (data.content.includes("\n\n")) {
                  data.content = data.content.replace("\n\n", "\n");
                }
                that.sseMessage += data.content;
                that.setAiReply();
              }

            },
            onclose() {
              that.messageEnd = true
              that.setAiReply()
              ctrl.abort()
              that.btnLoading = false
            },
            onerror(error) {
              console.log('error>>>>>>>>>>>>>>>', error)
              that.messageEnd = true
              ctrl.abort()
              that.btnLoading = false
            },
          })
        }
      })
    },
    // ai回复内容文本拼接
    setAiReply() {
      if (this.sseMessage.length % 1 != 0 && !this.messageEnd) {
        return
      }
      this.$refs.wangEditor.html = this.sseMessage;
    },
    // 设置当前对话id
    setMessageId(id){
      if (!this.messageId){
        this.messageId = id;
      }
    },
    // ai创作生成后用户可进行修改并且保存
    handleClickSaveCreate(){
      if (!this.messageId){
        this.$message.warning("请使用AI创作生成内容后，再进行保存操作")
        return
      }
      let content = this.$refs.wangEditor.editor.getHtml();
      this.saveChatLogMessageFun(content)
    },
    saveChatLogMessageFun(content){
      let a = {
        id: this.messageId,
        messageA: content
      }
      this.btnLoading = true;
      updateChatLogMessageFun(a).then(res => {
        this.$message.success("保存成功")
      }).catch(res => {
        this.$message.error("保存失败")
      }).finally(() => {
        this.btnLoading = false;
      })
    },
    // 获取历史消息记录
    getHistoryList() {
      let param = {}
      if (this.activeName === 'history'){
        this.historyParam.funDataId = this.selectTLMId
        param = this.historyParam
      }
      if (this.activeName === 'collect'){
        this.collectParam.funDataId = this.selectTLMId
        param = this.collectParam
      }
      this.loading = true
      listUserChatLogMessageFun(param).then(res => {
        this.historyList = [];
        this.historyList.push(...res.rows);
        this.historyListTotal = res.total;
        if (this.historyList && this.historyList.length){
          this.messageId = this.historyList[0].id
          if (this.historyList[0].storeStatus === 0){
            this.isCollect = false
          }
          if (this.historyList[0].storeStatus === 1){
            this.isCollect = true
          }
          this.$nextTick(() => {
            document.getElementById("aiCreateHistory").innerHTML = this.historyList[0].messageA
          })
        }

      }).finally(() => {
        this.loading = false
      })
    },
    //
    handleSizeChange(){

    },
    // 清空编辑器
    clearEditor() {
      if (this.$refs.wangEditor && this.$refs.wangEditor.editor){
        this.$refs.wangEditor.editor.clear();
      }
    },

    // 历史记录相关
    // 翻页
    handleCurrentChange(val){
      if (this.activeName === 'history'){
        this.historyParam.pageNum = val
      }
      if (this.activeName === 'collect'){
        this.collectParam.pageNum = val
      }
      this.getHistoryList()
    },
    // 点击复制
    handleClickCopyHistory(){
      let copyText = "";
      if (this.showEditor){
        copyText = this.$refs.wangEditorHistory.editor.getHtml();
      }
      if (!this.showEditor){
        copyText = this.historyList[0].messageA
      }
      navigator.clipboard.writeText(copyText).then(() => {
        this.$message.success("复制成功");
      })
    },
    // 点击编辑
    handleClickEditHistory(){
      if (this.showEditor){
        return
      }
      this.showEditor = true;
      this.$nextTick(() => {
        this.$refs.wangEditorHistory.html = this.historyList[0].messageA;
        // this.$refs.wangEditorHistory.editor.focus();
      })
    },
    // 点击保存
    async handleClickSaveHistory(){
      if (!this.messageId){
        this.$message.error("系统异常")
      }
      let content = this.$refs.wangEditorHistory.editor.getHtml();
      await this.saveChatLogMessageFun(content);
      await this.getHistoryList()
      this.$nextTick(() => {
        this.showEditor = false;
      })
    },
    // 点击取消
    handleClickCancelHistory(){
      if (!this.showEditor){
        return
      }
      this.showEditor = false
    },
    // 点击收藏/取消收藏
    handleClickCollect(){
      if (!this.messageId){
        this.$message.warning("记录id为空!")
        return
      }
      changeCollectStatus(this.messageId).then(res => {
        this.$message.success(res.msg);
        this.getHistoryList()
      })
    },

    // 收藏相关


  },
}
</script>

<style lang="scss" scoped>
.main {
  background: #f0f2f5;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .content {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    font-size: 14px;

    .content-card {
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      width: 100%;
      height: 100%;

      .content-card-common {
        height: 100%;
        position: relative;

        .plate-title {
          padding: 10px 16px;
          font-weight: bold;
          border-bottom: 1px solid #f5f5f5;
        }
      }

      .content-card-left {
        width: 20%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f5f5f5;

        .left-scrollbar {
          height: 100%;
        }

        .left-content {
          overflow: hidden auto;
          padding: 10px 16px;

          .content-item {
            margin-bottom: 10px;

            .item-second-level {
              margin-bottom: 10px;
            }

            .item-third-level {
              background-color: #fff;
              border-radius: 12px;
              border: 2px solid #f1f1f1;
              padding: 10px 16px;
              margin-bottom: 10px;
              display: flex;
              cursor: pointer;

              .third-level-img {
                width: 32px;
                height: 32px;
                border-radius: 4px;
                margin-right: 10px;
              }

              .third-level-font {
                flex: 1;

                .font-label {
                  font-weight: 600;
                }

                .font-introduce {
                  color: #666;
                  font-size: 12px;
                  margin-top: 5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }

            .item-third-level-select {
              background-color: #f3f0ff;
              border-color: #5a54f9;
            }
          }
        }

      }

      .content-card-middle {
        width: 30%;
        display: flex;
        flex-direction: column;

        .middle-scrollbar {
          height: calc(100% - 90px);
        }

        .middle-content {
          padding: 10px 16px 0 16px;

          .content-form {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum';

            .form-label {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }


          }
        }

        .middle-submit-wrap {
          height: 90px;
          padding: 0 20px 20px 20px;
          background-color: #fff;
          border-top: 1px solid #f5f5f5;
          border-radius: 0 0 6px 0;

        }

        .middle-submit {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .submit-button-wrap {
            width: 100%;
            height: 40px;

            .submit-button {
              font-size: 16px;
              width: 100%;
              border-radius: 20px;
              color: #fff;
              background: #7A46FC;
              border-color: #7A46FC;
              text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
              box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            }

            .submit-button:hover, .submit-button:focus {
              color: #fff;
              background: #9d70ff;
              border-color: #9d70ff;
            }

            .submit-tip {
              position: absolute;
              bottom: -8px;
              color: #999;
              font-size: 12px;
              cursor: pointer;
            }

            .submit-tip:hover {
              color: #7A46FC;
            }
          }

        }
      }

      .content-card-right {
        width: 50%;
        background-color: rgb(245, 245, 245);
        border-left: 1px solid rgb(245, 245, 245);

        .right-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: rgb(255, 255, 255);
          padding: 0 5px;

          .top-tool {
            width: 100%;
          }

          .top-btn {
            color: #7A46FC;
            background: transparent;
            border-color: transparent;
            box-shadow: none;
            padding: 0 15px;
            font-size: 14px;
            cursor: pointer;
          }
        }

        .right-content {
          height: 100%;
        }

        .right-content-create {
          height: calc(100% - 41px);
        }

        .right-content-collect-wrap{
          padding: 10px 0 10px 10px;
          height: calc(100% - 61px);
        }

        .right-content-history-wrap{
          padding: 10px 0 10px 10px;
          height: calc(100% - 61px);

          .right-content-history-card{
            margin-bottom: 15px;
            height: calc(100% - 61px);
            background: white;
            box-sizing: border-box;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum';
            position: relative;
            border-radius: 5px;
            transition: all 0.3s;

            .right-scrollbar {
              height: calc(100% - 48px);
            }

            .card-content-wrap{
              padding: 24px;

              .content-title{
                color: #00000073;
              }

              .content-introduce{
                margin-top: 20px;
                margin-bottom: 15px;
              }
            }

            .content-tool{
              padding: 0 24px 24px 24px;
              align-items: center;
              display: inline-flex;
              gap: 20px;

              .content-tool-button{
                height: 24px;
                padding: 0 12px;
                font-size: 14px;
                border-radius: 24px;
                line-height: 1.5715;
                position: relative;
                display: inline-block;
                font-weight: 400;
                white-space: nowrap;
                text-align: center;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                cursor: pointer;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                touch-action: manipulation;
                color: rgba(0, 0, 0, 0.85);
                background: #fff;
                border: 1px solid #d9d9d9;
              }

              .content-tool-button:hover{
                color: #9d70ff;
                background: #fff;
                border-color: #9d70ff;
                text-decoration: none;
              }

              .button-primary{
                color: #fff;
                background: #7A46FC;
                border-color: #7A46FC;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
              }

              .button-primary:hover,
              .button-primary:focus{
                color: #fff;
                background: #9d70ff;
                border-color: #9d70ff;
              }

            }


          }

          .right-content-pagination{
            padding: 10px 0;

            /deep/ .el-pagination.is-background .btn-next,
            /deep/ .el-pagination.is-background .btn-prev,
            /deep/ .el-pagination.is-background .el-pager li{
              color: rgba(0, 0, 0, 0.25);
              border: 1px solid rgba(0, 0, 0, 0.25);
              background: white;
            }
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
              color: #7A46FC;
              border-color: #7A46FC;
            }
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
              color: #9d70ff;
              border-color: #9d70ff;
            }
            /deep/ .el-pagination.is-background .btn-next:not(.disabled):hover,
            /deep/ .el-pagination.is-background .btn-prev:not(.disabled):hover{
              color: #9d70ff;
              border-color: #9d70ff;
            }

          }
        }
      }

    }

  }

}

.el-form-item {
  margin-bottom: 24px;
}
</style>
