import {
	setStorage,
	getStorage,
	removeStorage
} from '@/utils/storage'
import { createStyle, query, removeHeadStyle, convert, createDIV } from '@/utils/hook'
import { getFontFamily, getPrimaryBGColor, getPrimaryColor } from '@/templates/config'

const get = getStorage,
  set = setStorage
export const CUSTOM_CSS_STYLE = 'custom-css-style',
  CUSTOM_MARKDOWN_PRIMARY_COLOR = 'custom-markdown-primary-color',
  CUSTOM_MARKDOWN_PRIMARY_BG_COLOR = 'custom_markdown_primary_bg_color',
  MARKDOWN_FONT = 'markdown-font',
  ADJUST_RESUME_MARGIN_TOP = 'ADJUST_RESUME_MARGIN_TOP',
  AUTO_ONE_PAGE = 'auto-one-page',
  WHITE_SPACE = 'white-space',
  LINE_HEIGHT = 'Line_Height',
  A4_HEIGHT = 1123,
  SELF_HEIGHT = -1234

export const tabbarMixin = {
	data() {
		return {
			renderCV: null,
			step: 90,
			scaleFactor: 1, //初始缩放因子
			pageSize: 1
		}
	},
	watch: {
		step(newVal, oldVal) {
			if(newVal === oldVal) return
			// if(oldVal > newVal) { //缩小
			// 	this.scaleFactor -= 0.1
			// }else{
			// 	this.scaleFactor += 0.1
			// }
			document.querySelector('.re-render').style.transform = `scale(${newVal/100})`;
		}
	},
	methods: {
		setStep(val) {
			this.step = val
		}
	}
}


function queryRenderCV() {
  return document.querySelector('.reference-dom')
}

export const useAvatarMixin = {
	methods: {
		async setAvatar(event) {
			const file = event.target.files[0]
			const reader = new FileReader()
			reader.readAsDataURL(file) // 暂时用base64处理 后期换cdn
			reader.onload = (event) => {
			  this.$emit('upload-avatar', event.target?.result)
			}
		}
	}
}

export const useCustomCSSMixin = {
	data() {
		return {
			cssDialog: false,
			customCSSCacheKey: CUSTOM_CSS_STYLE + '-' + this.resumeType,
			cssText: get(this.customCSSCacheKey) ? get(this.customCSSCacheKey) : ''
		}
	},
	methods: {
		toggleDialog() {
		  this.cssDialog = !this.cssDialog
		},
		setStyle() {
			this.cssDialog = false
			let style = query(this.customCSSCacheKey)

			const cssValue = this.cssText.trim(),
			  isAppend = style
			if (!this.cssText) {
			  return
			}
			if (!style) {
			  style = createStyle()
			  style.setAttribute(this.customCSSCacheKey, 'true')
			}
			style.textContent = cssValue
			!isAppend && document.head?.appendChild(style)
			set(this.customCSSCacheKey, cssValue)
		},
		removeStyle() {
			this.cssDialog = false
			removeHeadStyle(this.customCSSCacheKey)
			removeStorage(this.customCSSCacheKey)
			this.cssText = ''
		}
	},
	activated() {
		!query(this.customCSSCacheKey) && setTimeout(this.setStyle(), 50)
	}
}

export const usePrimaryBGColorMixin = {
	data() {
		return {
			primaryBGColorCacheKey: '',
			initialColor: '',
			primaryColor: ''
		}
	},
	methods: {
		setPrimaryColor(color) {
			if (!color) {
			  this.primaryColor = this.initialColor
			  color = this.initialColor
			}
			let style = query(this.primaryBGColorCacheKey)
			const append = style
			if (!style) {
			  style = createStyle()
			  style.setAttribute(this.primaryBGColorCacheKey, 'true')
			}
			style.textContent = `:root { --markdown-primary-bg-color: ${color}; }`
			!append && document.head.appendChild(style)
			set(this.primaryBGColorCacheKey, color)
		}
	},
	mounted() {
		this.primaryBGColorCacheKey = CUSTOM_MARKDOWN_PRIMARY_BG_COLOR + '-' + this.resumeType
		this.initialColor = getPrimaryBGColor(this.resumeType)
		this.primaryColor = get(this.primaryBGColorCacheKey) ? get(this.primaryBGColorCacheKey) : this.initialColor
		!query(this.primaryBGColorCacheKey) && this.setPrimaryColor(this.primaryColor)
	}
}


// todo: 回跳后颜色显示的问题，后续考虑接入后端解决
export const usePrimaryColorMixin = {
	data() {
		return {
			primaryColorCachekey: '',
			initialColor: '',
			color: get(this.primaryColorCachekey) ? get(this.primaryColorCachekey) : this.initialColor
		}
	},
	methods: {
		setColor(value) {
		  if (!value) {
		    this.color = this.initialColor
		    value = this.initialColor
		  }
		  let styleDOM = query(this.primaryColorCachekey)
		  const isAppend = styleDOM

		  if (!styleDOM) {
		    styleDOM = createStyle()
		    styleDOM.setAttribute(this.primaryColorCachekey, 'true')
		  }
		  styleDOM.textContent = `:root { --markdown-primary-color: ${value} }`
		  !isAppend && document.head.appendChild(styleDOM)
		  set(this.primaryColorCachekey, value)
		}
	},
	mounted() {
		this.primaryColorCachekey = CUSTOM_MARKDOWN_PRIMARY_COLOR + '-' + this.resumeType
		this.initialColor = getPrimaryColor(this.resumeType)
		this.color = get(this.primaryColorCachekey) ? get(this.primaryColorCachekey) : this.initialColor
		!query(this.primaryColorCachekey) && this.setColor(this.color)
	}
}

// 自定义字体
export const useCustomFontMixin = {
	data() {
		return {
			customFontCacheKey: '',
			fontOptions: [
				{ value: 'Noto Serif SC', label: 'Noto Serif SC' },
				{ value: 'Noto Sans SC', label: 'Noto Sans SC' },
				{ value: 'Nunito', label: 'Nunito(英文)' },
				{ value: 'KaiTi', label: '楷体' },
				{ value: 'SimSuncss', label: '宋体' },
				{ value: 'NSimSun', label: '新宋体'},
				{ value: 'STSong', label: '华文宋体'},
			],
			font: null
		}
	},
	methods: {
		setFont(fontFamily, first) {
		  let style = query(this.customFontCacheKey)
		  const isAppend = style
		  if (!style) {
		    style = createStyle()
		    style.setAttribute(this.customFontCacheKey, 'true')
		  }

		  style.textContent = `.jufe * { font-family: ${fontFamily}, 'Noto Sans SC', 'Noto Serif SC', 'Nunito', sans-serif, serif; }`
		  !isAppend && document.head.appendChild(style)
		  set(this.customFontCacheKey, fontFamily)
		  const renderCV = queryRenderCV()
		  ensureEmptyPreWhiteSpace(renderCV)
		  !first && this.splitPage(renderCV)
		}
	},
	mounted() {
		this.customFontCacheKey = MARKDOWN_FONT + '-' + this.resumeType
		this.font = get(this.customFontCacheKey) ? get(this.customFontCacheKey) : getFontFamily(this.resumeType) || this.fontOptions[0]
		this.setFont(this.font, true)
	}
}

/* 一键重置 */
export function restResumeContent(resumeType) {
	removeStorage({name: `${CUSTOM_CSS_STYLE}-${resumeType}`})
	removeStorage({name: `${CUSTOM_MARKDOWN_PRIMARY_COLOR}-${resumeType}`})
	removeStorage({name: `${CUSTOM_MARKDOWN_PRIMARY_BG_COLOR}-${resumeType}`})
	removeStorage({name: `${MARKDOWN_FONT}-${resumeType}`})
	removeStorage({name: `${AUTO_ONE_PAGE}-${resumeType}`})
	removeStorage({name: `${ADJUST_RESUME_MARGIN_TOP}-${resumeType}`})
	removeStorage({name: `${LINE_HEIGHT}-${resumeType}`})
	removeStorage({name: `markdown-content-${resumeType}`})
  location.reload()
}

// 调节元素边距
export const useAdjustMixin = {
	data() {
		return {
			visible: false,
			properties: [],
			adjustCacheKey: ADJUST_RESUME_MARGIN_TOP + '-' + this.resumeType
		}
	},
	methods: {
		getProperties(element) {
			const curProperties = []
			const seenTags = new Set()
			const seenClassNames = new Set()

			function helper(el) {
			  if (el !== element) {
				const computedStyle = window.getComputedStyle(el) // 获取计算后的样式
				const marginTop = parseInt(computedStyle.marginTop) // 获取 marginTop 值
				const marginBottom = parseInt(computedStyle.marginBottom)
				const tagName = el.tagName.toLowerCase() // 获取标签名，转换为小写
				const className = el.className.split(' ')[0] || '' // 获取类名，如果没有则用 'No Class' 代替
				const name = convert(className || tagName)

				// 判断标签名和类名是否已经处理过，如果没有，则将其加入结果数组，并添加到 seenTags 和 seenClassNames 集合中
				if (!seenTags.has(tagName) || !seenClassNames.has(className)) {
				  curProperties.push({ tagName, name, marginBottom, marginTop, className })
				  seenTags.add(tagName)
				  seenClassNames.add(className)
				}
			  }
			  // 遍历当前元素的所有子节点，并递归调用该函数
			  const children = el.children
			  for (let i = 0; i < children.length; i++) helper(children[i])
			}

			helper(element) // 调用递归函数开始获取 marginTop 和 lineHeight 值
			return curProperties
		},
		adjustMargin() {
		  this.setVisible()
		  // 获取dom元素
		  const targetElement = queryRenderCV()
		  const curProperties = this.getProperties(targetElement)
		  this.properties.length = 0
		  this.properties.push(...curProperties)
		},
		confirmAdjustment() {
		  this.setVisible()
		  let styleDOM = query(this.adjustCacheKey),
		    cssText = ''
		  const isAppend = styleDOM

		  if (!styleDOM) {
		    styleDOM = createStyle()
		    styleDOM.setAttribute(this.adjustCacheKey, 'true')
		  }
		  for (const property of this.properties) {
		    const target = property.className ? `.${property.className}` : property.tagName
		    cssText += `.jufe ${target} {margin-top: ${property.marginTop}px!important; margin-bottom: ${property.marginBottom}px!important;}`
		  }
		  styleDOM.textContent = cssText
		  this.priorityInsert(isAppend, styleDOM)
		  set(this.adjustCacheKey, cssText)
		  const renderCV = queryRenderCV()
		  this.ensureEmptyPreWhiteSpace(renderCV)
		  this.splitPage(renderCV)
		},
		priorityInsert(isAppend, styleDOM) {
		  if (!isAppend) {
		    // 插入到自动一页css前面 因为调整的优先级是最低的
		    const autoOnePage = query(AUTO_ONE_PAGE + '-' + this.resumeType)
		    const customCSS = query(CUSTOM_CSS_STYLE + '-' + this.resumeType)
		    if (autoOnePage || customCSS) {
		      const siblingStyle = autoOnePage || customCSS
		      document.head.insertBefore(styleDOM, siblingStyle)
		    } else {
		      // 如果没有的话直接追加到head中
		      document.head.appendChild(styleDOM)
		    }
		  }
		},
		setVisible() {
		  this.visible = !this.visible
		},
		// 进入页面读取历史样式 并初始化CSS
		initAdjustCSS() {
		  const adjustCSS = get(this.adjustCacheKey) || ''
		  if (!adjustCSS) return
		  let styleDOM = query(this.adjustCacheKey)
		  const isAppend = styleDOM
		  if (!styleDOM) {
		    styleDOM = createStyle()
		    styleDOM.setAttribute(this.adjustCacheKey, 'true')
		  }
		  styleDOM.textContent = adjustCSS
		  this.priorityInsert(isAppend, styleDOM)
		}
	},
	activated() {
		!query(this.adjustCacheKey) && this.initAdjustCSS()
	}
}

// 跟随滚动
export const useFollowRollMixin = {
	data() {
		return {
			followRoll: false
		}
	},
	methods: {
		scrollHandler() {
			if (!this.followRoll) return null
			const wem = document.querySelector('.writable-edit-mode')
			const cs = document.querySelector('.cm-scroller')
			const render = document.querySelector('.markdown-render')
			// const reallRenderHeight = document.querySelector('.jufe') as HTMLElement

			cs?.addEventListener('scroll', this.cscb())
			wem?.addEventListener('scroll', this.wemcb())

			return () => {
			  wem?.removeEventListener('scroll', this.wemcb())
			  cs?.removeEventListener('scroll', this.cscb())
			}
		},
		wemcb(render,wem) {
		  if (this.followRoll) {
		    render.scrollTop = render.scrollHeight * (wem.scrollTop / wem.scrollHeight)
		  }
		},
		cscb(render, cs) {
		  if (this.followRoll) {
		    render.scrollTop = render.scrollHeight * (cs.scrollTop / cs.scrollHeight)
		  }
		},
		setFollowRoll() {
		  this.destory && this.destory()
		  this.destory = this.scrollHandler()
		}
	},
	mounted() {
		this.setFollowRoll()
	}
}

// 分割视图
export const splitPageMixin = {
  methods: {
	  splitPage(renderCV) {
		  this.$nextTick(() => {
			  let page = 0, realHeight = 0
			  const target = renderCV.clientHeight, reRender = document.querySelector('.re-render')
			  reRender.innerHTML = ''
			  while (target - realHeight > 0) {
			    const wrapper = createDIV(),
			      resumeNode = renderCV.cloneNode(true)
			    wrapper.classList.add('jufe-wrapper-page')
			    // 创建里面的内容 最小化高度
			    const realRenderHeight = Math.min(target - realHeight, A4_HEIGHT)
			    const wrapperItem = createDIV()
			    wrapperItem.classList.add('jufe-wrapper-page-item')
			    wrapperItem.style.height = realRenderHeight + 'px'
			    resumeNode.style.position = 'absolute'
			    resumeNode.style.top = -page * A4_HEIGHT + 'px'
			    resumeNode.style.left = 0 + 'px'

			    wrapperItem.appendChild(resumeNode)
			    wrapper.appendChild(wrapperItem)

			    realHeight += A4_HEIGHT
			    page++
			    reRender?.appendChild(wrapper)
			  }
			  this.pageSize = page
			  
		  })
	  }
  }
}

// 确保处理之前将之前的空元素删除 否则在多页情况下多次调用会多次生成空白占位符
export function ensureEmptyPreWhiteSpace(renderCV) {
	  const children = Array.from(renderCV.children)
	  for (const child of children) {
		if (child.getAttribute(WHITE_SPACE)) renderCV.removeChild(child)
		else {
		  ensureEmptyPreWhiteSpace(child)
		}
	  }
}
