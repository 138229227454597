import request from "@/api/request";
import website from "@/config/website";

/**
 * 获取用户聊天列表
 */
export function getChatListByUser(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/list/listByUser`,
        method: 'get',
    })
}

/**
 * 获取用户聊天列表
 */
export function delChatListById(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/list/${id}`,
        method: 'delete',
    })
}

/**
 * 获取聊天列表对应消息记录
 */
export function getChatLogMessageList(query){
    return request({
        url: `/api/${website.apiRequestHead}/chat/logMessage/getWebList`,
        method: 'get',
        params: query
    })
}
