
import request from "@/api/request";
import website from "@/config/website";

// 修改聊天记录 前端主要用于用户保存功能
export const updateChatLogMessageFun = (data) => {
    return request({
        url: `/api/${website.apiRequestHead}/chat/log/message/fun/update`,
        method: 'put',
        data: data
    })
}

// // 查询聊天记录列表
// export const listUserChatLogMessageFun = (data) => {
//     return request({
//         url: `/api/${website.apiRequestHead}/chat/log/message/fun/list/user`,
//         method: 'get',
//         param: data
//     })
// }

export function listUserChatLogMessageFun(query) {
    return request({
        url: `/api/${website.apiRequestHead}/chat/log/message/fun/list/user`,
        method: 'get',
        params: query
    })
}

export function changeCollectStatus(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/log/message/fun/collect/changeStatus?id=${id}`,
        method: 'get',
    })
}
