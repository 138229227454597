<template>
  <el-container class="chat" style="height: 100%">
    <el-aside class="el-aside" width="200px">
      <el-menu class="el-menu left-menu" :default-active=firstLevelMenuIndex>
        <el-menu-item v-for="(item, i) in firstLevelMenuList"
                      :key="i"
                      :index="item.name"
                      :id="item.id"
                      @click="handleClickFirstLevelMenu(item)">
          <template slot="title">
            <div class="left-menu-item">
              <div style="display: flex;align-items: center">
                <div style="color: #7A46FC;display: flex;align-items: center">
                  <svg-icon :icon-class="item.icon" :style="'font-size: '+ item.size + 'px'"
                            v-if="item.size !== 0"></svg-icon>
                  <el-image :src="item.icon" style="height: 20px;width: 20px" v-if="item.size === 0"></el-image>
                </div>
                <span style="margin-left: 10px">{{ item.label }}</span>
              </div>
            </div>
          </template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main style="padding: 0">
      <router-view @talkChild="talkChild"></router-view>
    </el-main>
  </el-container>
</template>

<script>
import {treeListAiCreateTool} from "@/api/chat/aiCreateTool";

export default {
  name: "talk",
  data() {
    return {
      // id随便填不重复即可
      firstLevelMenuList: [
        {
          id: "menu-index",
          name: "talk-index",
          icon: "chat",
          label: "AI聊天对话",
          size: 20,
          isDivider: false,
        },
        {
          id: "menu-create-tool",
          name: "talk-create-tool",
          icon: "createTool",
          label: "AI创作工具",
          size: 20,
          isDivider: false,
        },
        {
          id: "menu-prompt",
          name: "talk-prompt",
          icon: "promptNav",
          label: "AI提示词",
          size: 20,
          isDivider: true,
        },
      ],
      firstLevelMenuIndex: "talk-index",
    }
  },
  mounted() {
    this.$emit("childClick", '/index/talk/talk-index');
    this.getFirstAiCreateTool();
  },
  methods: {
    handleClickFirstLevelMenu(item) {
      if (item.icon === "application") {
        this.$message.warning("该功能即将上线!")
        this.firstLevelMenuIndex = "talk-index2";
        return
      }
      if (item.name.includes("talk-create-tool-c")) {
        this.$router.push({
          path: "talk-create-tool", query: {
            c: item.id.replace("talk-create-tool-c","")
          }
        });
        return;
      }
      this.$router.push({name: item.name});
    },
    talkChild(name) {
      this.firstLevelMenuIndex = name;
      this.$emit("childClick", '/index/talk/talk-index');
    },
    getFirstAiCreateTool() {
      treeListAiCreateTool({
        pid: 0
      }).then(res => {
        let a = res.data;
        a.forEach(item => {
          this.firstLevelMenuList.push({
            id: "talk-create-tool-c" + item.id,
            name: "talk-create-tool-c" + item.id,
            icon: item.icon,
            label: item.label,
            size: 0,
            isDivider: item.id === a[a.length - 1].id,
          })
        })
        this.$nextTick(() => {
          this.firstLevelMenuList.forEach(item => {
            if (item.isDivider) {
              let elementById = document.getElementById(item.id);
              let divider = document.createElement('div');
              divider.className = "divider"
              elementById.parentNode.insertBefore(divider, elementById.nextSibling);
            }
          })
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
