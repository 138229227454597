<template>
  <div class="chat">
    <el-scrollbar style="height: 100%;width: 100%" ref="scrollbar">
      <div class="chat-drawing-index chat-drawing">
        <div class="drawing-index-top">
          <div class="top-title">
            <span>浩通</span>
            <div class="text">AI</div>
            <span>绘画</span>
          </div>
          <div class="sub-top-title">一张图，一句话 ，AI灵感绘图，创造无限可能</div>
          <div class="top-search">
            <el-input placeholder="搜索全站作品" v-model="search"></el-input>
          </div>
          <div class="top-btn">
            <div class="btn btn-1">开始制作</div>
            <div class="btn btn-2">探索AI新人必进</div>
          </div>
        </div>
        <div class="drawing-index-tags">
          <div class="tags-hot-scenes-title">
            <div class="flex-items-center" style="font-size: 23px">
              <svg-icon icon-class="hotScenes" />
              <div class="title">热门场景</div>
            </div>
            <div class="flex-items-center" style="font-size: 14px;cursor: pointer;color: #999999;" @click="$router.push({path:'/index/drawing/drawing-classify'})">
              <div>查看更多</div>
              <svg-icon icon-class="right" />
            </div>
          </div>
          <div class="tags-hot-scenes-list">
            <el-row :gutter="15">
              <el-col v-for="(item,i) in hotTagsList" :key="i" :span="3">
                <div class="list-item">
                  <el-image :src="item.img" class="image" fit="cover">
                    <div slot="error" class="image-slot">
                      <el-image :src="require('@/static/img/error.png')"></el-image>
                    </div>
                  </el-image>
                  <div class="mask">{{item.name}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="tags-hot-scenes-title">
            <div class="flex-items-center" style="font-size: 23px">
              <svg-icon icon-class="hotModel" />
              <div class="title">热门模型</div>
            </div>
            <div class="flex-items-center" style="font-size: 14px;cursor: pointer;color: #999999;" @click="$router.push({path:'/index/drawing/drawing-classify'})">
              <div>查看更多</div>
              <svg-icon icon-class="right" />
            </div>
          </div>
          <div class="tags-hot-name-list">
            <div v-for="(item,i) in hotTagsList " :key='i'>
              <div class="list-item" @click="handleQuery(item.name)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="drawing-waterfall">
          <el-row v-masonry :gutter="10" >
            <el-col :span="4" v-masonry-tile  v-for="(item, index) in imageList" :key="index">
              <div class="card">
                <el-image :src="item.url1" fit="cover" class="image"/>
                <div class="mask"></div>
                <div class="mask-text-wrap">
                  <div class="mask-text">
                    {{item.description}}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {getHotTags, showImage} from "@/api/chat/aiDrawingImage";

export default {
  name: "drawing-index",
  data() {
    return {
      search: "",
      hotTagsList: [],
      imageList: [],
      queryParam: {
        tagsName: undefined,
      },
    }
  },
  mounted() {
    window.addEventListener("scroll", this.refresh, true)
    this.getHotTagsList();
    this.getImageList();
  },
  methods: {
    getImageList(){
      showImage(this.queryParam).then(res => {
        this.imageList = res.data;
      })
    },
    getHotTagsList(){
      getHotTags().then(res => {
        this.hotTagsList = res.data;
      })
    },
    handleQuery(val){

    },
    refresh(){
      this.$redrawVueMasonry();
    },
  },

}
</script>

<style scoped>

</style>
