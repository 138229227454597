<template>
  <div class="chat" style="background: #f0f2f5">
    <div class="chat-prompt-warp">
      <el-scrollbar style="height: 100%;width: 100%">
        <div class="prompt-page-center">
          <div class="page-title">发现最好用的Chat AI提示词</div>
          <div class="page-sub-title"> 最佳的Chat AI提示词集合,按类别、专业或用例查找最佳的Chat AI提示。</div>
          <div class="page-search-btn-tool">
            <el-button class="ant-btn ant-btn-primary ant-btn-lg" @click="$router.push({name:'talk-prompt-detail'})">发现提示词</el-button>
            <el-button class="ant-btn ant-btn-lg" @click="openSearch = true">搜索提示词</el-button>
          </div>
          <div class="page-prompt-list-wrap">
            <div class="list-group" v-for="(group,i) in aiTalkPromptGroupList" :key="i">
              <div class="group-name">{{ group.label }}</div>
              <el-row class="group-list">
                <el-col :span="6" v-for="(item,j) in group.children" :key="j">
                  <div class="group-list-item" @click="gotoDetail(group.id === 2 ? group.id : 1 ,item.id)">
                    <div class="item-label">{{ item.label }}</div>
                    <div class="item-info">{{ item.info }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog class="chat-search-dialog"
               center
               :append-to-body="true"
               :close-on-click-modal="false"
               title="搜索关键词"
               :visible.sync="openSearch"
               width="40%">
      <div style="margin: 20px 0 10px 0">
        <el-input v-model="search" placeholder="请输入要搜索的标题" @input="getTemplateList(search)"></el-input>
      </div>
      <el-scrollbar ref="scroll" style="height: 500px;width: calc(100% + 15px)">
        <div class="list-wrap" v-infinite-scroll="getCatchTemplateList" :infinite-scroll-immediate="isDisabledScroll">
          <div class="list-item" v-for="item in catchTemplateList" :key="item.id"
               @click="$router.push({name:'talk-prompt-detail',query:{f:'1',s:''+item.templatePId,t:''+item.id}})">
            <div class="list-item-title">{{item.title}}</div>
            <div class="list-item-content">{{item.content}}</div>
          </div>
          <div class="tip" v-if="isDisabledScroll">没有更多了...</div>
        </div>
      </el-scrollbar>


    </el-dialog>
  </div>
</template>

<script>
import {treeListAiTalkPromptTemplateGroup, treeListAllElementByPid} from "@/api/chat/aiTalkPromptTemplate";

export default {
  name: "talk-prompt",
  data() {
    return {
      aiTalkPromptGroupList: [],
      openSearch: false,
      search: undefined,
      treeList: [],
      allTemplateList: [],
      templateList: [],
      pageNum: 1,
      pageSize: 10,
      catchTemplateList: [],
      isDisabledScroll: false,
    }
  },
  mounted() {
    this.getAiTalkPromptGroupList();
    this.getAllElementByPid(0);
    this.$emit('talkChild','talk-prompt');
  },
  methods: {
    getAiTalkPromptGroupList() {
      treeListAiTalkPromptTemplateGroup().then(res => {
        res.data.forEach(item => {
          if (item.id === 1) {
            this.aiTalkPromptGroupList.push(...item.children)
            return
          }
          this.aiTalkPromptGroupList.push(item)
        })

      })
    },
    getAllElementByPid(pid){
      treeListAllElementByPid(pid).then(res => {
        this.treeList = res.data;
        this.getAllTemplateList();
      })
    },
    getAllTemplateList(){
      this.allTemplateList = [];
      this.treeList[0].children.forEach(i => {
        i.children.forEach(j => {
          if (j.templateList && j.templateList.length){
            j.templateList.forEach(k => {
              k.templatePId = j.id
              this.allTemplateList.push(k)
            })
          }
        })
      })
      this.getTemplateList()
    },
    getTemplateList(val){
      this.templateList = [];
      this.catchTemplateList = [];
      this.pageNum = 1;
      if (!val){
        this.templateList.push(...this.allTemplateList)
      }
      if (val){
        let a = this.allTemplateList.filter(item => {
          return item.title.includes(val)
        });
        this.templateList.push(...a)
      }
      this.getCatchTemplateList()
    },
    getCatchTemplateList(){
      if ((this.pageNum - 1) * this.pageSize >= this.templateList.length){
        this.isDisabledScroll = true;
        return
      }
      let a = [];
      a.push(...this.templateList);
      let start = (this.pageNum++ - 1) * this.pageSize
      this.isDisabledScroll = a.length < start + this.pageSize;
      this.catchTemplateList.push(...a.splice(start,this.pageSize))
      this.$nextTick(() => {
        if (this.$refs.scroll){
          this.$refs.scroll.update()
        }
      })
      console.log(this.catchTemplateList,333)
    },
    gotoDetail(i, j) {
      this.$router.push({
        name: 'talk-prompt-detail',
        query: {
          f: i,
          s: j
        },
      })
    }
  },
}
</script>

<style scoped>

</style>
