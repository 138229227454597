<template>
  <div class="vip">
    <el-dialog
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
      :center="true"
    >
      <div class="vip-box">
        <div class="vip-box_left">
          <div class="left_title">支付方式</div>
          <div
            class="teg payStyle"
            :class="payIndex === i ? 'paymode' : ''"
            v-for="(item, i) in payModeList"
            :key="i"
            @click="payMode(i, item.mode)"
          >
            <span class="iconfont" :class="item.icon" style="font-size:32px;margin-right:6px;"></span>
            {{ item.text }}
          </div>
          <el-divider></el-divider>
          <div class="teg" style="background:#960a0f;color:#fff;" @click="payMoney">立即解锁</div>
          <div class="teg" style="background:#fff;" @click="dialogVisible = false">取消</div>
        </div>
        <div class="vip-box_right">
          <div class="mealStyle">
            <div class="left_title" style="color:#000;">会员套餐</div>
            <div class="mealStyle_list">
              <div
                class="mealStyle_list-item"
                :style="vipIndex === index && type === 'vip' ? 'border-color: #9d000b;' : ''"
                v-for="(item, index) in vipGoodsList"
                :key="index"
                @click="changeVip(index)"
              >
                <div class="taocan">{{ item.title }}</div>
                <div
                  :style="vipIndex === index && type === 'vip' ? 'color: #9d000b;' : ''"
                  class="sjjg"
                >
                  ￥
                  <div class="sjjg-num">{{ item.price }}</div>
                </div>
                <div style="font-size: 12px;color: #9d010a;">燃料+{{ item.rl_num }}</div>
                <div class="ckjg">
                  ￥{{ item.cost_price }}
                  <div class="yi"></div>
                </div>
                <div class="meitian">{{ item.remark }}</div>
                <div class="tueijian" v-if="index === 0">最多人购买</div>
              </div>
            </div>
          </div>
          <div style="border-right:1px solid #ccc;height:90%;"></div>
          <div class="mealStyle">
            <div class="left_title" style="color:#000;">燃料套餐</div>
            <div class="mealStyle_list">
              <div
                class="mealStyle_list-item"
                :style="rlIndex === index && type === 'rl' ? 'border-color: #9d000b;' : ''"
                v-for="(item, index) in rlGoodsList"
                :key="index"
                @click="changeRl(index)"
              >
                <div class="taocan">{{ item.title }}</div>
                <div
                  :style="vipIndex === index && type === 'rl' ? 'color: #9d000b;' : ''"
                  class="sjjg"
                >
                  ￥
                  <div class="sjjg-num">{{ item.price }}</div>
                </div>
                <div style="font-size: 12px;color: #9d010a;">燃料+{{ item.rl_num }}</div>
                <div class="ckjg">
                  ￥{{ item.cost_price }}
                  <div class="yi"></div>
                </div>
                <div class="meitian">{{ item.remark }}</div>
                <div class="tueijian" v-if="index === 0">最多人购买</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="codeShow" width="26%" :modal="false" :show-close="false">
      <div
        style="padding:10px 20px 0 0;text-align:right;background:#b1a389;cursor: pointer;"
        @click="cancel"
      >X</div>
      <div class="paycodeStyle">
        <div>请在五分钟之内付款</div>
        <el-image style="width: 200px; height: 200px" :src="payCode"></el-image>
        <div>请使用微信扫一扫</div>
        <div>扫描二维码支付</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="rgShow" width="26%" :modal="false">
      <div class="paycodeStyle">
        <div>请添加客服微信进行人工充值</div>
        <el-image style="width: 200px; height: 200px" :src="rgCode"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVipGoodsListApi, getWxPayStateApi } from '@/api/pay'
import { paymode } from '@/utils/paymode'
// import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
export default {
  name: 'vip',
  data() {
    return {
      codeShow: false,
      payCode: '',
      dialogVisible: false,
      payModeList: [
        { icon: 'icon-weixin', text: '微信支付', mode: 'wx_app' },
        { icon: 'icon-zhifu-zhifubao', text: '支付宝支付', mode: 'zfb' },
        { icon: 'icon-anquanlihui', text: '人工充值', mode: 'rgzf' },
      ],
      payIndex: 0, //人工 || wx
      vipIndex: 0,
      vipGoodsList: [], //会员套餐列表
      rlIndex: 0,
      rlGoodsList: [], //燃料套餐列表
      type: 'vip',
      timer: null, // 查询是否付款
      rgCode: '', //人工支付图片
      rgShow: false,
      sysConfig: store.getters.sysConfig,
      isLogin: store.getters.isLogin,
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.isLogin) {
          this.getVipGoodsList()
        } else {
          this.$router.push({name: 'login'})
        }
      } else {
        this.vipGoodsList = []
        this.rlGoodsList = []
      }
    },
  },
  mounted() {
    this.getVipGoodsList()
  },
  computed: {
    // ...mapGetters(['isLogin', 'sysConfig']),
  },
  methods: {
    // ...mapActions(['getUserInfoActions']),
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    // 获取套餐列表
    async getVipGoodsList() {
      let data = await getVipGoodsListApi()
      if (data.code === 200) {
        data.data.forEach((item) => {
          if (item.type === 'vip') {
            this.vipGoodsList.push(item)
          } else {
            this.rlGoodsList.push(item)
          }
        })
      }
    },
    // 支付方式
    payMode(index) {
      this.payIndex = index
    },
    // 切换套餐
    changeVip(index) {
      this.type = 'vip'
      this.vipIndex = index
    },
    changeRl(index) {
      this.type = 'rl'
      this.rlIndex = index
    },
    // 支付
    payMoney() {
      if (this.payModeList[this.payIndex].mode === 'rgzf') {
        this.rgCode = this.sysConfig.chat_vip_rgcz
        this.rgShow = true
        return false
      }
      let list
      if (this.type === 'vip') {
        list = this.vipGoodsList[this.vipIndex]
      } else {
        list = this.rlGoodsList[this.rlIndex]
      }
      let params = {
        price: list.price,
        title: list.title,
        goodsId: list.id,
        type: this.payModeList[this.payIndex].mode,
      }
      this.$showLoading({
        text: '加载中',
      })
      paymode(params).then((res) => {
        this.$hideLoading()
        if (this.payModeList[this.payIndex].mode === 'wx_app') {
          this.codeShow = true
          this.payCode = res.data.wx_result
          if (this.codeShow) {
            this.timer = setInterval(() => {
              getWxPayStateApi(res.data.orderCode)
                .then((res) => {
                  if (res.data) {
                    clearInterval(this.timer)
                    setTimeout(() => {
                      this.$message.success('支付成功')
                      this.codeShow = false
                      this.dialogVisible = false
                    }, 2000)
                    setTimeout(() => {
                      store.dispatch('getUserInfoActions')
                    }, 4000)
                  }
                })
                .catch((err) => {
                  this.$message.warning('未支付')
                  this.codeShow = false
                })
            }, 2000)
          }
        }
      })
    },
    // 关闭支付弹窗
    cancel() {
      this.$showLoading({
        text: '取消中',
      })
      setTimeout(() => {
        this.$hideLoading({
          message: '取消成功',
          type: 'success',
        })
        this.codeShow = false
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
.el-dialog__headerbtn {
  padding: 4px;
  color: #fff !important;
  background-color: #cccccc;
  border-radius: 50%;
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
  margin: 0 !important;
}

.el-dialog__headerbtn,
.el-dialog__close {
  color: #fff !important;
}
.left_title {
  width: 260px;
  margin: 20px;
  font-size: 18px;
  font-weight: 800;
}
.vip-box {
  display: flex;
  height: 590px;
  // background: url('../static/morechat_bg.png') no-repeat;

  .vip-box_left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e0d1b4;
    color: #000;

    .teg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 44px;
      margin: 10px 0;
      border: 1px solid #960a0f;
      border-radius: 10px;
      font-size: 18px;
      cursor: pointer;
    }

    .payStyle {
      border: 1px solid #ccc;
      background: #fff;
      color: #960a0f;
    }
  }

  .vip-box_right {
    display: flex;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    background: #faf4ec;
    .mealStyle {
      width: 48%;
      height: 100%;

      .mealStyle_list {
        display: grid;
        grid-template-columns: repeat(3, 30%);
        gap: 18px 19.2px;

        .mealStyle_list-item {
          height: 152px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 14px 0;
          box-sizing: border-box;
          align-items: center;
          border-radius: 10px;
          color: #000;
          border: 1px solid #cccccc;
		  cursor: pointer;

          .taocan {
            font-size: 16px;
            font-size: 800;
          }

          .sjjg {
            height: 26px;
            display: flex;
            justify-content: center;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
            font-weight: 800;

            .sjjg-num {
              font-size: 22px;
            }
          }

          .ckjg {
            position: relative;
            height: 20px;
            font-size: 14px;
            color: #cccccc;

            .yi {
              position: absolute;
              top: 10px;
              width: 40px;
              border-bottom: 1px solid #cccccc;
            }
          }

          .meitian {
            font-size: 14px;
          }

          .tueijian {
            width: 80px;
            height: 26px;
            position: absolute;
            top: -15px;
            font-size: 14px;
            font-weight: 800;
            text-align: center;
            line-height: 26px;
            background-color: #ff9933;
            color: #ffffff;
            border-radius: 14px;
          }
        }
      }
    }
  }
}

.paymode {
  border: 1px solid #9d010a !important;
}

.paycodeStyle {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: #b1a389;
  color: #fff;

  div {
    padding: 10px 0;
  }
}
</style>
