// 创作页面列表参数
import request from "@/api/request";
import website from "@/config/website";

export const getAiToolTemplateMenuEleByPid = (id) => {
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiToolTemplateMenu/open/getElementByPid/${id}`,
        method: 'get',
    })
}

// 创作页面创建sse连接
export const createSSE = (data) => {
    return request({
        url: `/api/${website.apiRequestHead}/chat/ai/create/createSSE`,
        method: 'post',
        data: data
    })
}

export function getAiToolTemplateApplicationStar(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiToolTemplateApplicationStar/web/list`,
        method: 'get',
    })
}

export function aiToolTemplateApplicationSetStar(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiToolTemplateApplicationStar/setStar/${id}`,
        method: 'get',
    })
}


