<template>
  <div class="chat" style="background: #f0f2f5">
    <el-scrollbar style="height: 100%;width: 100%">
      <div class="chat-drawing-classify chat-drawing">
        <div class="drawing-classify-top">
          <div class="top-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/index/drawing/drawing-index' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>分类页</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="top-tags-list">
            <div class="list-item">
              <div class="tags-title">类型</div>
              <div :class="['tags-name', tagsSelectType === 0 ? 'tags-select' : '' ]" @click="handelClickTagsType(0)" >{{"全部"}}</div>
              <div :class="['tags-name', tagsSelectType === 1 ? 'tags-select' : '' ]" @click="handelClickTagsType(1)" >{{"文生图"}}</div>
              <div :class="['tags-name', tagsSelectType === 2 ? 'tags-select' : '' ]" @click="handelClickTagsType(2)" >{{"图生图"}}</div>
            </div>
            <div class="list-item" v-for="(item1,i) in tagsGroupList" :key="i">
              <div class="tags-title">
                {{ item1.groupName }}
              </div>
              <div :class="['tags-name', tagsSelect[''+i] === 0 ? 'tags-select' : '' ]" :key="0"
                   @click="handleClickTags(i, 0)">全部
              </div>
              <div :class="['tags-name', tagsSelect[''+i] === j+1 ? 'tags-select' : '' ]"
                   v-for="(item2,j) in item1.tagList" :key="j+1" @click="handleClickTags(i, j+1)">{{ item2.name }}
              </div>
            </div>
          </div>
        </div>
        <div style="padding-left: 30px">
          <el-divider></el-divider>
        </div>
        <div class="drawing-classify-sort-tool">
          <div v-for="item in sortToolList"
               :key="item.id"
               :class="['tool-item' , selectToolId === item.id ? 'tool-item-select' : '' ]"
               @click="handleClickSortTool(item.id)" >
            {{item.name}}
          </div>
        </div>
        <div class="drawing-waterfall">
          <el-row v-masonry :gutter="10" >
            <el-col :span="4" v-masonry-tile  v-for="(item, index) in imageList" :key="index">
              <div class="card">
                <el-image :src="item.url1" fit="cover" class="image"/>
                <div class="mask"></div>
                <div class="mask-text-wrap">
                  <div class="mask-text">
                    {{item.description}}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>

</template>

<script>
import {getTagsGroupList} from "@/api/chat/aiDrawingTagsGroup";
import {showImage} from "@/api/chat/aiDrawingImage";

export default {
  name: "drawing-classify",
  data() {
    return {
      tagsGroupList: [],
      tagsSelect: {},
      selectTagsList: [],
      sortToolList:[
        {
          id: 1,
          name: "综合排序",
        },
        {
          id: 2,
          name: "最新上传",
        },
        {
          id: 3,
          name: "最多收藏",
        }
      ],
      selectToolId: 1,
      initTagName: undefined,
      queryParam: {
        tagsList: undefined,
        genType: undefined,
      },
      imageList: [],
      tagsSelectType: 0,
    }
  },
  mounted() {
    this.getTagsGroupList();
    if (this.initTagName){
      this.queryParam.tagsList = [];
      this.queryParam.tagsList.push(this.initTagName)
    }
    this.getImageList();
  },
  methods: {
    getImageList(){
      showImage(this.queryParam).then(res => {
        this.imageList = res.data;
      })
    },
    getTagsGroupList() {
      getTagsGroupList().then(res => {
        this.tagsGroupList = res.data
        let flag = true
        for (let i = 0; i < this.tagsGroupList.length; i++) {
          if (this.initTagName && flag){
            let a = this.tagsGroupList[i].tagList
            for (let j = 0; j < a.length; j++) {
              if (this.initTagName === a[j].name){
                this.tagsSelect["" + i] = j + 1;
                flag = false;
                break;
              }
            }
          }
          this.tagsSelect["" + i] = 0;
        }
      })
    },
    async handleClickTags(val1, val2) {
      this.tagsSelect["" + val1] = val2;
      this.$forceUpdate()
      await this.getSelectTagsList()
      this.getImageList()
    },
    getSelectTagsList() {
      this.selectTagsList = [];
      for (let i = 0; i < this.tagsGroupList.length; i++) {
        if (this.tagsSelect["" + i] !== 0) {
          this.selectTagsList.push(this.tagsGroupList[i].tagList[this.tagsSelect["" + i] - 1].name)
        }
      }
      this.queryParam.tagsList = [];
      this.queryParam.tagsList.push(...this.selectTagsList)
    },
    handleClickSortTool(val){
      this.selectToolId = val;
    },
    handelClickTagsType(val){
      this.tagsSelectType = val
      if (this.tagsSelectType === 0){
        this.queryParam.genType = undefined;
      }
      if (this.tagsSelectType === 1){
        this.queryParam.genType = "text2img";
      }
      if (this.tagsSelectType === 2){
        this.queryParam.genType = "img2img";
      }
      this.getImageList()
    },
  },
}
</script>

<style scoped>

</style>
