<template>
  <div :class="parentFun" >
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        class="editor"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
    />
  </div>
</template>
<script>

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {DomEditor} from '@wangeditor/editor'
import {Boot} from '@wangeditor/editor'
import FileSaver from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";

class EditorExport {

  constructor() {
    this.title = '导出 ' // 自定义菜单标题
    this.tag = 'button'
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor) {                              // JS 语法
    return ' hello '
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor) {
    return false
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor) {
    return false
  }

  // 点击菜单时触发的函数
  exec(editor, value) {
    const contentHtml = editor.getHtml();
    let content = `
            <!DOCTYPE html><html>
            	<head>
	                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
	            </head>
	            <body>
	                <div>
                  		${contentHtml}
                	</div>
	            </body>
            </html>`;
    const blob = htmlDocx.asBlob(content);
    FileSaver(blob, '浩通AI创作.docx');
  }

}

export default {
  components: {Editor, Toolbar},
  props: {
    parentFun: String
  },
  data() {
    return {
      editor: null,
      html: undefined,
      toolbarConfig: {
      },
      editorConfig: {placeholder: '请在《开始创作》栏输入相关信息...'},
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.toolbarConfig.toolbarKeys = [
        "bold", "underline", "italic", "through", "|", "emotion", "|", "undo", "redo", "|", "exportToWord"
      ]
      // 需要注册的菜单列表
      let registerMenuList = [];
      // 已经注册的菜单列表
      let menuKeys = editor.getAllMenuKeys();
      // 注册导出word文档菜单
      const exportMenu = {
        key: 'exportToWord', // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new EditorExport()
        },
      }
      registerMenuList.push(exportMenu)
      // 注册其他菜单

      // 全局注册一次，否则报错
      registerMenuList.forEach(item => {
        if (!menuKeys.includes(item.key)){
          Boot.registerMenu(exportMenu)
        }
      })


      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    setTimeout(() => {
      const toolbar1 = DomEditor.getToolbar(this.editor)
    }, 100)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }

  /**
   *
   *
   *
   */
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
.editor {
  overflow: auto;
  height: calc(100% - 41px);
}

.w-e-text-container {
  background: #f5f5f5;
}

.aiCreateNew{
  border: 1px solid #ccc;
  border-top: none;
}

.aiCreateHistory{
  border: 1px solid #ccc;
}

</style>
