import { Message } from 'element-ui'
import Vue from 'vue'


import { ensureEmptyPreWhiteSpace } from '@/views/resume/component/tabbar/hook'
import {
	setStorage,
	getStorage,
	removeStorage
} from '@/utils/storage'
import { getCurrentTypeContent, match } from '@/templates/config'

const MARKDOWN_CONTENT = 'markdown-content'
const WRITABLE = 'writable'
const INIT_TEMPLATE = 'initTemplate'
const SAVE_COUNT = 'save-count'
const USER_TEMPLATE_ID = 'user-template-id'

const editor = {
	state: {
		templates: [],
		MDContent: '',
		nativeContent: '',
		initTemplate: getStorage({name: INIT_TEMPLATE}),
		saveCount: getStorage({name: SAVE_COUNT}) || 0,
		userTemplateId: getStorage({name: USER_TEMPLATE_ID}) || '',
		// writable: Boolean(getStorage({name: WRITABLE})) || true
		writable: true
	},
	mutations: {
		SET_USER_TEMPLATE_ID(state, userTemplateId) {
			setStorage({name: USER_TEMPLATE_ID, content: userTemplateId})
			state.userTemplateId = getStorage({name: USER_TEMPLATE_ID}) ? getStorage({name: USER_TEMPLATE_ID}) : ''
		},
		RESET_USER_TEMPLATE_ID(state) {
			state.userTemplateId = ''
			removeStorage({name: USER_TEMPLATE_ID})
		},
		SET_SAVE_COUNT(state) {
			setStorage({name: SAVE_COUNT, content: state.saveCount + 1})
			state.saveCount = getStorage({name: SAVE_COUNT}) ? getStorage({name: SAVE_COUNT}) : 0
		},
		RESET_SAVE_COUNT(state) {
			state.saveCount = 0
			removeStorage({name: SAVE_COUNT})
		},
		SET_INIT_TEMPLATE(state, status) {
			state.initTemplate = status;
			setStorage({
				name: INIT_TEMPLATE,
				content: status
			})
		},
		RESET_INIT_TEMPLATE(state) {
			removeStorage({name: INIT_TEMPLATE})
			state.initTemplate = false
		},
		// 初始化编辑器内容（默认为Markdown模式）
		INIT_MD_CONTENT(state, resumeType) {
			const cacheKey = MARKDOWN_CONTENT + '-' + resumeType
			state.MDContent = getStorage({name: cacheKey}) ?
				getStorage({name: cacheKey}) :
				getCurrentTypeContent(resumeType)
		},
		SET_MD_CONTENT(state, params) {
			state.MDContent = params.nv
			// 处理之后的操作
			// if (!params.nv) return
			setStorage({
				name: `${MARKDOWN_CONTENT}-${params.resumeType}`,
				content: params.nv,
			 })
		},
		SET_RICH_CONTENT(state, params) {
			state.MDContent = params.nv
			// if (!params.nv) return
			setStorage({
				name: `${MARKDOWN_CONTENT}-${params.resumeType}`,
				content: params.nv
			})
			Vue.nextTick(() => {
				params.originHTML = params.originHTML || document.querySelector('.reference-dom')
				params.originHTML = params.originHTML.cloneNode(true)
				const DOMTree = document.querySelector('.writable-edit-mode')
				ensureEmptyPreWhiteSpace(params.originHTML)
				DOMTree && (DOMTree.innerHTML = params.originHTML.innerHTML)
			})
			// nextTick(() => {
			// 	originHTML = originHTML || (document.querySelector('.reference-dom') as HTMLElement)
			// 	originHTML = < HTMLElement > originHTML.cloneNode(true)
			// 	const DOMTree = document.querySelector('.writable-edit-mode') as HTMLElement
			// 	ensureEmptyPreWhiteSpace(originHTML)
			// 	DOMTree && (DOMTree.innerHTML = originHTML.innerHTML)
			// })
		},
		// 切换编辑模式
		SET_WRITABLE_MODE(state, originHTML) {
			state.writable = !state.writable
			setStorage({ name: WRITABLE, content: state.writable })
			Message({
				message: `您已切换至${state.writable ? '内容模式' : 'Markdown模式'}`,
				type: 'info'
			})
			// if (state.writable) {
			// 	nextTick(() => {
			// 		originHTML = originHTML || (document.querySelector('.reference-dom'))
			// 		originHTML = originHTML.cloneNode(true)
			// 		const DOMTree = document.querySelector('.writable-edit-mode')
			// 		ensureEmptyPreWhiteSpace(originHTML)
			// 		DOMTree && (DOMTree.innerHTML = originHTML.innerHTML)
			// 	})
			// }
		},
		SET_NATIVE_CONTENT(state, content) {
			state.nativeContent = content
		},
		REST_NATIVE_CONTENT(state) {
			state.nativeContent = ''
		},
		ADD_TEMPLATE(state, template) {
			state.templates.push(template)
		},
		SORT_TEMPLATES(state) {
			state.templates = state.templates.sort((a, b) => match(b) - match(a))
		}
	},
	actions: {
		setUserTemplateId({commit}, userTemplateId) {
			commit('SET_USER_TEMPLATE_ID', userTemplateId)
		},
		resetUserTemplateId({commit}){
			commit('RESET_USER_TEMPLATE_ID')
		},
		setSaveCount({commit}) {
			commit('SET_SAVE_COUNT')
		},
		resetSaveCount({commit}) {
			commit('RESET_SAVE_COUNT')
		},
		setInitTemplate({commit}, status) {
			commit('SET_INIT_TEMPLATE',status)
		},
		resetInitTemplate({commit}) {
			commit('RESET_INIT_TEMPLATE')
		},
		initMDContent({commit}, resumeType) {
			commit('INIT_MD_CONTENT', resumeType)
		},
		setMDContent({commit}, params) {
			commit('SET_MD_CONTENT', params)
		},
		setRichContent({commit}, params) {
			commit('SET_RICH_CONTENT', params)
		},
		setWritableMode({commit}, originHTML) {
			commit('SET_WRITABLE_MODE', originHTML)
		},
		setNativeContent({commit}, content) {
			commit('SET_NATIVE_CONTENT', content)
		},
		resetNativeContent({commit}) {
			commit('REST_NATIVE_CONTENT')
		},
		addTemplate({commit}, template) {
			commit('ADD_TEMPLATE', template)
		},
		sortTemplates({commit}) {
			commit('SORT_TEMPLATES')
		}
	},
}

export default editor