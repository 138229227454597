import store from '@/store'


const initialCVState = new Map()

// 创作模板的默认配置
initialCVState.set('create', ['#333', '#333', '', '25'])

const moduleFiles = require.context('@/templates/modules', true, /\.js$/)
moduleFiles.keys().forEach(module_item => {
	const content = moduleFiles(module_item).default
	content.id = Math.ceil(Math.random() * 1000000000)
	content.type = module_item.split('/')[2]
	store.dispatch("addTemplate", content)
	initialCVState.set(content.type, [
	  content.primaryColor,
	  content.primaryBackground,
	  content.font || '',
	  String(content.lineHeight || 25)
	])
})


// for (const [path, curModule] of moduleEntries) {
//   const content = (curModule).default
//   content.id = Math.ceil(Math.random() * 1000000000)
//   content.type = path.split('/')[2]
//   store.dispatch("editor/addTemplate", content)
//   initialCVState.set(content.type, [
//     content.primaryColor,
//     content.primaryBackground,
//     content.font || '',
//     String(content.lineHeight || 25)
//   ])
// }

export const match = (module) => +(module.type.match(/^\d+/))[0]
// store.dispatch("editor/sortTemplates")

export function getPrimaryBGColor(type) {
  return initialCVState.get(type)[1]
}

export function getPrimaryColor(type) {
  return initialCVState.get(type)[0]
}

export function getFontFamily(type) {
  return initialCVState.get(type)[2]
}

export const getCurrentTypeContent = (type) => {
  for (const template of store.getters["templates"]) {
    if (type === template.type) {
      return template.content
    }
  }
  return ''
}
