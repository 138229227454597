<template>
  <div class="main">
    <!--    顶部菜单栏-->
    <div class="fl-menu-list">
      <div v-for="(item,i) in firstLevelMenuList" :key="i" @click="handleClickFLM(i)">
        <el-badge :value="item.id === -1 ? starList.length : item.childCount" :class="['item', 'badge', selectFLM === i ? 'fl-menu-select':'']" :max="99" >
          <div class="fl-menu-item">
            <el-image v-if="i !== 0 && item.id !== -1"
                      :src="item.icon"
                      alt=""
                      class="fl-menu-item-img"
                      fit="cover"/>
            <svg-icon v-if="i === 0" icon-class="application" class="fl-menu-item-img" style="color: #7A46FC"/>
            <svg-icon v-if="item.id === -1" icon-class="favorites" class="fl-menu-item-img" style="color: #7A46FC"/>
            <div class="fl-menu-item-name">{{ item.label }}</div>
          </div>
        </el-badge>
      </div>
    </div>
    <!--    应用栏-->
    <div>
      <el-scrollbar style="width: 100%;">
        <div class="tl-menu-list">
          <div class="tl-menu-item-wrap" v-for="(item,i) in thirdLevelMenuList" :key="i" @click="handleClickTLM(item)">
            <div class="tl-menu-item">
              <div class="tl-menu-item-star flex-items-center">
                <svg-icon :icon-class="item.applicationStarId || item.isStar ? 'starSolid' : 'starHollow'" @click.stop="handleClickStar(item)" />
              </div>
              <div class="tl-menu-item-top">
                <el-image :src="item.icon"
                          alt=""
                          class="top-img"
                          fit="cover"/>
                <div class="top-name">
                  {{ item.label }}
                </div>
              </div>
              <div class="tl-menu-item-middle">
                {{ item.introduce }}
              </div>
              <div class="tl-menu-item-bottom">
                <i class="el-icon-right"/>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  aiToolTemplateApplicationSetStar,
  getAiToolTemplateApplicationStar,
  getAiToolTemplateMenuEleByPid
} from "@/api/chat/fun";

export default {
  name: "toolTemplate-index",
  props: {
    cateId: Number,
  },
  data() {
    return {
      aiCreateMenuList: [],
      firstLevelMenuList: [],
      secondLevelMenuList: [],
      thirdLevelMenuList: [],
      firstLevelMenuIndex: "0",
      selectFLM: 0,
      selectSLM: 0,
      selectTLM: 0,
      allMenuCount: 0,
      orgId: "1",
      starList: [],
    }
  },
  computed: {
    ...mapGetters(['sysConfig', 'isLogin']),
  },
  watch: {
    $route(to, from) {
      if (to.query.cateId) {
        this.selectFLM = parseInt(to.query.cateId)
      }
      if (to.query.o){
        this.orgId = to.query.o
        this.getList()
      }
      if (to.query.o != from.query.o){
        this.selectFLM = 0;
      }
    },
    async selectFLM(newV, oldV) {
      if (newV !== oldV && newV === 1){
        await this.getStarList();
      }
      this.getThirdLevelMenuList();
    }
  },
  created() {
    if (this.$route.query.o){
      this.orgId = this.$route.query.o
    }
  },
  mounted() {
    if (this.$route.query.cateId) {
      this.selectFLM = parseInt(this.$route.query.cateId)
    }
    if (!this.$route.query.cateId && this.isLogin){
      this.selectFLM = 1;
    }
    this.getList();
  },
  methods: {
    getList() {
      this.$emit("toolTemplateChild", this.orgId)
      getAiToolTemplateMenuEleByPid(this.orgId).then(res => {
        this.aiCreateMenuList = res.data
        // 筛选一级菜单
        this.getStarList().then(res => {
          this.getFirstLevelMenu();
          this.getThirdLevelMenuList();
        });
      })
    },
    getStarList(){
      return new Promise((resolve => {
        if (this.isLogin){
          getAiToolTemplateApplicationStar().then(res => {
            this.starList = res.data;
            resolve(true)
          })
        }
        if (!this.isLogin){
          resolve(false)
        }
      }))
    },
    getStarApp(){
      this.thirdLevelMenuList = [];
      this.starList.forEach(item => {
        item.flmId = -1;
        item.label = item.name;
        item.isStar = true;
        this.thirdLevelMenuList.push(item);
      })
    },
    // 获取一级菜单
    getFirstLevelMenu() {
      this.firstLevelMenuList = [];
      let sumChildCount = 0;
      this.aiCreateMenuList.forEach(item => {
        let childCount = 0;
        if (item.children && item.children.length) {
          item.children.forEach(c => {
            if (c.applicationList && c.applicationList.length) {
              childCount += c.applicationList.length
            }
          })
        }
        this.firstLevelMenuList.push({
          id: item.id,
          label: item.label,
          icon: item.icon,
          childCount: childCount,
        })
        sumChildCount += childCount;
      })
      this.firstLevelMenuList.unshift({
        id: -1,
        label: '我的收藏',
        icon: '',
        childCount: 0,
      })
      this.firstLevelMenuList.unshift({
        id: 0,
        label: '全部',
        icon: '',
        childCount: sumChildCount,
      })
      this.$emit('getList', this.firstLevelMenuList)
      this.$emit('showFirstLevelMenu', this.selectFLM + '')
    },
    // 点击一级菜单
    handleClickFLM(i) {
      this.selectFLM = i;
      this.$emit('showFirstLevelMenu', i + '')
    },
    // 获取三级菜单
    getThirdLevelMenuList() {
      this.thirdLevelMenuList = [];
      if (this.selectFLM === 0){
        this.aiCreateMenuList.forEach(i => {
          if (i && i.children){
            i.children.forEach(j => {
              j.applicationList.forEach(k => {
                k.flmId = i.id
                this.thirdLevelMenuList.push(k)
              })
            })
          }
        })
        return;
      }
      if (this.selectFLM === 1){
        this.getStarApp();
        return;
      }
      let slm = this.aiCreateMenuList[this.selectFLM - 2]
      if (slm && slm.children) {
        slm.children.forEach(item => {
          if (item.applicationList) {
            this.thirdLevelMenuList.push(...item.applicationList);
          }
        })
      }
    },
    // 点击三级菜单
    handleClickTLM(row) {
      let pid = 0;
      if (this.selectFLM === 0 || this.selectFLM === 1){
        pid = row.flmId
      }
      if (this.selectFLM !== 0 && this.selectFLM !== 1){
        pid = this.aiCreateMenuList[this.selectFLM - 2].id
      }
      this.$router.push({
        path: '/index/toolTemplate/toolTemplate-detail',
        query: {
          o: this.orgId,
          pid: pid,
          menuId: row.id
        },
      })
    },

    // 点击收藏
    handleClickStar(item){
      aiToolTemplateApplicationSetStar(item.id).then(res => {
        this.getList();
        this.$message.success(res.msg);
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  padding: 0 20px;
  width: calc(100% - 40px);
  background-image: url('../../../static/index/bg3.png');

  /*一级菜单列表样式*/
  .fl-menu-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0;

    .badge {
      box-sizing: border-box;
      margin: 0 10px;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
    }

    .fl-menu-select {
      /deep/ .el-badge__content {
        background-color: rgb(122, 70, 252);
        color: rgb(255, 255, 255);
        box-shadow: none;
      }

      .fl-menu-item {
        background-color: rgba(0, 0, 0, 0.05);
        color: #7A46FC;
        border-radius: 6px;
      }
    }

    .fl-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      cursor: pointer;

      .fl-menu-item-img {
        width: 30px;
        height: 30px;
      }

      .fl-menu-item-name {
        margin-top: 10px;
      }
    }

    .fl-menu-item:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: #7A46FC;
      border-radius: 6px;
      transform: translateY(-3px);
      transition: all 0.3s linear;
    }


  }

  .tl-menu-list {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100% - 140px);
    overflow: hidden auto;
    padding-bottom: 10px;

    .tl-menu-item-wrap {
      width: 20%;
      padding: 0 10px;
      box-sizing: border-box;

      .tl-menu-item {
        width: 100%;
        height: 164px;
        border-radius: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        margin: 10px auto;
        padding: 20px 16px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.3s linear;
        position: relative;

        .tl-menu-item-star{
          position: absolute;
          right: 15px;
          top: 15px;
          font-size: 22px;
          opacity: 0;
        }

        .tl-menu-item-top {
          display: flex;
          align-items: center;

          .top-img {
            width: 46px;
            height: 46px;
          }

          .top-name {
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }

        }

        .tl-menu-item-middle {
          margin-top: 20px;
          color: #767676;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .tl-menu-item-bottom {
          color: #7A46FC;
          position: absolute;
          right: 20px;
          bottom: 10px;
          font-size: 20px;
          visibility: hidden;
        }

      }

      .tl-menu-item:hover {
        transform: translateY(-3px);
        border: 1px solid #7A46FC;

        .tl-menu-item-star{
          opacity: 1;
        }

        .tl-menu-item-bottom {
          visibility: visible;
        }

      }

    }

  }

  /deep/ .el-badge__content {
    background-color: rgb(255, 255, 255);
    color: rgb(122, 70, 252);
    box-shadow: none;
  }

}


</style>
