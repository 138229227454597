<template>
  <div class="message-input">
    <talk-ai style="height: calc(100% - 173px);" ref="talkAi" @cleanPrologue="cleanPrologue" @clickMarkdownLink="clickMarkdownLink"></talk-ai>
    <div class="message-input-tools">
      <el-dropdown @command="recommendChange" trigger="click">
        <el-button class="tools-btn ant-btn ant-btn-primary ant-btn-background-ghost" style="width: unset">
          {{`聊天模型: ${modelValue} `}}<svg-icon icon-class="up" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
              v-for="(item, index) in modelList"
              :key="index"
              :command="item"
          >{{ item.showName }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="message-input-bottom">
      <div class="bottom-input">
        <el-input type="textarea" placeholder="有问题尽管问我... " v-model="talkQuestion" :rows="4"></el-input>
      </div>
      <el-button class="bottom-button ant-btn ant-btn-primary" :disabled="!talkQuestion" @click="send" :loading="sendLoading">{{ sendLoading ? '发送中' : '发 送' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import TalkAi from "@/views/talk/component/talk-ai";
import {getAllModelApi} from "@/api/system";
import {mapActions, mapGetters} from "vuex";
import {updataSettingDataApi} from "@/api/user";
import {getTalkModel} from "@/api/chat/model";


export default {
  name: "talk-ai-input",
  computed: {
    ...mapGetters(['isLogin', 'settingObj']),
  },
  components: {TalkAi},
  data() {
    return {
      talkQuestion: undefined,
      sendLoading: false,
      modelValue: undefined,
      modelList: [],
      isCleanPrologue: false,
      templateType : undefined,
      templateId : undefined,
    }
  },
  mounted() {
    this.getChatModel()
  },
  methods: {
    ...mapActions(['getSettingDataActions', 'getUserInfoActions']),
    // 获取模型
    getChatModel() {
      getTalkModel().then(res => {
        this.modelList = res.data;
        if (this.isLogin){
          this.getSettingDataActions()
          let a = this.modelList.filter(item => item.model == this.settingObj.ai_model)
          if (a && a.length){
            this.modelValue = a[0].showName;
          }
        }
      })
    },
    send(){
      if (!this.isCleanPrologue){
        this.$refs.talkAi.cleanPrologue();
      }
      if (this.templateType && this.templateId){
        this.$refs.talkAi.sendTemplateMessage(this.talkQuestion, this.templateType, this.templateId)
        return
      }
      this.$refs.talkAi.sendMessage(this.talkQuestion)
    },
    showPrologue(val){
      this.$refs.talkAi.showPrologue(val)
    },
    showMarkDownPrologue(val){
      this.$refs.talkAi.showMarkDownPrologue(val)
    },
    cleanPrologue(val){
      this.isCleanPrologue = val;
    },
    recommendChange(item){
      if (!this.isLogin) {
        return this.$router.push({ name: 'login' })
      }
      if (item.name === this.modelValue){
        return
      }
      if (item.isUseRl === 1 && this.userInfo.rl_cou <= 0) {
        this.$message.warning("燃料不足!")
        return false
      }
      this.$showLoading({
        text: '更换中',
      })
      updataSettingDataApi({
        aiModel: item.modelName,
      }).then((res) => {
        this.modelValue = item.name
        this.getSettingDataActions()
        this.$hideLoading({
          message: '更换成功',
          type: 'success',
        })
      }).catch(() => {
        this.$hideLoading({
          message: '更换失败',
          type: 'error',
        })
      })
    },
    clickMarkdownLink(val){
      this.talkQuestion = val
    },
    setTemplate(type, id){
      this.templateType = type;
      this.templateId = id;
    },

  },
}
</script>

<style scoped>

</style>
