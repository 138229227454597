<template>
  <div class="message"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="#f0f2f5">
    <el-scrollbar ref="scrollbar" style="height: 100%;width: calc(100% + 10px)">
      <div class="message-list" >
        <div v-for="(item,i) in messageList" :key="i">
          <div class="message-right" v-if="!item.prologue || item.prologue !== 1">
            <div class="message-main">
              <el-image class="avatar" :src="!userInfo.wxAvatar ? userInfo.wxAvatar : require('@/static/df_avatar_nan.png')" />
              <div class="message-content">
                <div class="message-card">
                  <div class="message-text">{{item.messageContent}}</div>
                </div>
                <div class="message-copy-warp" @click="copyUserMessage(item.messageContent)">
                  <svg-icon icon-class="copy" class-name="message-copy-icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="message-left">
            <div class="message-main">
              <el-image class="avatar" :src="require('@/../public/AI-logo.png')" />
              <div class="message-content">
                <div class="message-card">
                  <vue-markdown class="message-text" :source="item.children?item.children[0].messageContent:'暂无回答'"
                                :breaks="false" :typographer="true" :linkify="true" :highlight="false"></vue-markdown>
<!--                  <div class="message-text" v-html="item.children?item.children[0].messageContent:'暂无回答'"></div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {getChatLogMessageList} from "@/api/chat/aiTalk";
import {mapGetters} from "vuex";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import website from "@/config/website";
import {getStorage} from "@/utils/storage";
import VueMarkdown from 'vue-markdown';

export default {
  name: "talk-ai",
  props: {
    chatListId: String,
  },
  components: {
    VueMarkdown
  },
  data() {
    return{
      messageList: [],
      userAvatar: undefined,
      loading: false,
      reply: undefined,
      listId: undefined,
      aaa: undefined,
    }
  },
  watch: {
    chatListId(newValue,oldValue){
      if (newValue === oldValue){
        return
      }
      this.listId = this.chatListId
      this.getMessageList()
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'currMqttMsg', 'reconMqttTime', 'sysConfig', 'isLogin', 'settingObj']),
  },
  created() {
    this.getMessageList();
  },
  methods: {
    getMessageList(){
      if (!this.chatListId){
        return
      }
      this.messageList = [];
      this.loading = true;
      getChatLogMessageList({
        chatListId: this.chatListId
      }).then(res => {
        this.messageList = res.data
        this.scrollToBottom()
      }).finally(() => {
        this.loading = false
      })
    },
    sendMessage(message){
      let a = {
        question: message,
        chatListId: this.listId,
      };
      this.sendCommonMessage(a);
    },
    /**
     * 发送模板消息
     * @param message 问题
     * @param type 模板类型
     * @param id 模板id
     */
    sendTemplateMessage(message, type, id){
      let a = {
        question: message,
        chatListId: this.listId,
      };
      if (type && id){
        a[type] = id
      }
      this.sendCommonMessage(a);
    },
    /**
     * 发送通用消息
     * @param body 请求body
     */
    async sendCommonMessage(body){
      // 校验登录
      if (!this.isLogin) {
        return this.$router.push({ name: 'login' })
      }
      // 校验积分/燃料是否足够
      if (this.userInfo.rl_cou <= 0 && this.settingObj.chatUseRl === 1) {
        this.$message.warning('您的燃料不足!')
        this.$emit("sendEnd")
        return
      }
      if (this.settingObj.chatUseRl !== 1 && this.userInfo.questionCou <= 0 && !this.userInfo.memberFlag) {
        this.$message.warning('您的积分不足!')
        this.$emit("sendEnd")
        return
      }
      let reply = "";
      this.reply = "";
      let msgId = "";
      let that = this;
      const ctrl = new AbortController();
      await fetchEventSource(`/api/${website.apiRequestHead}/chat/list/createSSE`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/event-stream',
          Authorization: `Basic ${website.Authorization}`,
          'Blade-Auth': `bearer ${getStorage({name: 'token'})}`,
          Connection: 'keep-alive',
          'Cache-Control': 'no-cache',
        },
        openWhenHidden: true,
        signal: ctrl.signal,
        body: JSON.stringify(body),
        onopen(event) {
          that.$emit("sendEnd")
          if (event.status !== 200){
            ctrl.abort();
            that.$message.error("系统异常")
            console.log("异常信息---->" + event)
          }

        },
        async onmessage(event) {
          if (ctrl.signal.aborted) {
            return;
          }
          try {
            let data = JSON.parse(event.data)
            if (event.id === 'error' || event.id === 'param') {
              that.listId = data.chatListId;
              that.messageList.push(data)
              return;
            }
            if (event.id === '[DONE]'){
              if (data && data.content){
                console.log(data.content, 22222)
                await that.setAiReply(msgId, data.content, "")
              }
              return;
            }
            if (!msgId){
              msgId = event.id;
            }
            if (data && data.content) {
              if (data.content.includes("\n\n")){
                data.content = data.content.replace("\n\n", "\n");
              }
            }
            that.reply = that.reply + data.content;
            reply = await that.setAiReply(msgId, data.content, reply)
          } catch (e) {
            ctrl.abort()
          }
        },
        onclose() {
          ctrl.abort()
        },
        onerror(error) {
          ctrl.abort()
        },
      })
    },
    setAiReply(id, data, reply) {
      // if (!data){
      //   return reply;
      // }
      this.messageList.forEach(item => {
        if (id === item.children[0].id) {
          reply += data;
          item.children[0].messageContent = reply;
        }
      })
      this.scrollToBottom()
      return reply;
    },
    async copyUserMessage(text){
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(text).then(() => {
          this.$message.success('复制成功')
        });
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // 使 text area 不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = '0';
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res(null) : rej();
          textArea.remove();
          this.$message.success('复制成功')
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.scrollbar.$refs && this.$refs.scrollbar.$refs.wrap){
          const scrollbar = this.$refs.scrollbar.$refs.wrap; // 获取滚动区域的DOM元素
          scrollbar.scrollTop = scrollbar.scrollHeight; // 设置滚动位置到底部
        }
      });
    },
    showPrologue(val){
      this.messageList.push({
        prologue: 1,
        messageContent: "",
        children:[
          {
            messageContent: val
          }
        ]
      })
    },
    async showMarkDownPrologue(val){
      const marked = require('marked');
      let a = marked.parse(val);
      let b = JSON.stringify(a).replaceAll("\\n","");
      let c = JSON.parse(b);
      let d = c.replaceAll(/<li>#(.*?)#<\/li>/g, (match, p) => {
        return `<li><el-link :underline="false" class="markdown-el-link" href="javascript:viod(0)" >${p}</el-link></li>`;
      });
      await this.showPrologue(d);
      let that = this;
      this.$nextTick(() => {
        let domList = [];
        domList.push(...document.getElementsByClassName("markdown-el-link"))
        domList.forEach(item => {
          item.addEventListener("click", function (){
            that.$emit("clickMarkdownLink", item.innerText)
          })
        })
      })
    },
    cleanPrologue(){
      if (this.messageList && this.messageList.length && this.messageList[0].prologue && this.messageList[0].prologue === 1){
        this.messageList.splice(0, 1)
      }
      this.$emit("cleanPrologue", true)
    },
    sendTestMessage(val){
      const markdownString = "### 你好，我是一名文案万能写作助手\n我可以为你快速生成各种类型的文案，无论是广告文案、产品描述、宣传文案还是其他营销文案。只需告诉我你的产品、服务或宣传目标，我就可以为你撰写一篇富有创意和吸引力的文案。\n\n以下是一些你可以向我提问示例：\n- #请帮我写一则广告文案，推广我们的新产品，我的产品是XXXXX。#\n- #我需要一份产品描述文案，介绍我们的特色服务，特色是XXXXX，字数要求500字。#\n- #请为我创作一篇宣传文案，强调我们的核心价值观。#";
      const marked = require('marked');
      let htmlString = JSON.stringify(marked.parse(markdownString));
      htmlString = htmlString.replaceAll("\\n","")
      htmlString = JSON.parse(htmlString)
      this.messageList.push({
        messageContent: val,
        children:[
          {
            messageContent: htmlString
          }
        ]
      })
      this.scrollToBottom()
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
