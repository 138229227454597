<template>
  <div class="chat">
    <div class="chat-drawing-detail">
      <div class="drawing-detail-left">
        <div class="left-list-wrap" v-if="m === '1'">
          <div class="left-list-search">
            <el-input placeholder="请输入要搜索的内容" v-model="creationSearch" @keyup.enter.native="handleSearch" >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="left-list-tab">
            <div :class="['tab-item', activeTab === 'hot' ? 'tab-item-select' : '']" @click="handleClickTabTool('hot')">热门作品</div>
            <div :class="['tab-item', activeTab === 'star' ? 'tab-item-select' : '']" @click="handleClickTabTool('star')">我的收藏</div>
            <div :class="['tab-item', activeTab === 'create' ? 'tab-item-select' : '']" @click="handleClickTabTool('create')">我的创作</div>
          </div>
          <div class="left-list-image" v-loading="loading">
            <div v-if="!showDrawingImageList || !showDrawingImageList.length">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <el-scrollbar style="height: 100%" v-if="showDrawingImageList && showDrawingImageList.length">
              <div class="list-image-item-wrap">
                <div v-for="item in showDrawingImageList" :key="item.id" class="list-image-item" :style="`background-image: url(${item.url1});`" @click="handleClickShow(item)" >
                  <div class="image-mask">
                    <div class="mask-text">
                      {{item.description}}
                    </div>
                    <div class="mask-icon-star" v-if="item.isStar == 0" @click.stop="handleClickStar(item.id)">
                      <svg-icon icon-class="starHollow" />
                    </div>
                    <div class="mask-icon-star" v-if="item.isStar == 1" @click.stop="handleClickStar(item.id)">
                      <svg-icon icon-class="starSolid" />
                    </div>
                    <div class="mask-icon-download" @click.stop="handleDownloadImage(item.url1)">
                      <svg-icon icon-class="download" />
                    </div>
                  </div>
                </div>

              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="left-show-wrap" >
          <div v-if="!preImage" class="drawing-detail-default">
            <el-image :src="require('@/static/img/drawing/drawingDefault.png')" class="default-image"/>
            <div class="default-title" >生成的图片将会显示在这里</div>
            <div class="default-description" >请在右侧输入创意，生成你的作品吧</div>
          </div>
          <div v-if="preImage" class="flex-items-center drawing-detail-info">
            <div class="drawing-detail-image flex-items-center" :style="'width: ' + (preImageWidth ? preImageWidth : '40%')">
              <el-image :src="preImage" fit="cover" :preview-src-list="[preImage]"></el-image>
              <div class="right-tool">
                <el-tooltip content="下载" placement="right">
                  <div class="right-tool-item flex-items-center" @click="handleDownloadImage(preImage)">
                    <svg-icon icon-class="downloadElegant"/>
                  </div>
                </el-tooltip>
                <el-tooltip content="收藏" placement="right" >
                  <div class="right-tool-item flex-items-center" @click="handleClickStar(drawingImageId)">
                    <svg-icon icon-class="collect"/>
                  </div>
                </el-tooltip>
                <el-tooltip content="发布" placement="right">
                  <div class="right-tool-item flex-items-center" @click="handleReleaseImage(drawingImageId)">
                    <svg-icon icon-class="release"/>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="drawing-detail-tag flex-items-center">
              <el-tag class="tag-item" v-for="(item, i) in drawingImageTags" :key="i">
                {{ item }}
              </el-tag>
              <el-button class="ant-btn" style="margin: 2px 5px" @click="handleClickOpenTagList"><i class="el-icon-plus"/>新增标签
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="drawing-detail-right">
        <div class="right-top">
          <el-scrollbar style="height: 100%">
            <div class="tab-list">
              <div :class="['tab-sd', 'tab-common', selectTabIndex === 1 ? 'tab-select' : '']"
                   @click="handleClickTab(1)">SD模型
              </div>
              <div :class="['tab-mj', 'tab-common', selectTabIndex === 2 ? 'tab-select' : '']"
                   @click="handleClickTab(2)">MJ模型
              </div>
            </div>
            <div class="tab-param">
              <el-form ref="form" :model="form" label-position="top" :validate-on-rule-change="false">
                <el-form-item>
                  <div slot="label" class="label">
                    <div>选择风格</div>
                    <div>
                      <el-tooltip effect="dark" placement="top">
                        <div slot="content" style="font-size: 14px">
                          可以选择风格
                        </div>
                        <div><i class="el-icon-info"></i></div>
                      </el-tooltip>
                    </div>
                  </div>
                  <template>
                    <el-row>
                      <el-col :class="['style-card', item.id == selectStyleId ? 'style-card-select' : '']"
                              v-for="item in sdStyleList"
                              :key="item.id"
                              :span="8">
                        <div class="card-image" :style="`background-image: url(${item.img});`"
                             @click="handleClickStyleCard(item)">
                          <!--                          <el-image class="card-image" :src="item.img" fit="fill"></el-image>-->
                          <div :class="[item.id == selectStyleId ? 'move-box' : '']">
                          </div>
                          <div class="title">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </template>
                </el-form-item>
                <el-form-item label="提示词(使用英文最佳)">
                  <template>
                    <div class="prompt-card">
                      <el-input type="textarea"
                                placeholder="在此输入您的创意，每个提示词用逗号分开，如：一个女孩，绿色头发，校园"
                                :rows="6"
                                v-model="form.prompt">
                      </el-input>
                      <div class="footer-tools">
                        <el-button class="ant-btn ant-btn-link" @click="handleClickOpenPrompt">推荐词</el-button>
                        <div class="btn-clean" @click="handleClickCleanPrompt">清空</div>
                      </div>
                    </div>
                  </template>
                </el-form-item>
                <el-form-item label="参考图片" v-if="drawingType === '2'">
                  <template>
                    <el-upload
                        style="width: 100%;"
                        class="upload-demo"
                        drag
                        :action="apiRequestHead"
                        :headers="header"
                        :on-success="handleSuccess"
                        :on-error="handleError">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                  </template>
                </el-form-item>
                <el-form-item label="负面提示词" >
                  <template>
                    <div class="prompt-card" >
                      <el-input type="textarea"
                                placeholder="请输入参考图片中不希望出现的内容"
                                :rows="6"
                                @input="handleChangeItem"
                                v-model="form.negativePrompt">
                      </el-input>
                    </div>
                  </template>
                </el-form-item>
                <el-form-item v-for="(item,i) in testJsonData" :key="i">
                  <div slot="label" class="label">
                    <div>{{ item.label }}</div>
                    <div v-if="item.description && item.description.length">
                      <el-tooltip effect="dark" placement="top">
                        <div slot="content" style="font-size: 14px">
                          {{ item.description }}
                        </div>
                        <div><i class="el-icon-info"></i></div>
                      </el-tooltip>
                    </div>
                  </div>
                  <template>
                    <el-select v-model="form[item.id]" v-if="item.type === 'select'" @change="handleChangeItem()">
                      <el-option
                          v-for="(s,i) in item.selectContent"
                          :key="i"
                          :label="s"
                          :value="s"
                      ></el-option>
                    </el-select>
                    <div class="slider-card">
                      <el-slider input-size="small" v-model="form[item.id]" show-input
                                 :max="item.max ? item.max : 100"
                                 :min="item.min ? item.min : 0"
                                 v-if="item.type === 'slider'"
                                 :drag-on-click="true"
                                 @input="handleChangeItem()">
                      </el-slider>
                    </div>
                  </template>
                </el-form-item>
              </el-form>
            </div>
            <div style="height: 10px"/>
          </el-scrollbar>
        </div>
        <div class="right-bottom">
          <div class="consume_tips"> 将消耗：1次</div>
          <el-button :loading="btnLoading" class="ant-btn gen-btn" @click="handleClickGen">
            立即生成
          </el-button>
          <div class="tip">
            <el-tooltip
                effect="dark"
                placement="top">
              <div slot="content" style="font-size: 14px">
                该服务处于前沿探索阶段，您应当合<br/>
                法合规使用本服务，并承担由此产生<br/>
                的所有责任。我们对您的使用不做保<br/>
                证且不承担任何责任。
              </div>
              <div><i class="el-icon-warning-outline"/>免责声明</div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="chat-drawing-prompt-dialog"
               title="新增标签"
               :visible.sync="openTagList"
               width="40%"
               :close-on-click-modal="false"
               center
               :append-to-body="true">
      <div class="prompt-list">
        <el-checkbox-group size="mini" v-model="currentDrawingImageTags">
          <el-checkbox-button :label="item.name" v-for="(item, i) in tagList" :key="i">
            {{ item.name }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div style="display: flex;justify-content: right;margin-top: 10px">
        <el-button class="ant-btn" @click="openTagList = false">取 消</el-button>
        <el-button class="ant-btn ant-btn-primary" @click="submitImageTag">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog class="chat-drawing-prompt-dialog"
               title="推荐词"
               :visible.sync="openPrompt"
               width="50%"
               :close-on-click-modal="false"
               center
               top="10vh"
               :append-to-body="true">
      <el-scrollbar style="height: 500px">
        <div class="prompt-list">
          <div :class="['prompt-list-item', item.id === selectFLM ? 'prompt-list-item-select' : '']"
               v-for="item in firstLevelMenuList"
               :key="item.id"
               @click="handleClickTag(item)">
            {{ item.name }}
          </div>
          <div style="height: 20px;width: 100%;"/>
          <el-collapse v-model="activeNames" style="width: 100%">
            <el-collapse-item v-for="(item1, index) in secondLevelMenuList" :key="index" :name="index+''">
              <template slot="title">
                {{ `${item1.label}(${item1.children.length})` }}
              </template>
              <div class="prompt-list">
                <el-checkbox-group size="mini" v-model="checkboxGroup">
                  <el-checkbox-button :label="item2.content" v-for="(item2, j) in item1.children" :key="j">
                    {{ `${item2.label}(${item2.content})` }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-scrollbar>
      <div style="display: flex;justify-content: right;margin-top: 10px">
        <el-button class="ant-btn" @click="handleClickCancelPrompt">取 消</el-button>
        <el-button class="ant-btn ant-btn-primary" @click="handleClickSurePrompt">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listAiDrawingModelStyle} from "@/api/chat/aiDrawingModelStyle";
import {treeListAllElementByPid} from "@/api/chat/aiDrawingPrompt";
import {
  getAiDrawingImageById,
  img2img,
  listAndStar,
  listByLogin,
  listByStar,
  releaseImage,
  setStar,
  text2img,
  updateTags
} from "@/api/chat/aiDrawingImage";
import {getTagsList} from "@/api/chat/aiDrawingTags";
import website from "@/config/website";
import {getStorage} from "@/utils/storage";
import {mapGetters} from "vuex";

export default {
  name: "drawing-detail",
  data() {
    return {
      selectTabIndex: 1,
      tabNameList: ["stableDiffusion", "midJourney"],
      form: {
        prompt: undefined
      },
      modelStyleList: [],
      sdStyleList: [],
      mjStyleList: [],
      selectStyleId: 1,
      drawingPromptList: [],
      openPrompt: false,
      firstLevelMenuList: [],
      secondLevelMenuList: [],
      selectFLM: 1,
      activeNames: '0',
      checkboxGroup: [],
      testJsonData: [
        {
          id: "width",
          label: "宽度",
          type: "select",
          selectContent: ["256", "512", "576", "768", "1024"],
          p: "请选择宽度",
          description: "16:9请选择1024/576、4:3请选择1024/768"
        },
        {
          id: "height",
          label: "高度",
          type: "select",
          selectContent: ["256", "512", "576", "768", "1024"],
          p: "请选择高度",
          description: "9:16请选择576/1024、3:4请选择768/1024"
        },
        {
          id: "number",
          label: "数量",
          type: "slider",
          max: 4,
          min: 1,
          defaultValue: 1,
          p: "请选择数量",
        },
        {
          id: "step",
          label: "步数",
          type: "slider",
          defaultValue: 20,
          min: 1,
          description: "推理步数",
        },
        {
          id: "scheduler",
          label: "调度器",
          type: "select",
          selectContent: ["DDPMScheduler", "DDIMScheduler", "PNDMScheduler", "LMSDiscreteScheduler"
            , "EulerDiscreteScheduler", "EulerAncestralDiscreteScheduler", "DPMSolverMultistepScheduler", "HeunDiscreteScheduler"
            , "KDPM2DiscreteScheduler", "DPMSolverSinglestepScheduler", "KDPM2AncestralDiscreteScheduler", "UniPCMultistepScheduler"
            , "DDIMInverseScheduler", "DEISMultistepScheduler", "IPNDMScheduler", "KarrasVeScheduler"
            , "ScoreSdeVeScheduler", "LCMScheduler"],
          p: "请选择调度器",
          defaultValue: "DDIMScheduler",
        },
      ],
      preImage: undefined,
      preImageWidth: undefined,
      btnLoading: false,
      tagList: [],
      drawingImageTags: [],
      currentDrawingImageTags: [],
      openTagList: false,
      drawingImageId: undefined,
      apiRequestHead: `/api/${website.apiRequestHead}/cgform-api/upload/file`,
      header: {
        Authorization: `Basic ${website.Authorization}`,
        'Blade-Auth': `bearer ${getStorage({name: 'token'})} `,
      },
      fileList: [],
      drawingType: '1',
      creationSearch: undefined,
      activeTab: "hot",
      drawingImageList: [],
      showDrawingImageList: [],
      m: "0",
      loading: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.t){
        this.drawingType = to.query.t
        this.setNegativePrompt();
        this.$emit("drawingChild", "drawing-baseCreate-c" + this.drawingType)
      }
      if (this.$route.query.m){
        this.m = this.$route.query.m;
      }
      this.preImage = null;
      this.form = {};
      this.$forceUpdate()
    }
  },
  mounted() {
    this.getModelStyleList();
    this.getDrawingPromptList();
    this.getTagsList();
    this.getFormDefaultValue();
    this.getDrawingImageList();
    if (this.$route.query.t){
      this.drawingType = this.$route.query.t
    }
    if (this.$route.query.m){
      this.m = this.$route.query.m;
    }
    if (this.m == '0'){
      this.$emit("drawingChild", "drawing-baseCreate-c" + this.drawingType)
    }
    if (this.m == '1'){
      this.$emit("drawingChild", "drawing-myCreate")
    }
    this.setNegativePrompt();

  },
  computed: {
    ...mapGetters(['isLogin', 'userInfo', 'isSign']),
  },
  methods: {
    setNegativePrompt(){
      if (this.drawingType == '1'){
        this.form.negativePrompt = null;
      }
      if (this.drawingType == '2'){
        this.form.negativePrompt = "painting, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, " +
            "deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, " +
            "double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime";
      }
    },
    getTagsList() {
      getTagsList().then(res => {
        this.tagList = res.data;
      })
    },
    getModelStyleList() {
      listAiDrawingModelStyle().then(res => {
        this.modelStyleList = res.data
        this.modelStyleList.forEach(item => {
          if (item.model === 'stableDiffusion') {
            this.sdStyleList.push(...item.styleList)
          }
          if (item.model === 'midJourney') {
            this.mjStyleList.push(...item.styleList)
          }
        })
      })
    },
    getDrawingPromptList() {
      treeListAllElementByPid(0).then(res => {
        this.drawingPromptList = res.data;
        this.drawingPromptList.forEach(item => {
          let a = {}
          a.id = item.id
          a.name = item.label
          this.firstLevelMenuList.push(a)
        })
        this.selectFLM = this.firstLevelMenuList[0].id;
        this.getSecondLevelMenuList();
      })
    },
    handleClickTab(val) {
      this.selectTabIndex = val;
      this.getModelConfig();
    },
    handleClickStyleCard(item) {
      this.selectStyleId = item.id;
    },
    getModelConfig() {
      if (this.selectTabIndex === 2) {
        this.$message.warning("该功能即将上线!")
        this.selectTabIndex = 1;
        return;
      }
      this.form.model = this.tabNameList[this.selectTabIndex - 1];
    },
    getSecondLevelMenuList() {
      this.secondLevelMenuList = [];
      let a = this.drawingPromptList.filter(item => item.id === this.selectFLM)
      this.secondLevelMenuList.push(...a[0].children)
    },
    handleClickTag(item) {
      this.selectFLM = item.id
      this.getSecondLevelMenuList()
    },
    handleClickCancelPrompt() {
      this.checkboxGroup = [];
      this.openPrompt = false;
    },
    handleClickSurePrompt() {
      if (this.form.prompt && this.form.prompt.length) {
        this.form.prompt = this.form.prompt + "," + this.checkboxGroup.join(",")
      }
      if (!this.form.prompt) {
        this.form.prompt = this.checkboxGroup.join(",")
      }
      this.checkboxGroup = [];
      this.openPrompt = false;
    },
    handleClickOpenPrompt() {
      this.openPrompt = true;
    },
    handleClickCleanPrompt() {
      this.form.prompt = null;
    },
    handleClickGen() {
      // 校验登录
      if (!this.isLogin) {
        return this.$router.push({name: 'login'})
      }
      if (!this.form.model) {
        this.form.model = this.tabNameList[0];
      }
      this.btnLoading = true;
      this.getFormStyle();
      this.setPreImage();
      if (this.drawingType == '1'){
        text2img(this.form).then(res => {
          if (res.code === 500) {
            this.preImage = null;
            this.$message.warning(res.msg)
            this.btnLoading = false
          }
          if (res.code === 200) {
            this.drawingImageId = res.data.id;
            this.queryImage();
          }
        }).catch(() => {
          this.btnLoading = false;
        })
        return
      }
      if (this.drawingType == '2'){
        img2img(this.form).then(res => {
          if (res.code === 500) {
            this.preImage = null;
            this.$message.warning(res.msg)
            this.btnLoading = false
          }
          if (res.code === 200) {
            this.drawingImageId = res.data.id;
            this.queryImage();
          }
        }).catch(() => {
          this.btnLoading = false;
        })
      }
    },
    handleClickOpenTagList() {
      this.currentDrawingImageTags = this.drawingImageTags;
      this.openTagList = true
    },
    submitImageTag() {
      let a = {}
      a.id = this.drawingImageId;
      a.tagsName = this.currentDrawingImageTags.join(",");
      updateTags(a).then(res => {
        if (res.code === 500) {
          this.$message.warning(res.msg)
        }
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.drawingImageTags = this.currentDrawingImageTags;
          this.openTagList = false
          this.getDrawingImageList();
        }
      })
    },
    queryImage() {
      this.timer = setInterval(() => {
        getAiDrawingImageById(this.drawingImageId).then(res => {
          if (res.data.isEnd === 1) {
            window.clearInterval(this.timer)
            if (res.data.url1){
              this.preImage = res.data.url1;
            }
            if (!res.data.url1){
              this.$message.error(res.data.remark)
            }
            this.btnLoading = false;
          }
        }).catch(() => {
          window.clearInterval(this.timer);
          this.btnLoading = false;
        })
      }, 3000)
    },
    getFormStyle() {
      if (this.selectTabIndex === 1) {
        let a = this.sdStyleList.filter(item => item.id == this.selectStyleId)
        this.form.style = a[0].style;
      }
    },
    setPreImage(){
      this.preImage = null;
      this.drawingImageTags = [];
      if (this.form.height > this.form.width){
        this.preImageWidth = "40%";
      }else {
        this.preImageWidth = "60%";
      }
    },
    getFormDefaultValue() {
      this.testJsonData.forEach(item => {
        if (item.defaultValue) {
          this.form[item.id] = item.defaultValue
        }
      })
      this.$forceUpdate();
    },
    handleChangeItem() {
      this.$forceUpdate();
    },
    // 上传图片
    handleSuccess(response, file, fileList) {
      this.form.referImage = response.data.link
    },
    // 上传失败
    handleError(err) {
      this.$message.error('上传失败')
    },
    // 点击标签页
    handleClickTabTool(val){
      if (this.activeTab === val){
        return
      }
      this.activeTab = val
      this.getDrawingImageList();
    },
    getDrawingImageList(){
      this.loading = true;
      if (this.activeTab === 'hot'){
        listAndStar().then(res => {
          this.drawingImageList = res.data;
          this.handleSearch();
        })
      }
      if (this.activeTab === 'star'){
        listByStar().then(res => {
          this.drawingImageList = res.data;
          this.handleSearch();
        })
      }
      if (this.activeTab === 'create'){
        listByLogin().then(res => {
          this.drawingImageList = res.data;
          this.handleSearch();
        })
      }

    },
    handleSearch(){
      if (!this.creationSearch){
        this.showDrawingImageList = this.drawingImageList;
      }
      if (this.creationSearch){
        this.showDrawingImageList = this.drawingImageList.filter(item => item.description.includes(this.creationSearch));
      }
      this.loading = false;
    },
    async handleClickStar(id){
      this.loading = true;
      setStar(id).then(async res => {
        if (res.code == '500') {
          this.$message.warning(res.msg);
          this.loading = false;
        }
        if (res.code == '200') {
          this.$message.success(res.msg);
          this.getDrawingImageList();
        }
      }).catch(() => {
        this.$message.warning("系统异常")
        this.loading = false;
      })
    },
    handleReleaseImage(id){
      releaseImage(id).then(res => {
        if (res.code == '500') {
          this.$message.warning(res.msg);
          this.loading = false;
        }
        if (res.code == '200') {
          this.$message.success(res.msg);
          this.getDrawingImageList();
        }
      })
    },
    handleDownloadImage(val){
      this.downloadImage(val);
    },
    handleClickShow(item){
      this.drawingImageId = item.id;
      this.preImage = item.url1;
      this.form.prompt = item.description;
      this.form.width = item.width;
      this.form.height = item.height;
      if (parseInt(item.height) > parseInt(item.width)){
        this.preImageWidth = "40%";
      }else {
        this.preImageWidth = "60%";
      }
      this.form.style = item.model;
      this.setDrawingImageTags(item.tagsName);
    },
    setDrawingImageTags(s){
      if (!s){
        this.drawingImageTags = [];
        return
      }
      this.drawingImageTags = [];
      let split = s.split(",");
      this.drawingImageTags.push(...split)
    },

    downloadImage(url){
      let split = url.split("/");
      let name = split[split.length - 1];
      const image = new Image()
      // 解决跨域Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.download = name || 'photo'
          a.href = url
          a.click()
          a.remove()
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    }
  },


}
</script>

<style lang="scss" scoped>
/deep/ .prompt-card .el-textarea__inner {
  background-color: #f6f7f9;
  padding: 5px;
  font-size: 12px;
}

.slider-card {
  .el-slider--with-input {
    display: flex;
    flex-direction: column;

    /deep/ .el-slider__input {
      width: 100%;

    }

    /deep/ .el-slider__runway {
      width: 100%;

      .el-slider__button {
        border-color: #9d70ff;
      }

      .el-slider__bar {
        background-color: #9d70ff;
      }
    }
  }
}

/deep/ .el-upload{
  width: 100%;
  .el-upload-dragger{
    width: 100%;
  }
  .el-upload-dragger:hover{
    border-color: #9d70ff;
  }
}



</style>
