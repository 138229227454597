import Vue from 'vue'
import VueRouter from 'vue-router'
import { getStorage } from '@/utils/storage'

import talkIndex from "@/views/talk/component/talk-index";
import talkPrompt from "@/views/talk/component/talk-prompt";
import talkPromptDetail from "@/views/talk/component/talk-prompt-detail";
import talkCreateTool from "@/views/talk/component/talk-create-tool";
import toolTemplateIndex from "@/views/toolTemplate/component/toolTemplate-index";
import toolTemplateDetail from "@/views/toolTemplate/component/toolTemplate-detail";
import drawingIndex from "@/views/drawing/component/drawing-index";
import drawingClassify from "@/views/drawing/component/drawing-classify";
import drawingDetail from "@/views/drawing/component/drawing-detail";

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: "/index/toolTemplate/toolTemplate-index",
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    children: [
      {
        path: '/index/home',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ '../views/home.vue'),
      },
      {
        path: '/index/piazza',
        name: 'piazza',
        component: () => import(/* webpackChunkName: "piazza" */ '../views/piazza.vue'),
      },
      {
        path: '/index/chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ '../views/chat.vue'),
      },
      {
        path: '/index/chat-talk',
        name: 'chat-talk',
        component: () => import(/* webpackChunkName: "chat" */ '../views/chat-talk.vue'),
      },
      {
        path: '/index/toolTemplate',
        name: 'toolTemplate',
        component: () => import(/* webpackChunkName: "chat" */ '../views/toolTemplate/index.vue'),
        children: [
          {
            path: '/index/toolTemplate/toolTemplate-index',
            name: 'toolTemplate-index',
            component: toolTemplateIndex,
            props: true
          },
          {
            path: '/index/toolTemplate/toolTemplate-detail',
            name: 'toolTemplate-detail',
            component: toolTemplateDetail,
            props: true
          }
        ]
      },
      {
        path: '/index/talk',
        name: 'talk',
        component: () => import(/* webpackChunkName: "chat" */ '../views/talk/index.vue'),
        children: [
          {
            path: '/index/talk/talk-index',
            name: 'talk-index',
            component: talkIndex,
            props: true
          },
          {
            path: '/index/talk/talk-prompt',
            name: 'talk-prompt',
            component: talkPrompt,
            props: true
          },
          {
            path: '/index/talk/talk-prompt-detail',
            name: 'talk-prompt-detail',
            component: talkPromptDetail,
            props: true
          },
          {
            path: '/index/talk/talk-create-tool',
            name: 'talk-create-tool',
            component: talkCreateTool,
            props: true
          },
        ]
      },
      {
        path: '/index/draw',
        name: 'draw',
        component: () => import(/* webpackChunkName: "drawing" */ '../views/drawing/index.vue'),
        children: [
          {
            path: '/index/drawing/drawing-index',
            name: 'drawing-index',
            component: drawingIndex,
            props: true
          },
          {
            path: '/index/drawing/drawing-classify',
            name: 'drawing-classify',
            component: drawingClassify,
            props: true
          },
          {
            path: '/index/drawing/drawing-detail',
            name: 'drawing-detail',
            component: drawingDetail,
            props: true
          },
        ]
      },
      {
        path: '/index/drawing',
        name: 'drawing',
        component: () => import(/* webpackChunkName: "drawing" */ '../views/drawing.vue'),
      },
      {
        path: '/index/creative',
        name: 'creative',
        component: () => import(/* webpackChunkName: "creative" */ '../views/creative.vue'),
      },
      {
        path: '/index/image-details',
        name: 'image-details',
        component: () => import(/* webpackChunkName: "image-details" */ '../views/image-details.vue'),
      },
      {
        path: '/index/handbook',
        name: 'handbook',
        component: () => import(/* webpackChunkName: "handbook" */ '../views/handbook.vue'),
      },
      {
        path: '/index/inform',
        name: 'inform',
        component: () => import(/* webpackChunkName: "inform" */ '../views/inform.vue'),
      },
      {
        path: '/index/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/user.vue'),
        children: [
          {
            path: '/index/user/equity-center',
            name: 'equity-center',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "equity-center" */ '../views/user/equity-center.vue'),
          },
          {
            path: '/index/user/userinfo',
            name: 'userinfo',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "userinfo" */ '../views/user/userinfo.vue'),
          },
          {
            path: '/index/user/share',
            name: 'share',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "share" */ '../views/user/share.vue'),
          },
          {
            path: '/index/user/setting',
            name: 'setting',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "setting" */ '../views/user/setting.vue'),
          },
          {
            path: '/index/user/rl-detail',
            name: 'rl-detail',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "rl-detail" */ '../views/user/rl-detail.vue'),
          },
          {
            path: '/index/user/platform',
            name: 'platform',
            component: () => import(/* webpackChunkName: "platform" */ '../views/user/platform.vue'),
          },
        ]
      },
      {
        path: '/index/more',
        name: 'resume',
        component: () => import(/* webpackChunkName: "drawing" */ '../views/more/index.vue'),
        children: [
          {
            path: '/index/ppt',
            name: 'ppt',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/ppt/index.vue'),
          },
          {
            path: '/index/video',
            name: 'video',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/video/index.vue'),
          },
          {
            path: '/index/resume/resume-index',
            name: 'resume-index',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-index.vue'),
          },
          {
            path: '/index/resume/resume-history',
            name: 'resume-history',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-history.vue'),
          },
          {
            path: '/index/resume/resume-analysis',
            name: 'resume-analysis',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-analysis.vue'),
          },
          {
            path: '/index/resume/resume-detect',
            name: 'resume-detect',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-detect.vue'),
          },
          {
            path: '/index/resume/resume-match',
            name: 'resume-match',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-match.vue'),
          },
          {
            path: '/index/resume/resume-resolve',
            name: 'resume-resolve',
            component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-resolve.vue'),
          },
		  {
		    path: '/index/resume/resume-template',
		    name: 'resume-template',
		    component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-template.vue'),
		  },
          // {
          //   path: '/index/more/more-test',
          //   name: 'more-test',
          //   component: () => import(/* webpackChunkName: "drawing" */ '../views/more/component/more-test.vue'),
          // },
        ],
      },
      // 训练页面
      {
        path: '/index/training',
        name: 'training',
        component: () => import( '../views/training.vue'),
      },
      // vip页面
      {
        path: '/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "login" */ '../views/vip.vue'),
      },
    ]
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/resume/component/download/index.vue')
  },
  {
  	path: '/index/resume/resume-edit',
  	name: 'resume-edit',
  	component: () => import(/* webpackChunkName: "drawing" */ '../views/resume/component/resume-edit.vue'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/index'),
    children: [
      {
        path: '',
        name: 'a1',
        component: () => import('@/views/my/components/Ani/home.vue'),
      },
      {
        path: 'a2',
        name: 'a2',
        component: () => import('@/views/my/components/Ani/a2.vue'),
      },
      {
        path: 'WenshengDiagram',
        name: 'WenshengDiagram',
        component: () => import('@/views/my/components/WenshengDiagram/index.vue'),
      },
      {
        path: 'DiagramSketch',
        name: 'a4',
        component: () => import('@/views/my/components/DiagramSketch/index'),
      },
      {
        path: 'Realistic',
        name: 'Realistics',
        component: () => import('@/views/my/components/Realistic/index'),
      },
      {
        path: 'Anime',
        name: 'Animes',
        component: () => import('@/views/my/components/Anime/index'),
      },
      {
        path: 'HDFixes',
        name: 'HDFixest',
        component: () => import('@/views/my/components/HDFixes/index'),
      },
      // 背景
      {
        path: 'background',
        name: 'background',
        component: () => import('@/views/my/components/Background/index'),
      },
      {
        path: 'WenshengDiagram',
        name: 'WenshengDiagram',
        component: () => import('@/views/my/components/WenshengDiagram/index'),
      },
      {
        path: 'v5',
        name: 'v5',
        component: () => import('@/views/my/components/v5/index'),
      },
      {
        path: 'gddd',
        name: 'gddd',
        component: () => import('@/views/my/components/Ani/gd.vue'),
      },
    ]
  },
  {
    path: '/bindphone',
    name: 'bindphone',
    component: () => import(/* webpackChunkName: "bindphone" */ '../views/bindphone.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
  },
]

const router = new VueRouter({
  mode:"history",
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {     // 判断该路由是否需要登录权限
    let token = getStorage({ name: 'token' })
    if (token) {              // 通过localStorage.getItem()获取当前的 token 是否存在
      next()
    }
    else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath    // 将跳转的路由path作为参数，登录成功后跳转到该路由
        }
      })
    }
  }
  else {
    next()
  }
})

export default router
