<template>
  <div class="chat" style="background: #f0f2f5">
    <div class="chat-prompt-warp">
      <div class="prompt-detail-page-center">
        <div class="left">
          <div class="tab-wrap">
            <div :class="item.id === selectFLM ? 'tab-item-select' : 'tab-item'" v-for="item in flmList" :key="item.id" @click="handleClickTab(item.id)">
              {{item.label}}
            </div>
          </div>
          <div class="tree-list">
              <el-menu class="left-menu" style="height: unset" :default-active="navMenuIndex">
                <div v-for="(j,i) in navMenuList" :key="parseInt('1000' + i)">
                  <el-menu-item :index="j.id===0 ? '0' : selectFLM + '-' + j.id"
                                v-if="!j.children"
                                @click="handleClickNavMenu(j.id)">{{j.label}}</el-menu-item>
                </div>
                <div v-for="(j,i) in navMenuList" :key="parseInt('5000' + i)">
                  <el-submenu :index="selectFLM + '-' + j.id" v-if="j.children" >
                    <template slot="title">{{j.label}}</template>
                    <el-menu-item :index="selectFLM + '-' + k.id"
                                  v-for="(k) in j.children"
                                  :key="parseInt(selectFLM + '' + k.id)"
                                  @click="handleClickNavMenu(k.id)">
                      {{k.label}}
                    </el-menu-item>
                  </el-submenu>
                </div>
              </el-menu>
          </div>
        </div>
        <div class="right">
          <div class="prompt-detail-list-wrap" v-if="!showTalkIndex">
            <el-empty v-if="!templateList || !templateList.length" style="height: 100%" description="暂无数据"></el-empty>
            <el-scrollbar v-if="templateList && templateList.length" style="height: 100%;width: calc(100% + 10px)">
              <div class="prompt-detail-list">
                <div class="prompt-detail-list-item" v-for="item in templateList" :key="item.id" @click="clickPromptCard(item)">
                  <div class="item-p-label">{{item.templatePName}}</div>
                  <div class="item-title">{{item.title}}</div>
                  <div class="item-content">{{item.description}}</div>
                  <div class="item-use-num">
                    <el-tooltip content="该模板使用数量" class="item-use-num" placement="bottom" effect="light">
                      <div style="display: flex;align-items: center">
                        <svg-icon icon-class="hotTemplate"></svg-icon>
                        <div style="margin-left: 5px">{{parseInt(item.useNum) > 100 ? item.useNum : '小于100'}}</div>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="chat-pagination" v-if="selectSLM === 0">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :pager-count="7"
                    :page-sizes="[20,40]"
                    :page-size="pageSize"
                    :current-page.sync="pageNum"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="allTemplateList.length">
                </el-pagination>
              </div>
            </el-scrollbar>
          </div>
          <div v-if="showTalkIndex" style="width: 100%;height: 100%;">
            <talk-ai-input ref="talkAiInput"></talk-ai-input>
          </div>

        </div>
      </div>
    </div>
    <el-dialog class="chat-replace-dialog" top="40vh"
               :append-to-body="true"
               :show-close="false"
               title="请替换关键词"
               :visible.sync="openReplaceParam"
               width="50%">
      <div class="replace-body-wrap">
        <el-row class="replace-body-row" :gutter="20">
          <el-col class="replace-body-col" :span="8" v-for="(item,i) in replaceParamList" :key="i">
            <div>{{item.key}}</div>
            <el-input v-model="item.value" :placeholder="'请输入内容替换' + item.key"></el-input>
          </el-col>
        </el-row>
        <svg-icon class-name="replace-body-icon" icon-class="replaceSend" @click="handleSendReplace()"/>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {treeListAllElementByPid} from "@/api/chat/aiTalkPromptTemplate";
import Talk from "@/views/talk";
import TalkIndex from "@/views/talk/component/talk-index";
import TalkAiInput from "@/views/talk/component/talk-ai-input";

export default {
  name: "talk-prompt-detail",
  components: {TalkAiInput, TalkIndex, Talk},
  data() {
    return{
      treeList: [],
      flmList: [],
      navMenuList: [],
      templateList: [],
      allTemplateList: [],
      selectFLM: 1,
      selectSLM: 0,
      selectTLM: undefined,
      pageNum: 1,
      pageSize: 20,
      openReplaceParam: false,
      replaceParamList: [],
      waitForMatchStr: undefined,
      navMenuIndex: "0",
      showTalkIndex: false,
    }
  },
  mounted() {
    this.getAllElementByPid(0)
    if (this.$route.query.f){
      this.selectFLM = parseInt(this.$route.query.f)
    }
    if (this.$route.query.s){
      this.selectSLM = parseInt(this.$route.query.s)
      this.navMenuIndex = this.selectFLM + '-' + this.selectSLM
    }
    if (this.$route.query.t){
      this.selectTLM = parseInt(this.$route.query.t)
    }
  },
  methods:{
    getAllElementByPid(pid){
      treeListAllElementByPid(pid).then(res => {
        this.treeList = res.data;
        this.getFirstLevelMenu();
        this.getNavMenuList();
        this.getAllTemplateList();
      })
    },
    getFirstLevelMenu(){
      this.treeList.forEach(item => {
        let a = {
          id: item.id,
          label: item.label
        };
        this.flmList.push(a)
      })
    },
    getNavMenuList(){
      this.navMenuList = [];
      let a = this.treeList.filter(item => item.id === this.selectFLM)[0].children
      this.navMenuList.push({
        id: 0,
        label: '全部'
      })
      this.navMenuList.push(...a)
    },
    async handleClickTab(id){
      this.showTalkIndex = false;
      this.selectTLM = null;
      this.selectFLM = id;
      this.getNavMenuList(id);
      this.getAllTemplateList();
    },
    handleClickNavMenu(id){
      this.showTalkIndex = false;
      this.selectTLM = null;
      this.selectSLM = id;
      this.getTemplateList();
    },
    getAllTemplateList(){
      this.templateList = [];
      this.allTemplateList = [];
      let a = this.treeList.filter(item => item.id === this.selectFLM)[0];
      a.children.forEach(ac => {
        if (ac.children && ac.children.length){
          ac.children.forEach(acc => {
            if (acc.templateList){
              acc.templateList.forEach(acct => {
                acct.templatePName = acc.label
                acct.templatePId = acc.id
                this.allTemplateList.push(acct)
              })
            }

          })
        }
        if (!ac.children && ac.templateList){
          ac.templateList.forEach(act => {
            act.templatePName = ac.label
            act.templatePId = ac.id
            this.allTemplateList.push(act)
          })
        }
      })
      this.getTemplateList();
    },
    getTemplateList(){
      this.templateList = [];
      if (this.selectSLM === 0){
        this.showNavBarToAll();
        return
      }
      if (this.selectTLM){
        this.templateList = this.allTemplateList.filter(item => item.id === this.selectTLM)
        return;
      }
      this.templateList = this.allTemplateList.filter(item => item.templatePId === this.selectSLM)
    },
    showNavBarToAll(){
      this.templateList = [];
      let start = (this.pageNum - 1) * this.pageSize;
      let a = [];
      a.push(...this.allTemplateList)
      this.templateList = a.splice(start,this.pageSize)
    },
    // 页码改变
    handleCurrentChange(val){
      this.showNavBarToAll()
    },
    // 页面数量改变
    handleSizeChange(val){
      this.pageSize = val
      this.showNavBarToAll()
    },
    // 点击卡片
    clickPromptCard(item){
      this.waitForMatchStr = null;
      this.replaceParamList = [];
      const regex = /\{.*?\}/g;
      this.waitForMatchStr = item.content;
      let match = item.content.match(regex);
      if (match && match.length){
        match.forEach(j => {
          this.replaceParamList.push({
            key: j,
            value: ''
          })
        })
        this.openReplaceParam = true;
        return
      }
      this.handleSendReplace();
    },
    handleSendReplace(){
      let a = this.waitForMatchStr;
      if (this.replaceParamList && this.replaceParamList.length){
        this.replaceParamList.forEach(item => {
          a = this.waitForMatchStr.replace(item.key,item.value)
        })
      }
      this.openReplaceParam = false;
      this.showTalkIndex = true;
      this.$nextTick(() => {
        this.$refs.talkAiInput.talkQuestion = a;
        this.$refs.talkAiInput.showPrologue("可将输入框内模板内容进行修改后发送!")
      })
    },
    send(){

    },
  }
}
</script>

<style scoped>

</style>
