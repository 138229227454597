import XLSX from "xlsx";

let a2 = [
    {
        "id": 67,
        "parent_id": 66,
        "org_id": 1,
        "cat_name": "微信公众号",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-08-12 19:32:57",
        "updated_at": "2023-08-12 19:32:57",
        "deleted_at": null,
        "app_list": [
            {
                "id": 27,
                "name": "公众号万能写作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719059076f643805.png",
                "info": "专注微信公众号写作，可以生成各种类型的公众号原创文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "品牌推广",
                            "企业宣传",
                            "知识分享",
                            "行业解读",
                            "新闻解读",
                            "教育培训",
                            "旅游攻略",
                            "健康生活",
                            "文化艺术",
                            "产品营销",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您内容的主题"
                    },
                    {
                        "label": "内容描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "输入您内容描述"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "输入内容的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "多使用排比",
                            "引用名人名言",
                            "塑造情绪价值",
                            "段落之间用悬念承接",
                            "表达引起共鸣的三观",
                            "多使用比喻",
                            "多使用对比",
                            "融合热点事件",
                            "引用案例",
                            "挖掘痛点 刺激读者",
                            "有很强的说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "可以选择多个对内容的要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "热情",
                            "挖苦",
                            "鼓励",
                            "共情",
                            "质疑",
                            "说服",
                            "幽默",
                            "深沉",
                            "深情",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 218,
                "name": "实体商家万能写作",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/660389ddd72a774875.png",
                "info": "适合生成各行业实体商家的公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "经营行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你的经营行业，如：奶茶店、蛋糕店、餐厅"
                    },
                    {
                        "label": "内容类型",
                        "options": [
                            "种草类型",
                            "推广营销",
                            "通用介绍",
                            "新店开业",
                            "店铺活动"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的内容类型",
                        "defaultValue": "通用介绍"
                    },
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入详细的商家名称，如：海底捞火锅"
                    },
                    {
                        "label": "商家地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入商家经营地址，如：广州白云区万达广场3楼"
                    },
                    {
                        "label": "商家联系方式",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "可输入电话或微信号等联系方式"
                    },
                    {
                        "label": "创作要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请详细输入你的创作要求，如：店铺新店开业，全场7折优惠，充值领礼品"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励",
                            "严谨",
                            "说服",
                            "严肃",
                            "激动"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 220,
                "name": "内容仿写/改写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/6603a68965c3112169.png",
                "info": "根据你提供的内容，自动仿写/改写",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "原内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您要仿写的内容"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "温馨",
                            "愉快",
                            "真诚",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑",
                            "轻松",
                            "建议",
                            "傲慢",
                            "严肃",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "温馨"
                    }
                ]
            },
            {
                "id": 191,
                "name": "公众号万能标题",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231110/654d850ea6b8465038.png",
                "info": "根据提供信息创作小红书标题",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "标题内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你需要创作的标题内容"
                    },
                    {
                        "label": "标题风格",
                        "options": [
                            "正常",
                            "愉快",
                            "真诚",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑",
                            "建议",
                            "严肃",
                            "崇敬",
                            "傲慢"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "生成数量",
                        "options": [
                            "1条",
                            "2条",
                            "3条",
                            "4条",
                            "5条"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "1条"
                    }
                ]
            },
            {
                "id": 43,
                "name": "公众号爆款标题",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647e9d7e5b6ef30739.png",
                "info": "生成公众号爆款文章标题",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "给出关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "填写内容关键词"
                    },
                    {
                        "label": "爆款关键词",
                        "options": [
                            "首发",
                            "YYDS",
                            "福利",
                            "神器",
                            "正确姿势",
                            "必备",
                            "爆款",
                            "竟然",
                            "玩玩没想到"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择爆款关键词"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "引导读者思考的疑问式标题",
                            "引发好奇心的悬念标题",
                            "让读者产生情感共鸣",
                            "很有说服力的标题",
                            "吸引眼球的炸裂标题",
                            "刺激读者的标题",
                            "挖掘痛点的标题",
                            "正常普通的标题"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择标题要求"
                    },
                    {
                        "label": "标题风格",
                        "options": [
                            "正常",
                            "深情",
                            "深沉",
                            "急迫",
                            "幽默",
                            "热情",
                            "冷漠",
                            "讽刺",
                            "崇敬",
                            "正式",
                            "说服"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择标题风格"
                    },
                    {
                        "label": "生成数量",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择生成几个标题"
                    }
                ]
            },
            {
                "id": 143,
                "name": "知识科普",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98a7b5d1db36193.png",
                "info": "根据你的需求，帮你生成知识科普类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 144,
                "name": "专业知识",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b7ed308822883.png",
                "info": "根据你的需求，帮你生成专业知识类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 146,
                "name": "活动宣传",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b6f29e5971179.png",
                "info": "根据你的需求，帮你生成活动宣传类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 147,
                "name": "健康养生",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98ac24e21322883.png",
                "info": "根据你的需求，帮你生成健康养生类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 148,
                "name": "旅行游玩",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b9f9b2e971179.png",
                "info": "根据你的需求，帮你生成旅行游玩类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 149,
                "name": "时尚穿搭",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98c569213e99424.png",
                "info": "根据你的需求，帮你生成时尚穿搭类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 150,
                "name": "幽默搞笑",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98e4d2e9c490535.png",
                "info": "根据你的需求，帮你生成幽默搞笑类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 151,
                "name": "情感文案",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98f1d3df5099424.png",
                "info": "根据你的需求，帮你生成情感文案类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 152,
                "name": "教育文章",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98feac556790535.png",
                "info": "根据你的需求，帮你生成教育文章类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 153,
                "name": "美食菜谱",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d991c20c79f81083.png",
                "info": "根据你的需求，帮你生成美食菜谱类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 154,
                "name": "党政公文",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d995bb7ffb881083.png",
                "info": "根据你的需求，帮你生成党政公文类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 155,
                "name": "星座运势",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9964d7152984474.png",
                "info": "根据你的需求，帮你生成星座运势类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 156,
                "name": "母婴育儿",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d99711ccaf233938.png",
                "info": "根据你的需求，帮你生成母婴育儿类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的写作风格",
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 172,
                "name": "科技文章",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650baa04b39b797288.png",
                "info": "根据你的需求，帮你生成科技类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 173,
                "name": "汽车讲解",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650baa2e6749699813.png",
                "info": "根据你的需求，帮你生成汽车类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "汽车品牌和型号",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入汽车品牌和型号"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 186,
                "name": "影视点评/解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530a8e810aa925928.png",
                "info": "生成影视点评类或解说类文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "解说类",
                            "点评类"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作类型，解说类或点评类"
                    },
                    {
                        "label": "影视节目名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视节目的名称"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "真诚",
                            "质疑",
                            "热情",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 217,
                "name": "楼市资讯",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/66038821ec39263362.png",
                "info": "根据你的需求，帮你生成知识科普类公众号文章",
                "cat_id": 67,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你要创作的内容主题"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "严谨",
                            "讽刺",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励",
                            "幽默"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格",
                        "defaultValue": "严谨"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600字-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list67"
    }
]
let a1 = [
    {
        "id": 65,
        "parent_id": 64,
        "org_id": 1,
        "cat_name": "小红书笔记",
        "cat_info": "",
        "cat_icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d257b1dac5923695.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-08-08 22:56:57",
        "updated_at": "2023-08-08 22:59:49",
        "deleted_at": null,
        "app_list": [
            {
                "id": 22,
                "name": "小红书万能笔记",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194c999e1467731.png",
                "info": "啥都能写，根据内容，帮您生成极具吸引力的小红书风格笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "好物分享",
                            "时尚穿搭",
                            "商品推广",
                            "美妆个护",
                            "影视",
                            "情感",
                            "家居",
                            "旅行",
                            "健身塑形",
                            "游戏",
                            "职场",
                            "知识科普",
                            "母婴",
                            "室内设计",
                            "搞笑日常",
                            "书单推荐",
                            "珠宝首饰",
                            "手工",
                            "外语学习"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作的类型"
                    },
                    {
                        "label": "内容主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您要写的内容主题"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请尽可能多的输入内容关键词，以便生成更符合您的的内容"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "提出痛点",
                            "刺激客户",
                            "引用名言",
                            "引用数据",
                            "列举例子",
                            "多用对比",
                            "深入场景描述",
                            "口语化表达"
                        ],
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入内容要求：如：提出痛点、引用数据、列举例子等"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "愉快",
                            "沉思",
                            "幽默",
                            "激动",
                            "崇敬",
                            "温馨",
                            "安慰",
                            "轻松",
                            "喜悦",
                            "清河",
                            "热情",
                            "质疑",
                            "真诚",
                            "亲切",
                            "鼓励",
                            "建议",
                            "说服",
                            "讽刺",
                            "傲慢"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择一个写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 139,
                "name": "内容仿写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d3a1e95407320035.png",
                "info": "根据你提供的内容，自动仿写成小红书风格的笔记内容",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您要仿写的内容"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑",
                            "轻松",
                            "建议",
                            "傲慢",
                            "严肃",
                            "崇敬",
                            "温馨"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您想要的写作风格"
                    }
                ]
            },
            {
                "id": 21,
                "name": "爆款标题",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194c082cdc77404.png",
                "info": "根据内容帮您生成小红书风格的爆款标题",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "标题关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入你要发布的内容关键词帮您生成标题"
                    },
                    {
                        "label": "生成几个标题",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择输入的标题"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "包含有紧迫感和惊喜感的标点符号",
                            "具有挑战性和悬念的表述",
                            "加入热点话题",
                            "描述具体功效",
                            "使用emoji表情增加活力",
                            "以口语化表述",
                            "通过正面刺激、负面刺激吸引用户眼球"
                        ],
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "可以选择多个要求"
                    },
                    {
                        "label": "选择爆款词",
                        "options": [
                            "好用到哭",
                            "小白必看",
                            "正确姿势",
                            "宝藏",
                            "神器",
                            "都给我冲",
                            "YYDS",
                            "划重点",
                            "建议收藏",
                            "手把手",
                            "揭秘",
                            "沉浸式",
                            "吐血整理",
                            "高级感",
                            "正确姿势",
                            "破防了",
                            "被夸爆",
                            "爆款",
                            "万万没想到",
                            "搞钱必看"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "根据实际需求选择，不选则留空"
                    }
                ]
            },
            {
                "id": 219,
                "name": "实体商家万能笔记",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/66038e67c7cd478668.png",
                "info": "专为实体店商家创作，适合生成各类型实体商家小红书笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "经营行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你的经营行业，如：奶茶店、蛋糕店、餐厅"
                    },
                    {
                        "label": "内容类型",
                        "options": [
                            "种草类型",
                            "推广营销",
                            "新店开业",
                            "店铺活动",
                            "通用介绍"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的内容类型",
                        "defaultValue": "种草类型"
                    },
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入详细的商家名称，如：海底捞火锅"
                    },
                    {
                        "label": "商家地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入商家经营地址，如：广州白云区万达广场3楼"
                    },
                    {
                        "label": "创作主题和要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请详细输入你的创作要求，如：店铺新店开业，全场7折优惠，充值领礼品"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "质疑",
                            "热情",
                            "真诚",
                            "鼓励",
                            "严谨",
                            "说服",
                            "严肃",
                            "激动"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "亲切"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 190,
                "name": "小红书万能标题",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231110/654d83ac2ac7a63501.png",
                "info": "根据提供信息创作小红书标题",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "标题内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你需要创作的标题内容"
                    },
                    {
                        "label": "标题风格",
                        "options": [
                            "正常",
                            "愉快",
                            "真诚",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑",
                            "建议",
                            "严肃",
                            "崇敬",
                            "傲慢"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "生成数量",
                        "options": [
                            "1条",
                            "2条",
                            "3条",
                            "4条",
                            "5条"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "1条"
                    }
                ]
            },
            {
                "id": 61,
                "name": "餐饮探店文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64819caad4eb178735.png",
                "info": "生成餐饮探店小红书文案",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写商家名称",
                        "defaultValue": ""
                    },
                    {
                        "label": "商家地址",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入商家地址",
                        "defaultValue": ""
                    },
                    {
                        "label": "商家特色",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "defaultValue": [
                            "环境好",
                            "味道好",
                            "服务态度好",
                            "很暖心"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "商家招牌菜",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "可输入多个商家招牌菜",
                        "defaultValue": [],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "商家类别",
                        "options": [
                            "火锅",
                            "烧烤烤肉",
                            "自助餐",
                            "鱼海鲜楼",
                            "小吃快餐",
                            "饮品店",
                            "面包蛋糕甜品",
                            "食品生鲜",
                            "异域料理",
                            "地方菜系",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "烧烤烤肉"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "包含有紧迫感和惊喜感的标点符号",
                            "具有挑战性和悬念的表述",
                            "描述具体体验",
                            "使用emoji表情增加活力",
                            "以口语化表述",
                            "通过正面刺激、负面刺激吸引用户眼球"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "标题包含的爆款词",
                        "options": [
                            "YYDS",
                            "好吃到哭",
                            "强烈推荐",
                            "破防了",
                            "治愈",
                            "万万没想到"
                        ],
                        "formType": "select",
                        "isRequired": false
                    },
                    {
                        "label": "文章风格",
                        "options": [
                            "正常",
                            "热情",
                            "喜出望外",
                            "轻松",
                            "愤怒",
                            "讽刺",
                            "强烈推荐"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "文章要求",
                        "options": [
                            "提出痛点",
                            "刺激客户",
                            "引用名言",
                            "引用数据",
                            "多用对比",
                            "深入场景描述",
                            "口语化表达",
                            "以我的视角描述",
                            "每段多用emoji"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "defaultValue": [
                            "提出痛点",
                            "刺激客户"
                        ]
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 135,
                "name": "测评笔记",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d24f0213fa210068.png",
                "info": "根据需求自动生成各商品的测评笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "测评商品",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请具体输入您要测评的商品，如：iPhone 14pro"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您的要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "幽默",
                            "质疑",
                            "严肃",
                            "讽刺",
                            "激动"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择一个写作风格",
                        "defaultValue": "真诚"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 137,
                "name": "影视点评/解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d2551321bab28277.png",
                "info": "生成影视点评类或解说类笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "解说类",
                            "点评类"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作类型，解说类或点评类"
                    },
                    {
                        "label": "影视节目名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视节目的名称"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您的内容要求"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您想要的写作风格",
                        "defaultValue": "真诚"
                    }
                ]
            },
            {
                "id": 138,
                "name": "美食教程",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d39dc1d41aa53547.png",
                "info": "生成美食教程类小红书文案",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "菜品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入菜品名称，如：麻辣小龙虾"
                    },
                    {
                        "label": "美食分类",
                        "options": [
                            "中餐",
                            "西餐",
                            "日本料理",
                            "韩国料理",
                            "土耳其菜系",
                            "法国菜系",
                            "点心",
                            "饮料",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "请选择对应的分类"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您的内容要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "幽默",
                            "激动",
                            "分享"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您想要的写作风格",
                        "defaultValue": "分享"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（100-200字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 134,
                "name": "旅游笔记",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230807/64d0e8c29ffa485395.png",
                "info": "帮你生成小红书旅游笔记内容",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "城市/景点",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入游玩城市或旅游景区",
                        "defaultValue": "北京"
                    },
                    {
                        "label": "游玩天数",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入游玩天数",
                        "defaultValue": "3"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您要写的内容主题或要求，可以写出发城市、交通工具等",
                        "defaultValue": ""
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "讽刺",
                            "幽默",
                            "激动",
                            "温馨",
                            "质疑",
                            "严肃"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择一个写作风格",
                        "defaultValue": "愉快"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 136,
                "name": "景点打卡",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d253a4eec8628277.png",
                "info": "输入城市或景点的名称，便可得到一篇打卡文案",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "城市/景点",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入游玩城市或旅游景区"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入您要写的内容主题或要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择需要创作的字数",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 141,
                "name": "书籍点评/解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d3a3aaca22c20035.png",
                "info": "输入书籍的名称，帮你创作书籍点评类笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "解说类",
                            "点评类"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作类型，解说类或点评类",
                        "defaultValue": "解说类"
                    },
                    {
                        "label": "书籍名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入书籍的名称，如：红楼梦"
                    },
                    {
                        "label": "内容要求",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入您的内容要求，若“无”则留空"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "真诚",
                            "愉快",
                            "讽刺",
                            "幽默",
                            "激动",
                            "质疑",
                            "分享"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您想要的写作风格",
                        "defaultValue": "真诚"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 142,
                "name": "笔记加emoji",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230812/64d6e24a7310886026.png",
                "info": "给你的笔记内容自动加上适合的emoji符号",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的内容"
                    }
                ]
            },
            {
                "id": 174,
                "name": "情感笔记",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bac1db119034310.png",
                "info": "生成情感类小红书笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "情感笔记类型",
                        "options": [
                            "幸福",
                            "悲伤",
                            "喜悦",
                            "厌恶",
                            "爱情",
                            "正能量",
                            "恐惧",
                            "愤怒"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你想要的情感类型"
                    },
                    {
                        "label": "内容主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的内容主题或要求"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 175,
                "name": "养生健康",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650badabc744930473.png",
                "info": "帮你生成养身类爆款笔记内容",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "内容主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您要写的内容主题"
                    },
                    {
                        "label": "针对人群性别",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择性别"
                    },
                    {
                        "label": "针对人群年龄",
                        "options": [
                            "少儿",
                            "青少年",
                            "青年",
                            "中年",
                            "老年"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "真诚",
                            "讽刺",
                            "激动",
                            "质疑",
                            "愉快"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您想要的写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 176,
                "name": "生活记录",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb237294d980316.png",
                "info": "帮你生成各类生活记录类型的笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "生活类型",
                        "options": [
                            "品质生活",
                            "校园生活",
                            "接地气生活",
                            "中外生活",
                            "颓废生活",
                            "日常生活",
                            "工作生活"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容主题"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "愉快",
                            "真诚",
                            "严肃",
                            "讽刺",
                            "激动",
                            "质疑",
                            "鼓励",
                            "热情",
                            "傲慢"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-400字）",
                            "长文案（400-800字）"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 177,
                "name": "母婴育儿",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb431b6a0f28153.png",
                "info": "生成育儿、早教、孕产类笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "笔记类型",
                        "options": [
                            "育儿经验",
                            "妈妈经验",
                            "早教知识",
                            "孕产经验"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容主题"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "愉快",
                            "真诚",
                            "严肃",
                            "激动",
                            "质疑",
                            "热情",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 178,
                "name": "教育笔记",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb618eaa6634831.png",
                "info": "生成教育类型笔记内容",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "笔记类型",
                        "options": [
                            "家庭教育",
                            "K12教育",
                            "幼儿教育",
                            "大学教育",
                            "留学教育",
                            "语言教育"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要创作的类型"
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容主题"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "愉快",
                            "真诚",
                            "严肃",
                            "激动",
                            "鼓励",
                            "讽刺"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-400字）",
                            "长文案（400-800字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 179,
                "name": "小红书账号简介",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650c10f5f049a91570.png",
                "info": "帮你创作小红书账号的简介内容",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "账号类目",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您小红书账号所经营的类目，如：时尚饰品"
                    },
                    {
                        "label": "特别要求",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "如果有其他要求可以在这里输入"
                    },
                    {
                        "label": "简介风格",
                        "options": [
                            "真诚",
                            "幽默",
                            "官方",
                            "正式"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你想要的简介风格"
                    }
                ]
            },
            {
                "id": 180,
                "name": "母婴辅食",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d8a93db70d74689.png",
                "info": "生成母婴辅食类爆款笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "博主身份",
                        "options": [
                            "宝妈",
                            "孕妈",
                            "奶爸",
                            "宝宝父母",
                            "营养师",
                            "儿科医生",
                            "产科医生",
                            "儿科护士"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的身份"
                    },
                    {
                        "label": "产品",
                        "formType": "input",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入产品，如：米粉",
                        "defaultValue": "",
                        "newKeywordsAdding": false,
                        "addDefaultTagVisible": false
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入产品的品牌名"
                    },
                    {
                        "label": "内容类型偏好",
                        "options": [
                            "好物种草",
                            "生活记录",
                            "创意创造"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择内容偏好"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "真诚",
                            "讽刺",
                            "激动",
                            "质疑",
                            "愉快"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 181,
                "name": "护肤防晒产品",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d8e91b677852879.png",
                "info": "帮你生成护肤防晒类产品笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "博主身份",
                        "options": [
                            "青春少女",
                            "熟龄女性",
                            "学生党",
                            "精致男性",
                            "皮肤科医生",
                            "护肤品体验官"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的身份"
                    },
                    {
                        "label": "产品品牌",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入产品品牌名称"
                    },
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入产品名称，如水动力洁面乳"
                    },
                    {
                        "label": "内容类型偏好",
                        "options": [
                            "好物种草",
                            "生活记录",
                            "测评分享"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择内容偏好"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择目标群体"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "真诚",
                            "讽刺",
                            "激动",
                            "质疑",
                            "愉快"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 182,
                "name": "护肤防晒技巧",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d9664117a674689.png",
                "info": "帮你生成护肤防晒技巧知识类笔记",
                "cat_id": 65,
                "user_form": [
                    {
                        "label": "目标群体",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择目标群体"
                    },
                    {
                        "label": "主题偏好",
                        "options": [
                            "护肤",
                            "防晒",
                            "保养"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择主题偏好"
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容主题，如：军训期间如何保护皮肤"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "真诚",
                            "讽刺",
                            "激动",
                            "质疑",
                            "愉快"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list65"
    }
]
let a3 = [
    {
        "id": 30,
        "parent_id": 29,
        "org_id": 1,
        "cat_name": "短视频",
        "cat_info": "帮您创作具有吸引力的短视频",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64715f786264c76597.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 14,
                "name": "短视频文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/6471635504bad88861.png",
                "info": "帮你生成极具吸引力的短视频文案",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "汽车",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的创作类型"
                    },
                    {
                        "label": "发布平台",
                        "options": [
                            "抖音",
                            "快手",
                            "小红书",
                            "视频号"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的发布平台"
                    },
                    {
                        "label": "视频主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您视频的主题"
                    },
                    {
                        "label": "视频关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您的视频关键词，以便生成更符合的的创作文案"
                    },
                    {
                        "label": "风格",
                        "options": [
                            "正常",
                            "幽默",
                            "愤怒",
                            "热情",
                            "轻松",
                            "傲慢",
                            "挖苦",
                            "讽刺",
                            "崇敬",
                            "鼓励",
                            "深沉",
                            "反思",
                            "情感共鸣"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择文案风格"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "有强烈的吸引力，可以使用疑问 感叹语气",
                            "有趣幽默，适当运用搞笑、调侃",
                            "情感共鸣，可以讲述一个感人的故事",
                            "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                            "引用名人名言增强说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择创作文案的要求"
                    },
                    {
                        "label": "字数",
                        "options": [
                            "150字以内",
                            "150-300字",
                            "300-450字",
                            "450-600字",
                            "600-750字",
                            "750-900字",
                            "900字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "一分钟大概150字，请根据实际情况选择字数"
                    }
                ]
            },
            {
                "id": 15,
                "name": "短视频拍摄脚本",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474430fd892460992.png",
                "info": "根据镜头场景帮你生成拍摄脚本",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "创作内容",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "汽车",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您创作的类型"
                    },
                    {
                        "label": "创作的主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您创作的主题"
                    },
                    {
                        "label": "关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您创作的关键词"
                    },
                    {
                        "label": "视频受众群体",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入视频受众群体"
                    },
                    {
                        "label": "视频受众群体痛点",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入视频受众群体的痛点"
                    },
                    {
                        "label": "视频时长",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入视频时长，单位是秒"
                    },
                    {
                        "label": "风格要求",
                        "options": [
                            "正常",
                            "幽默搞笑",
                            "愤怒",
                            "讽刺",
                            "深沉",
                            "伤感",
                            "悲痛",
                            "激励",
                            "质疑"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频风格"
                    }
                ]
            },
            {
                "id": 16,
                "name": "短视频文案改写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474433f22b5876775.png",
                "info": "根据短视频文案进行改写，伪原创生成爆款文案",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "模仿文案",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "复制您的文案在此处，进行伪原创"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（50-200字）",
                            "中文案（200-400字）",
                            "长文案（400-800字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 53,
                "name": "营销类短视频脚本",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648145aeeaaf952047.png",
                "info": "自动生成营销类短视频脚本",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "公司品牌信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "包括公司名称、网站以及任何使品牌与众不同的东西（例如其独特的卖点）"
                    },
                    {
                        "label": "视频目的",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请说明视频目的，比如优惠促销等"
                    },
                    {
                        "label": "目标受众",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "定义观众的兴趣和行业"
                    },
                    {
                        "label": "关键信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "列出您想在视频中传达的要点"
                    },
                    {
                        "label": "号召性用语",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "说明您希望观看者在观看视频后采取哪些操作"
                    },
                    {
                        "label": "上下文",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "提供有关您在视频中涵盖的主题的任何其他相关信息，例如您要包含或排除的内容的具体示例"
                    },
                    {
                        "label": "出镜人",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "谁将在视频中出现？他们在镜头前吗？是多人吗？"
                    },
                    {
                        "label": "视频风格",
                        "options": [
                            "正常",
                            "激情饱满",
                            "推荐说服",
                            "轻松喜悦",
                            "制造紧迫感"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频的语气风格"
                    },
                    {
                        "label": "视频长度",
                        "options": [
                            "1分钟",
                            "2分钟",
                            "3分钟",
                            "4分钟",
                            "5分钟",
                            "5分钟以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频长度"
                    }
                ]
            },
            {
                "id": 80,
                "name": "抖音探店文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482aec5d3f7440372.png",
                "info": "生成抖音探店文案",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "商家名称"
                    },
                    {
                        "label": "商家地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "商家地址"
                    },
                    {
                        "label": "商家特色",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写商家的特色和优势"
                    },
                    {
                        "label": "商家具体做什么",
                        "options": [
                            "餐饮",
                            "按摩足疗",
                            "KTV",
                            "健身",
                            "瑜伽",
                            "医美",
                            "美容美发",
                            "汽车维修护理洗车",
                            "超市",
                            "宠物护理及宠物医院",
                            "酒店住宿",
                            "电影演出",
                            "亲子乐园游乐场",
                            "洗浴汗蒸",
                            "生活服务",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择商家具体做什么"
                    },
                    {
                        "label": "商家补充信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "更多需要告知的商家信息"
                    },
                    {
                        "label": "文案内容要求",
                        "options": [
                            "提出痛点，刺激观众",
                            "引用名言或权威数据，增强信任感",
                            "与其他同行对比，强调优势",
                            "以我的视角深入场景描述体验",
                            "尽量口语化的表述"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择对文案内容的要求"
                    },
                    {
                        "label": "文案内容情感风格",
                        "options": [
                            "正常",
                            "惊讶",
                            "喜出望外",
                            "愤怒",
                            "轻松",
                            "正式",
                            "严肃",
                            "讽刺",
                            "强烈说服"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择文案内容风格"
                    },
                    {
                        "label": "视频播放长度",
                        "options": [
                            "1分钟",
                            "2分钟",
                            "3分钟",
                            "4分钟",
                            "5分钟"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择视频播放长度"
                    }
                ]
            },
            {
                "id": 17,
                "name": "短视频口播稿文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/64744b019993029734.png",
                "info": "帮您生成适合口播的短视频文案",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的创作类型"
                    },
                    {
                        "label": "文稿主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入文稿的主题"
                    },
                    {
                        "label": "文稿观点",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的观点"
                    },
                    {
                        "label": "字数",
                        "options": [
                            "150字以内",
                            "150-300字",
                            "300-450字",
                            "450-600字",
                            "600-750字",
                            "750-900字",
                            "900字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "一分钟大概150字"
                    },
                    {
                        "label": "内容风格",
                        "options": [
                            "正常",
                            "幽默搞笑",
                            "热情奔放",
                            "悲痛",
                            "伤感",
                            "喜悦",
                            "讽刺",
                            "激励鼓励",
                            "说服购买",
                            "惊讶",
                            "情感共鸣"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择内容风格"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "前30字有强烈的吸引力，可以使用疑问 感叹语气",
                            "有趣幽默，适当运用搞笑、调侃",
                            "情感共鸣，可以讲述一个感人的故事",
                            "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                            "引用名人名言增强说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择内容要求，生成更好的内容"
                    }
                ]
            },
            {
                "id": 54,
                "name": "吸睛开头创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64814c4f8cf5523459.png",
                "info": "创作短视频吸引眼球的开头",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "视频内容文本",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入视频大概的视频内容"
                    },
                    {
                        "label": "视频风格",
                        "options": [
                            "正常",
                            "激情",
                            "深沉",
                            "喜悦",
                            "说服",
                            "质疑",
                            "讽刺",
                            "骄傲"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频风格"
                    }
                ]
            },
            {
                "id": 55,
                "name": "带货短视频创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648150bb7d93477647.png",
                "info": "创作带货类别的短视频",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "输入产品或服务名称"
                    },
                    {
                        "label": "产品优势及特色",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "你的产品或服务有什么特别的地方"
                    },
                    {
                        "label": "优惠福利",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "你的产品有哪些优惠、折扣或福利"
                    },
                    {
                        "label": "开头文案",
                        "options": [
                            "名人推荐增强信任，比如：XX真诚推荐",
                            "使用惊讶语气增强好奇心，比如：不会还有人不知道……",
                            "夸张产品使用效果，比如：我用了X天就……",
                            "低价与效果对比反差，比如：X元能买到这种好东西……",
                            "第一人称视角拉近距离，比如：我已经买了好多次了"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择想要的开头文案示例"
                    },
                    {
                        "label": "黄金3秒开头",
                        "options": [
                            "提出痛点问题引发观众思考",
                            "提出与产品关联的社会现象",
                            "制造反差冲突吸引观众了解产品",
                            "利用优惠福利诱惑消费者"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择黄金3秒的开头形式"
                    },
                    {
                        "label": "介绍产品的方式",
                        "options": [
                            "从原料上，打消消费者顾虑",
                            "从工艺上，强调产品质量和优势",
                            "从结果上，突出产品的使用效果和用户体验",
                            "从功能上，介绍消费者痛点"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择如何介绍你的产品"
                    },
                    {
                        "label": "促销的方式",
                        "options": [
                            "将价格换算成另外一个便宜东西-例：X杯奶茶钱就能买到",
                            "低价并且包邮的营销方式-例：X元到手还包邮....",
                            "和以前的价格做对比突出优惠-例：以前要XX元才能买到，现在XX元还包邮....",
                            "购买有赠品等福利-例：买XX还送XX...."
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择如何促销你的产品"
                    },
                    {
                        "label": "引导促进成交话术",
                        "options": [
                            "直击目标人群的痛点-例：有什么痛点的人群，一定要购买....",
                            "锁定目标人群引导购买-例：家里有XX的一定要...",
                            "利用口碑引导购买-例：凡是购买过的..",
                            "饥饿营销，制造紧迫感-例：数量有限...."
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择引导促进成交的话术"
                    }
                ]
            },
            {
                "id": 185,
                "name": "影视解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530a6df50e4477504.png",
                "info": "生成各类影视解说文案",
                "cat_id": 30,
                "user_form": [
                    {
                        "label": "影视节目名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视节目的名称"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的内容要求"
                    },
                    {
                        "label": "视频时长",
                        "options": [
                            "短视频（30-120秒）",
                            "中视频（120-300秒）",
                            "长视频（300-600秒）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "短视频（30-120秒）"
                    }
                ]
            }
        ],
        "arrKey": "list30"
    },
    {
        "id": 31,
        "parent_id": 29,
        "org_id": 1,
        "cat_name": "直播",
        "cat_info": "根据直播主题，生成直播口播稿",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64715f839b8cb68099.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 18,
                "name": "直播带货口播文稿",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64717344793b257870.png",
                "info": "帮你生成直播带货话术文稿",
                "cat_id": 31,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您带货的产品"
                    },
                    {
                        "label": "产品的亮点提炼",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的产品的亮点"
                    },
                    {
                        "label": "优惠政策",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您产品的优惠机制"
                    }
                ]
            },
            {
                "id": 56,
                "name": "开场聚人阶段口播创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481717e6fb1992065.png",
                "info": "在直播的开场阶段，生成暖场及预告口播文案",
                "cat_id": 31,
                "user_form": [
                    {
                        "label": "产品介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入要直播的产品简介"
                    },
                    {
                        "label": "目标人群",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写你的目标营销人群"
                    },
                    {
                        "label": "福利预告",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "直播的福利预告"
                    }
                ]
            },
            {
                "id": 57,
                "name": "直播中留客锁客口播创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64817e7b7619932910.png",
                "info": "直播过程中留下客户 锁定客户的口播文案",
                "cat_id": 31,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入产品名称"
                    },
                    {
                        "label": "互动话术",
                        "options": [
                            "这款产品大家以前用过吗？如果不清楚的可以扣1，我再来给大家讲解",
                            "宝宝们如果有不清楚拍什么的可以把你们的疑问打出来",
                            "大家能听到我的声音吗？听到的请打1，谢谢大家",
                            "想要X的刷1，想要X的刷2",
                            "想要X宝贝的宝宝们，我们满屏刷起来好不好，要福利的宝宝把1刷起来",
                            "新来的宝贝先点一下关注，可以领取到一个粉丝特价价格哦",
                            "宝宝们现在先不要下单，这款领了优惠券之后再下单哈",
                            "新涌进来的宝宝们，我们现在正在给大家做福利秒杀，现在点赞十下并给主播点一个关注可以领取一个优惠券哦",
                            "宝宝们，什么是爱什么是喜欢？爱是点关注，喜欢是刷礼物。有一种陪伴叫带我看花海！你们是爱我还是喜欢我呢！",
                            "欢迎各位帅哥美女们来到我的直播间，进来直播间的是美女，还是帅哥呢？帅哥请刷1，美女请刷2"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择希望在直播间出现的互动话术"
                    },
                    {
                        "label": "锁客话术",
                        "options": [
                            "展示产品的质量、使用感受和其他渠道对比的价格优势",
                            "多强调“我”用过且喜欢，并强调“我”在使用产品时的感受",
                            "从专业的角度出发，针对产品以及同类其他产品的材质等进行详细讲解，增加粉丝的信任度",
                            "现场试用产品，分享使用体验与效果，验证产品功能"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择锁客话术进行创作"
                    },
                    {
                        "label": "其他要求",
                        "formType": "textarea",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 58,
                "name": "直播催单下单口播文案创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648180d14891d80735.png",
                "info": "创作引导粉丝购买 催单及下单的口播文案",
                "cat_id": 31,
                "user_form": [
                    {
                        "label": "产品介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写产品相关的特色 优势 功效等信息"
                    },
                    {
                        "label": "优惠介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "介绍赠送 优惠 折扣等福利信息"
                    },
                    {
                        "label": "说服购买话术",
                        "options": [
                            "从产品的功效、价位、成分、包装设计、促销力度和现场使用结果等，与竞品对比，进一步帮用户排除选择",
                            "通过折扣、优惠、福利等信息进一步刺激粉丝，让粉丝有紧迫感并且怕错过这些福利",
                            "构建美好的场景，让用户体会到购买后的好处，满足用户的想象和虚荣心"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择需要的说服话术"
                    },
                    {
                        "label": "催单话术",
                        "options": [
                            "我们之前试播的时候，这个产品一上架就秒空了，超级抢手，所以进来宝宝千万不要犹豫，下单就行了。",
                            "这款产品数量有限，如果看中了一定要及时下单，不然等会儿就抢不到了哦。",
                            "抢到就是赚到、秒杀单品数量有限”/“先付先得、最后2分钟！最后2分钟",
                            "这次活动的力度真的很大，您可以再加一套的，很划算，错过真的很可惜。这个产品还有最后两件咯",
                            "点商品链接、大家尽快点优惠券、点立即购买、填数量、点确认了"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择包含哪些催单话术"
                    },
                    {
                        "label": "感谢话术",
                        "options": [
                            "感谢你们来观看我的直播，谢谢你们的礼物，陪伴是最长情的告白，你们的爱意我收到了",
                            "最后一首歌《XXXX》谢谢大家的陪伴，唱完下播，希望大家睡个好觉做个好梦，明天新的一天好好工作，晚上我们再聚",
                            "今天的直播接近尾声了，明天晚上 XX 点~XX 点同样时间开播/明天会提早一点播 XX 点就开了，各位奔走相告吧！"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "下单后感谢粉丝的话术"
                    }
                ]
            }
        ],
        "arrKey": "list31"
    }
]
let a4 = [
    {
        "id": 42,
        "parent_id": 40,
        "org_id": 1,
        "cat_name": "数码电器",
        "cat_info": "",
        "cat_icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230722/64bb7e1740d8089666.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 13:40:20",
        "updated_at": "2023-07-22 20:46:45",
        "deleted_at": null,
        "app_list": [
            {
                "id": 92,
                "name": "按摩椅",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230722/64bb53a5237f195615.png",
                "info": "可为不同品牌的【按摩椅】生成针对【特定人群】的卖点文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择输出语言",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称，示例：奥克斯"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容，示例：家用按摩椅 全身零重力全自动 多功能电动按摩椅沙发椅子 3D智能语音"
                    },
                    {
                        "label": "目标人群",
                        "options": [
                            "办公室职员",
                            "运动员",
                            "中老年人",
                            "外贸销售人员",
                            "家庭使用者"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的目标人群",
                        "defaultValue": "中老年人"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "patternIds": [],
                        "newKeywords": "",
                        "placeholder": "请输入产品的关键词",
                        "defaultValue": [
                            "舒适性",
                            "健康",
                            "多功能性",
                            "便捷性"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "超短文案（60字内）"
                    }
                ]
            },
            {
                "id": 93,
                "name": "电视",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf4934f3d33938.png",
                "info": "可为不同品牌的家用电器生成针对特定人群的卖点文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "液晶电视",
                            "激光电视",
                            "游戏电视",
                            "会议电视",
                            "艺术电视"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "平板电视"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "patternIds": [],
                        "placeholder": "请输入产品的描述内容，如60寸超薄高清全面屏，活动只要3888元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "patternIds": [],
                        "newKeywords": "",
                        "defaultValue": [
                            "4K高清",
                            "超薄",
                            "特价"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 94,
                "name": "空调",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf754157812817.png",
                "info": "可为不同品牌的空调生成针对特定人群的卖点文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容，如一级能效1.5匹变频空调，目前活动价1288元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入产品的核心卖点",
                        "defaultValue": [
                            "变频",
                            "冷暖两用",
                            "自动除菌",
                            "新一级能效",
                            "超省电",
                            "1.5匹"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择文案字数",
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择想要的文案风格",
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 95,
                "name": "洗衣机",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf58ba80e33914.png",
                "info": "可为不同品牌的洗衣机生成卖点文案，为对应品牌的洗衣机做产品介绍",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择输出语言",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容，如10公斤全自动滚筒洗衣机洗烘一体机 热风除菌 智能烘干，目前活动价2588元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "除菌",
                            "全自动",
                            "烘干"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择文案字数",
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择文案风格",
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 96,
                "name": "冰箱",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c0bc939fa9671513.png",
                "info": "可为不同品牌的冰箱生成卖点文案，为对应品牌的冰箱做产品介绍",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容，如双开门变频冰箱，一级能效，净味除菌，目前活动价2588元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "除菌",
                            "液晶显示屏",
                            "双开门"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 98,
                "name": "笔记本电脑",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c08a0170a5939782.png",
                "info": "生成各种品牌型号笔记本的测评、行情等营销文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容，如联想笔记本电脑小新pro16轻薄本 酷睿i5，目前活动价4588元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "defaultValue": [
                            "便携性",
                            "高配置",
                            "低能耗",
                            "超薄本",
                            "高性能"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 99,
                "name": "厨房小家电",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c08ceb74ff038011.png",
                "info": "厨房小家电包含电饭煲、微波炉、电磁炉、空气炸锅、咖啡机、榨汁机、破壁机、热水壶等，可为不同品牌的产品生成卖点文案和产品介绍",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "电饭煲",
                            "微波炉",
                            "电磁炉",
                            "空气炸锅",
                            "咖啡机",
                            "榨汁机",
                            "破壁机",
                            "热水壶"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "电饭煲"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "家用",
                            "触控式",
                            "新品"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 100,
                "name": "厨卫大家电",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf91b3f8612817.png",
                "info": "厨卫大家电包含油烟机、集成灶、消毒柜、洗碗机、电热水器等，可为不同品牌的产品生成卖点文案和产品介绍",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "油烟机",
                            "集成灶",
                            "消毒柜",
                            "洗碗机",
                            "电热水器"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "油烟机"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入产品的描述内容"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "智能",
                            "一级能效"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 101,
                "name": "耳机",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e3ed47cfc71513.png",
                "info": "生成多种品牌耳机的营销文案，文案内容可多细节展开描述",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：2023年全新款的降噪耳机，活动价108元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "入耳式",
                            "立体环绕",
                            "高效降噪"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 102,
                "name": "平板电脑",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32e69c2f8b31384.png",
                "info": "生成各种品牌型号平板电脑的测评、行情等营销文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，如Apple iPad Pro 11英寸平板电脑 蓝色，目前活动价6888元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "十核安卓",
                            "护眼",
                            "娱乐",
                            "超薄",
                            "二合一",
                            "超清4K"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false
                    }
                ]
            },
            {
                "id": 103,
                "name": "手机",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e66e0177b54520.png",
                "info": "生成各种品牌型号手机的测评、行情等营销文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，如iPhone 14 Pro Max 256GB 暗紫色 支持移动联通电信5G 官方正品，目前活动价9088元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "defaultValue": [
                            "双卡双待",
                            "超清护眼",
                            "超长待机",
                            "闪电快充",
                            "1亿像素",
                            "轻薄设计"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 104,
                "name": "投影仪",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e77c6eaa473566.png",
                "info": "生成多品牌投影仪的营销文案，可从清晰度、对比度等技术维度进行描述",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "标题\t输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：家用投影仪性价比之王，活动价2998元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "智能对焦",
                            "内置音响",
                            "环绕声场"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 105,
                "name": "相机",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e8534677270763.png",
                "info": "生成各种品牌型号数码相机的测评、行情等营销文案",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：新一代vlog相机，4K视频，超广角，大光圈，活动价3888元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "续航高手",
                            "小巧便携",
                            "口袋相机",
                            "4k画质",
                            "全画幅",
                            "数码相机"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 106,
                "name": "音响",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e9463309424366.png",
                "info": "生成专业的音响营销文案，围绕音响的多个参数维度展开详细的描述",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：bose 550优质重低音，殿堂级的听觉享受，活动价108元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "重低音",
                            "声音透明",
                            "清晰"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            },
            {
                "id": 107,
                "name": "智能手表",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1ea5c3704954520.png",
                "info": "可生成各品牌智能手表的营销文案，文案内容可从设计、性能等维度展开阐述",
                "cat_id": 42,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "型号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入商品型号"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：国产品牌的旗舰天花板，活动价999元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "持久续航",
                            "智能唤醒",
                            "多端联动"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "促销活动",
                            "营销软文",
                            "专业介绍",
                            "评测风格"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "促销活动"
                    }
                ]
            }
        ],
        "arrKey": "list42"
    },
    {
        "id": 46,
        "parent_id": 40,
        "org_id": 1,
        "cat_name": "化妆护肤",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-26 10:02:39",
        "updated_at": "2023-07-26 10:02:39",
        "deleted_at": null,
        "app_list": [
            {
                "id": 108,
                "name": "唇膏",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c22e2a7198f73566.png",
                "info": "针对唇膏类产品，生成各种当红博主风格种草、测评文案，包括唇彩、唇膏、唇釉等",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "唇膏",
                            "唇彩",
                            "唇釉",
                            "唇蜜",
                            "润唇膏",
                            "唇膜"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "唇膏"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "高级",
                            "磨砂质感",
                            "复古"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 109,
                "name": "底妆",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c23be70ba7f87342.png",
                "info": "生成各品牌的粉饼、粉底、遮瑕等产品文案",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "粉底",
                            "隔离霜",
                            "粉饼",
                            "素颜霜",
                            "干粉",
                            "定妆喷雾",
                            "底妆"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "粉底"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：妈生伪素颜，清透干净底妆，活动价188"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性",
                            "熬夜党"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "少女感",
                            "原装进口",
                            "丝滑遮瑕",
                            "控油",
                            "持久保湿",
                            "内透亮",
                            "不假白"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 110,
                "name": "护发产品",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c23daa08e5270763.png",
                "info": "可生成各品牌的护发类产品，包括洗发水、发膜、护发素等",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "洗发水",
                            "护发素",
                            "发膜",
                            "焗油膏",
                            "头皮护理"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "洗发水"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：无硅油洗发水，发丝根根分明，养护头皮，活动价28"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性",
                            "熬夜党",
                            "脱发人群"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "无硅油",
                            "天然成分",
                            "防脱发",
                            "无香型",
                            "养护头皮"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 111,
                "name": "护肤精华",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32876e36d976502.png",
                "info": "生成与护肤精华类产品相关的测评、种草、促销类文案，适用于小红书、唯品会、淘宝等种草平台",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：小棕瓶2代，最新修复抗衰老成分，亲测效果显著"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性",
                            "熬夜党",
                            "脱发人群"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "抗衰",
                            "面部美白",
                            "提亮肤色"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 112,
                "name": "化妆水",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c329b83e6e576502.png",
                "info": "生成多类型化妆水的种草文案、测评文案等",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，如加班神器，天然成分爽肤水，有效去除暗黄"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "女性"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "加班熬夜",
                            "去暗黄",
                            "美白",
                            "提亮"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 113,
                "name": "口红",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32b274100055097.png",
                "info": "可为不同场景下的口红生成卖点文案",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "色号",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入口红色号"
                    },
                    {
                        "label": "口红类型",
                        "options": [
                            "日常口红",
                            "持久口红",
                            "哑光口红",
                            "渐变口红",
                            "礼仪口红"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "日常口红"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：YSL圣罗兰小金条口红1966 2.2g 哑光礼盒，活动价288"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "自然",
                            "持久",
                            "保湿",
                            "百搭"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 114,
                "name": "面膜",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32c545513755097.png",
                "info": "围绕面膜产品，生成有真实体验感的测评种草文案，适用于小红书、唯品会、淘宝等种草平台",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：夏季晒后修复面霜，深层补水滋养，水嫩白皙"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性",
                            "熬夜党",
                            "脱发人群"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "美白",
                            "补水",
                            "夏季保养",
                            "晒后养护"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 115,
                "name": "面霜",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32d7b1b8b317114.png",
                "info": "生成与面霜类产品相关的测评、种草、促销类文案，适用于小红书、唯品会、淘宝等种草平台",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：欧莱雅补水面霜滋润不油腻，干皮救星"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性",
                            "熬夜党",
                            "脱发人群"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "修复",
                            "保养",
                            "保湿",
                            "水润"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内",
                            "文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活拨",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 116,
                "name": "香水",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32f455fc3412642.png",
                "info": "可为不同场景下的香水，生成卖点文案",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "香水类型",
                        "options": [
                            "中性香水",
                            "车载香水",
                            "男士香水",
                            "女士香水",
                            "儿童香水"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "中性香水"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，示例：祖玛珑蓝风铃七夕礼盒30ml 生日礼物送女友送男友，活动价588"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "香味持久",
                            "礼盒包装",
                            "清新香氛"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 117,
                "name": "眼霜",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c332674c5be17114.png",
                "info": "专业眼霜营销文案生成模型，适用于小红书、知乎等平台的种草、测评文案",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容，抗皱祛黑眼圈功效甚好"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "女性"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "抗初老",
                            "祛皱",
                            "眼圈"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 118,
                "name": "眼妆",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c3335d6e34954805.png",
                "info": "针对眼妆的需求，可生成眼影、眼线笔等眼妆产品的种草测品文案",
                "cat_id": 46,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": false,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "产品类型",
                        "options": [
                            "眼影",
                            "眼妆",
                            "眼线笔",
                            "眉笔",
                            "卧蚕笔"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "眼影"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入产品的描述内容"
                    },
                    {
                        "label": "目标群体",
                        "options": [
                            "通用",
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "通用"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "温柔",
                            "少女感",
                            "提亮",
                            "防水",
                            "不飞粉"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "测评",
                            "清单",
                            "教程",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            }
        ],
        "arrKey": "list46"
    },
    {
        "id": 47,
        "parent_id": 40,
        "org_id": 1,
        "cat_name": "服饰鞋包",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-26 10:02:46",
        "updated_at": "2023-07-26 10:02:46",
        "deleted_at": null,
        "app_list": [
            {
                "id": 119,
                "name": "包包",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c334b64aa4a73356.png",
                "info": "可根据需求生成多种风格男包女包文案",
                "cat_id": 47,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "目标人群",
                        "options": [
                            "女性",
                            "男性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "女性"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如真牛皮男包商务风，双层拉链，限时活动199元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "大容量",
                            "牛皮"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 120,
                "name": "服装",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c33598591ca53010.png",
                "info": "可根据需求生成不同年龄多种风格的服装卖点文案",
                "cat_id": 47,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "目标人群",
                        "options": [
                            "女装",
                            "男装",
                            "男童装",
                            "女童装",
                            "婴儿"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "女装"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请详细输入产品的描述内容，建议包含适合年龄、风格、面料等属性，如20-25岁甜美风格女装夏季连衣裙，波西米亚风，修身显瘦，桑蚕丝面料，限时活动99元",
                        "defaultValue": ""
                    },
                    {
                        "label": "适合季节",
                        "options": [
                            "春季",
                            "夏季",
                            "秋季",
                            "冬季"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "",
                        "defaultValue": "春季"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "吸汗",
                            "时尚",
                            "宽松",
                            "个性印花"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺",
                            "质疑",
                            "真诚",
                            "鼓励",
                            "亲切"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 121,
                "name": "鞋子",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c337127eb0d31384.png",
                "info": "可根据需求生成多种风格的鞋子卖点文案",
                "cat_id": 47,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "目标人群",
                        "options": [
                            "女鞋",
                            "男鞋"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "女鞋"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如牛皮女鞋商务风格，限时活动99元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "防滑",
                            "透气",
                            "时尚"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            },
            {
                "id": 122,
                "name": "珠宝首饰",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c337e28bc8814025.png",
                "info": "可根据需求生成珠宝首饰文案，包括项链、戒指、耳环等",
                "cat_id": 47,
                "user_form": [
                    {
                        "label": "输出语言",
                        "options": [
                            "简体中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "简体中文"
                    },
                    {
                        "label": "适合人群",
                        "options": [
                            "女性",
                            "男性",
                            "通用"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "女性"
                    },
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如淡水珍珠项链S925银，时尚高贵气质，限时活动999元"
                    },
                    {
                        "label": "核心卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "newKeywords": "",
                        "placeholder": "请输入核心卖点",
                        "defaultValue": [
                            "S925",
                            "黄金",
                            "珍珠吊坠",
                            "镶钻"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "超短文案（60字内）",
                            "短文案（60-150字内）",
                            "中文案（150-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "超短文案（60字内）"
                    },
                    {
                        "label": "文案类型",
                        "options": [
                            "种草",
                            "促销"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "种草"
                    },
                    {
                        "label": "行文风格",
                        "options": [
                            "严谨",
                            "接地气",
                            "活泼",
                            "文艺"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "严谨"
                    }
                ]
            }
        ],
        "arrKey": "list47"
    }
]
let a5 = [
    {
        "id": 87,
        "parent_id": 86,
        "org_id": 1,
        "cat_name": "影视解说",
        "cat_info": "",
        "cat_icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65790fcfe123190895.jpg",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-12-13 09:58:41",
        "updated_at": "2023-12-13 09:58:41",
        "deleted_at": null,
        "app_list": [
            {
                "id": 216,
                "name": "影视缩写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240313/65f136aa658a281969.png",
                "info": "把已有的影视内容缩减成解说文案",
                "cat_id": 87,
                "user_form": [
                    {
                        "label": "影视剧名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视剧的名称"
                    },
                    {
                        "label": "影视内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请放入原来影视作品的内容"
                    },
                    {
                        "label": "创作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "建议",
                            "鼓励",
                            "热情",
                            "质疑"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 193,
                "name": "影视解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/657912aad0ba273239.jpg",
                "info": "生成任意影视剧的解说文案",
                "cat_id": 87,
                "user_form": [
                    {
                        "label": "影视剧名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视剧的名称"
                    },
                    {
                        "label": "影视剧类型",
                        "options": [
                            "电影",
                            "电视剧",
                            "纪录片",
                            "综艺节目",
                            "动画片"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选对应的类型"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请详细输入您的内容要求，如解说电视剧西游记第1集或其他要求，输入越详细效果越好"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）",
                            "超长文章（1000-1600字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 192,
                "name": "影视点评",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/6579102b5392368311.jpg",
                "info": "生成任意影视剧点评文案",
                "cat_id": 87,
                "user_form": [
                    {
                        "label": "影视剧名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入影视剧的名称"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的内容要求，如第几集或其他要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "真诚",
                            "质疑",
                            "热情",
                            "鼓励",
                            "建议",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）",
                            "超长文章（1000-1600字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 197,
                "name": "影视推荐",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65791fd7bcf1945021.jpg",
                "info": "为你推荐适合的影视剧，并给出推荐理由",
                "cat_id": 87,
                "user_form": [
                    {
                        "label": "影视分类",
                        "options": [
                            "电影",
                            "电视剧",
                            "综艺节目"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "内容类型",
                        "options": [
                            "不限类型",
                            "喜剧",
                            "爱情",
                            "悬疑",
                            "动画",
                            "武侠",
                            "古装",
                            "家庭",
                            "犯罪",
                            "科幻",
                            "恐怖",
                            "历史",
                            "战争",
                            "动作",
                            "冒险",
                            "传记",
                            "剧情",
                            "奇幻",
                            "惊悚",
                            "灾难"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "不限类型"
                    },
                    {
                        "label": "地区",
                        "options": [
                            "不限地区",
                            "中国大陆",
                            "欧美",
                            "国外",
                            "韩国",
                            "日本",
                            "中国香港",
                            "美国",
                            "英国",
                            "泰国",
                            "中国台湾",
                            "意大利",
                            "法国",
                            "德国",
                            "俄罗斯",
                            "印度"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "中国大陆"
                    },
                    {
                        "label": "年代",
                        "options": [
                            "2023年",
                            "2022年",
                            "2021年",
                            "2020年",
                            "2019年",
                            "2010年代",
                            "2000年代",
                            "90年代",
                            "80年代",
                            "70年代",
                            "60年代"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "2023年"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "真诚",
                            "质疑",
                            "热情",
                            "鼓励",
                            "建议",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list87"
    },
    {
        "id": 88,
        "parent_id": 86,
        "org_id": 1,
        "cat_name": "书籍解说",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-12-13 09:58:48",
        "updated_at": "2023-12-13 09:58:48",
        "deleted_at": null,
        "app_list": [
            {
                "id": 194,
                "name": "书籍点评",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/657914a1f265d65821.png",
                "info": "生成任意书籍点评文案",
                "cat_id": 88,
                "user_form": [
                    {
                        "label": "书籍名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入书籍的名称，如：红楼梦"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的内容要求，如：第几章节等要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "真诚",
                            "质疑",
                            "热情",
                            "鼓励",
                            "建议",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）",
                            "超长文章（1000-1600字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 195,
                "name": "书籍解说",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/6579165e5cb7976733.jpg",
                "info": "生成任意书籍解说文案",
                "cat_id": 88,
                "user_form": [
                    {
                        "label": "书籍名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入书籍的名称，如：红楼梦"
                    },
                    {
                        "label": "内容要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的内容要求，如：第几章节或其他创作要求"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）",
                            "超长文章（1000-1600字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 196,
                "name": "书籍推荐",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65791cc67e45122025.jpg",
                "info": "为你挑选适合的书，并给出推荐理由",
                "cat_id": 88,
                "user_form": [
                    {
                        "label": "国内外书籍",
                        "options": [
                            "国内书籍",
                            "国外书籍"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "国内书籍"
                    },
                    {
                        "label": "观看人群",
                        "options": [
                            "儿童",
                            "青年人",
                            "老年人"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "青年人"
                    },
                    {
                        "label": "书籍类型",
                        "options": [
                            "文艺",
                            "励志",
                            "人文社科",
                            "生活",
                            "经营",
                            "科技",
                            "教育",
                            "童书",
                            "漫画",
                            "小说"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "文艺"
                    },
                    {
                        "label": "推荐书籍数量",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择为你推荐的书籍数量"
                    },
                    {
                        "label": "其他要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入你的其他要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "幽默",
                            "真诚",
                            "质疑",
                            "热情",
                            "鼓励",
                            "建议",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正常"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list88"
    }
]
let a6 = [
    {
        "id": 24,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "抖音",
        "cat_info": "抖音相关内容创作",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230613/6487bc513e3b989442.png",
        "is_industry": 1,
        "sort": 43,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-09-14 15:58:37",
        "deleted_at": null,
        "app_list": [
            {
                "id": 14,
                "name": "短视频文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/6471635504bad88861.png",
                "info": "帮你生成极具吸引力的短视频文案",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "汽车",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的创作类型"
                    },
                    {
                        "label": "发布平台",
                        "options": [
                            "抖音",
                            "快手",
                            "小红书",
                            "视频号"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的发布平台"
                    },
                    {
                        "label": "视频主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您视频的主题"
                    },
                    {
                        "label": "视频关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您的视频关键词，以便生成更符合的的创作文案"
                    },
                    {
                        "label": "风格",
                        "options": [
                            "正常",
                            "幽默",
                            "愤怒",
                            "热情",
                            "轻松",
                            "傲慢",
                            "挖苦",
                            "讽刺",
                            "崇敬",
                            "鼓励",
                            "深沉",
                            "反思",
                            "情感共鸣"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择文案风格"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "有强烈的吸引力，可以使用疑问 感叹语气",
                            "有趣幽默，适当运用搞笑、调侃",
                            "情感共鸣，可以讲述一个感人的故事",
                            "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                            "引用名人名言增强说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择创作文案的要求"
                    },
                    {
                        "label": "字数",
                        "options": [
                            "150字以内",
                            "150-300字",
                            "300-450字",
                            "450-600字",
                            "600-750字",
                            "750-900字",
                            "900字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "一分钟大概150字，请根据实际情况选择字数"
                    }
                ]
            },
            {
                "id": 15,
                "name": "短视频拍摄脚本",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474430fd892460992.png",
                "info": "根据镜头场景帮你生成拍摄脚本",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "创作内容",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "汽车",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您创作的类型"
                    },
                    {
                        "label": "创作的主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您创作的主题"
                    },
                    {
                        "label": "关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您创作的关键词"
                    },
                    {
                        "label": "视频受众群体",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入视频受众群体"
                    },
                    {
                        "label": "视频受众群体痛点",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入视频受众群体的痛点"
                    },
                    {
                        "label": "视频时长",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入视频时长，单位是秒"
                    },
                    {
                        "label": "风格要求",
                        "options": [
                            "正常",
                            "幽默搞笑",
                            "愤怒",
                            "讽刺",
                            "深沉",
                            "伤感",
                            "悲痛",
                            "激励",
                            "质疑"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频风格"
                    }
                ]
            },
            {
                "id": 16,
                "name": "短视频文案改写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474433f22b5876775.png",
                "info": "根据短视频文案进行改写，伪原创生成爆款文案",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "模仿文案",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "复制您的文案在此处，进行伪原创"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（50-200字）",
                            "中文案（200-400字）",
                            "长文案（400-800字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 53,
                "name": "营销类短视频脚本",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648145aeeaaf952047.png",
                "info": "自动生成营销类短视频脚本",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "公司品牌信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "包括公司名称、网站以及任何使品牌与众不同的东西（例如其独特的卖点）"
                    },
                    {
                        "label": "视频目的",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请说明视频目的，比如优惠促销等"
                    },
                    {
                        "label": "目标受众",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "定义观众的兴趣和行业"
                    },
                    {
                        "label": "关键信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "列出您想在视频中传达的要点"
                    },
                    {
                        "label": "号召性用语",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "说明您希望观看者在观看视频后采取哪些操作"
                    },
                    {
                        "label": "上下文",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "提供有关您在视频中涵盖的主题的任何其他相关信息，例如您要包含或排除的内容的具体示例"
                    },
                    {
                        "label": "出镜人",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "谁将在视频中出现？他们在镜头前吗？是多人吗？"
                    },
                    {
                        "label": "视频风格",
                        "options": [
                            "正常",
                            "激情饱满",
                            "推荐说服",
                            "轻松喜悦",
                            "制造紧迫感"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频的语气风格"
                    },
                    {
                        "label": "视频长度",
                        "options": [
                            "1分钟",
                            "2分钟",
                            "3分钟",
                            "4分钟",
                            "5分钟",
                            "5分钟以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频长度"
                    }
                ]
            },
            {
                "id": 80,
                "name": "抖音探店文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482aec5d3f7440372.png",
                "info": "生成抖音探店文案",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "商家名称"
                    },
                    {
                        "label": "商家地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "商家地址"
                    },
                    {
                        "label": "商家特色",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写商家的特色和优势"
                    },
                    {
                        "label": "商家具体做什么",
                        "options": [
                            "餐饮",
                            "按摩足疗",
                            "KTV",
                            "健身",
                            "瑜伽",
                            "医美",
                            "美容美发",
                            "汽车维修护理洗车",
                            "超市",
                            "宠物护理及宠物医院",
                            "酒店住宿",
                            "电影演出",
                            "亲子乐园游乐场",
                            "洗浴汗蒸",
                            "生活服务",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择商家具体做什么"
                    },
                    {
                        "label": "商家补充信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "更多需要告知的商家信息"
                    },
                    {
                        "label": "文案内容要求",
                        "options": [
                            "提出痛点，刺激观众",
                            "引用名言或权威数据，增强信任感",
                            "与其他同行对比，强调优势",
                            "以我的视角深入场景描述体验",
                            "尽量口语化的表述"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择对文案内容的要求"
                    },
                    {
                        "label": "文案内容情感风格",
                        "options": [
                            "正常",
                            "惊讶",
                            "喜出望外",
                            "愤怒",
                            "轻松",
                            "正式",
                            "严肃",
                            "讽刺",
                            "强烈说服"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择文案内容风格"
                    },
                    {
                        "label": "视频播放长度",
                        "options": [
                            "1分钟",
                            "2分钟",
                            "3分钟",
                            "4分钟",
                            "5分钟"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择视频播放长度"
                    }
                ]
            },
            {
                "id": 17,
                "name": "短视频口播稿文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/64744b019993029734.png",
                "info": "帮您生成适合口播的短视频文案",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "三农",
                            "美食",
                            "文化教育",
                            "科技",
                            "旅行",
                            "校园",
                            "时尚",
                            "体育",
                            "亲子",
                            "二次元",
                            "娱乐",
                            "才艺",
                            "泛生活",
                            "公益",
                            "动植物",
                            "游戏",
                            "政务媒体",
                            "剧情",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的创作类型"
                    },
                    {
                        "label": "文稿主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入文稿的主题"
                    },
                    {
                        "label": "文稿观点",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的观点"
                    },
                    {
                        "label": "字数",
                        "options": [
                            "150字以内",
                            "150-300字",
                            "300-450字",
                            "450-600字",
                            "600-750字",
                            "750-900字",
                            "900字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "一分钟大概150字"
                    },
                    {
                        "label": "内容风格",
                        "options": [
                            "正常",
                            "幽默搞笑",
                            "热情奔放",
                            "悲痛",
                            "伤感",
                            "喜悦",
                            "讽刺",
                            "激励鼓励",
                            "说服购买",
                            "惊讶",
                            "情感共鸣"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择内容风格"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "前30字有强烈的吸引力，可以使用疑问 感叹语气",
                            "有趣幽默，适当运用搞笑、调侃",
                            "情感共鸣，可以讲述一个感人的故事",
                            "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                            "引用名人名言增强说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择内容要求，生成更好的内容"
                    }
                ]
            },
            {
                "id": 54,
                "name": "吸睛开头创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64814c4f8cf5523459.png",
                "info": "创作短视频吸引眼球的开头",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "视频内容文本",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入视频大概的视频内容"
                    },
                    {
                        "label": "视频风格",
                        "options": [
                            "正常",
                            "激情",
                            "深沉",
                            "喜悦",
                            "说服",
                            "质疑",
                            "讽刺",
                            "骄傲"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择视频风格"
                    }
                ]
            },
            {
                "id": 55,
                "name": "带货短视频创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648150bb7d93477647.png",
                "info": "创作带货类别的短视频",
                "cat_id": 24,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "输入产品或服务名称"
                    },
                    {
                        "label": "产品优势及特色",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "你的产品或服务有什么特别的地方"
                    },
                    {
                        "label": "优惠福利",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "你的产品有哪些优惠、折扣或福利"
                    },
                    {
                        "label": "开头文案",
                        "options": [
                            "名人推荐增强信任，比如：XX真诚推荐",
                            "使用惊讶语气增强好奇心，比如：不会还有人不知道……",
                            "夸张产品使用效果，比如：我用了X天就……",
                            "低价与效果对比反差，比如：X元能买到这种好东西……",
                            "第一人称视角拉近距离，比如：我已经买了好多次了"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择想要的开头文案示例"
                    },
                    {
                        "label": "黄金3秒开头",
                        "options": [
                            "提出痛点问题引发观众思考",
                            "提出与产品关联的社会现象",
                            "制造反差冲突吸引观众了解产品",
                            "利用优惠福利诱惑消费者"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择黄金3秒的开头形式"
                    },
                    {
                        "label": "介绍产品的方式",
                        "options": [
                            "从原料上，打消消费者顾虑",
                            "从工艺上，强调产品质量和优势",
                            "从结果上，突出产品的使用效果和用户体验",
                            "从功能上，介绍消费者痛点"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择如何介绍你的产品"
                    },
                    {
                        "label": "促销的方式",
                        "options": [
                            "将价格换算成另外一个便宜东西-例：X杯奶茶钱就能买到",
                            "低价并且包邮的营销方式-例：X元到手还包邮....",
                            "和以前的价格做对比突出优惠-例：以前要XX元才能买到，现在XX元还包邮....",
                            "购买有赠品等福利-例：买XX还送XX...."
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择如何促销你的产品"
                    },
                    {
                        "label": "引导促进成交话术",
                        "options": [
                            "直击目标人群的痛点-例：有什么痛点的人群，一定要购买....",
                            "锁定目标人群引导购买-例：家里有XX的一定要...",
                            "利用口碑引导购买-例：凡是购买过的..",
                            "饥饿营销，制造紧迫感-例：数量有限...."
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择引导促进成交的话术"
                    }
                ]
            }
        ],
        "arrKey": "list24"
    },
    {
        "id": 18,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "微信",
        "cat_info": "帮您生成朋友圈风格的文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/6470662d46b5a27741.png",
        "is_industry": 0,
        "sort": 42,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-08-11 17:17:49",
        "deleted_at": null,
        "app_list": [
            {
                "id": 23,
                "name": "朋友圈文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194b29f30478116.png",
                "info": "根据发圈目的帮您生成朋友圈文案",
                "cat_id": 18,
                "user_form": [
                    {
                        "label": "发圈目的",
                        "options": [
                            "记录生活",
                            "宣传产品和服务",
                            "促进产品销售",
                            "建立品牌形象",
                            "推广营销活动",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的发圈目的"
                    },
                    {
                        "label": "内容重点描述",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入你要描述的内容重点或者关键词"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "幽默",
                            "深情",
                            "说服",
                            "讽刺",
                            "正常",
                            "深沉",
                            "挖苦",
                            "伤心",
                            "热情",
                            "急迫"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择朋友圈文案风格"
                    },
                    {
                        "label": "书写要求",
                        "options": [
                            "加入客户口碑说服读者",
                            "用利益诱惑引起读者注意力",
                            "用娱乐八卦吸引注意力",
                            "用情感共鸣吸引读者",
                            "激发读者好奇心",
                            "通过痛点刺激读者",
                            "将读者带入场景",
                            "举出真实案例说服读者",
                            "借势明星大咖增加信赖",
                            "用福利优惠吸引读者",
                            "用产品价值吸引读者",
                            "制造稀缺让读者感到紧迫性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "请选择书写朋友圈的要求"
                    }
                ]
            },
            {
                "id": 52,
                "name": "微信群发文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648042d8e9aab40965.png",
                "info": "创作微信群发文案",
                "cat_id": 18,
                "user_form": [
                    {
                        "label": "内容类型",
                        "options": [
                            "优惠促销",
                            "产品上新",
                            "节日问候",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择群发内容的类型"
                    },
                    {
                        "label": "简单说明",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "大概介绍下意图"
                    },
                    {
                        "label": "内容风格",
                        "options": [
                            "正常",
                            "热情",
                            "乞求",
                            "幽默",
                            "深沉",
                            "轻松"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择类型"
                    }
                ]
            }
        ],
        "arrKey": "list18"
    },
    {
        "id": 19,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "大众点评/美团",
        "cat_info": "探店打卡，帮您生成大众点评/美团风格文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/6470664fd67b781431.png",
        "is_industry": 0,
        "sort": 41,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-08-11 17:17:58",
        "deleted_at": null,
        "app_list": [
            {
                "id": 24,
                "name": "探店点评",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/6471941d9f43776009.png",
                "info": "帮商家写探店点评的文案",
                "cat_id": 19,
                "user_form": [
                    {
                        "label": "商家类型",
                        "options": [
                            "美食",
                            "休闲娱乐",
                            "旅游出行",
                            "美发",
                            "美甲",
                            "美睫",
                            "瑜伽",
                            "健身",
                            "舞蹈",
                            "宠物",
                            "电影演出",
                            "学习培训",
                            "拍照写真",
                            "母婴服务",
                            "医学美容",
                            "购物"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择商家的类型"
                    },
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商家的名称"
                    },
                    {
                        "label": "评价等级",
                        "options": [
                            "好评",
                            "中评",
                            "差评"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择评价等级"
                    },
                    {
                        "label": "评价关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入评价的关键词"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "50-100字",
                            "200-500字",
                            "500-1000字",
                            "根据当前输入内容自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求"
                    },
                    {
                        "label": "请输入商家的地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入这个商家的具体地址"
                    },
                    {
                        "label": "感情风格",
                        "options": [
                            "正常",
                            "幽默",
                            "轻松",
                            "喜出望外",
                            "伤感",
                            "愤怒",
                            "讽刺",
                            "挖苦",
                            "鼓励"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择文案风格"
                    },
                    {
                        "label": "热门关键词",
                        "options": [
                            "福利多多",
                            "强烈推荐",
                            "YYDS",
                            "划重点",
                            "好吃到哭",
                            "都给我冲",
                            "吐血推荐",
                            "万万没想到",
                            "建议收藏"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "文案中加入热门关键词"
                    }
                ]
            }
        ],
        "arrKey": "list19"
    },
    {
        "id": 21,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "微博",
        "cat_info": "帮您生成微博的文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/64707314659e099593.png",
        "is_industry": 0,
        "sort": 2,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 26,
                "name": "微博品牌推文",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64717e333b9da29695.png",
                "info": "创作以品牌方或博主口吻的推广博文",
                "cat_id": 21,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "时尚",
                            "汽车",
                            "美食",
                            "母婴",
                            "体育",
                            "房产",
                            "读书",
                            "摄影",
                            "数码",
                            "综艺",
                            "星座",
                            "军事",
                            "家居",
                            "萌宠",
                            "科技",
                            "动漫",
                            "旅游",
                            "美妆",
                            "设计",
                            "历史",
                            "艺术",
                            "健康",
                            "音乐",
                            "游戏",
                            "校园",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作的类型"
                    },
                    {
                        "label": "创作角色",
                        "options": [
                            "品牌方",
                            "博主"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的角色"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容的主题"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入内容关键词"
                    }
                ]
            },
            {
                "id": 44,
                "name": "普通微博创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647ea7d75153118017.png",
                "info": "创作普通微博和产品宣推微博",
                "cat_id": 21,
                "user_form": [
                    {
                        "label": "内容关键词",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简单输入微博中需要包含的关键词"
                    },
                    {
                        "label": "爆款关键词",
                        "options": [
                            "YYDS",
                            "爆款",
                            "小白必看",
                            "正确姿势",
                            "宝藏",
                            "神器",
                            "沉浸式",
                            "手把手",
                            "治愈",
                            "破防",
                            "玩玩没想到",
                            "吹爆",
                            "压箱底",
                            "高级感"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择爆款关键词"
                    },
                    {
                        "label": "风格",
                        "options": [
                            "正常",
                            "幽默",
                            "热情",
                            "急迫",
                            "说服",
                            "深沉",
                            "伤感",
                            "喜悦",
                            "悲痛",
                            "崇敬",
                            "傲慢",
                            "挖苦",
                            "讽刺"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择内容风格"
                    },
                    {
                        "label": "要求",
                        "options": [
                            "加入客户口碑说服读者",
                            "用情感共鸣方式感动读者",
                            "用痛点刺激读者",
                            "用福利优惠诱惑读者",
                            "用名人或名言增加说服力",
                            "用真实案例说服读者",
                            "用价值打动读者"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择创作的要求"
                    }
                ]
            }
        ],
        "arrKey": "list21"
    },
    {
        "id": 20,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "知乎问答",
        "cat_info": "根据您的知乎问题，知乎风格回答你",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/64706a565d12445320.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 25,
                "name": "知乎问答",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647193ad542cc92551.png",
                "info": "根据提问，生成知乎风格的回答",
                "cat_id": 20,
                "user_form": [
                    {
                        "label": "问题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您的问题，例如：如何看新款苹果手机？"
                    },
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "请输入您的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "以问题为导向，将问题阐述清楚之后再进行详细的解答",
                            "引用相关领域的专业术语和数据，准确地表达作者的观点，并且显得更加专业和权威",
                            "突出作者个人的经验和感悟，使文章更加亲切和有感染力，读者也更容易产生共鸣",
                            "采用问答形式，将一个问题分成多个小问题逐一回答",
                            "从多个角度思考问题，探讨问题的各个方面",
                            "引用名人名言，这样能够增强文章的说服力和影响力",
                            "深入剖析问题本质，探讨问题的根源和本质原因",
                            "以具体的案例来证明观点，使文章更加生动、具体和有说服力",
                            "采用故事叙述法，通过一个个小故事来阐述观点和解决问题",
                            "以行动号召结尾，鼓励读者去实践和行动"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择内容要求，写出更高质量的文章"
                    }
                ]
            },
            {
                "id": 184,
                "name": "知乎测评文章",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231018/652fc86d5361511803.png",
                "info": "一键生成知乎测评类文章",
                "cat_id": 20,
                "user_form": [
                    {
                        "label": "主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入主题，如：华为Mate60Pro为什么像素这么高？"
                    },
                    {
                        "label": "作者身份",
                        "options": [
                            "美食专家",
                            "经济学家",
                            "旅行专家",
                            "金融专家",
                            "科技大佬",
                            "母婴专家",
                            "职场专家",
                            "汽车专家",
                            "人文学者",
                            "社会科学专家",
                            "时尚达人",
                            "数码专家",
                            "体育专家",
                            "心理学专家",
                            "艺术大师",
                            "音乐大师",
                            "影视爱好者",
                            "游戏达人",
                            "娱乐专家",
                            "运动健身教练",
                            "动物专家",
                            "自然科学学者",
                            "时尚博主",
                            "职场专家"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "科技大佬"
                    },
                    {
                        "label": "文章口吻",
                        "options": [
                            "新闻稿",
                            "消费者",
                            "讽刺",
                            "搞笑",
                            "口语化",
                            "真诚",
                            "惊喜"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "真诚"
                    },
                    {
                        "label": "大概内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请描述你文章的大概内容"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "100-300字",
                            "400-600字",
                            "700-1000字"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            }
        ],
        "arrKey": "list20"
    },
    {
        "id": 23,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "百度问答",
        "cat_info": "回答百度问答里的问题",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482abed4426652205.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 79,
                "name": "百度知道回答",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482ac0f773b393572.png",
                "info": "根据百度知道里的问题生成答案",
                "cat_id": 23,
                "user_form": [
                    {
                        "label": "问题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入百度知道里的问题"
                    }
                ]
            }
        ],
        "arrKey": "list23"
    },
    {
        "id": 68,
        "parent_id": 16,
        "org_id": 1,
        "cat_name": "小红书",
        "cat_info": "",
        "cat_icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230914/6502bd6159ec774456.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-09-14 15:59:33",
        "updated_at": "2023-09-14 15:59:33",
        "deleted_at": null,
        "app_list": [
            {
                "id": 22,
                "name": "小红书万能笔记",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194c999e1467731.png",
                "info": "啥都能写，根据内容，帮您生成极具吸引力的小红书风格笔记",
                "cat_id": 68,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "好物分享",
                            "时尚穿搭",
                            "商品推广",
                            "美妆个护",
                            "影视",
                            "情感",
                            "家居",
                            "旅行",
                            "健身塑形",
                            "游戏",
                            "职场",
                            "知识科普",
                            "母婴",
                            "室内设计",
                            "搞笑日常",
                            "书单推荐",
                            "珠宝首饰",
                            "手工",
                            "外语学习"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作的类型"
                    },
                    {
                        "label": "内容主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您要写的内容主题"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请尽可能多的输入内容关键词，以便生成更符合您的的内容"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "提出痛点",
                            "刺激客户",
                            "引用名言",
                            "引用数据",
                            "列举例子",
                            "多用对比",
                            "深入场景描述",
                            "口语化表达"
                        ],
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入内容要求：如：提出痛点、引用数据、列举例子等"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "严肃",
                            "愉快",
                            "沉思",
                            "幽默",
                            "激动",
                            "崇敬",
                            "温馨",
                            "安慰",
                            "轻松",
                            "喜悦",
                            "清河",
                            "热情",
                            "质疑",
                            "真诚",
                            "亲切",
                            "鼓励",
                            "建议",
                            "说服",
                            "讽刺",
                            "傲慢"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择一个写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择字数要求",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list68"
    }
]
let a7 = [
    {
        "id": 36,
        "parent_id": 35,
        "org_id": 1,
        "cat_name": "长文章",
        "cat_info": "写长文章",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840abc1756e61994.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 87,
                "name": "写作文",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648410695027997222.png",
                "info": "根据提供的要求写作文",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "作文主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "详细输入作文主题内容"
                    },
                    {
                        "label": "作文要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写作文的其他要求"
                    },
                    {
                        "label": "作文水准",
                        "options": [
                            "小学作文",
                            "初中作文",
                            "高中作文",
                            "大学作文",
                            "雅思作文",
                            "专业知识作文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "200-400字",
                            "400-600字",
                            "600-800字",
                            "800-1000字",
                            "1000-1500字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 86,
                "name": "文章创作及改写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840e7707e0333299.png",
                "info": "可以帮你创作新文章或者改写已有文章",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "内容简介或原文",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写需要生成文章的原文或者文章概要"
                    },
                    {
                        "label": "生成文章的风格要求",
                        "options": [
                            "正常",
                            "活泼",
                            "幽默",
                            "热情",
                            "可爱",
                            "严肃",
                            "崇敬",
                            "愤怒",
                            "讽刺",
                            "激动",
                            "真诚"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "生成文章的风格要求"
                    },
                    {
                        "label": "文章字数要求",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写需要生成文章的大楷字数"
                    },
                    {
                        "label": "文章的其他要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "文章的其他要求"
                    }
                ]
            },
            {
                "id": 85,
                "name": "写新闻稿",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840b08d7c8c22845.png",
                "info": "创作新闻 产品发布  功能上新等类型新闻稿",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "内容要点",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "比如：相关关键词、简单概要"
                    },
                    {
                        "label": "内容框架要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "生成的结果要包括哪些框架内容"
                    },
                    {
                        "label": "内容要求补充信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "有哪些额外要求"
                    },
                    {
                        "label": "新闻稿类型",
                        "options": [
                            "新品发布",
                            "产品更新",
                            "营销活动",
                            "领导活动",
                            "开工开业",
                            "讲座会议等",
                            "其他类型"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择内容类型"
                    },
                    {
                        "label": "新闻稿风格要求",
                        "options": [
                            "正常",
                            "严肃",
                            "庄重",
                            "活泼",
                            "轻松",
                            "幽默",
                            "愤怒",
                            "讽刺",
                            "号召"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择新闻稿用什么风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "500字以下",
                            "500-1000字",
                            "1000-1500字",
                            "1500-2000字",
                            "2000字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "字数要求"
                    }
                ]
            },
            {
                "id": 88,
                "name": "写发言稿",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648415b0967a249471.png",
                "info": "自动生成发言稿 演讲稿",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "发言稿内容概要",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请填写发言稿内容概要"
                    },
                    {
                        "label": "发言稿使用场景",
                        "options": [
                            "开幕词：指比较隆重的大型会议开始时所用的讲话稿",
                            "闭幕词：指较为大型的会议结束时，领导同志所做的要求性的讲话",
                            "会议报告：召开大中型会议时，有关领导代表一定的机关进行中心发言时所使用的文稿",
                            "动员发言：在部署重要工作或活动的会议上，有关领导所使用的用于鼓励人们积极开展此项工作或参加此项活动的文稿",
                            "总结性发言：指某一事项，或某一活动结束后、有关领导对其进行回顾、概括时所使用的文稿。",
                            "指示性发言：指有关领导对特定的机关和人员布置的工作、任务、指出希望和要求并规定某些指导原则时使用的文稿。",
                            "纪念性发言：指有关领导在追忆某一特殊的日子、事件或人物时，所使用的文稿。"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择发言稿用在什么场景"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "500字以内",
                            "500-1000字",
                            "1000-1500字",
                            "1500-2000字",
                            "2000字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "字数要求"
                    },
                    {
                        "label": "演讲稿语气风格",
                        "options": [
                            "正常",
                            "严肃",
                            "庄重",
                            "崇敬",
                            "幽默",
                            "轻松",
                            "喜悦",
                            "急迫",
                            "激情"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择演讲稿语气风格"
                    }
                ]
            },
            {
                "id": 183,
                "name": "PPT大纲",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231018/652fc6c318a9837400.png",
                "info": "根据你的主题帮你生成对应的PPT大纲内容",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "PPT主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的PPT大纲主题内容"
                    },
                    {
                        "label": "PPT内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请描述你的PPT主要内容"
                    }
                ]
            },
            {
                "id": 208,
                "name": "公文写作",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659907c5f020582147.png",
                "info": "专业公文写作，格式规范，逻辑严谨",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "公文主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入清晰准确的公文主题"
                    },
                    {
                        "label": "辅助信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "建议输入相关内容信息，如：发文对象、发文背景、关键词、核心内容等"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "200-400字",
                            "400-600字",
                            "600-1000字",
                            "1000-2000字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 209,
                "name": "申请书",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/6599091ee5c2341795.png",
                "info": "一键撰写各类申请书",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入申请主题"
                    },
                    {
                        "label": "申请事项",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的申请事项"
                    },
                    {
                        "label": "申请原因",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的申请原因"
                    },
                    {
                        "label": "辅助信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请输入其他辅助信息，如个人简介、个人优势等"
                    },
                    {
                        "label": "申请人",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请准确输入申请人信息"
                    }
                ]
            },
            {
                "id": 210,
                "name": "通知公告",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65990a77aca9a87257.png",
                "info": "帮你写各类通知公告内容",
                "cat_id": 36,
                "user_form": [
                    {
                        "label": "主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入你的内容主题"
                    },
                    {
                        "label": "通知对象",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "通知内容概述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入通知内容信息，如：时间、地点、事件等"
                    }
                ]
            }
        ],
        "arrKey": "list36"
    },
    {
        "id": 37,
        "parent_id": 35,
        "org_id": 1,
        "cat_name": "计划总结汇报",
        "cat_info": "写总结 计划 汇报",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6484529b57a0497738.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 89,
                "name": "总结报告",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648454170d21043418.png",
                "info": "自动创作总结报告及汇报",
                "cat_id": 37,
                "user_form": [
                    {
                        "label": "总结报告相关内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写与总结汇报相关的一些内容概要"
                    },
                    {
                        "label": "总结报告的类型",
                        "options": [
                            "思想总结报告",
                            "经济总结报告",
                            "地区总结报告",
                            "部门总结报告",
                            "单位总结报告",
                            "个人总结报告",
                            "月份总结报告",
                            "季度总结报告",
                            "年度总结报告",
                            "三年以上总结报告",
                            "综合性总结",
                            "专题性总结"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "总结报告的类型"
                    },
                    {
                        "label": "字数要求",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入字数要求"
                    }
                ]
            },
            {
                "id": 90,
                "name": "工作计划",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64845694af7ee87003.png",
                "info": "自动创作各种工作计划",
                "cat_id": 37,
                "user_form": [
                    {
                        "label": "工作计划大概内容",
                        "formType": "textarea",
                        "placeholder": "填写大概有哪些工作计划内容"
                    },
                    {
                        "label": "多长时间的工作计划",
                        "options": [
                            "周工作计划",
                            "月工作计划",
                            "季度工作计划",
                            "年度工作计划"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择工作计划时间长度"
                    },
                    {
                        "label": "工作计划书写要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "提出你的具体要求"
                    },
                    {
                        "label": "字数要求",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "需要生成多少字的工作计划"
                    }
                ]
            },
            {
                "id": 211,
                "name": "工作日报/月报/年报",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991373d176212636.png",
                "info": "根据需求快速创作工作日报、月报、年报",
                "cat_id": 37,
                "user_form": [
                    {
                        "label": "类型",
                        "options": [
                            "日报",
                            "周报",
                            "月报",
                            "年报"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "日报"
                    },
                    {
                        "label": "您的职位",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你的职位，如：产品经理"
                    },
                    {
                        "label": "工作内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请概述您的工作内容"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "100-300字",
                            "300-500字",
                            "500-800字",
                            "800字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 212,
                "name": "培训总结",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659914d4d7b8396308.png",
                "info": "快速创作培训总结报告",
                "cat_id": 37,
                "user_form": [
                    {
                        "label": "培训内容",
                        "formType": "textarea",
                        "placeholder": "请输入培训内容，如：培训地点、培训内容、培训感受等"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "200-400字",
                            "400-600字",
                            "600-900字",
                            "900-1300字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            }
        ],
        "arrKey": "list37"
    },
    {
        "id": 38,
        "parent_id": 35,
        "org_id": 1,
        "cat_name": "方案",
        "cat_info": "撰写各种方案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230611/648539384966b87570.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 35,
                "name": "活动策划安排",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6dae9ba9199982.png",
                "info": "策划安排公司活动",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "活动类型",
                        "options": [
                            "员工生日会",
                            "公司集体旅游",
                            "公司体育比赛",
                            "公司拓展活动",
                            "其他互动"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择要安排的活动类型"
                    },
                    {
                        "label": "活动信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请填写活动的要求和补充信息，可以要求推荐具体旅游地点"
                    }
                ]
            },
            {
                "id": 7,
                "name": "商业计划书",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719d398310164462.png",
                "info": "根据企业目标生成商业计划书",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "企划目标",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的招商融资或其它发展目标"
                    },
                    {
                        "label": "项目简介",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入项目的简单介绍"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "公司和项目状况",
                            "未来发展潜力",
                            "企业成长经历",
                            "产品服务",
                            "市场营销",
                            "管理团队",
                            "股权结构",
                            "组织人事",
                            "现今财务状况",
                            "运营方案",
                            "融资方案",
                            "企业战略规划与执行"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择计划书包含哪些内容"
                    },
                    {
                        "label": "其他补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "填写需要补充的信息"
                    }
                ]
            },
            {
                "id": 36,
                "name": "竞品分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6cb5a6ecb79830.png",
                "info": "分析相关竞品",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "填写产品的更多补充信息"
                    }
                ]
            },
            {
                "id": 37,
                "name": "各类方案撰写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6cee01e1652241.png",
                "info": "自动生成各种类型的方案",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "方案类型",
                        "options": [
                            "策划方案",
                            "施工方案",
                            "软件方案",
                            "活动方案",
                            "系统集成方案",
                            "运营方案",
                            "其他方案"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择方案类型"
                    },
                    {
                        "label": "方案名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请填写方案名称"
                    },
                    {
                        "label": "方案信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "补充方案更多信息，达成更好的方案效果"
                    }
                ]
            },
            {
                "id": 60,
                "name": "节日营销活动方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648196f751d4812823.png",
                "info": "为指定商品生成节日营销活动方案",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "输入您的产品名称"
                    },
                    {
                        "label": "目标客户群",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入您的产品对应的目标客户"
                    },
                    {
                        "label": "节日",
                        "options": [
                            "春节",
                            "情人节",
                            "端午节",
                            "清明节",
                            "五一劳动节",
                            "六一儿童节",
                            "国庆节",
                            "七夕情人节",
                            "中秋节",
                            "圣诞节",
                            "万圣节",
                            "父亲节",
                            "母亲节",
                            "复活节",
                            "元旦",
                            "618",
                            "双11",
                            "双12",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择节日"
                    }
                ]
            },
            {
                "id": 82,
                "name": "营销策略",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483ea1b3928843815.png",
                "info": "创作营销策略方案",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "产品及服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品及服务名称"
                    },
                    {
                        "label": "产品及服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品及服务介绍"
                    },
                    {
                        "label": "产品及服务品类行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务所在的品类或者行业"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如：年轻女性 高学历"
                    },
                    {
                        "label": "其他信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "比如产品的优劣势、特性等"
                    },
                    {
                        "label": "营销方案需要包含的内容",
                        "options": [
                            "营销战略制订",
                            "产品全市市场推广",
                            "营销团队建设",
                            "促销政策制定",
                            "专卖体系等特殊销售模式打造",
                            "终端销售业绩提升",
                            "样板市场打造",
                            "分销体系建立",
                            "渠道建设",
                            "直营体系建设",
                            "价格体系建设",
                            "招商策划",
                            "新产品上市策划",
                            "产品规划",
                            "市场定位",
                            "营销诊断",
                            "市场环境分析",
                            "消费心理分析",
                            "产品优势分析",
                            "营销方式和平台的选择"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "营销方案需要包含的内容"
                    },
                    {
                        "label": "选择营销策略组合",
                        "options": [
                            "4P’s营销策略组合",
                            "6P’s营销策略组合",
                            "11P’s营销策略组合"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择营销策略组合"
                    }
                ]
            },
            {
                "id": 83,
                "name": "营销策划方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483f53b1b14448090.png",
                "info": "创作营销活动方案",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务名称"
                    },
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "产品或服务介绍"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "用关键词描述面向的客户群体"
                    }
                ]
            },
            {
                "id": 91,
                "name": "会议活动执行方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230611/6485396589b5120637.png",
                "info": "会议型活动执行方案",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "会议活动介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写会议活动相关的信息"
                    },
                    {
                        "label": "会议活动类型",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "比如：发布会、招商会、营销会等等"
                    },
                    {
                        "label": "活动安排的城市",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写在哪里举办活动"
                    }
                ]
            },
            {
                "id": 213,
                "name": "邀请函",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659915db0e98385161.png",
                "info": "快速创作符合你要求的邀请函",
                "cat_id": 38,
                "user_form": [
                    {
                        "label": "邀请者",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入邀请者信息"
                    },
                    {
                        "label": "受邀者",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入受邀者信息"
                    },
                    {
                        "label": "活动内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入活动内容信息"
                    },
                    {
                        "label": "活动地点",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入活动地点信息"
                    },
                    {
                        "label": "活动时间",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入活动时间信息"
                    }
                ]
            }
        ],
        "arrKey": "list38"
    },
    {
        "id": 39,
        "parent_id": 35,
        "org_id": 1,
        "cat_name": "自媒体",
        "cat_info": "各种自媒体文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230613/6487b8f1dc1c590009.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 27,
                "name": "公众号万能写作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719059076f643805.png",
                "info": "专注微信公众号写作，可以生成各种类型的公众号原创文章",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "品牌推广",
                            "企业宣传",
                            "知识分享",
                            "行业解读",
                            "新闻解读",
                            "教育培训",
                            "旅游攻略",
                            "健康生活",
                            "文化艺术",
                            "产品营销",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您内容的主题"
                    },
                    {
                        "label": "内容描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "输入您内容描述"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "输入内容的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "多使用排比",
                            "引用名人名言",
                            "塑造情绪价值",
                            "段落之间用悬念承接",
                            "表达引起共鸣的三观",
                            "多使用比喻",
                            "多使用对比",
                            "融合热点事件",
                            "引用案例",
                            "挖掘痛点 刺激读者",
                            "有很强的说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "可以选择多个对内容的要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "热情",
                            "挖苦",
                            "鼓励",
                            "共情",
                            "质疑",
                            "说服",
                            "幽默",
                            "深沉",
                            "深情",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 61,
                "name": "餐饮探店文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64819caad4eb178735.png",
                "info": "生成餐饮探店小红书文案",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写商家名称",
                        "defaultValue": ""
                    },
                    {
                        "label": "商家地址",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入商家地址",
                        "defaultValue": ""
                    },
                    {
                        "label": "商家特色",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "defaultValue": [
                            "环境好",
                            "味道好",
                            "服务态度好",
                            "很暖心"
                        ],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "商家招牌菜",
                        "formType": "keywords",
                        "isRequired": true,
                        "newKeywords": "",
                        "placeholder": "可输入多个商家招牌菜",
                        "defaultValue": [],
                        "newKeywordsAdding": false
                    },
                    {
                        "label": "商家类别",
                        "options": [
                            "火锅",
                            "烧烤烤肉",
                            "自助餐",
                            "鱼海鲜楼",
                            "小吃快餐",
                            "饮品店",
                            "面包蛋糕甜品",
                            "食品生鲜",
                            "异域料理",
                            "地方菜系",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "defaultValue": "烧烤烤肉"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "包含有紧迫感和惊喜感的标点符号",
                            "具有挑战性和悬念的表述",
                            "描述具体体验",
                            "使用emoji表情增加活力",
                            "以口语化表述",
                            "通过正面刺激、负面刺激吸引用户眼球"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "标题包含的爆款词",
                        "options": [
                            "YYDS",
                            "好吃到哭",
                            "强烈推荐",
                            "破防了",
                            "治愈",
                            "万万没想到"
                        ],
                        "formType": "select",
                        "isRequired": false
                    },
                    {
                        "label": "文章风格",
                        "options": [
                            "正常",
                            "热情",
                            "喜出望外",
                            "轻松",
                            "愤怒",
                            "讽刺",
                            "强烈推荐"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "文章要求",
                        "options": [
                            "提出痛点",
                            "刺激客户",
                            "引用名言",
                            "引用数据",
                            "多用对比",
                            "深入场景描述",
                            "口语化表达",
                            "以我的视角描述",
                            "每段多用emoji"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "defaultValue": [
                            "提出痛点",
                            "刺激客户"
                        ]
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "短文案（80-150字）",
                            "中文案（200-500字）",
                            "长文案（500-1000字）",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 43,
                "name": "公众号爆款标题",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647e9d7e5b6ef30739.png",
                "info": "生成公众号爆款文章标题",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "给出关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "填写内容关键词"
                    },
                    {
                        "label": "爆款关键词",
                        "options": [
                            "首发",
                            "YYDS",
                            "福利",
                            "神器",
                            "正确姿势",
                            "必备",
                            "爆款",
                            "竟然",
                            "玩玩没想到"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择爆款关键词"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "引导读者思考的疑问式标题",
                            "引发好奇心的悬念标题",
                            "让读者产生情感共鸣",
                            "很有说服力的标题",
                            "吸引眼球的炸裂标题",
                            "刺激读者的标题",
                            "挖掘痛点的标题",
                            "正常普通的标题"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择标题要求"
                    },
                    {
                        "label": "标题风格",
                        "options": [
                            "正常",
                            "深情",
                            "深沉",
                            "急迫",
                            "幽默",
                            "热情",
                            "冷漠",
                            "讽刺",
                            "崇敬",
                            "正式",
                            "说服"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择标题风格"
                    },
                    {
                        "label": "生成数量",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择生成几个标题"
                    }
                ]
            },
            {
                "id": 44,
                "name": "普通微博创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647ea7d75153118017.png",
                "info": "创作普通微博和产品宣推微博",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "内容关键词",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简单输入微博中需要包含的关键词"
                    },
                    {
                        "label": "爆款关键词",
                        "options": [
                            "YYDS",
                            "爆款",
                            "小白必看",
                            "正确姿势",
                            "宝藏",
                            "神器",
                            "沉浸式",
                            "手把手",
                            "治愈",
                            "破防",
                            "玩玩没想到",
                            "吹爆",
                            "压箱底",
                            "高级感"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择爆款关键词"
                    },
                    {
                        "label": "风格",
                        "options": [
                            "正常",
                            "幽默",
                            "热情",
                            "急迫",
                            "说服",
                            "深沉",
                            "伤感",
                            "喜悦",
                            "悲痛",
                            "崇敬",
                            "傲慢",
                            "挖苦",
                            "讽刺"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择内容风格"
                    },
                    {
                        "label": "要求",
                        "options": [
                            "加入客户口碑说服读者",
                            "用情感共鸣方式感动读者",
                            "用痛点刺激读者",
                            "用福利优惠诱惑读者",
                            "用名人或名言增加说服力",
                            "用真实案例说服读者",
                            "用价值打动读者"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择创作的要求"
                    }
                ]
            },
            {
                "id": 23,
                "name": "朋友圈文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194b29f30478116.png",
                "info": "根据发圈目的帮您生成朋友圈文案",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "发圈目的",
                        "options": [
                            "记录生活",
                            "宣传产品和服务",
                            "促进产品销售",
                            "建立品牌形象",
                            "推广营销活动",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择你的发圈目的"
                    },
                    {
                        "label": "内容重点描述",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入你要描述的内容重点或者关键词"
                    },
                    {
                        "label": "文案风格",
                        "options": [
                            "幽默",
                            "深情",
                            "说服",
                            "讽刺",
                            "正常",
                            "深沉",
                            "挖苦",
                            "伤心",
                            "热情",
                            "急迫"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择朋友圈文案风格"
                    },
                    {
                        "label": "书写要求",
                        "options": [
                            "加入客户口碑说服读者",
                            "用利益诱惑引起读者注意力",
                            "用娱乐八卦吸引注意力",
                            "用情感共鸣吸引读者",
                            "激发读者好奇心",
                            "通过痛点刺激读者",
                            "将读者带入场景",
                            "举出真实案例说服读者",
                            "借势明星大咖增加信赖",
                            "用福利优惠吸引读者",
                            "用产品价值吸引读者",
                            "制造稀缺让读者感到紧迫性"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "请选择书写朋友圈的要求"
                    }
                ]
            },
            {
                "id": 25,
                "name": "知乎问答",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647193ad542cc92551.png",
                "info": "根据提问，生成知乎风格的回答",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "问题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您的问题，例如：如何看新款苹果手机？"
                    },
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "请输入您的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "以问题为导向，将问题阐述清楚之后再进行详细的解答",
                            "引用相关领域的专业术语和数据，准确地表达作者的观点，并且显得更加专业和权威",
                            "突出作者个人的经验和感悟，使文章更加亲切和有感染力，读者也更容易产生共鸣",
                            "采用问答形式，将一个问题分成多个小问题逐一回答",
                            "从多个角度思考问题，探讨问题的各个方面",
                            "引用名人名言，这样能够增强文章的说服力和影响力",
                            "深入剖析问题本质，探讨问题的根源和本质原因",
                            "以具体的案例来证明观点，使文章更加生动、具体和有说服力",
                            "采用故事叙述法，通过一个个小故事来阐述观点和解决问题",
                            "以行动号召结尾，鼓励读者去实践和行动"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择内容要求，写出更高质量的文章"
                    }
                ]
            },
            {
                "id": 52,
                "name": "微信群发文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648042d8e9aab40965.png",
                "info": "创作微信群发文案",
                "cat_id": 39,
                "user_form": [
                    {
                        "label": "内容类型",
                        "options": [
                            "优惠促销",
                            "产品上新",
                            "节日问候",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择群发内容的类型"
                    },
                    {
                        "label": "简单说明",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "大概介绍下意图"
                    },
                    {
                        "label": "内容风格",
                        "options": [
                            "正常",
                            "热情",
                            "乞求",
                            "幽默",
                            "深沉",
                            "轻松"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择类型"
                    }
                ]
            }
        ],
        "arrKey": "list39"
    }
]
let a8 = [
    {
        "id": 27,
        "parent_id": 25,
        "org_id": 1,
        "cat_name": "商品评价",
        "cat_info": "商品评价，帮您生成购物风格的文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647067a762c0593982.png",
        "is_industry": 0,
        "sort": 35,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-08-11 17:21:01",
        "deleted_at": null,
        "app_list": [
            {
                "id": 28,
                "name": "商品好评",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719ae30066d20146.png",
                "info": "帮您生成超真实的商品好评文案",
                "cat_id": 27,
                "user_form": [
                    {
                        "label": "发布平台",
                        "options": [
                            "淘宝",
                            "抖音",
                            "天猫",
                            "京东",
                            "唯品会",
                            "拼多多",
                            "微信",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请输入发布平台"
                    },
                    {
                        "label": "商品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商品名称"
                    },
                    {
                        "label": "商品描述",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请简要描述商品的特点"
                    },
                    {
                        "label": "商品卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请添加商品卖点提示词"
                    }
                ]
            },
            {
                "id": 51,
                "name": "虚拟购买评论",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64801b0ad8c1015026.png",
                "info": "生成虚拟评论，引导更多人购买",
                "cat_id": 27,
                "user_form": [
                    {
                        "label": "产品描述",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简单描述你卖的产品"
                    },
                    {
                        "label": "生成条数",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "生成多少条评论"
                    }
                ]
            },
            {
                "id": 81,
                "name": "差评转化",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482b602c3f3579803.png",
                "info": "应对客户差评，转化差评为好评",
                "cat_id": 27,
                "user_form": [
                    {
                        "label": "客户给的差评",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "客户给的差评评论"
                    },
                    {
                        "label": "回复给客户的语气风格",
                        "options": [
                            "正常语气",
                            "卖萌博得同情",
                            "乞求让客户放过",
                            "幽默博客户开心"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "用什么语气回复客户"
                    }
                ]
            }
        ],
        "arrKey": "list27"
    },
    {
        "id": 26,
        "parent_id": 25,
        "org_id": 1,
        "cat_name": "淘宝/京东商品",
        "cat_info": "创作脱颖而出的商品文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647066d39c9e810504.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 19,
                "name": "商品详情文案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647179df74f2424118.png",
                "info": "帮您生成一个商品详情页描述的文案稿",
                "cat_id": 26,
                "user_form": [
                    {
                        "label": "发布平台",
                        "options": [
                            "抖音",
                            "淘宝",
                            "京东",
                            "天猫",
                            "唯品会",
                            "拼多多",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择要发布的平台"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "商品名称",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入商品名称"
                    },
                    {
                        "label": "商品品类",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入商品品类"
                    },
                    {
                        "label": "商品定位",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "请输入商品定位"
                    },
                    {
                        "label": "商品卖点",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "请输入商品卖点"
                    }
                ]
            },
            {
                "id": 20,
                "name": "团购套餐",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647179f9b279d65531.png",
                "info": "帮您生成一个团购套餐的介绍",
                "cat_id": 26,
                "user_form": [
                    {
                        "label": "商家名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入商家名称"
                    },
                    {
                        "label": "套餐名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入套餐名称"
                    },
                    {
                        "label": "套餐合作价格",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入套餐价格"
                    },
                    {
                        "label": "套餐卖点",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入套餐卖点，可包含多个关键词"
                    }
                ]
            },
            {
                "id": 59,
                "name": "商品标题生成",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64818c0f2ca4c65184.png",
                "info": "生成电商平台的商品标题",
                "cat_id": 26,
                "user_form": [
                    {
                        "label": "商品核心名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写商品的核心词，可以两到三个，比如：打底衫 毛衣"
                    },
                    {
                        "label": "商品属性",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "比如：2023款 秋季 纯棉长袖"
                    },
                    {
                        "label": "电商平台",
                        "options": [
                            "抖音",
                            "淘宝",
                            "天猫",
                            "京东",
                            "拼多多",
                            "唯品客",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择哪个电商平台"
                    }
                ]
            }
        ],
        "arrKey": "list26"
    },
    {
        "id": 28,
        "parent_id": 25,
        "org_id": 1,
        "cat_name": "营销活动",
        "cat_info": "生成电商营销活动方案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648196c989cfc85890.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 60,
                "name": "节日营销活动方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648196f751d4812823.png",
                "info": "为指定商品生成节日营销活动方案",
                "cat_id": 28,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "输入您的产品名称"
                    },
                    {
                        "label": "目标客户群",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入您的产品对应的目标客户"
                    },
                    {
                        "label": "节日",
                        "options": [
                            "春节",
                            "情人节",
                            "端午节",
                            "清明节",
                            "五一劳动节",
                            "六一儿童节",
                            "国庆节",
                            "七夕情人节",
                            "中秋节",
                            "圣诞节",
                            "万圣节",
                            "父亲节",
                            "母亲节",
                            "复活节",
                            "元旦",
                            "618",
                            "双11",
                            "双12",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择节日"
                    }
                ]
            },
            {
                "id": 83,
                "name": "营销策划方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483f53b1b14448090.png",
                "info": "创作营销活动方案",
                "cat_id": 28,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务名称"
                    },
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "产品或服务介绍"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "用关键词描述面向的客户群体"
                    }
                ]
            }
        ],
        "arrKey": "list28"
    }
]
let a9 = [
    {
        "id": 4,
        "parent_id": 1,
        "org_id": 1,
        "cat_name": "品牌",
        "cat_info": "品牌营销相关的内容创作",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481a2da450fe77925.png",
        "is_industry": 0,
        "sort": 3,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 66,
                "name": "品牌起名及生成Slogan",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481bf744a4aa81624.png",
                "info": "为你起品牌名字并且生成Slogan",
                "cat_id": 4,
                "user_form": [
                    {
                        "label": "产品所在行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "产品的客户画像",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如：年轻人 女性 高学历"
                    },
                    {
                        "label": "产品的特点优势",
                        "formType": "textarea",
                        "isRequired": true
                    },
                    {
                        "label": "品牌的价值主张",
                        "formType": "input",
                        "isRequired": false
                    },
                    {
                        "label": "品牌起名的要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "比如字数、风格等要求"
                    },
                    {
                        "label": "品牌名字来源要求",
                        "options": [
                            "参考古诗词",
                            "包含某种动物的汉字",
                            "包含某种植物的汉字",
                            "看上去像是外国品牌",
                            "没有来源要求"
                        ],
                        "formType": "select",
                        "isRequired": false
                    },
                    {
                        "label": "品牌Slogan要求",
                        "options": [
                            "传递产品功能",
                            "传递品牌价值",
                            "挖掘用户痛点",
                            "激励客户群体",
                            "通过愿景使命获得用户认同",
                            "通过精神认同强调某种价值观和观点",
                            "引用其他权威机构或品牌增强信赖感",
                            "激发用户从众心理，别人买我也要买",
                            "明确告诉客户需要使用我们的产品",
                            "从使用场景引导客户使用",
                            "用常见的事物比喻产品性能",
                            "提出并承诺最突出的利益点"
                        ],
                        "formType": "select",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 65,
                "name": "产品介绍",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481b570bd8e079457.png",
                "info": "生成吸引客户的产品介绍文案",
                "cat_id": 4,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "产品功能",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "产品优势",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "产品客户画像",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "产品解决的问题",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "包括的内容",
                        "options": [
                            "简介",
                            "功能",
                            "适用对象",
                            "适用人群",
                            "解决的痛点",
                            "优势",
                            "特点",
                            "客户案例",
                            "客户口碑",
                            "工艺",
                            "材料",
                            "产地",
                            "面料",
                            "用户体验",
                            "效果",
                            "功效",
                            "解决的问题",
                            "口感",
                            "适用感受"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "产品介绍需要包括哪些内容"
                    }
                ]
            },
            {
                "id": 62,
                "name": "品牌故事",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481acfa9f6bb11346.png",
                "info": "生成生动的品牌故事",
                "cat_id": 4,
                "user_form": [
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true
                    },
                    {
                        "label": "品牌成立时间",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "比如：1908年7月"
                    },
                    {
                        "label": "品牌所属行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "比如服装生产"
                    },
                    {
                        "label": "品牌相应关键词",
                        "formType": "keywords",
                        "isRequired": true
                    },
                    {
                        "label": "品牌用户画像",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如女性 20-30岁 时尚 高学历"
                    },
                    {
                        "label": "品牌故事风格",
                        "options": [
                            "正常",
                            "激情",
                            "深沉",
                            "轻松",
                            "喜悦",
                            "生机勃勃"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择哪种风格的品牌故事"
                    },
                    {
                        "label": "补充信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "更多品牌的相关信息补充"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "彰显自身品牌的悠久历史，给人留下传统、正宗的品牌印象",
                            "讲述创始人从创业前的窘迫，到创业时的艰辛，再到现在的伟大，来为自己树立来自民众、回归民众的形象",
                            "展现自己的专业度和匠心精神，使受众增强对品牌的信任",
                            "通过对于情感的描述，可以增强消费者对于品牌核心精神的认知",
                            "能体现品牌的思想价值观念，从而引起消费者群体的共鸣"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择故事的内容要求"
                    }
                ]
            },
            {
                "id": 63,
                "name": "公司介绍",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481a48e5750852253.png",
                "info": "生成公司介绍",
                "cat_id": 4,
                "user_form": [
                    {
                        "label": "公司名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "公司主营业务",
                        "formType": "textarea",
                        "isRequired": true
                    },
                    {
                        "label": "公司成立日期",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "比如2023年5月"
                    },
                    {
                        "label": "公司成立地点",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "比如：北京"
                    },
                    {
                        "label": "补充信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "还有哪些信息补充？"
                    }
                ]
            },
            {
                "id": 64,
                "name": "广告语",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481b637cb2f043932.png",
                "info": "生成各种广告语",
                "cat_id": 4,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "产品所属品类",
                        "formType": "input",
                        "isRequired": true
                    },
                    {
                        "label": "产品定位",
                        "formType": "input",
                        "isRequired": true
                    },
                    {
                        "label": "产品特性",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "产品用户画像",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "比如高学历 精英 年轻人 女性"
                    },
                    {
                        "label": "广告投放渠道",
                        "options": [
                            "传统纸媒",
                            "电视",
                            "电梯/地铁/公交",
                            "互联网",
                            "户外广告",
                            "活动推广",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "广告投放目的",
                        "options": [
                            "扩大品牌曝光度",
                            "让更多人知道品牌",
                            "提高产品销量",
                            "树立更好的品牌形象"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "广告文案风格",
                        "options": [
                            "正常",
                            "幽默",
                            "轻松",
                            "讽刺",
                            "严肃",
                            "喜悦",
                            "奔放",
                            "激励",
                            "厚重"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "字数要求",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "生成文案的大概字数，只填写数字即可"
                    }
                ]
            }
        ],
        "arrKey": "list4"
    },
    {
        "id": 3,
        "parent_id": 1,
        "org_id": 1,
        "cat_name": "营销",
        "cat_info": "产品营销及品牌营销",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483e9c60234912367.png",
        "is_industry": 0,
        "sort": 1,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 7,
                "name": "商业计划书",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719d398310164462.png",
                "info": "根据企业目标生成商业计划书",
                "cat_id": 3,
                "user_form": [
                    {
                        "label": "企划目标",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的招商融资或其它发展目标"
                    },
                    {
                        "label": "项目简介",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入项目的简单介绍"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "公司和项目状况",
                            "未来发展潜力",
                            "企业成长经历",
                            "产品服务",
                            "市场营销",
                            "管理团队",
                            "股权结构",
                            "组织人事",
                            "现今财务状况",
                            "运营方案",
                            "融资方案",
                            "企业战略规划与执行"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择计划书包含哪些内容"
                    },
                    {
                        "label": "其他补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "填写需要补充的信息"
                    }
                ]
            },
            {
                "id": 36,
                "name": "竞品分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6cb5a6ecb79830.png",
                "info": "分析相关竞品",
                "cat_id": 3,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "填写产品的更多补充信息"
                    }
                ]
            },
            {
                "id": 60,
                "name": "节日营销活动方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648196f751d4812823.png",
                "info": "为指定商品生成节日营销活动方案",
                "cat_id": 3,
                "user_form": [
                    {
                        "label": "产品名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "输入您的产品名称"
                    },
                    {
                        "label": "目标客户群",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入您的产品对应的目标客户"
                    },
                    {
                        "label": "节日",
                        "options": [
                            "春节",
                            "情人节",
                            "端午节",
                            "清明节",
                            "五一劳动节",
                            "六一儿童节",
                            "国庆节",
                            "七夕情人节",
                            "中秋节",
                            "圣诞节",
                            "万圣节",
                            "父亲节",
                            "母亲节",
                            "复活节",
                            "元旦",
                            "618",
                            "双11",
                            "双12",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择节日"
                    }
                ]
            },
            {
                "id": 82,
                "name": "营销策略",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483ea1b3928843815.png",
                "info": "创作营销策略方案",
                "cat_id": 3,
                "user_form": [
                    {
                        "label": "产品及服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品及服务名称"
                    },
                    {
                        "label": "产品及服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品及服务介绍"
                    },
                    {
                        "label": "产品及服务品类行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务所在的品类或者行业"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如：年轻女性 高学历"
                    },
                    {
                        "label": "其他信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "比如产品的优劣势、特性等"
                    },
                    {
                        "label": "营销方案需要包含的内容",
                        "options": [
                            "营销战略制订",
                            "产品全市市场推广",
                            "营销团队建设",
                            "促销政策制定",
                            "专卖体系等特殊销售模式打造",
                            "终端销售业绩提升",
                            "样板市场打造",
                            "分销体系建立",
                            "渠道建设",
                            "直营体系建设",
                            "价格体系建设",
                            "招商策划",
                            "新产品上市策划",
                            "产品规划",
                            "市场定位",
                            "营销诊断",
                            "市场环境分析",
                            "消费心理分析",
                            "产品优势分析",
                            "营销方式和平台的选择"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "营销方案需要包含的内容"
                    },
                    {
                        "label": "选择营销策略组合",
                        "options": [
                            "4P’s营销策略组合",
                            "6P’s营销策略组合",
                            "11P’s营销策略组合"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择营销策略组合"
                    }
                ]
            },
            {
                "id": 83,
                "name": "营销策划方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483f53b1b14448090.png",
                "info": "创作营销活动方案",
                "cat_id": 3,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务名称"
                    },
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "产品或服务介绍"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "用关键词描述面向的客户群体"
                    }
                ]
            }
        ],
        "arrKey": "list3"
    },
    {
        "id": 2,
        "parent_id": 1,
        "org_id": 1,
        "cat_name": "SEO",
        "cat_info": "根据你的内容帮你生成SEO标题和内容",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230508/6458964228e0f36601.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 4,
                "name": "网页TDK生成",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/64704bc3b870236233.png",
                "info": "帮您生成网页title 关键词和描述",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "网页内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简单描述下网页内容"
                    },
                    {
                        "label": "相关关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "输入网页相关的关键词"
                    },
                    {
                        "label": "生成条数",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "生成几条"
                    }
                ]
            },
            {
                "id": 1,
                "name": "伪原创改写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647048a64194c77988.png",
                "info": "针对SEO的文章进行伪原创改写",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "原始文本",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "粘贴您原始的文本在这里"
                    },
                    {
                        "label": "改写目的",
                        "options": [
                            "SEO优化",
                            "文章复用",
                            "防止抄袭"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "您选择你的改写目的"
                    },
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "填写或复制原始文中的关键词，保证他的关联性"
                    },
                    {
                        "label": "改写篇数",
                        "options": [
                            "1",
                            "2",
                            "3"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请填写伪原创的篇数"
                    }
                ]
            },
            {
                "id": 2,
                "name": "关键词热门相关",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647048ceac5c552522.png",
                "info": "根据你的内容关键词生成热门关键词",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入关键词"
                    },
                    {
                        "label": "关键词个数",
                        "options": [
                            "5",
                            "10",
                            "20",
                            "30",
                            "50",
                            "80",
                            "100",
                            "200"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择生成的关键词个数"
                    }
                ]
            },
            {
                "id": 45,
                "name": "百度下拉词",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800b9380f9f70865.png",
                "info": "获取指定关键词的百度下拉词",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "给出你想要的查询的关键词"
                    }
                ]
            },
            {
                "id": 46,
                "name": "长尾词查询",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800bbc53a6167506.png",
                "info": "查询指定关键词的长尾词",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "关键词",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入需要查询的关键词"
                    }
                ]
            },
            {
                "id": 47,
                "name": "文章扩写",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800cf8aadf156286.png",
                "info": "根据给定的关键词创作符合SEO规则的文章",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "关键词",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "可以填写多个关键词，间隔是空格或者顿号都可以"
                    },
                    {
                        "label": "字数",
                        "options": [
                            "500字以内",
                            "500-1000字",
                            "1000字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择生成多少字的文章"
                    }
                ]
            },
            {
                "id": 48,
                "name": "SEO诊断",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64801254850f238842.png",
                "info": "诊断你网址的seo问题",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "页面地址",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入需要诊断的页面地址"
                    },
                    {
                        "label": "页面核心关键词",
                        "formType": "keywords",
                        "isRequired": true
                    }
                ]
            },
            {
                "id": 49,
                "name": "网站创作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648014101f15b19459.png",
                "info": "做公司官网的时候自动生成各版块文案",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "产品和服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "输入你要做的网站对应的产品和服务简介"
                    },
                    {
                        "label": "网站板块",
                        "options": [
                            "网页标题 关键词和描述",
                            "公司简介",
                            "发展历程",
                            "产品介绍",
                            "服务介绍",
                            "我们的优势",
                            "客户感言",
                            "客户案例",
                            "功能要点",
                            "解决方案"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择你的网站包括的板块"
                    }
                ]
            },
            {
                "id": 50,
                "name": "内容采集源",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648018d4c2df337238.png",
                "info": "根据关键词找对应的公众号采集源",
                "cat_id": 2,
                "user_form": [
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "填写相关的关键词"
                    }
                ]
            }
        ],
        "arrKey": "list2"
    },
    {
        "id": 5,
        "parent_id": 1,
        "org_id": 1,
        "cat_name": "自媒体",
        "cat_info": "自媒体文案",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230613/6487b9337c86c27481.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-07-22 10:00:53",
        "deleted_at": null,
        "app_list": [
            {
                "id": 27,
                "name": "公众号万能写作",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719059076f643805.png",
                "info": "专注微信公众号写作，可以生成各种类型的公众号原创文章",
                "cat_id": 5,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "品牌推广",
                            "企业宣传",
                            "知识分享",
                            "行业解读",
                            "新闻解读",
                            "教育培训",
                            "旅游攻略",
                            "健康生活",
                            "文化艺术",
                            "产品营销",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您内容的主题"
                    },
                    {
                        "label": "内容描述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "输入您内容描述"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "输入内容的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "多使用排比",
                            "引用名人名言",
                            "塑造情绪价值",
                            "段落之间用悬念承接",
                            "表达引起共鸣的三观",
                            "多使用比喻",
                            "多使用对比",
                            "融合热点事件",
                            "引用案例",
                            "挖掘痛点 刺激读者",
                            "有很强的说服力"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "可以选择多个对内容的要求"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正常",
                            "亲切",
                            "讽刺",
                            "热情",
                            "挖苦",
                            "鼓励",
                            "共情",
                            "质疑",
                            "说服",
                            "幽默",
                            "深沉",
                            "深情",
                            "崇敬"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择写作风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "短文章（150-300字）",
                            "中文章（300-600字）",
                            "长文章（600-1000字）"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 43,
                "name": "公众号爆款标题",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647e9d7e5b6ef30739.png",
                "info": "生成公众号爆款文章标题",
                "cat_id": 5,
                "user_form": [
                    {
                        "label": "给出关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "填写内容关键词"
                    },
                    {
                        "label": "爆款关键词",
                        "options": [
                            "首发",
                            "YYDS",
                            "福利",
                            "神器",
                            "正确姿势",
                            "必备",
                            "爆款",
                            "竟然",
                            "玩玩没想到"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择爆款关键词"
                    },
                    {
                        "label": "标题要求",
                        "options": [
                            "引导读者思考的疑问式标题",
                            "引发好奇心的悬念标题",
                            "让读者产生情感共鸣",
                            "很有说服力的标题",
                            "吸引眼球的炸裂标题",
                            "刺激读者的标题",
                            "挖掘痛点的标题",
                            "正常普通的标题"
                        ],
                        "formType": "select",
                        "isRequired": false,
                        "placeholder": "选择标题要求"
                    },
                    {
                        "label": "标题风格",
                        "options": [
                            "正常",
                            "深情",
                            "深沉",
                            "急迫",
                            "幽默",
                            "热情",
                            "冷漠",
                            "讽刺",
                            "崇敬",
                            "正式",
                            "说服"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择标题风格"
                    },
                    {
                        "label": "生成数量",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择生成几个标题"
                    }
                ]
            },
            {
                "id": 26,
                "name": "微博品牌推文",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64717e333b9da29695.png",
                "info": "创作以品牌方或博主口吻的推广博文",
                "cat_id": 5,
                "user_form": [
                    {
                        "label": "创作类型",
                        "options": [
                            "财经",
                            "时尚",
                            "汽车",
                            "美食",
                            "母婴",
                            "体育",
                            "房产",
                            "读书",
                            "摄影",
                            "数码",
                            "综艺",
                            "星座",
                            "军事",
                            "家居",
                            "萌宠",
                            "科技",
                            "动漫",
                            "旅游",
                            "美妆",
                            "设计",
                            "历史",
                            "艺术",
                            "健康",
                            "音乐",
                            "游戏",
                            "校园",
                            "其他"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择创作的类型"
                    },
                    {
                        "label": "创作角色",
                        "options": [
                            "品牌方",
                            "博主"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择您的角色"
                    },
                    {
                        "label": "品牌名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入品牌名称"
                    },
                    {
                        "label": "内容主题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入内容的主题"
                    },
                    {
                        "label": "内容关键词",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "请输入内容关键词"
                    }
                ]
            },
            {
                "id": 25,
                "name": "知乎问答",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647193ad542cc92551.png",
                "info": "根据提问，生成知乎风格的回答",
                "cat_id": 5,
                "user_form": [
                    {
                        "label": "问题",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入您的问题，例如：如何看新款苹果手机？"
                    },
                    {
                        "label": "关键词",
                        "formType": "keywords",
                        "isRequired": false,
                        "placeholder": "请输入您的关键词"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "以问题为导向，将问题阐述清楚之后再进行详细的解答",
                            "引用相关领域的专业术语和数据，准确地表达作者的观点，并且显得更加专业和权威",
                            "突出作者个人的经验和感悟，使文章更加亲切和有感染力，读者也更容易产生共鸣",
                            "采用问答形式，将一个问题分成多个小问题逐一回答",
                            "从多个角度思考问题，探讨问题的各个方面",
                            "引用名人名言，这样能够增强文章的说服力和影响力",
                            "深入剖析问题本质，探讨问题的根源和本质原因",
                            "以具体的案例来证明观点，使文章更加生动、具体和有说服力",
                            "采用故事叙述法，通过一个个小故事来阐述观点和解决问题",
                            "以行动号召结尾，鼓励读者去实践和行动"
                        ],
                        "formType": "multiSelect",
                        "isRequired": false,
                        "placeholder": "选择内容要求，写出更高质量的文章"
                    }
                ]
            }
        ],
        "arrKey": "list5"
    }
]
let a10 = [
    {
        "id": 33,
        "parent_id": 32,
        "org_id": 1,
        "cat_name": "商业分析",
        "cat_info": "市场 竞品 营销等分析",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64828d83d1cd085145.png",
        "is_industry": 1,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-09-18 20:07:36",
        "deleted_at": null,
        "app_list": [
            {
                "id": 67,
                "name": "SWOT分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64828e243cb7281849.png",
                "info": "替您的业务识别其优势、弱点、机会与威胁",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请填写您的产品和服务名称"
                    },
                    {
                        "label": "产品或服务面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如：高学历 女性 20-35岁"
                    },
                    {
                        "label": "产品或服务简介",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品介绍、特性、优势"
                    },
                    {
                        "label": "产品或服务的客单价",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "填写产品或服务的客单价"
                    }
                ]
            },
            {
                "id": 68,
                "name": "SCAMPER智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/648296262ee6366124.png",
                "info": "站在创新前沿，SCAMPER智能分析师(奔驰法)帮您冲破思维局限",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或者服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写产品或者服务的简单介绍"
                    }
                ]
            },
            {
                "id": 69,
                "name": "5W2H智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/648297dcb05a163136.png",
                "info": "5W2H智能分析是一种营销工具，它可以帮助你在制定营销计划时进行全面深入的思考和分析",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "介绍产品的功能 用户群体 特性"
                    }
                ]
            },
            {
                "id": 70,
                "name": "PESTLE智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829a2a9aaf590032.png",
                "info": "帮助用户评估其产品的政治、经济、社会、技术、法律和环境因素，以制定更有效的营销策略",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 71,
                "name": "3C战略三角模型",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829af2ed20036413.png",
                "info": "从公司自身 公司顾客和竞争对手做商业分析",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 72,
                "name": "麦肯锡7S智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829d3f5ee8839262.png",
                "info": "麦肯锡7S模型，包括结构(Structure)、制度(Systems)、风格(Style)、员工(Staff)、技能(Skills)、战略(Strategy)、共同价值观(Shared Values)",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 73,
                "name": "PDCA智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829ed35bcfa81783.png",
                "info": "自动分析计划、执行、检查、行动的各项指标，并快速为您提供个性化的改进建议",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 74,
                "name": "SMART模型智能分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829fd7d649e76834.png",
                "info": "从明确性(specific)、可衡量性(measurable)、可达成性(attainable)、相关性(realistic)和时限性(time-based)等五个方面对目标进行设定的方法",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 75,
                "name": "波特五力分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a198a542980508.png",
                "info": "供应商的讨价还价能力、购买者的讨价还价能力、潜在竞争者进入的能力、替代品的替代能力、行业内竞争者现在的竞争能力",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 76,
                "name": "价值创新计划ERRC分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a6321a14f64486.png",
                "info": "使用ERRC模型，即消除、减少、提升、创造四个步骤，帮助企业在市场中实现差异化优势",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 77,
                "name": "安索夫矩阵分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a6ff647aa22395.png",
                "info": "以产品和市场作为两大基本面向，区别出四种产品/市场组合和相对应的营销策略",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 78,
                "name": "AIDA模型分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a7c37987f68603.png",
                "info": "A为Attention，即引起注意；I为Interest，即诱发兴趣；D为Desire，即刺激欲望；最后一个字母A为Action，即促成购买",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品或服务介绍"
                    }
                ]
            },
            {
                "id": 84,
                "name": "竞品分析",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840305bb23f19290.png",
                "info": "生成竞品分析报告",
                "cat_id": 33,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务名称"
                    },
                    {
                        "label": "补充信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "比如竞品名称、产品优势、产品特性"
                    }
                ]
            }
        ],
        "arrKey": "list33"
    },
    {
        "id": 34,
        "parent_id": 32,
        "org_id": 1,
        "cat_name": "方案计划",
        "cat_info": "商业方案及计划",
        "cat_icon": "https://cos.kuaijingai.com/upload/work_ai/20230613/6487be0fdbbdf64861.png",
        "is_industry": 1,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-07-22 10:00:53",
        "updated_at": "2023-09-18 20:10:46",
        "deleted_at": null,
        "app_list": [
            {
                "id": 7,
                "name": "商业计划书",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719d398310164462.png",
                "info": "根据企业目标生成商业计划书",
                "cat_id": 34,
                "user_form": [
                    {
                        "label": "企划目标",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入您的招商融资或其它发展目标"
                    },
                    {
                        "label": "项目简介",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入项目的简单介绍"
                    },
                    {
                        "label": "内容要求",
                        "options": [
                            "公司和项目状况",
                            "未来发展潜力",
                            "企业成长经历",
                            "产品服务",
                            "市场营销",
                            "管理团队",
                            "股权结构",
                            "组织人事",
                            "现今财务状况",
                            "运营方案",
                            "融资方案",
                            "企业战略规划与执行"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "选择计划书包含哪些内容"
                    },
                    {
                        "label": "其他补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "填写需要补充的信息"
                    }
                ]
            },
            {
                "id": 82,
                "name": "营销策略",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483ea1b3928843815.png",
                "info": "创作营销策略方案",
                "cat_id": 34,
                "user_form": [
                    {
                        "label": "产品及服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品及服务名称"
                    },
                    {
                        "label": "产品及服务介绍",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "产品及服务介绍"
                    },
                    {
                        "label": "产品及服务品类行业",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务所在的品类或者行业"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "比如：年轻女性 高学历"
                    },
                    {
                        "label": "其他信息补充",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "比如产品的优劣势、特性等"
                    },
                    {
                        "label": "营销方案需要包含的内容",
                        "options": [
                            "营销战略制订",
                            "产品全市市场推广",
                            "营销团队建设",
                            "促销政策制定",
                            "专卖体系等特殊销售模式打造",
                            "终端销售业绩提升",
                            "样板市场打造",
                            "分销体系建立",
                            "渠道建设",
                            "直营体系建设",
                            "价格体系建设",
                            "招商策划",
                            "新产品上市策划",
                            "产品规划",
                            "市场定位",
                            "营销诊断",
                            "市场环境分析",
                            "消费心理分析",
                            "产品优势分析",
                            "营销方式和平台的选择"
                        ],
                        "formType": "multiSelect",
                        "isRequired": true,
                        "placeholder": "营销方案需要包含的内容"
                    },
                    {
                        "label": "选择营销策略组合",
                        "options": [
                            "4P’s营销策略组合",
                            "6P’s营销策略组合",
                            "11P’s营销策略组合"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "选择营销策略组合"
                    }
                ]
            },
            {
                "id": 83,
                "name": "营销策划方案",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483f53b1b14448090.png",
                "info": "创作营销活动方案",
                "cat_id": 34,
                "user_form": [
                    {
                        "label": "产品或服务名称",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "产品或服务名称"
                    },
                    {
                        "label": "产品或服务介绍",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "产品或服务介绍"
                    },
                    {
                        "label": "面向的客户群体",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "用关键词描述面向的客户群体"
                    }
                ]
            }
        ],
        "arrKey": "list34"
    }
]
let a11 = [
    {
        "id": 83,
        "parent_id": 82,
        "org_id": 1,
        "cat_name": "合同助手",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2023-09-20 22:33:53",
        "updated_at": "2023-09-21 09:42:03",
        "deleted_at": null,
        "app_list": [
            {
                "id": 171,
                "name": "万能合同助手",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba756d4f1e15186.png",
                "info": "根据你的需求，帮你生成各类合同范本",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "合同类型",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入你想要的合同范本，如：房屋转让合同"
                    },
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 160,
                "name": "租房合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7609b6cf25268.png",
                "info": "一键生成租房合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 161,
                "name": "劳动合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7665597282234.png",
                "info": "一键生成符合中国法律的劳动合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 162,
                "name": "承包合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba76be5bbf75231.png",
                "info": "一键生成符合中国法律的承包合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 163,
                "name": "代理合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba772071ba62433.png",
                "info": "一键生成符合中国法律的代理合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 164,
                "name": "采购合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7784611230868.png",
                "info": "一键生成采购合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 165,
                "name": "服务合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba77d3631057294.png",
                "info": "一键生成服务合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 166,
                "name": "股权合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba782d4c2e94020.png",
                "info": "一键生成股权合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 167,
                "name": "出售合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba787b177c54270.png",
                "info": "一键生成出售合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 168,
                "name": "工程合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba78f6f77567920.png",
                "info": "一键生成工程合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 169,
                "name": "房屋出售合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7946a26a56912.png",
                "info": "一键生成房屋出售合同",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            },
            {
                "id": 170,
                "name": "二手车合同",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7b27c39e57218.png",
                "info": "适合二手车出售或出租",
                "cat_id": 83,
                "user_form": [
                    {
                        "label": "合同类型",
                        "options": [
                            "二手车出售",
                            "二手车出租"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "二手车出售"
                    },
                    {
                        "label": "重点说明",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请描述你对合同的其他要求，若无则不填写"
                    }
                ]
            }
        ],
        "arrKey": "list83"
    }
]
let a12 = [
    {
        "id": 91,
        "parent_id": 90,
        "org_id": 1,
        "cat_name": "教育",
        "cat_info": "",
        "cat_icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65981343847b455680.png",
        "is_industry": 0,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2024-01-05 22:33:41",
        "updated_at": "2024-01-05 22:33:41",
        "deleted_at": null,
        "app_list": [
            {
                "id": 87,
                "name": "写作文",
                "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648410695027997222.png",
                "info": "根据提供的要求写作文",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "作文主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "详细输入作文主题内容"
                    },
                    {
                        "label": "作文要求",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写作文的其他要求"
                    },
                    {
                        "label": "作文水准",
                        "options": [
                            "小学作文",
                            "初中作文",
                            "高中作文",
                            "大学作文",
                            "雅思作文",
                            "专业知识作文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": ""
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "200-400字",
                            "400-600字",
                            "600-800字",
                            "800-1000字",
                            "1000-1500字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 201,
                "name": "论文创作",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/6597e89b34a2a94949.png",
                "info": "一键创作论文草稿",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "论文题目",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请清晰具体描述您研究的主题"
                    },
                    {
                        "label": "研究背景",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "描述您研究领域的背景"
                    },
                    {
                        "label": "研究目的",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "指明您的研究的主要问题或目标"
                    },
                    {
                        "label": "研究方法",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简要描述您计划采用的研究方法或方法论"
                    },
                    {
                        "label": "研究结果和结论",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请说明你的研究结果和结论"
                    },
                    {
                        "label": "语言",
                        "options": [
                            "中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "中文"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "2000-4000字",
                            "4000-6000字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 200,
                "name": "论文大纲",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980eefd4df048744.png",
                "info": "优秀的论文从一份完美的大纲开始",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "论文主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入论文的研究主题"
                    },
                    {
                        "label": "其他信息",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "可输入其他信息和要求，如研究领域、研究目标等"
                    },
                    {
                        "label": "创作语言",
                        "options": [
                            "中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "中文"
                    }
                ]
            },
            {
                "id": 205,
                "name": "论文大纲扩写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/6598ff0299caa75752.png",
                "info": "以论文大纲为基础进行扩写内容",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "论文主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请准确输入你的论文主题"
                    },
                    {
                        "label": "论文大纲内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的论文大纲内容"
                    },
                    {
                        "label": "其他要求",
                        "formType": "textarea",
                        "isRequired": false
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "600-1000字",
                            "1000-1500字",
                            "1500-2000字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 198,
                "name": "论文摘要",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980ee30cf0344849.png",
                "info": "优秀的摘要是论文的精华所在",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "论文题目",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入清晰准确的论文主题"
                    },
                    {
                        "label": "研究背景",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简要描述您的研究背景，让我知道您的研究领域是什么"
                    },
                    {
                        "label": "研究问题或目标",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "阐明您的研究中具体解决的问题或实现的目标"
                    },
                    {
                        "label": "研究方法",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "说明您采用的研究方法或方法论，以及这些方法的适用性和优势"
                    },
                    {
                        "label": "研究结果",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "提供您的研究所得到的主要结果或发现"
                    },
                    {
                        "label": "特殊要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "如果您对论文摘要有特殊的格式或内容要求，请明确提出"
                    },
                    {
                        "label": "语言",
                        "options": [
                            "中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "中文"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "200-300字",
                            "300-500字",
                            "500-800字",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 199,
                "name": "论文开题报告",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980ef8b651443966.png",
                "info": "帮你轻松搞定开题报告",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "研究题目",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "提供您的研究题目，确保清晰明了"
                    },
                    {
                        "label": "研究目的",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "概述您的研究的主要目标和意图"
                    },
                    {
                        "label": "研究问题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "列出您计划研究的具体问题"
                    },
                    {
                        "label": "研究方法",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "简要描述您计划采用的研究方法或方法论"
                    },
                    {
                        "label": "预期结果",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "说明结果如何有助于解决问题或增进知识"
                    },
                    {
                        "label": "研究意义",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": " 阐明您的研究对于学术领域、实际应用的重要性"
                    },
                    {
                        "label": "文献综述",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "如果有的话，提供已有文献的简要综述"
                    },
                    {
                        "label": "创作语言",
                        "options": [
                            "中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "中文"
                    },
                    {
                        "label": "创作字数",
                        "options": [
                            "1000-2000字",
                            "2000-3000字",
                            "3000-5000字",
                            "5000-8000字",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 202,
                "name": "文献综述",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/659809718a83f96608.png",
                "info": "文献总结评价",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "研究领域和主题",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "描述您的研究领域和主题"
                    },
                    {
                        "label": "研究问题或目标",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "指明您研究的主要问题或目标"
                    },
                    {
                        "label": "关键字",
                        "formType": "keywords",
                        "isRequired": true,
                        "placeholder": "提供与您研究相关的关键字"
                    },
                    {
                        "label": "文献类型",
                        "options": [
                            "学术期刊",
                            "学术会议",
                            "书籍",
                            "技术报告",
                            "综述文章",
                            "专利",
                            "学位论文",
                            "白皮书",
                            "电子文献"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "学位论文"
                    },
                    {
                        "label": "其他要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "请填写其他要求或信息，若无则留空"
                    },
                    {
                        "label": "创作语言",
                        "options": [
                            "中文",
                            "英文"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "",
                        "defaultValue": "中文"
                    }
                ]
            },
            {
                "id": 203,
                "name": "论文致谢",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980c77b47e249978.png",
                "info": "专业论文致谢，点睛之笔",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "指定感谢对象",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "填写你需要感谢的具体对象"
                    },
                    {
                        "label": "具体贡献",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "列出每个对象的具体贡献"
                    },
                    {
                        "label": "写作风格",
                        "options": [
                            "正式风格",
                            "亲切感激",
                            "专业语气",
                            "表达情感",
                            "简练明了",
                            "幽默轻松"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "正式风格"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入自动判断",
                            "200-400字",
                            "400-600字",
                            "600-800字",
                            "800-1000字",
                            "1000字以上"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 204,
                "name": "论文研究内容扩写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980f97e0abd27588.png",
                "info": "帮你扩写论文的研究内容",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "研究内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的研究内容"
                    }
                ]
            },
            {
                "id": 206,
                "name": "论文改写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659903d72827460299.png",
                "info": "改写润色，妙笔生花，完美不同",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "原文内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请完整输入你的原文内容"
                    },
                    {
                        "label": "改写要求",
                        "options": [
                            "科学实证风格",
                            "理论思辨风格",
                            "批判性写作风格",
                            "历史研究风格",
                            "案例研究风格",
                            "叙事性写作风格",
                            "语法和结构调整",
                            "简化和澄清",
                            "提高表达效果",
                            "缩短冗余内容"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "科学实证风格"
                    }
                ]
            },
            {
                "id": 207,
                "name": "实验报告",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659904a86e20882368.png",
                "info": "实验过程的整理呈现和科学分析",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "实验题目",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入清晰准确的实验题目"
                    },
                    {
                        "label": "辅助信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "建议输入创作辅助信息，如：实验目的、实验原理、实验材料、程序方法、结果数据、分析观点等"
                    }
                ]
            },
            {
                "id": 214,
                "name": "论文研究方法扩写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991a8a0485b80906.png",
                "info": "帮你扩写论文的研究方法",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "基础信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入你的研究方法信息，如：研究方法的选择、实验设计、研究方法解释和讨论等信息"
                    }
                ]
            },
            {
                "id": 215,
                "name": "论文研究结果扩写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991bd52ee1c24025.png",
                "info": "一键创作论文研究结果",
                "cat_id": 91,
                "user_form": [
                    {
                        "label": "研究结果信息",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "建议输入研究的描述内容、结果、推论、建议等信息，输入越详细，效果越好"
                    }
                ]
            }
        ],
        "arrKey": "list91"
    }
]
let a13 = [
    {
        "id": 95,
        "parent_id": 94,
        "org_id": 1,
        "cat_name": "小说推文创作",
        "cat_info": "",
        "cat_icon": "",
        "is_industry": 1,
        "sort": 0,
        "status": 1,
        "is_import": 0,
        "created_at": "2024-05-20 10:17:05",
        "updated_at": "2024-05-20 10:17:37",
        "deleted_at": null,
        "app_list": [
            {
                "id": 221,
                "name": "内容润色改写",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab45c207df82927.png",
                "info": "输入小说原文内容，自动改写润色",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "原文内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入原文内容，不超过1000字符"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "鉴宝",
                            "脑洞",
                            "甜宠",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "恐怖",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择小说类型"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "200-400字",
                            "400-600字",
                            "600-1000字",
                            "根据当前输入自动判断"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入自动判断"
                    }
                ]
            },
            {
                "id": 222,
                "name": "书名创作",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab90b0769620685.png",
                "info": "根据你输入的内容，自动创作书名",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入小说内容，最多不超过1000字"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "鉴宝",
                            "甜宠",
                            "脑洞",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "恐怖",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择小说类型"
                    }
                ]
            },
            {
                "id": 223,
                "name": "别名创作",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab9f8718ec48639.png",
                "info": "根据你的小说标题，创作对应小说别名",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说书名",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入小说原来的书名"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "鉴宝",
                            "甜宠",
                            "脑洞",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "恐怖",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "placeholder": "请选择小说类型"
                    }
                ]
            },
            {
                "id": 224,
                "name": "吸睛开头",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664af819a802a55598.png",
                "info": "帮你创作推文视频吸睛开头（200字内）",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入小说开头内容，最多200字"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "恐怖",
                            "甜宠",
                            "脑洞",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "鉴宝",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "言情"
                    },
                    {
                        "label": "创作风格",
                        "options": [
                            "激情",
                            "悬疑",
                            "讽刺",
                            "深沉",
                            "喜悦",
                            "说服",
                            "傲慢",
                            "惊喜"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "激情"
                    }
                ]
            },
            {
                "id": 225,
                "name": "内容概括",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664afb7f0bc9563022.png",
                "info": "根据你输入的小说内容，智能概括精华内容",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入小说原文内容，最多1000字"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "恐怖",
                            "甜宠",
                            "脑洞",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "鉴宝",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "言情"
                    },
                    {
                        "label": "字数要求",
                        "options": [
                            "根据当前输入，自动判断",
                            "100-300字",
                            "400-600字",
                            "700-1000字"
                        ],
                        "formType": "select",
                        "isRequired": true,
                        "defaultValue": "根据当前输入，自动判断"
                    }
                ]
            },
            {
                "id": 226,
                "name": "短视频标题",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664afce43813887542.png",
                "info": "生成短视频标题文案",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说书名",
                        "formType": "input",
                        "isRequired": true,
                        "placeholder": "请输入小说书名"
                    },
                    {
                        "label": "小说类型",
                        "options": [
                            "言情",
                            "都市",
                            "现代",
                            "赘婿",
                            "恐怖",
                            "甜宠",
                            "脑洞",
                            "武侠",
                            "科幻",
                            "穿越",
                            "悬疑",
                            "历史",
                            "奇幻",
                            "鉴宝",
                            "传记"
                        ],
                        "formType": "select",
                        "isRequired": true
                    },
                    {
                        "label": "其他要求",
                        "formType": "textarea",
                        "isRequired": false,
                        "placeholder": "若有其他要求，请直接输入"
                    }
                ]
            },
            {
                "id": 227,
                "name": "小说内容绘画提示词",
                "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664b005f97a9c99100.png",
                "info": "根据你输入的信息，创作对应画面的AI绘画提示词",
                "cat_id": 95,
                "user_form": [
                    {
                        "label": "小说内容",
                        "formType": "textarea",
                        "isRequired": true,
                        "placeholder": "请输入小说原文内容"
                    }
                ]
            }
        ],
        "arrKey": "list95"
    }
]
let b = [
    {
        "id": 22,
        "name": "小红书万能笔记",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194c999e1467731.png",
        "info": "啥都能写，根据内容，帮您生成极具吸引力的小红书风格笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            68,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "好物分享",
                    "时尚穿搭",
                    "商品推广",
                    "美妆个护",
                    "影视",
                    "情感",
                    "家居",
                    "旅行",
                    "健身塑形",
                    "游戏",
                    "职场",
                    "知识科普",
                    "母婴",
                    "室内设计",
                    "搞笑日常",
                    "书单推荐",
                    "珠宝首饰",
                    "手工",
                    "外语学习"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择创作的类型"
            },
            {
                "label": "内容主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您要写的内容主题"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请尽可能多的输入内容关键词，以便生成更符合您的的内容"
            },
            {
                "label": "内容要求",
                "options": [
                    "提出痛点",
                    "刺激客户",
                    "引用名言",
                    "引用数据",
                    "列举例子",
                    "多用对比",
                    "深入场景描述",
                    "口语化表达"
                ],
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入内容要求：如：提出痛点、引用数据、列举例子等"
            },
            {
                "label": "写作风格",
                "options": [
                    "严肃",
                    "愉快",
                    "沉思",
                    "幽默",
                    "激动",
                    "崇敬",
                    "温馨",
                    "安慰",
                    "轻松",
                    "喜悦",
                    "清河",
                    "热情",
                    "质疑",
                    "真诚",
                    "亲切",
                    "鼓励",
                    "建议",
                    "说服",
                    "讽刺",
                    "傲慢"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择一个写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇小红书爆款笔记，要求：1.请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题；2.请在文中合理的运用emoji符号；3.请结合内容创作一个吸引人的标题，标题字符控制在16-25字；4.必须严格按照规定的字数要求，字数要求{字数要求}",
        "user_msg": "根据创作类型是：{创作类型}\n创作主题是：{内容主题}\n内容关键词：{内容关键词}\n字数要求：{字数要求}\n写作风格：{写作风格}\n内容要求：{内容要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list22"
    },
    {
        "id": 139,
        "name": "内容仿写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d3a1e95407320035.png",
        "info": "根据你提供的内容，自动仿写成小红书风格的笔记内容",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您要仿写的内容"
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑",
                    "轻松",
                    "建议",
                    "傲慢",
                    "严肃",
                    "崇敬",
                    "温馨"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您想要的写作风格"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，请按照小红书笔记的爆款逻辑结构，根据我所提供的内容信息，帮我仿写一篇相同的内容。请在文中合理的运用emoji符号。",
        "user_msg": "内容：{内容}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list139"
    },
    {
        "id": 21,
        "name": "爆款标题",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194c082cdc77404.png",
        "info": "根据内容帮您生成小红书风格的爆款标题",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "标题关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入你要发布的内容关键词帮您生成标题"
            },
            {
                "label": "生成几个标题",
                "options": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择输入的标题"
            },
            {
                "label": "标题要求",
                "options": [
                    "包含有紧迫感和惊喜感的标点符号",
                    "具有挑战性和悬念的表述",
                    "加入热点话题",
                    "描述具体功效",
                    "使用emoji表情增加活力",
                    "以口语化表述",
                    "通过正面刺激、负面刺激吸引用户眼球"
                ],
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "可以选择多个要求"
            },
            {
                "label": "选择爆款词",
                "options": [
                    "好用到哭",
                    "小白必看",
                    "正确姿势",
                    "宝藏",
                    "神器",
                    "都给我冲",
                    "YYDS",
                    "划重点",
                    "建议收藏",
                    "手把手",
                    "揭秘",
                    "沉浸式",
                    "吐血整理",
                    "高级感",
                    "正确姿势",
                    "破防了",
                    "被夸爆",
                    "爆款",
                    "万万没想到",
                    "搞钱必看"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "根据实际需求选择，不选则留空"
            }
        ],
        "system_msg": "你是一名小红书写作专家，请根据我给的信息，帮我创作小红书爆款标题。请创作{生成几个标题}条标题，每条标题字符必须控制在15-25个字符内，不可低于15个字符，也不可超出23个字符",
        "user_msg": "标题关键词：{标题关键词}\n标题要求：{标题要求}\n爆款关键词：{选择爆款词}\n条数：{生成几个标题}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list21"
    },
    {
        "id": 219,
        "name": "实体商家万能笔记",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/66038e67c7cd478668.png",
        "info": "专为实体店商家创作，适合生成各类型实体商家小红书笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "经营行业",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你的经营行业，如：奶茶店、蛋糕店、餐厅"
            },
            {
                "label": "内容类型",
                "options": [
                    "种草类型",
                    "推广营销",
                    "新店开业",
                    "店铺活动",
                    "通用介绍"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你的内容类型",
                "defaultValue": "种草类型"
            },
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入详细的商家名称，如：海底捞火锅"
            },
            {
                "label": "商家地址",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入商家经营地址，如：广州白云区万达广场3楼"
            },
            {
                "label": "创作主题和要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请详细输入你的创作要求，如：店铺新店开业，全场7折优惠，充值领礼品"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励",
                    "严谨",
                    "说服",
                    "严肃",
                    "激动"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "亲切"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇实体店商家{内容类型}的小红书爆款笔记，要求：1.请按照小红书笔记的爆款逻辑结构，根据我所提供的信息和内容创作主题和要求，帮我创作一篇笔记内容和对应标题；2.请在文中合理的运用emoji符号；3.请结合内容创作一个吸引人的标题，标题字符控制在16-25字；4.必须严格按照规定的字数要求，字数要求{字数要求}；5.内容创作开头需要引人入胜，不要用通俗的开头，如大家好，我是XX 之类的风格作为开头",
        "user_msg": "相关信息如下：\n经营行业：{经营行业}\n文章内容类型：{内容类型}\n商家名称：{商家名称}\n商家地址：{商家地址}\n内容创作主题和要求：{创作主题和要求}\n写作风格：{写作风格}\n字数要求：{字数要求}\n商家联系方式：请私信我",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list219"
    },
    {
        "id": 27,
        "name": "公众号万能写作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719059076f643805.png",
        "info": "专注微信公众号写作，可以生成各种类型的公众号原创文章",
        "cat_id": 5,
        "cat_id_arr": [
            39,
            5,
            67,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "品牌推广",
                    "企业宣传",
                    "知识分享",
                    "行业解读",
                    "新闻解读",
                    "教育培训",
                    "旅游攻略",
                    "健康生活",
                    "文化艺术",
                    "产品营销",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您内容的主题"
            },
            {
                "label": "内容描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "输入您内容描述"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "输入内容的关键词"
            },
            {
                "label": "内容要求",
                "options": [
                    "多使用排比",
                    "引用名人名言",
                    "塑造情绪价值",
                    "段落之间用悬念承接",
                    "表达引起共鸣的三观",
                    "多使用比喻",
                    "多使用对比",
                    "融合热点事件",
                    "引用案例",
                    "挖掘痛点 刺激读者",
                    "有很强的说服力"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "可以选择多个对内容的要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "热情",
                    "挖苦",
                    "鼓励",
                    "共情",
                    "质疑",
                    "说服",
                    "幽默",
                    "深沉",
                    "深情",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，根据给定的创作类型、内容主题、内容描述、内容关键词、内容要求、字数要求、写作风格，帮我写一篇给定风格的微信公众号文章，并生成对应标题。所创作的内容请进行美化排版，可以适当加上一些合理的emoji",
        "user_msg": "创作类型：{创作类型}\n内容主题：{内容主题}\n内容描述：{内容描述}\n内容关键词：{内容关键词}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 39,
        "top_cat_id": 1,
        "arrKey": "list27"
    },
    {
        "id": 218,
        "name": "实体商家万能写作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/660389ddd72a774875.png",
        "info": "适合生成各行业实体商家的公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "经营行业",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你的经营行业，如：奶茶店、蛋糕店、餐厅"
            },
            {
                "label": "内容类型",
                "options": [
                    "种草类型",
                    "推广营销",
                    "通用介绍",
                    "新店开业",
                    "店铺活动"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你的内容类型",
                "defaultValue": "通用介绍"
            },
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入详细的商家名称，如：海底捞火锅"
            },
            {
                "label": "商家地址",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入商家经营地址，如：广州白云区万达广场3楼"
            },
            {
                "label": "商家联系方式",
                "formType": "input",
                "isRequired": true,
                "placeholder": "可输入电话或微信号等联系方式"
            },
            {
                "label": "创作要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请详细输入你的创作要求，如：店铺新店开业，全场7折优惠，充值领礼品"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励",
                    "严谨",
                    "说服",
                    "严肃",
                    "激动"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一位非常擅长写微信公众号文章的高手，请帮我写一篇实体商家{内容类型}的微信公众号文章。1.请根据给定的相关信息和提供的内容创作要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题；2.内容创作开头需要引人入胜，能吸引别人继续阅读下文，不要用通俗的开头，如“大家好，我是XX” 之类的风格作为开头；3.所创作的内容请进行美化排版，可以适当加上一些合理的emoji",
        "user_msg": "相关信息如下：\n经营行业：{经营行业}\n文章内容类型：{内容类型}\n商家名称：{商家名称}\n商家地址：{商家地址}\n商家联系方式：{商家联系方式}\n内容创作要求：{创作要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list218"
    },
    {
        "id": 220,
        "name": "内容仿写/改写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/6603a68965c3112169.png",
        "info": "根据你提供的内容，自动仿写/改写",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "原内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您要仿写的内容"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            },
            {
                "label": "写作风格",
                "options": [
                    "温馨",
                    "愉快",
                    "真诚",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑",
                    "轻松",
                    "建议",
                    "傲慢",
                    "严肃",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "温馨"
            }
        ],
        "system_msg": "你是一名专业的微信公众号写作高手，请按照微信公众号文章的爆款逻辑结构，根据我所提供的原内容、字数要求、写作风格，帮我仿写一篇相同的内容，请不要脱离原内容的主题。所创作的内容请合理排版美化",
        "user_msg": "原内容：{原内容}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list220"
    },
    {
        "id": 87,
        "name": "写作文",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648410695027997222.png",
        "info": "根据提供的要求写作文",
        "cat_id": 36,
        "cat_id_arr": [
            36,
            74,
            91
        ],
        "user_form": [
            {
                "label": "作文主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "详细输入作文主题内容"
            },
            {
                "label": "作文要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写作文的其他要求"
            },
            {
                "label": "作文水准",
                "options": [
                    "小学作文",
                    "初中作文",
                    "高中作文",
                    "大学作文",
                    "雅思作文",
                    "专业知识作文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "200-400字",
                    "400-600字",
                    "600-800字",
                    "800-1000字",
                    "1000-1500字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的写作专家，会写各种风格的作文，请根据我提供的信息，帮我创作一篇{作文水准}，字数要求{字数要求}",
        "user_msg": "作文主题：{作文主题}\n作文要求：{作文要求}\n",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list87"
    },
    {
        "id": 86,
        "name": "文章创作及改写",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840e7707e0333299.png",
        "info": "可以帮你创作新文章或者改写已有文章",
        "cat_id": 36,
        "cat_id_arr": [
            36,
            74
        ],
        "user_form": [
            {
                "label": "内容简介或原文",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写需要生成文章的原文或者文章概要"
            },
            {
                "label": "生成文章的风格要求",
                "options": [
                    "正常",
                    "活泼",
                    "幽默",
                    "热情",
                    "可爱",
                    "严肃",
                    "崇敬",
                    "愤怒",
                    "讽刺",
                    "激动",
                    "真诚"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "生成文章的风格要求"
            },
            {
                "label": "文章字数要求",
                "formType": "input",
                "isRequired": true,
                "placeholder": "填写需要生成文章的大楷字数"
            },
            {
                "label": "文章的其他要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "文章的其他要求"
            }
        ],
        "system_msg": "请根据我提供的信息和要求，生成一篇新的文章和标题。",
        "user_msg": "-内容简介或原文：{内容简介或原文}\n-生成文章的风格要求：{生成文章的风格要求}\n-文章字数要求：{文章字数要求}\n-文章的其他要求：{文章的其他要求}",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list86"
    },
    {
        "id": 14,
        "name": "短视频文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/6471635504bad88861.png",
        "info": "帮你生成极具吸引力的短视频文案",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "财经",
                    "三农",
                    "美食",
                    "文化教育",
                    "科技",
                    "旅行",
                    "校园",
                    "时尚",
                    "体育",
                    "汽车",
                    "亲子",
                    "二次元",
                    "娱乐",
                    "才艺",
                    "泛生活",
                    "公益",
                    "动植物",
                    "游戏",
                    "政务媒体",
                    "剧情",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您的创作类型"
            },
            {
                "label": "发布平台",
                "options": [
                    "抖音",
                    "快手",
                    "小红书",
                    "视频号"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您的发布平台"
            },
            {
                "label": "视频主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您视频的主题"
            },
            {
                "label": "视频关键词",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您的视频关键词，以便生成更符合的的创作文案"
            },
            {
                "label": "风格",
                "options": [
                    "正常",
                    "幽默",
                    "愤怒",
                    "热情",
                    "轻松",
                    "傲慢",
                    "挖苦",
                    "讽刺",
                    "崇敬",
                    "鼓励",
                    "深沉",
                    "反思",
                    "情感共鸣"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择文案风格"
            },
            {
                "label": "内容要求",
                "options": [
                    "有强烈的吸引力，可以使用疑问 感叹语气",
                    "有趣幽默，适当运用搞笑、调侃",
                    "情感共鸣，可以讲述一个感人的故事",
                    "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                    "引用名人名言增强说服力"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择创作文案的要求"
            },
            {
                "label": "字数",
                "options": [
                    "150字以内",
                    "150-300字",
                    "300-450字",
                    "450-600字",
                    "600-750字",
                    "750-900字",
                    "900字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "一分钟大概150字，请根据实际情况选择字数"
            }
        ],
        "system_msg": "请根据我给的信息，包括视频主题、视频关键词、内容要求，创作一个发布在指定发布平台的短视频文案，该文案需要按照指定风格生成。短视频文案需要有很强的吸引力，让更多的观众能被吸引着看完短视频。生成的内容需要达到要求的字数",
        "user_msg": "创作类型：{创作类型}\n发布平台：{发布平台}\n视频主题：{视频主题}\n视频关键词：{视频关键词}\n风格：{风格}\n内容要求：{内容要求}\n字数：{字数}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list14"
    },
    {
        "id": 190,
        "name": "小红书万能标题",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231110/654d83ac2ac7a63501.png",
        "info": "根据提供信息创作小红书标题",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "标题内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你需要创作的标题内容"
            },
            {
                "label": "标题风格",
                "options": [
                    "正常",
                    "愉快",
                    "真诚",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑",
                    "建议",
                    "严肃",
                    "崇敬",
                    "傲慢"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "生成数量",
                "options": [
                    "1条",
                    "2条",
                    "3条",
                    "4条",
                    "5条"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "1条"
            }
        ],
        "system_msg": "你是一位小红书网红博主，非常擅长写吸引人的标题，请根据小红书的爆款逻辑和风格，根据我提供的标题内容和标题风格帮我生成出{生成数量}条小红书爆款标题",
        "user_msg": "标题内容：{标题内容}\n标题风格：{标题风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list190"
    },
    {
        "id": 61,
        "name": "餐饮探店文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64819caad4eb178735.png",
        "info": "生成餐饮探店小红书文案",
        "cat_id": 39,
        "cat_id_arr": [
            39,
            65,
            71,
            81
        ],
        "user_form": [
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "填写商家名称",
                "defaultValue": ""
            },
            {
                "label": "商家地址",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请准确输入商家地址",
                "defaultValue": ""
            },
            {
                "label": "商家特色",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "defaultValue": [
                    "环境好",
                    "味道好",
                    "服务态度好",
                    "很暖心"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "商家招牌菜",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "可输入多个商家招牌菜",
                "defaultValue": [],
                "newKeywordsAdding": false
            },
            {
                "label": "商家类别",
                "options": [
                    "火锅",
                    "烧烤烤肉",
                    "自助餐",
                    "鱼海鲜楼",
                    "小吃快餐",
                    "饮品店",
                    "面包蛋糕甜品",
                    "食品生鲜",
                    "异域料理",
                    "地方菜系",
                    "其他"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "烧烤烤肉"
            },
            {
                "label": "标题要求",
                "options": [
                    "包含有紧迫感和惊喜感的标点符号",
                    "具有挑战性和悬念的表述",
                    "描述具体体验",
                    "使用emoji表情增加活力",
                    "以口语化表述",
                    "通过正面刺激、负面刺激吸引用户眼球"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "标题包含的爆款词",
                "options": [
                    "YYDS",
                    "好吃到哭",
                    "强烈推荐",
                    "破防了",
                    "治愈",
                    "万万没想到"
                ],
                "formType": "select",
                "isRequired": false
            },
            {
                "label": "文章风格",
                "options": [
                    "正常",
                    "热情",
                    "喜出望外",
                    "轻松",
                    "愤怒",
                    "讽刺",
                    "强烈推荐"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "文章要求",
                "options": [
                    "提出痛点",
                    "刺激客户",
                    "引用名言",
                    "引用数据",
                    "多用对比",
                    "深入场景描述",
                    "口语化表达",
                    "以我的视角描述",
                    "每段多用emoji"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "defaultValue": [
                    "提出痛点",
                    "刺激客户"
                ]
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "根据以下信息：\n-商家名称\n-商家地址\n-商家特色\n-商家招牌菜\n-商家类别\n-字数要求\n-文章风格\n-文章要求\n-标题要求\n-标题包含的爆款词\n生成一篇小红书风格的探店标题和文章",
        "user_msg": "-商家名称：{商家名称}\n-商家地址：{商家地址}\n-商家特色：{商家特色}\n-商家招牌菜：{商家招牌菜}\n-商家类别：{商家类别}\n-字数要求：{字数要求}\n-文章风格：{文章风格}\n-文章要求：{文章要求}\n-标题要求：{标题要求}\n-标题包含的爆款词：{标题包含的爆款词}",
        "origin_cat_id": 39,
        "top_cat_id": 35,
        "arrKey": "list61"
    },
    {
        "id": 201,
        "name": "论文创作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/6597e89b34a2a94949.png",
        "info": "一键创作论文草稿",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "论文题目",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请清晰具体描述您研究的主题"
            },
            {
                "label": "研究背景",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "描述您研究领域的背景"
            },
            {
                "label": "研究目的",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "指明您的研究的主要问题或目标"
            },
            {
                "label": "研究方法",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简要描述您计划采用的研究方法或方法论"
            },
            {
                "label": "研究结果和结论",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请说明你的研究结果和结论"
            },
            {
                "label": "语言",
                "options": [
                    "中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "中文"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "2000-4000字",
                    "4000-6000字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，按照标准的论文结构和格式，帮我写一篇完整的论文，输出结果的语言需要是{语言}，字数要求{字数要求}，请严格遵守字数要求。",
        "user_msg": "论文题目：{论文题目}\n研究背景：{研究背景}\n研究目的：{研究目的}\n研究方法：{研究方法}\n研究结果和结论：{研究结果和结论}\n字数要求：{字数要求}\n创作语言：{语言}",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list201"
    },
    {
        "id": 19,
        "name": "商品详情文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647179df74f2424118.png",
        "info": "帮您生成一个商品详情页描述的文案稿",
        "cat_id": 26,
        "cat_id_arr": [
            26,
            75
        ],
        "user_form": [
            {
                "label": "发布平台",
                "options": [
                    "抖音",
                    "淘宝",
                    "京东",
                    "天猫",
                    "唯品会",
                    "拼多多",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择要发布的平台"
            },
            {
                "label": "品牌名称",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "商品名称",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入商品名称"
            },
            {
                "label": "商品品类",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入商品品类"
            },
            {
                "label": "商品定位",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "请输入商品定位"
            },
            {
                "label": "商品卖点",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "请输入商品卖点"
            }
        ],
        "system_msg": "根据：\n-发布平台\n-品牌名称\n-商品名称\n-商品品类\n-商品定位\n-商品卖点\n生成一个适合发布在上述发布平台上的商品详情的文案，需要包括以下信息：\n商品的品牌\n型号\n材质\n规格\n功能\n功效\n包装\n相关参数\n价格\n生产加工工艺\n产品优势\n售后服务政策",
        "user_msg": "-发布平台：{发布平台}\n-品牌名称：{品牌名称}\n-商品名称：{商品名称}\n-商品品类：{商品品类}\n-商品定位：{商品定位}\n-商品卖点：{商品卖点}",
        "origin_cat_id": 26,
        "top_cat_id": 25,
        "arrKey": "list19"
    },
    {
        "id": 18,
        "name": "直播带货口播文稿",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64717344793b257870.png",
        "info": "帮你生成直播带货话术文稿",
        "cat_id": 31,
        "cat_id_arr": [
            31,
            81
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您带货的产品"
            },
            {
                "label": "产品的亮点提炼",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的产品的亮点"
            },
            {
                "label": "优惠政策",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您产品的优惠机制"
            }
        ],
        "system_msg": "现在你是一位优秀带货主播，根据提供的产品信息帮我创作一个直播带货的口播稿\n\n产品名称：{产品名称}\n产品的亮点提炼：{产品的亮点提炼}\n优惠政策：{优惠政策}",
        "user_msg": "根据{产品名称}{产品的亮点提炼}{优惠政策}生成直播话术",
        "origin_cat_id": 31,
        "top_cat_id": 29,
        "arrKey": "list18"
    },
    {
        "id": 200,
        "name": "论文大纲",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980eefd4df048744.png",
        "info": "优秀的论文从一份完美的大纲开始",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "论文主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请准确输入论文的研究主题"
            },
            {
                "label": "其他信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "可输入其他信息和要求，如研究领域、研究目标等"
            },
            {
                "label": "创作语言",
                "options": [
                    "中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "中文"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我写一个关于【{论文主题}】的论文大纲，输出结果的语言需要是{语言}",
        "user_msg": "信息如下：\n论文主题：{论文主题}\n其他信息：{其他信息}",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list200"
    },
    {
        "id": 205,
        "name": "论文大纲扩写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/6598ff0299caa75752.png",
        "info": "以论文大纲为基础进行扩写内容",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "论文主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请准确输入你的论文主题"
            },
            {
                "label": "论文大纲内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的论文大纲内容"
            },
            {
                "label": "其他要求",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "600-1000字",
                    "1000-1500字",
                    "1500-2000字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的论文导师，请你根据论文主题，在论文大纲内容的基础上，进行扩写论文大纲内容，创作出来的字数请达到{字数要求}",
        "user_msg": "论文主题：{论文主题}\n论文大纲内容：{论文大纲内容}\n其他要求：{其他要求}\n字数要求：{字数要求}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list205"
    },
    {
        "id": 198,
        "name": "论文摘要",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980ee30cf0344849.png",
        "info": "优秀的摘要是论文的精华所在",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "论文题目",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入清晰准确的论文主题"
            },
            {
                "label": "研究背景",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简要描述您的研究背景，让我知道您的研究领域是什么"
            },
            {
                "label": "研究问题或目标",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "阐明您的研究中具体解决的问题或实现的目标"
            },
            {
                "label": "研究方法",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "说明您采用的研究方法或方法论，以及这些方法的适用性和优势"
            },
            {
                "label": "研究结果",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "提供您的研究所得到的主要结果或发现"
            },
            {
                "label": "特殊要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "如果您对论文摘要有特殊的格式或内容要求，请明确提出"
            },
            {
                "label": "语言",
                "options": [
                    "中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "中文"
            },
            {
                "label": "字数要求",
                "options": [
                    "200-300字",
                    "300-500字",
                    "500-800字",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我写一个论文摘要，输出结果的语言需要是{语言}，字数要求{字数要求}，请严格遵守字数要求。",
        "user_msg": "信息如下：\n论文题目：{论文题目}\n研究背景：{研究背景}\n研究问题或目标：{研究问题或目标}\n研究方法：{研究方法}\n研究结果：{研究结果}\n特殊要求：{特殊要求}",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list198"
    },
    {
        "id": 135,
        "name": "测评笔记",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d24f0213fa210068.png",
        "info": "根据需求自动生成各商品的测评笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "测评商品",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请具体输入您要测评的商品，如：iPhone 14pro"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您的要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "幽默",
                    "质疑",
                    "严肃",
                    "讽刺",
                    "激动"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择一个写作风格",
                "defaultValue": "真诚"
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇测评类笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇测评笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "请根据以下信息，帮我写一篇小红书风格的测评笔记\n测评商品：{测评商品}\n内容要求：{内容要求}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list135"
    },
    {
        "id": 137,
        "name": "影视点评/解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d2551321bab28277.png",
        "info": "生成影视点评类或解说类笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "解说类",
                    "点评类"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择创作类型，解说类或点评类"
            },
            {
                "label": "影视节目名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视节目的名称"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您的内容要求"
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您想要的写作风格",
                "defaultValue": "真诚"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇影视节目{创作类型}笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "创作类型：{创作类型}\n影视节目名称：{影视节目名称}\n内容要求：{内容要求}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list137"
    },
    {
        "id": 138,
        "name": "美食教程",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d39dc1d41aa53547.png",
        "info": "生成美食教程类小红书文案",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "菜品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入菜品名称，如：麻辣小龙虾"
            },
            {
                "label": "美食分类",
                "options": [
                    "中餐",
                    "西餐",
                    "日本料理",
                    "韩国料理",
                    "土耳其菜系",
                    "法国菜系",
                    "点心",
                    "饮料",
                    "其他"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "请选择对应的分类"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "幽默",
                    "激动",
                    "分享"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您想要的写作风格",
                "defaultValue": "分享"
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（100-200字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇菜品的制作教程，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "菜品名称：{菜品名称}\n内容要求：{内容要求}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list138"
    },
    {
        "id": 134,
        "name": "旅游笔记",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230807/64d0e8c29ffa485395.png",
        "info": "帮你生成小红书旅游笔记内容",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "城市/景点",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入游玩城市或旅游景区",
                "defaultValue": "北京"
            },
            {
                "label": "游玩天数",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入游玩天数",
                "defaultValue": "3"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您要写的内容主题或要求，可以写出发城市、交通工具等",
                "defaultValue": ""
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "讽刺",
                    "幽默",
                    "激动",
                    "温馨",
                    "质疑",
                    "严肃"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择一个写作风格",
                "defaultValue": "愉快"
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇旅游笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇旅游笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "创作类型：{创作类型}\n旅游地方：{城市/景点}\n游玩天数：{游玩天数}\n创作主题：{内容主题}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list134"
    },
    {
        "id": 136,
        "name": "景点打卡",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230808/64d253a4eec8628277.png",
        "info": "输入城市或景点的名称，便可得到一篇打卡文案",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "城市/景点",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入游玩城市或旅游景区"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您要写的内容主题或要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "options": [
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择需要创作的字数",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇旅游打卡笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇打卡笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "打卡景点：{城市/景点}\n内容要求：{内容要求}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list136"
    },
    {
        "id": 141,
        "name": "书籍点评/解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230809/64d3a3aaca22c20035.png",
        "info": "输入书籍的名称，帮你创作书籍点评类笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "解说类",
                    "点评类"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择创作类型，解说类或点评类",
                "defaultValue": "解说类"
            },
            {
                "label": "书籍名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入书籍的名称，如：红楼梦"
            },
            {
                "label": "内容要求",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入您的内容要求，若“无”则留空"
            },
            {
                "label": "写作风格",
                "options": [
                    "真诚",
                    "愉快",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑",
                    "分享"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您想要的写作风格",
                "defaultValue": "真诚"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇书籍{创作类型}笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "创作类型：{创作类型}\n书籍名称：{书籍名称}\n内容要求：{内容要求}\n字数要求：{字数要求}\n写作风格：{写作风格}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list141"
    },
    {
        "id": 199,
        "name": "论文开题报告",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980ef8b651443966.png",
        "info": "帮你轻松搞定开题报告",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "研究题目",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "提供您的研究题目，确保清晰明了"
            },
            {
                "label": "研究目的",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "概述您的研究的主要目标和意图"
            },
            {
                "label": "研究问题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "列出您计划研究的具体问题"
            },
            {
                "label": "研究方法",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简要描述您计划采用的研究方法或方法论"
            },
            {
                "label": "预期结果",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "说明结果如何有助于解决问题或增进知识"
            },
            {
                "label": "研究意义",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": " 阐明您的研究对于学术领域、实际应用的重要性"
            },
            {
                "label": "文献综述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "如果有的话，提供已有文献的简要综述"
            },
            {
                "label": "创作语言",
                "options": [
                    "中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "中文"
            },
            {
                "label": "创作字数",
                "options": [
                    "1000-2000字",
                    "2000-3000字",
                    "3000-5000字",
                    "5000-8000字",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我写一个论文开题报告，创作的文字语言需要是{语言}，字数要求{字数要求}，请严格遵守字数要求。",
        "user_msg": "信息如下：\n研究题目：{研究题目}\n研究目的：{研究目的}\n研究问题：{研究问题}\n研究方法：{研究方法}\n预期结果：{预期结果}\n研究意义：{研究意义}\n文献综述：{文献综述}\n",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list199"
    },
    {
        "id": 142,
        "name": "笔记加emoji",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230812/64d6e24a7310886026.png",
        "info": "给你的笔记内容自动加上适合的emoji符号",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            81
        ],
        "user_form": [
            {
                "label": "内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的内容"
            }
        ],
        "system_msg": "请根据我的内容，按照小红书笔记的风格，帮我分段排版，并加上多个emoji表情符号。注意：请不需要修改原来内容",
        "user_msg": "原来内容：{内容}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list142"
    },
    {
        "id": 171,
        "name": "万能合同助手",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba756d4f1e15186.png",
        "info": "根据你的需求，帮你生成各类合同范本",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "合同类型",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你想要的合同范本，如：房屋转让合同"
            },
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的合同范本，合同类型是{合同类型} 。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "合同类型：{合同类型}\n重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list171"
    },
    {
        "id": 191,
        "name": "公众号万能标题",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231110/654d850ea6b8465038.png",
        "info": "根据提供信息创作小红书标题",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "标题内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你需要创作的标题内容"
            },
            {
                "label": "标题风格",
                "options": [
                    "正常",
                    "愉快",
                    "真诚",
                    "讽刺",
                    "幽默",
                    "激动",
                    "质疑",
                    "建议",
                    "严肃",
                    "崇敬",
                    "傲慢"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "生成数量",
                "options": [
                    "1条",
                    "2条",
                    "3条",
                    "4条",
                    "5条"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "1条"
            }
        ],
        "system_msg": "你是一位微信公众号网红博主，非常擅长写吸引人的标题，请根据微信公众号爆款逻辑和风格，根据我提供的标题内容和标题风格帮我生成出{生成数量}条相关标题",
        "user_msg": "标题内容：{标题内容}\n标题风格：{标题风格}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list191"
    },
    {
        "id": 43,
        "name": "公众号爆款标题",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647e9d7e5b6ef30739.png",
        "info": "生成公众号爆款文章标题",
        "cat_id": 5,
        "cat_id_arr": [
            5,
            39,
            67,
            81
        ],
        "user_form": [
            {
                "label": "给出关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "填写内容关键词"
            },
            {
                "label": "爆款关键词",
                "options": [
                    "首发",
                    "YYDS",
                    "福利",
                    "神器",
                    "正确姿势",
                    "必备",
                    "爆款",
                    "竟然",
                    "玩玩没想到"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择爆款关键词"
            },
            {
                "label": "标题要求",
                "options": [
                    "引导读者思考的疑问式标题",
                    "引发好奇心的悬念标题",
                    "让读者产生情感共鸣",
                    "很有说服力的标题",
                    "吸引眼球的炸裂标题",
                    "刺激读者的标题",
                    "挖掘痛点的标题",
                    "正常普通的标题"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "选择标题要求"
            },
            {
                "label": "标题风格",
                "options": [
                    "正常",
                    "深情",
                    "深沉",
                    "急迫",
                    "幽默",
                    "热情",
                    "冷漠",
                    "讽刺",
                    "崇敬",
                    "正式",
                    "说服"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择标题风格"
            },
            {
                "label": "生成数量",
                "options": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择生成几个标题"
            }
        ],
        "system_msg": "你是一个写公众号文章标题的专家，根据给定的关键词、标题要求、标题风格，帮我写指定数量的公众号文章标题，目的是吸引更多的读者点击。如果指出了爆款关键词，标题中最好包含一个给定的爆款关键词",
        "user_msg": "关键词：{给出关键词}\n爆款关键词：{爆款关键词}\n标题要求：{标题要求}\n标题风格：{标题风格}\n数量：{生成数量}",
        "origin_cat_id": 5,
        "top_cat_id": 1,
        "arrKey": "list43"
    },
    {
        "id": 24,
        "name": "探店点评",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/6471941d9f43776009.png",
        "info": "帮商家写探店点评的文案",
        "cat_id": 19,
        "cat_id_arr": [
            19,
            71
        ],
        "user_form": [
            {
                "label": "商家类型",
                "options": [
                    "美食",
                    "休闲娱乐",
                    "旅游出行",
                    "美发",
                    "美甲",
                    "美睫",
                    "瑜伽",
                    "健身",
                    "舞蹈",
                    "宠物",
                    "电影演出",
                    "学习培训",
                    "拍照写真",
                    "母婴服务",
                    "医学美容",
                    "购物"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择商家的类型"
            },
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商家的名称"
            },
            {
                "label": "评价等级",
                "options": [
                    "好评",
                    "中评",
                    "差评"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择评价等级"
            },
            {
                "label": "评价关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入评价的关键词"
            },
            {
                "label": "字数要求",
                "options": [
                    "50-100字",
                    "200-500字",
                    "500-1000字",
                    "根据当前输入内容自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求"
            },
            {
                "label": "请输入商家的地址",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入这个商家的具体地址"
            },
            {
                "label": "感情风格",
                "options": [
                    "正常",
                    "幽默",
                    "轻松",
                    "喜出望外",
                    "伤感",
                    "愤怒",
                    "讽刺",
                    "挖苦",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择文案风格"
            },
            {
                "label": "热门关键词",
                "options": [
                    "福利多多",
                    "强烈推荐",
                    "YYDS",
                    "划重点",
                    "好吃到哭",
                    "都给我冲",
                    "吐血推荐",
                    "万万没想到",
                    "建议收藏"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "文案中加入热门关键词"
            }
        ],
        "system_msg": "根据输入的用户体验写一个探店的商家点评",
        "user_msg": "帮我写一个探店的商家点评，主要内容包括，商类型是{商家类型}，商家名称是{商家名称}，商家的地址{请输入商家的地址}，评价等级是{评价等级}，评价的关键词是{评价关键词}，字数要求是{字数要求}，内容风格为{感情风格}，文案中可以包含这些关键词：{热门关键词}",
        "origin_cat_id": 19,
        "top_cat_id": 16,
        "arrKey": "list24"
    },
    {
        "id": 15,
        "name": "短视频拍摄脚本",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474430fd892460992.png",
        "info": "根据镜头场景帮你生成拍摄脚本",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "创作内容",
                "options": [
                    "财经",
                    "三农",
                    "美食",
                    "文化教育",
                    "科技",
                    "旅行",
                    "校园",
                    "时尚",
                    "体育",
                    "汽车",
                    "亲子",
                    "二次元",
                    "娱乐",
                    "才艺",
                    "泛生活",
                    "公益",
                    "动植物",
                    "游戏",
                    "政务媒体",
                    "剧情",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您创作的类型"
            },
            {
                "label": "创作的主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您创作的主题"
            },
            {
                "label": "关键词",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您创作的关键词"
            },
            {
                "label": "视频受众群体",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入视频受众群体"
            },
            {
                "label": "视频受众群体痛点",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入视频受众群体的痛点"
            },
            {
                "label": "视频时长",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入视频时长，单位是秒"
            },
            {
                "label": "风格要求",
                "options": [
                    "正常",
                    "幽默搞笑",
                    "愤怒",
                    "讽刺",
                    "深沉",
                    "伤感",
                    "悲痛",
                    "激励",
                    "质疑"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择视频风格"
            }
        ],
        "system_msg": "根据给定的创作内容、创作的主题、关键词、内容风格要求、视频受众群体、视频受众群体痛点，创作指定时间长度的视频脚本，要求具有脚本拍摄的基本结构，比如：拍摄手法 台词 时长 运镜 bgm等详细的脚本设计，要求前5秒的镜头很吸引人",
        "user_msg": "创作内容：{创作内容}\n创作的主题：{创作的主题}\n内容风格要求：{风格要求}\n关键词：{关键词}\n视频受众群体：{视频受众群体}\n视频受众群体痛点：{视频受众群体痛点}\n时间长度：{视频时长}秒",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list15"
    },
    {
        "id": 16,
        "name": "短视频文案改写",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/6474433f22b5876775.png",
        "info": "根据短视频文案进行改写，伪原创生成爆款文案",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "模仿文案",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "复制您的文案在此处，进行伪原创"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（50-200字）",
                    "中文案（200-400字）",
                    "长文案（400-800字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位短视频文案写作高手，请根据我提供的短视频文案内容进行伪原创改写，请不要脱离文案原本的意思。请帮我生成1篇",
        "user_msg": "短视频文案：{模仿文案}\n字数要求：{字数要求}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list16"
    },
    {
        "id": 53,
        "name": "营销类短视频脚本",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648145aeeaaf952047.png",
        "info": "自动生成营销类短视频脚本",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "公司品牌信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "包括公司名称、网站以及任何使品牌与众不同的东西（例如其独特的卖点）"
            },
            {
                "label": "视频目的",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请说明视频目的，比如优惠促销等"
            },
            {
                "label": "目标受众",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "定义观众的兴趣和行业"
            },
            {
                "label": "关键信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "列出您想在视频中传达的要点"
            },
            {
                "label": "号召性用语",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "说明您希望观看者在观看视频后采取哪些操作"
            },
            {
                "label": "上下文",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "提供有关您在视频中涵盖的主题的任何其他相关信息，例如您要包含或排除的内容的具体示例"
            },
            {
                "label": "出镜人",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "谁将在视频中出现？他们在镜头前吗？是多人吗？"
            },
            {
                "label": "视频风格",
                "options": [
                    "正常",
                    "激情饱满",
                    "推荐说服",
                    "轻松喜悦",
                    "制造紧迫感"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择视频的语气风格"
            },
            {
                "label": "视频长度",
                "options": [
                    "1分钟",
                    "2分钟",
                    "3分钟",
                    "4分钟",
                    "5分钟",
                    "5分钟以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择视频长度"
            }
        ],
        "system_msg": "为企业生成高质量的营销视频脚本，其中包含以下详细信息：\n-公司：\n-目的：\n-观众：\n- 关键信息：\n- 号召性用语：\n-语气：\n-人才：\n-上下文：\n-长度：\n\n视频脚本包括拍摄手法 台词 时长 运镜 bgm 时间等内容，请按如下方式构建脚本：\n\n1. 引言（吸引注意力）\n2. 活动/产品/内容概述（突出关键信息）\n3. 对观众的好处\n4. 号召性用语\n5. 结论（重申推广，创造兴奋）",
        "user_msg": "-公司：{公司品牌信息}\n-目的：{视频目的}\n-观众：{目标受众}\n- 关键信息：{关键信息}\n- 号召性用语：{号召性用语}\n-语气：{视频风格}\n-人才：{出镜人}\n-上下文：{上下文}\n-长度：{视频长度}\n",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list53"
    },
    {
        "id": 80,
        "name": "抖音探店文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482aec5d3f7440372.png",
        "info": "生成抖音探店文案",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "商家名称"
            },
            {
                "label": "商家地址",
                "formType": "input",
                "isRequired": true,
                "placeholder": "商家地址"
            },
            {
                "label": "商家特色",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写商家的特色和优势"
            },
            {
                "label": "商家具体做什么",
                "options": [
                    "餐饮",
                    "按摩足疗",
                    "KTV",
                    "健身",
                    "瑜伽",
                    "医美",
                    "美容美发",
                    "汽车维修护理洗车",
                    "超市",
                    "宠物护理及宠物医院",
                    "酒店住宿",
                    "电影演出",
                    "亲子乐园游乐场",
                    "洗浴汗蒸",
                    "生活服务",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择商家具体做什么"
            },
            {
                "label": "商家补充信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "更多需要告知的商家信息"
            },
            {
                "label": "文案内容要求",
                "options": [
                    "提出痛点，刺激观众",
                    "引用名言或权威数据，增强信任感",
                    "与其他同行对比，强调优势",
                    "以我的视角深入场景描述体验",
                    "尽量口语化的表述"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择对文案内容的要求"
            },
            {
                "label": "文案内容情感风格",
                "options": [
                    "正常",
                    "惊讶",
                    "喜出望外",
                    "愤怒",
                    "轻松",
                    "正式",
                    "严肃",
                    "讽刺",
                    "强烈说服"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择文案内容风格"
            },
            {
                "label": "视频播放长度",
                "options": [
                    "1分钟",
                    "2分钟",
                    "3分钟",
                    "4分钟",
                    "5分钟"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择视频播放长度"
            }
        ],
        "system_msg": "请根据下面信息：\n-商家名称\n-商家地址\n-商家特色\n-商家具体做什么\n-商家补充信息\n-文案内容要求\n-文案内容情感风格\n-视频播放长度\n\n生成一个抖音短视频探店文案，用于制作抖音短视频来宣传给定的商家，文案要求开头非常有吸引力，文案长度需要符合根据给的视频播放长度，一分钟视频大概150个汉字",
        "user_msg": "商家名称：{商家名称}\n商家地址：{商家地址}\n商家特色：{商家特色}\n商家具体做什么：{商家具体做什么}\n商家补充信息：{商家补充信息}\n文案内容要求：{文案内容要求}\n文案内容情感风格：{文案内容情感风格}\n视频播放长度：{视频播放长度}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list80"
    },
    {
        "id": 26,
        "name": "微博品牌推文",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64717e333b9da29695.png",
        "info": "创作以品牌方或博主口吻的推广博文",
        "cat_id": 5,
        "cat_id_arr": [
            21,
            5,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "财经",
                    "时尚",
                    "汽车",
                    "美食",
                    "母婴",
                    "体育",
                    "房产",
                    "读书",
                    "摄影",
                    "数码",
                    "综艺",
                    "星座",
                    "军事",
                    "家居",
                    "萌宠",
                    "科技",
                    "动漫",
                    "旅游",
                    "美妆",
                    "设计",
                    "历史",
                    "艺术",
                    "健康",
                    "音乐",
                    "游戏",
                    "校园",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择创作的类型"
            },
            {
                "label": "创作角色",
                "options": [
                    "品牌方",
                    "博主"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您的角色"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入内容的主题"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入内容关键词"
            }
        ],
        "system_msg": "现在你是微博的博主或者品牌方，根据提供内容写出一篇微博风格的博文用推广品牌内容，微博内容往往在常见关键词两边加上#",
        "user_msg": "根据{创作类型}，以{创作角色}的身份，为{品牌名称} 围绕{内容主题} {内容关键词}写一篇微博",
        "origin_cat_id": 21,
        "top_cat_id": 1,
        "arrKey": "list26"
    },
    {
        "id": 216,
        "name": "影视缩写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240313/65f136aa658a281969.png",
        "info": "把已有的影视内容缩减成解说文案",
        "cat_id": 87,
        "cat_id_arr": [
            87
        ],
        "user_form": [
            {
                "label": "影视剧名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视剧的名称"
            },
            {
                "label": "影视内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请放入原来影视作品的内容"
            },
            {
                "label": "创作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "建议",
                    "鼓励",
                    "热情",
                    "质疑"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位影视解说文案创作高手，请根据我提供的影视内容，按照要求帮我润色为{创作风格}风格的影视解说内容，字数要求请满足{字数要求}。注意：我需要创作的是影视解说类型风格的内容，请不要胡编内容，请根据我已提供的影视内容进行润色和创作",
        "user_msg": "影视剧名称：{影视剧名称}\n影视内容：{影视内容}\n创作风格：{创作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 87,
        "top_cat_id": 86,
        "arrKey": "list216"
    },
    {
        "id": 193,
        "name": "影视解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/657912aad0ba273239.jpg",
        "info": "生成任意影视剧的解说文案",
        "cat_id": 87,
        "cat_id_arr": [
            87
        ],
        "user_form": [
            {
                "label": "影视剧名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视剧的名称"
            },
            {
                "label": "影视剧类型",
                "options": [
                    "电影",
                    "电视剧",
                    "纪录片",
                    "综艺节目",
                    "动画片"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选对应的类型"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请详细输入您的内容要求，如解说电视剧西游记第1集或其他要求，输入越详细效果越好"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）",
                    "超长文章（1000-1600字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位影视解说博主，你需要以生动的语言、简洁的文字，解说下{影视剧类型}《{影视剧名称}》的剧情，解说的内容需要能让观众沉浸在这剧情中。你一定要围绕着这部{影视剧名称}的剧情内容进行解说，不要自己随便瞎编，创作结果请满足我的内容要求和字数要求",
        "user_msg": "影视剧名称：{影视剧名称}\n影视剧类型：{影视剧类型}\n内容要求：{内容要求}\n文章字数要求：{字数要求}",
        "origin_cat_id": 87,
        "top_cat_id": 86,
        "arrKey": "list193"
    },
    {
        "id": 143,
        "name": "知识科普",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98a7b5d1db36193.png",
        "info": "根据你的需求，帮你生成知识科普类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": false
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇知识科普\n类的微信公众号文章。请根据给定的内容主题、内容要求、内容关\n键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文\n章，并生成对应标题。所创作的内容请进行美化排版，可以适当加上一些合理的emoji",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n内容关键词：{内容关键词}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list143"
    },
    {
        "id": 66,
        "name": "品牌起名及生成Slogan",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481bf744a4aa81624.png",
        "info": "为你起品牌名字并且生成Slogan",
        "cat_id": 4,
        "cat_id_arr": [
            4
        ],
        "user_form": [
            {
                "label": "产品所在行业",
                "formType": "input",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "产品的客户画像",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "比如：年轻人 女性 高学历"
            },
            {
                "label": "产品的特点优势",
                "formType": "textarea",
                "isRequired": true
            },
            {
                "label": "品牌的价值主张",
                "formType": "input",
                "isRequired": false
            },
            {
                "label": "品牌起名的要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "比如字数、风格等要求"
            },
            {
                "label": "品牌名字来源要求",
                "options": [
                    "参考古诗词",
                    "包含某种动物的汉字",
                    "包含某种植物的汉字",
                    "看上去像是外国品牌",
                    "没有来源要求"
                ],
                "formType": "select",
                "isRequired": false
            },
            {
                "label": "品牌Slogan要求",
                "options": [
                    "传递产品功能",
                    "传递品牌价值",
                    "挖掘用户痛点",
                    "激励客户群体",
                    "通过愿景使命获得用户认同",
                    "通过精神认同强调某种价值观和观点",
                    "引用其他权威机构或品牌增强信赖感",
                    "激发用户从众心理，别人买我也要买",
                    "明确告诉客户需要使用我们的产品",
                    "从使用场景引导客户使用",
                    "用常见的事物比喻产品性能",
                    "提出并承诺最突出的利益点"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "根据以下信息：\n-产品所在行业\n-产品的客户画像\n-产品的特点优势\n-品牌的价值主张\n-品牌起名的要求\n-品牌名字来源要求\n-品牌Slogan要求\n帮我起品牌名字，并按照品牌Slogan要求生成相应的slogan",
        "user_msg": "产品所在行业：{产品所在行业}\n产品的客户画像：{产品的客户画像}\n产品的特点优势：{产品的特点优势}\n品牌的价值主张：{品牌的价值主张}\n品牌起名的要求：{品牌起名的要求}\n品牌名字来源要求：{品牌名字来源要求}\n品牌Slogan要求：{品牌Slogan要求}",
        "origin_cat_id": 4,
        "top_cat_id": 1,
        "arrKey": "list66"
    },
    {
        "id": 4,
        "name": "网页TDK生成",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/64704bc3b870236233.png",
        "info": "帮您生成网页title 关键词和描述",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "网页内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简单描述下网页内容"
            },
            {
                "label": "相关关键词",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "输入网页相关的关键词"
            },
            {
                "label": "生成条数",
                "options": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "生成几条"
            }
        ],
        "system_msg": "你现在是一个seo专家，请根据我给的网页内容和相关关键词，生成指定条数的吸引人的网页tdk信息，生成的meta keyword信息需要根据给定的相关关键词进行拓展，description需要在80个汉字内（英文160个字符内），tdk信息分行显示，不要用html显示",
        "user_msg": "网页内容：{网页内容}\n相关关键词：{相关关键词}\n条数：{生成条数}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list4"
    },
    {
        "id": 35,
        "name": "活动策划安排",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6dae9ba9199982.png",
        "info": "策划安排公司活动",
        "cat_id": 38,
        "cat_id_arr": [
            10,
            38
        ],
        "user_form": [
            {
                "label": "活动类型",
                "options": [
                    "员工生日会",
                    "公司集体旅游",
                    "公司体育比赛",
                    "公司拓展活动",
                    "其他互动"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择要安排的活动类型"
            },
            {
                "label": "活动信息补充",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请填写活动的要求和补充信息，可以要求推荐具体旅游地点"
            }
        ],
        "system_msg": "你是一个企业管理专家和人事行政管理专家，公司为了更好的凝聚员工，让员工感受到公司的关怀和企业文化，请根据要求制定信息可执行的活动计划方案",
        "user_msg": "活动类型：{活动类型}，要求及补充信息：{活动信息补充}",
        "origin_cat_id": 38,
        "top_cat_id": 35,
        "arrKey": "list35"
    },
    {
        "id": 44,
        "name": "普通微博创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230606/647ea7d75153118017.png",
        "info": "创作普通微博和产品宣推微博",
        "cat_id": 21,
        "cat_id_arr": [
            21,
            39,
            81
        ],
        "user_form": [
            {
                "label": "内容关键词",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简单输入微博中需要包含的关键词"
            },
            {
                "label": "爆款关键词",
                "options": [
                    "YYDS",
                    "爆款",
                    "小白必看",
                    "正确姿势",
                    "宝藏",
                    "神器",
                    "沉浸式",
                    "手把手",
                    "治愈",
                    "破防",
                    "玩玩没想到",
                    "吹爆",
                    "压箱底",
                    "高级感"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择爆款关键词"
            },
            {
                "label": "风格",
                "options": [
                    "正常",
                    "幽默",
                    "热情",
                    "急迫",
                    "说服",
                    "深沉",
                    "伤感",
                    "喜悦",
                    "悲痛",
                    "崇敬",
                    "傲慢",
                    "挖苦",
                    "讽刺"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择内容风格"
            },
            {
                "label": "要求",
                "options": [
                    "加入客户口碑说服读者",
                    "用情感共鸣方式感动读者",
                    "用痛点刺激读者",
                    "用福利优惠诱惑读者",
                    "用名人或名言增加说服力",
                    "用真实案例说服读者",
                    "用价值打动读者"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "选择创作的要求"
            }
        ],
        "system_msg": "你是一个微博内容创作专家，请根据我给的内容关键词和要求创作一篇指定风格的微博，如果我给出了爆款关键词微博内容最好包含我给定的爆款关键词，创作这篇微博的目的是吸引更多人阅读和互动",
        "user_msg": "内容关键词：{内容关键词}\n爆款关键词：{爆款关键词}\n风格：{风格}\n要求：{要求}",
        "origin_cat_id": 21,
        "top_cat_id": 16,
        "arrKey": "list44"
    },
    {
        "id": 65,
        "name": "产品介绍",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481b570bd8e079457.png",
        "info": "生成吸引客户的产品介绍文案",
        "cat_id": 4,
        "cat_id_arr": [
            4
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "产品功能",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "产品优势",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "产品客户画像",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "产品解决的问题",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "包括的内容",
                "options": [
                    "简介",
                    "功能",
                    "适用对象",
                    "适用人群",
                    "解决的痛点",
                    "优势",
                    "特点",
                    "客户案例",
                    "客户口碑",
                    "工艺",
                    "材料",
                    "产地",
                    "面料",
                    "用户体验",
                    "效果",
                    "功效",
                    "解决的问题",
                    "口感",
                    "适用感受"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "产品介绍需要包括哪些内容"
            }
        ],
        "system_msg": "根据以下信息：\n-产品名称\n-产品功能\n-产品优势\n-产品客户画像\n-产品解决的问题\n-需要包括的内容\n生成一篇吸引给定产品客户画像群体的产品介绍",
        "user_msg": "产品名称：{产品名称}\n产品功能：{产品功能}\n产品优势：{产品优势}\n产品客户画像：{产品客户画像}\n产品解决的问题：{产品解决的问题}\n需要包括的内容：{包括的内容}",
        "origin_cat_id": 4,
        "top_cat_id": 1,
        "arrKey": "list65"
    },
    {
        "id": 1,
        "name": "伪原创改写",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647048a64194c77988.png",
        "info": "针对SEO的文章进行伪原创改写",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "原始文本",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "粘贴您原始的文本在这里"
            },
            {
                "label": "改写目的",
                "options": [
                    "SEO优化",
                    "文章复用",
                    "防止抄袭"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "您选择你的改写目的"
            },
            {
                "label": "关键词",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "填写或复制原始文中的关键词，保证他的关联性"
            },
            {
                "label": "改写篇数",
                "options": [
                    "1",
                    "2",
                    "3"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请填写伪原创的篇数"
            }
        ],
        "system_msg": "你现在是个伪原创内容改写高手，你得根据我提供的原始文章、改写目的、关键词对原始文章进行改写，改写后的文章需要与给定的关键词相关联，并保证生成内容的原创性",
        "user_msg": "原始文章：{原始文本}\n改写目的：{改写目的}\n关键词：{关键词}\n根据以上信息进行改写{改写篇数}篇伪原创文章",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list1"
    },
    {
        "id": 2,
        "name": "关键词热门相关",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230526/647048ceac5c552522.png",
        "info": "根据你的内容关键词生成热门关键词",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入关键词"
            },
            {
                "label": "关键词个数",
                "options": [
                    "5",
                    "10",
                    "20",
                    "30",
                    "50",
                    "80",
                    "100",
                    "200"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择生成的关键词个数"
            }
        ],
        "system_msg": "帮我生成{关键词}相关的{关键词个数}个热门关键词",
        "user_msg": "帮我生成{关键词}相关的的{关键词个数}个热门关键词",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list2"
    },
    {
        "id": 7,
        "name": "商业计划书",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719d398310164462.png",
        "info": "根据企业目标生成商业计划书",
        "cat_id": 3,
        "cat_id_arr": [
            8,
            3,
            38,
            34
        ],
        "user_form": [
            {
                "label": "企划目标",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的招商融资或其它发展目标"
            },
            {
                "label": "项目简介",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入项目的简单介绍"
            },
            {
                "label": "内容要求",
                "options": [
                    "公司和项目状况",
                    "未来发展潜力",
                    "企业成长经历",
                    "产品服务",
                    "市场营销",
                    "管理团队",
                    "股权结构",
                    "组织人事",
                    "现今财务状况",
                    "运营方案",
                    "融资方案",
                    "企业战略规划与执行"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择计划书包含哪些内容"
            },
            {
                "label": "其他补充",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "填写需要补充的信息"
            }
        ],
        "system_msg": "请帮我详细的写一份商业计划书，目的参考我给的企划目标，计划书的项目是我给的项目简介，计划书内必须包括我给出的内容要求，并结合我给的其他补充信息写一份详细的商业计划书。",
        "user_msg": "企划目标：{企划目标}\n项目简介：{项目简介}\n内容要求：{内容要求}\n其他补充信息：{其他补充}",
        "origin_cat_id": 34,
        "top_cat_id": 1,
        "arrKey": "list7"
    },
    {
        "id": 17,
        "name": "短视频口播稿文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230529/64744b019993029734.png",
        "info": "帮您生成适合口播的短视频文案",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "财经",
                    "三农",
                    "美食",
                    "文化教育",
                    "科技",
                    "旅行",
                    "校园",
                    "时尚",
                    "体育",
                    "亲子",
                    "二次元",
                    "娱乐",
                    "才艺",
                    "泛生活",
                    "公益",
                    "动植物",
                    "游戏",
                    "政务媒体",
                    "剧情",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您的创作类型"
            },
            {
                "label": "文稿主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入文稿的主题"
            },
            {
                "label": "文稿观点",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的观点"
            },
            {
                "label": "字数",
                "options": [
                    "150字以内",
                    "150-300字",
                    "300-450字",
                    "450-600字",
                    "600-750字",
                    "750-900字",
                    "900字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "一分钟大概150字"
            },
            {
                "label": "内容风格",
                "options": [
                    "正常",
                    "幽默搞笑",
                    "热情奔放",
                    "悲痛",
                    "伤感",
                    "喜悦",
                    "讽刺",
                    "激励鼓励",
                    "说服购买",
                    "惊讶",
                    "情感共鸣"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择内容风格"
            },
            {
                "label": "内容要求",
                "options": [
                    "前30字有强烈的吸引力，可以使用疑问 感叹语气",
                    "有趣幽默，适当运用搞笑、调侃",
                    "情感共鸣，可以讲述一个感人的故事",
                    "引导互动，通过话题、提问等方式鼓励观众留言、点赞、转发",
                    "引用名人名言增强说服力"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择内容要求，生成更好的内容"
            }
        ],
        "system_msg": "您现在是一个个人IP，需要进行口播视频的录制，请根据输入的观点生成口播的文案稿",
        "user_msg": "根据{创作类型}，主题：{文稿主题}，观点：{文稿观点}帮我生成一个{内容风格}风格的短视频口播稿，字数要求为{字数}，内容要求为：{内容要求}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list17"
    },
    {
        "id": 20,
        "name": "团购套餐",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647179f9b279d65531.png",
        "info": "帮您生成一个团购套餐的介绍",
        "cat_id": 26,
        "cat_id_arr": [
            26,
            75
        ],
        "user_form": [
            {
                "label": "商家名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商家名称"
            },
            {
                "label": "套餐名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入套餐名称"
            },
            {
                "label": "套餐合作价格",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入套餐价格"
            },
            {
                "label": "套餐卖点",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入套餐卖点，可包含多个关键词"
            }
        ],
        "system_msg": "帮我生成一个团购套餐的介绍，他包含商家名称，套餐名称，价格以及套餐的卖点",
        "user_msg": "根据商家名称{商家名称}，套餐名称：{套餐名称}，价格是{套餐合作价格}，套餐的卖点是{套餐卖点}生成一个团购套餐的介绍",
        "origin_cat_id": 26,
        "top_cat_id": 25,
        "arrKey": "list20"
    },
    {
        "id": 23,
        "name": "朋友圈文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647194b29f30478116.png",
        "info": "根据发圈目的帮您生成朋友圈文案",
        "cat_id": 18,
        "cat_id_arr": [
            18,
            39,
            81
        ],
        "user_form": [
            {
                "label": "发圈目的",
                "options": [
                    "记录生活",
                    "宣传产品和服务",
                    "促进产品销售",
                    "建立品牌形象",
                    "推广营销活动",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你的发圈目的"
            },
            {
                "label": "内容重点描述",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请输入你要描述的内容重点或者关键词"
            },
            {
                "label": "文案风格",
                "options": [
                    "幽默",
                    "深情",
                    "说服",
                    "讽刺",
                    "正常",
                    "深沉",
                    "挖苦",
                    "伤心",
                    "热情",
                    "急迫"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择朋友圈文案风格"
            },
            {
                "label": "书写要求",
                "options": [
                    "加入客户口碑说服读者",
                    "用利益诱惑引起读者注意力",
                    "用娱乐八卦吸引注意力",
                    "用情感共鸣吸引读者",
                    "激发读者好奇心",
                    "通过痛点刺激读者",
                    "将读者带入场景",
                    "举出真实案例说服读者",
                    "借势明星大咖增加信赖",
                    "用福利优惠吸引读者",
                    "用产品价值吸引读者",
                    "制造稀缺让读者感到紧迫性"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "请选择书写朋友圈的要求"
            }
        ],
        "system_msg": "根据输入的关键词写一个具有吸引力的朋友圈文案",
        "user_msg": "为了{发圈目的}编写发朋友圈，主要是为了描述{内容重点描述}，请按照{书写要求}并用{文案风格}的风格写一篇100字左右的朋友圈文案",
        "origin_cat_id": 18,
        "top_cat_id": 16,
        "arrKey": "list23"
    },
    {
        "id": 25,
        "name": "知乎问答",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/647193ad542cc92551.png",
        "info": "根据提问，生成知乎风格的回答",
        "cat_id": 5,
        "cat_id_arr": [
            20,
            5,
            39,
            81
        ],
        "user_form": [
            {
                "label": "问题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您的问题，例如：如何看新款苹果手机？"
            },
            {
                "label": "关键词",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "请输入您的关键词"
            },
            {
                "label": "内容要求",
                "options": [
                    "以问题为导向，将问题阐述清楚之后再进行详细的解答",
                    "引用相关领域的专业术语和数据，准确地表达作者的观点，并且显得更加专业和权威",
                    "突出作者个人的经验和感悟，使文章更加亲切和有感染力，读者也更容易产生共鸣",
                    "采用问答形式，将一个问题分成多个小问题逐一回答",
                    "从多个角度思考问题，探讨问题的各个方面",
                    "引用名人名言，这样能够增强文章的说服力和影响力",
                    "深入剖析问题本质，探讨问题的根源和本质原因",
                    "以具体的案例来证明观点，使文章更加生动、具体和有说服力",
                    "采用故事叙述法，通过一个个小故事来阐述观点和解决问题",
                    "以行动号召结尾，鼓励读者去实践和行动"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择内容要求，写出更高质量的文章"
            }
        ],
        "system_msg": "请用知乎的风格回答我给出的问题，回答需要结合我给出的关键词，还需要按照给定的内容要求进行创作。知乎内容的特点是高质量、有深度。",
        "user_msg": "问题：{问题}\n关键词：{关键词}\n内容要求：{内容要求}",
        "origin_cat_id": 20,
        "top_cat_id": 1,
        "arrKey": "list25"
    },
    {
        "id": 28,
        "name": "商品好评",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230527/64719ae30066d20146.png",
        "info": "帮您生成超真实的商品好评文案",
        "cat_id": 27,
        "cat_id_arr": [
            27,
            75
        ],
        "user_form": [
            {
                "label": "发布平台",
                "options": [
                    "淘宝",
                    "抖音",
                    "天猫",
                    "京东",
                    "唯品会",
                    "拼多多",
                    "微信",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请输入发布平台"
            },
            {
                "label": "商品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商品名称"
            },
            {
                "label": "商品描述",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请简要描述商品的特点"
            },
            {
                "label": "商品卖点",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "请添加商品卖点提示词"
            }
        ],
        "system_msg": "根据发布平台：{发布平台}，给{商品名称}写一个商品好评，包含{商品描述}和{商品卖点}",
        "user_msg": "根据发布平台：{发布平台}，给{商品名称}写一个商品好评，包含{商品描述}和{商品卖点}",
        "origin_cat_id": 27,
        "top_cat_id": 25,
        "arrKey": "list28"
    },
    {
        "id": 36,
        "name": "竞品分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6cb5a6ecb79830.png",
        "info": "分析相关竞品",
        "cat_id": 3,
        "cat_id_arr": [
            11,
            3,
            38
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "信息补充",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "填写产品的更多补充信息"
            }
        ],
        "system_msg": "请根据我给出的产品名称和信息补充，列出我给出产品的竞品信息，用列表的方式给出竞品的公司、产品名称、价格、网址、swot分析",
        "user_msg": "产品名称：{产品名称}，信息补充：{信息补充}",
        "origin_cat_id": 38,
        "top_cat_id": 1,
        "arrKey": "list36"
    },
    {
        "id": 37,
        "name": "各类方案撰写",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230605/647d6cee01e1652241.png",
        "info": "自动生成各种类型的方案",
        "cat_id": 38,
        "cat_id_arr": [
            11,
            38
        ],
        "user_form": [
            {
                "label": "方案类型",
                "options": [
                    "策划方案",
                    "施工方案",
                    "软件方案",
                    "活动方案",
                    "系统集成方案",
                    "运营方案",
                    "其他方案"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择方案类型"
            },
            {
                "label": "方案名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请填写方案名称"
            },
            {
                "label": "方案信息补充",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "补充方案更多信息，达成更好的方案效果"
            }
        ],
        "system_msg": "你是一个方案撰写专家，请根据我给出的方案名称和方案补充信息替我书写详细可执行的方案，方案内容需要根据我给出的方案类型撰写。",
        "user_msg": "方案类型：{方案类型}，方案名称：{方案名称}，方案补充信息：{方案信息补充}",
        "origin_cat_id": 38,
        "top_cat_id": 35,
        "arrKey": "list37"
    },
    {
        "id": 45,
        "name": "百度下拉词",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800b9380f9f70865.png",
        "info": "获取指定关键词的百度下拉词",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "关键词",
                "formType": "input",
                "isRequired": true,
                "placeholder": "给出你想要的查询的关键词"
            }
        ],
        "system_msg": "你是一个seo专家，我给你指定的关键词，你列出与之对应的百度下拉词",
        "user_msg": "{关键词}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list45"
    },
    {
        "id": 46,
        "name": "长尾词查询",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800bbc53a6167506.png",
        "info": "查询指定关键词的长尾词",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "关键词",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入需要查询的关键词"
            }
        ],
        "system_msg": "你是一个seo专家，我给你一个关键词，你列出与之相关的至少50个长尾词",
        "user_msg": "{关键词}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list46"
    },
    {
        "id": 47,
        "name": "文章扩写",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64800cf8aadf156286.png",
        "info": "根据给定的关键词创作符合SEO规则的文章",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "关键词",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "可以填写多个关键词，间隔是空格或者顿号都可以"
            },
            {
                "label": "字数",
                "options": [
                    "500字以内",
                    "500-1000字",
                    "1000字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择生成多少字的文章"
            }
        ],
        "system_msg": "你现在是seo专家，请根据我给定的关键词，创作一篇更适合seo的文章，按照我给定的字数要求创作",
        "user_msg": "关键词：{关键词}\n字数：{字数}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list47"
    },
    {
        "id": 48,
        "name": "SEO诊断",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64801254850f238842.png",
        "info": "诊断你网址的seo问题",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "页面地址",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入需要诊断的页面地址"
            },
            {
                "label": "页面核心关键词",
                "formType": "keywords",
                "isRequired": true
            }
        ],
        "system_msg": "你是一个seo专家，请分析我给的页面地址的html，结合我给的页面核心关键词、分析html的各个标签、html结构合理性、页面关键词布局、tdk信息、相关内容是否合理等角度给出详细的seo建议",
        "user_msg": "页面地址：{页面地址}\n页面核心关键词：{页面核心关键词}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list48"
    },
    {
        "id": 49,
        "name": "网站创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648014101f15b19459.png",
        "info": "做公司官网的时候自动生成各版块文案",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "产品和服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "输入你要做的网站对应的产品和服务简介"
            },
            {
                "label": "网站板块",
                "options": [
                    "网页标题 关键词和描述",
                    "公司简介",
                    "发展历程",
                    "产品介绍",
                    "服务介绍",
                    "我们的优势",
                    "客户感言",
                    "客户案例",
                    "功能要点",
                    "解决方案"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择你的网站包括的板块"
            }
        ],
        "system_msg": "我需要做一个公司官方网站，请根据我提供的产品和服务介绍，做出相应的网站，按照我给的网站板块，分别给出相应的内容，每个板块最好分别列出至少4条内容",
        "user_msg": "产品和服务介绍：{产品和服务介绍}\n网站板块：{网站板块}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list49"
    },
    {
        "id": 50,
        "name": "内容采集源",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648018d4c2df337238.png",
        "info": "根据关键词找对应的公众号采集源",
        "cat_id": 2,
        "cat_id_arr": [
            2
        ],
        "user_form": [
            {
                "label": "关键词",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "填写相关的关键词"
            }
        ],
        "system_msg": "我给出关键词信息，你查找有哪些公众号与我给的关键词相关，请详细列出至少10个公众号名称、对应的微信号和网址",
        "user_msg": "{关键词}",
        "origin_cat_id": 2,
        "top_cat_id": 1,
        "arrKey": "list50"
    },
    {
        "id": 51,
        "name": "虚拟购买评论",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/64801b0ad8c1015026.png",
        "info": "生成虚拟评论，引导更多人购买",
        "cat_id": 27,
        "cat_id_arr": [
            27,
            75
        ],
        "user_form": [
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "简单描述你卖的产品"
            },
            {
                "label": "生成条数",
                "formType": "input",
                "isRequired": true,
                "placeholder": "生成多少条评论"
            }
        ],
        "system_msg": "根据我给的产品描述，虚拟生成指定条数的购买评论，用于在社交媒体上发布点评，然后让其他人看到这些评论能放心购买，每条50字以内，要求真实而且非常口语化",
        "user_msg": "产品描述：{产品描述}\n条数：{生成条数}",
        "origin_cat_id": 27,
        "top_cat_id": 25,
        "arrKey": "list51"
    },
    {
        "id": 52,
        "name": "微信群发文案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230607/648042d8e9aab40965.png",
        "info": "创作微信群发文案",
        "cat_id": 18,
        "cat_id_arr": [
            18,
            39
        ],
        "user_form": [
            {
                "label": "内容类型",
                "options": [
                    "优惠促销",
                    "产品上新",
                    "节日问候",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择群发内容的类型"
            },
            {
                "label": "简单说明",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "大概介绍下意图"
            },
            {
                "label": "内容风格",
                "options": [
                    "正常",
                    "热情",
                    "乞求",
                    "幽默",
                    "深沉",
                    "轻松"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择类型"
            }
        ],
        "system_msg": "我要给客户发一个指定内容类型的消息，请根据我给的意图说明，生成一段指定内容风格的消息，我来发给客户，消息长度不超过200字。生成的消息内容要很口语化，并且让客户感觉是在和他聊天",
        "user_msg": "内容类型：{内容类型}\n意图说明：{简单说明}\n内容风格：{内容风格}",
        "origin_cat_id": 39,
        "top_cat_id": 16,
        "arrKey": "list52"
    },
    {
        "id": 54,
        "name": "吸睛开头创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64814c4f8cf5523459.png",
        "info": "创作短视频吸引眼球的开头",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "视频内容文本",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "输入视频大概的视频内容"
            },
            {
                "label": "视频风格",
                "options": [
                    "正常",
                    "激情",
                    "深沉",
                    "喜悦",
                    "说服",
                    "质疑",
                    "讽刺",
                    "骄傲"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择视频风格"
            }
        ],
        "system_msg": "根据我给的视频内容文本创作一个短视频的开头文案和与生成的文案对应的视频脚本，要求如下：\n1、需要非常吸引人，能让观众继续看下去\n2、用创作出来的文案不超过100字，脚本对应的视频长度不超过20秒\n3、按照我给的视频风格生成对应语气和风格的文案和视频脚本\n按照以下结构输出内容：\n1、视频文案\n2、视频脚本",
        "user_msg": "视频内容文本：{视频内容文本}\n视频风格：{视频风格}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list54"
    },
    {
        "id": 55,
        "name": "带货短视频创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648150bb7d93477647.png",
        "info": "创作带货类别的短视频",
        "cat_id": 24,
        "cat_id_arr": [
            30,
            24,
            81
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "输入产品或服务名称"
            },
            {
                "label": "产品优势及特色",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "你的产品或服务有什么特别的地方"
            },
            {
                "label": "优惠福利",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "你的产品有哪些优惠、折扣或福利"
            },
            {
                "label": "开头文案",
                "options": [
                    "名人推荐增强信任，比如：XX真诚推荐",
                    "使用惊讶语气增强好奇心，比如：不会还有人不知道……",
                    "夸张产品使用效果，比如：我用了X天就……",
                    "低价与效果对比反差，比如：X元能买到这种好东西……",
                    "第一人称视角拉近距离，比如：我已经买了好多次了"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "选择想要的开头文案示例"
            },
            {
                "label": "黄金3秒开头",
                "options": [
                    "提出痛点问题引发观众思考",
                    "提出与产品关联的社会现象",
                    "制造反差冲突吸引观众了解产品",
                    "利用优惠福利诱惑消费者"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "选择黄金3秒的开头形式"
            },
            {
                "label": "介绍产品的方式",
                "options": [
                    "从原料上，打消消费者顾虑",
                    "从工艺上，强调产品质量和优势",
                    "从结果上，突出产品的使用效果和用户体验",
                    "从功能上，介绍消费者痛点"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择如何介绍你的产品"
            },
            {
                "label": "促销的方式",
                "options": [
                    "将价格换算成另外一个便宜东西-例：X杯奶茶钱就能买到",
                    "低价并且包邮的营销方式-例：X元到手还包邮....",
                    "和以前的价格做对比突出优惠-例：以前要XX元才能买到，现在XX元还包邮....",
                    "购买有赠品等福利-例：买XX还送XX...."
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择如何促销你的产品"
            },
            {
                "label": "引导促进成交话术",
                "options": [
                    "直击目标人群的痛点-例：有什么痛点的人群，一定要购买....",
                    "锁定目标人群引导购买-例：家里有XX的一定要...",
                    "利用口碑引导购买-例：凡是购买过的..",
                    "饥饿营销，制造紧迫感-例：数量有限...."
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "选择引导促进成交的话术"
            }
        ],
        "system_msg": "创作一个短视频文案，目的是让观众看完短视频之后有购买指定产品名称的欲望。\n短视频包括视频开头（按照给定的黄金3秒开头、开头文案要求创作），产品介绍（按照产品优势及特色、介绍产品的方式创作），优惠促销介绍（按照优惠福利、促销的方式创作）、引导下单话术（按照引导促进成交话术创作）",
        "user_msg": "-产品名称：{产品名称}\n-产品优势及特色：{产品优势及特色}\n-优惠福利：{优惠福利}\n-开头文案：{开头文案}\n-介绍产品的方式：{介绍产品的方式}\n-促销的方式：{促销的方式}\n-引导促进成交话术：{引导促进成交话术}\n-黄金3秒开头：{黄金3秒开头}",
        "origin_cat_id": 30,
        "top_cat_id": 16,
        "arrKey": "list55"
    },
    {
        "id": 56,
        "name": "开场聚人阶段口播创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481717e6fb1992065.png",
        "info": "在直播的开场阶段，生成暖场及预告口播文案",
        "cat_id": 31,
        "cat_id_arr": [
            31,
            81
        ],
        "user_form": [
            {
                "label": "产品介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "输入要直播的产品简介"
            },
            {
                "label": "目标人群",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写你的目标营销人群"
            },
            {
                "label": "福利预告",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "直播的福利预告"
            }
        ],
        "system_msg": "我通过网络直播卖货，根据我给出的下面信息：\n-产品介绍：{产品介绍}\n-目标人群：{目标人群}\n-福利预告：{福利预告}\n创作一个直播开场暖场的口播文案，文案从几个方面创作：\n1、首先欢迎在直播间里的粉丝，拉近用户距离，可以根据目标人群寻找共同话题聊天，并且根据福利预告来吸引粉丝留在直播间\n2、根据产品介绍告知直播的时间和内容\n3、根据产品介绍，创作产品的功能 优势 口碑，吸引更多粉丝\n4、让粉丝耐心等待留在直播间，准备售卖抢福利",
        "user_msg": "{福利及产品介绍}{目标人群}{产品介绍}{福利预告}",
        "origin_cat_id": 31,
        "top_cat_id": 29,
        "arrKey": "list56"
    },
    {
        "id": 57,
        "name": "直播中留客锁客口播创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64817e7b7619932910.png",
        "info": "直播过程中留下客户 锁定客户的口播文案",
        "cat_id": 31,
        "cat_id_arr": [
            31,
            81
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入产品名称"
            },
            {
                "label": "互动话术",
                "options": [
                    "这款产品大家以前用过吗？如果不清楚的可以扣1，我再来给大家讲解",
                    "宝宝们如果有不清楚拍什么的可以把你们的疑问打出来",
                    "大家能听到我的声音吗？听到的请打1，谢谢大家",
                    "想要X的刷1，想要X的刷2",
                    "想要X宝贝的宝宝们，我们满屏刷起来好不好，要福利的宝宝把1刷起来",
                    "新来的宝贝先点一下关注，可以领取到一个粉丝特价价格哦",
                    "宝宝们现在先不要下单，这款领了优惠券之后再下单哈",
                    "新涌进来的宝宝们，我们现在正在给大家做福利秒杀，现在点赞十下并给主播点一个关注可以领取一个优惠券哦",
                    "宝宝们，什么是爱什么是喜欢？爱是点关注，喜欢是刷礼物。有一种陪伴叫带我看花海！你们是爱我还是喜欢我呢！",
                    "欢迎各位帅哥美女们来到我的直播间，进来直播间的是美女，还是帅哥呢？帅哥请刷1，美女请刷2"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择希望在直播间出现的互动话术"
            },
            {
                "label": "锁客话术",
                "options": [
                    "展示产品的质量、使用感受和其他渠道对比的价格优势",
                    "多强调“我”用过且喜欢，并强调“我”在使用产品时的感受",
                    "从专业的角度出发，针对产品以及同类其他产品的材质等进行详细讲解，增加粉丝的信任度",
                    "现场试用产品，分享使用体验与效果，验证产品功能"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择锁客话术进行创作"
            },
            {
                "label": "其他要求",
                "formType": "textarea",
                "isRequired": true
            }
        ],
        "system_msg": "我正在通过抖音直播间卖货，为了留住直播间的客户，需要生成与直播间粉丝互动和留住粉丝的话术。\n-产品名称\n-互动话术\n-锁客话术\n-其他要求\n在生成的话术中把“产品”两个字和“X”改成我给的产品名称，对我给出的互动话术进行润色修改，并根据锁客话术的要求生成相应的文案",
        "user_msg": "-产品名称：{产品名称}\n-互动话术：{互动话术}\n-锁客话术：{锁客话术}\n-其他要求：{其他要求}",
        "origin_cat_id": 31,
        "top_cat_id": 29,
        "arrKey": "list57"
    },
    {
        "id": 58,
        "name": "直播催单下单口播文案创作",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648180d14891d80735.png",
        "info": "创作引导粉丝购买 催单及下单的口播文案",
        "cat_id": 31,
        "cat_id_arr": [
            31,
            81
        ],
        "user_form": [
            {
                "label": "产品介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写产品相关的特色 优势 功效等信息"
            },
            {
                "label": "优惠介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "介绍赠送 优惠 折扣等福利信息"
            },
            {
                "label": "说服购买话术",
                "options": [
                    "从产品的功效、价位、成分、包装设计、促销力度和现场使用结果等，与竞品对比，进一步帮用户排除选择",
                    "通过折扣、优惠、福利等信息进一步刺激粉丝，让粉丝有紧迫感并且怕错过这些福利",
                    "构建美好的场景，让用户体会到购买后的好处，满足用户的想象和虚荣心"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择需要的说服话术"
            },
            {
                "label": "催单话术",
                "options": [
                    "我们之前试播的时候，这个产品一上架就秒空了，超级抢手，所以进来宝宝千万不要犹豫，下单就行了。",
                    "这款产品数量有限，如果看中了一定要及时下单，不然等会儿就抢不到了哦。",
                    "抢到就是赚到、秒杀单品数量有限”/“先付先得、最后2分钟！最后2分钟",
                    "这次活动的力度真的很大，您可以再加一套的，很划算，错过真的很可惜。这个产品还有最后两件咯",
                    "点商品链接、大家尽快点优惠券、点立即购买、填数量、点确认了"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "选择包含哪些催单话术"
            },
            {
                "label": "感谢话术",
                "options": [
                    "感谢你们来观看我的直播，谢谢你们的礼物，陪伴是最长情的告白，你们的爱意我收到了",
                    "最后一首歌《XXXX》谢谢大家的陪伴，唱完下播，希望大家睡个好觉做个好梦，明天新的一天好好工作，晚上我们再聚",
                    "今天的直播接近尾声了，明天晚上 XX 点~XX 点同样时间开播/明天会提早一点播 XX 点就开了，各位奔走相告吧！"
                ],
                "formType": "multiSelect",
                "isRequired": false,
                "placeholder": "下单后感谢粉丝的话术"
            }
        ],
        "system_msg": "根据以下信息：\n-产品介绍\n-优惠介绍\n-说服购买话术\n-催单话术\n-感谢话术\n帮我修改并润色生成抖音直播间的口播文案，目的是让客户尽快下单购买，内容主要包括：\n说服粉丝购买（根据产品介绍 优惠介绍和说服购买话术进行创作）\n催粉丝下单（根据产品介绍 优惠介绍 催单话术进行改写创作）\n感谢粉丝（根据感谢话术创作）",
        "user_msg": "-产品介绍：{产品介绍}\n-优惠介绍：{优惠介绍}\n-说服购买话术：{说服购买话术}\n-催单话术：{催单话术}\n-感谢话术：{感谢话术}",
        "origin_cat_id": 31,
        "top_cat_id": 29,
        "arrKey": "list58"
    },
    {
        "id": 59,
        "name": "商品标题生成",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/64818c0f2ca4c65184.png",
        "info": "生成电商平台的商品标题",
        "cat_id": 26,
        "cat_id_arr": [
            26,
            75
        ],
        "user_form": [
            {
                "label": "商品核心名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "填写商品的核心词，可以两到三个，比如：打底衫 毛衣"
            },
            {
                "label": "商品属性",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "比如：2023款 秋季 纯棉长袖"
            },
            {
                "label": "电商平台",
                "options": [
                    "抖音",
                    "淘宝",
                    "天猫",
                    "京东",
                    "拼多多",
                    "唯品客",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择哪个电商平台"
            }
        ],
        "system_msg": "根据下面信息：\n-商品核心名称\n-商品属性\n-电商平台\n你是一个电商平台seo专家，帮我写一个发布在指定电商平台的商品标题，要求如下：\n1、好的商品标题由：商品核心名称、商品属性和相应的长尾词组成\n2、标题长度25-30个字\n3、生成5个标题\n4、标题里不含空格和标点，不含电商平台名称",
        "user_msg": "商品核心名称：{商品核心名称}\n商品属性：{商品属性}\n电商平台：{电商平台}\n下面是例子：\n正品新百伦学生男女303平板鞋复古夏季防滑情侣百搭休闲运动鞋子\n",
        "origin_cat_id": 26,
        "top_cat_id": 25,
        "arrKey": "list59"
    },
    {
        "id": 60,
        "name": "节日营销活动方案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/648196f751d4812823.png",
        "info": "为指定商品生成节日营销活动方案",
        "cat_id": 3,
        "cat_id_arr": [
            28,
            38,
            3
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "输入您的产品名称"
            },
            {
                "label": "目标客户群",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "输入您的产品对应的目标客户"
            },
            {
                "label": "节日",
                "options": [
                    "春节",
                    "情人节",
                    "端午节",
                    "清明节",
                    "五一劳动节",
                    "六一儿童节",
                    "国庆节",
                    "七夕情人节",
                    "中秋节",
                    "圣诞节",
                    "万圣节",
                    "父亲节",
                    "母亲节",
                    "复活节",
                    "元旦",
                    "618",
                    "双11",
                    "双12",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择节日"
            }
        ],
        "system_msg": "你是营销策划专家，请按照我给的信息生成一份营销策划方案",
        "user_msg": "我们公司是做{产品名称}的，面向的客户群体主要是{目标客户群}，为了提高销量，今年{节日}我们应该做什么样的活动，请从促销、平台、线上线下、流量裂变等各个角度给出详细的活动执行方案",
        "origin_cat_id": 3,
        "top_cat_id": 1,
        "arrKey": "list60"
    },
    {
        "id": 62,
        "name": "品牌故事",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481acfa9f6bb11346.png",
        "info": "生成生动的品牌故事",
        "cat_id": 4,
        "cat_id_arr": [
            4
        ],
        "user_form": [
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true
            },
            {
                "label": "品牌成立时间",
                "formType": "input",
                "isRequired": true,
                "placeholder": "比如：1908年7月"
            },
            {
                "label": "品牌所属行业",
                "formType": "input",
                "isRequired": true,
                "placeholder": "比如服装生产"
            },
            {
                "label": "品牌相应关键词",
                "formType": "keywords",
                "isRequired": true
            },
            {
                "label": "品牌用户画像",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "比如女性 20-30岁 时尚 高学历"
            },
            {
                "label": "品牌故事风格",
                "options": [
                    "正常",
                    "激情",
                    "深沉",
                    "轻松",
                    "喜悦",
                    "生机勃勃"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择哪种风格的品牌故事"
            },
            {
                "label": "补充信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "更多品牌的相关信息补充"
            },
            {
                "label": "内容要求",
                "options": [
                    "彰显自身品牌的悠久历史，给人留下传统、正宗的品牌印象",
                    "讲述创始人从创业前的窘迫，到创业时的艰辛，再到现在的伟大，来为自己树立来自民众、回归民众的形象",
                    "展现自己的专业度和匠心精神，使受众增强对品牌的信任",
                    "通过对于情感的描述，可以增强消费者对于品牌核心精神的认知",
                    "能体现品牌的思想价值观念，从而引起消费者群体的共鸣"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "选择故事的内容要求"
            }
        ],
        "system_msg": "请根据以下信息：\n-品牌名称\n-品牌成立时间\n-品牌所属行业\n-品牌相应关键词\n-品牌用户画像\n-补充信息\n-内容要求\n\n生成一篇容易传播的指定品牌故事风格的品牌故事",
        "user_msg": "品牌名称：{品牌名称}\n品牌成立时间：{品牌成立时间}\n品牌所属行业：{品牌所属行业}\n品牌相应关键词：{品牌相应关键词}\n品牌用户画像：{品牌用户画像}\n品牌故事风格：{品牌故事风格}\n补充信息：{补充信息}\n内容要求：{内容要求}",
        "origin_cat_id": 4,
        "top_cat_id": 1,
        "arrKey": "list62"
    },
    {
        "id": 63,
        "name": "公司介绍",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481a48e5750852253.png",
        "info": "生成公司介绍",
        "cat_id": 4,
        "cat_id_arr": [
            4
        ],
        "user_form": [
            {
                "label": "公司名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "公司主营业务",
                "formType": "textarea",
                "isRequired": true
            },
            {
                "label": "公司成立日期",
                "formType": "input",
                "isRequired": true,
                "placeholder": "比如2023年5月"
            },
            {
                "label": "公司成立地点",
                "formType": "input",
                "isRequired": true,
                "placeholder": "比如：北京"
            },
            {
                "label": "补充信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "还有哪些信息补充？"
            }
        ],
        "system_msg": "根据以下信息：\n-公司名称\n-公司主营业务\n-公司成立日期\n-公司成立地点\n-补充系信息\n写一篇公司介绍，要求包括以下内容\n1、公司简介，公司主营业务介绍，公司的定位\n2、介绍公司历史、现状，主要包括公司员工数据、规模、亮点经营数据，资质、荣誉、客户情况等\n3、介绍公司发展历程、特点和核心竞争力\n4、公司的文化、愿景和使命\n4、介绍公司的未来发展规划及信心",
        "user_msg": "{公司名称}{公司主营业务}{公司成立日期}{公司成立地点}{补充信息}",
        "origin_cat_id": 4,
        "top_cat_id": 1,
        "arrKey": "list63"
    },
    {
        "id": 64,
        "name": "广告语",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230608/6481b637cb2f043932.png",
        "info": "生成各种广告语",
        "cat_id": 4,
        "cat_id_arr": [
            4
        ],
        "user_form": [
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "产品所属品类",
                "formType": "input",
                "isRequired": true
            },
            {
                "label": "产品定位",
                "formType": "input",
                "isRequired": true
            },
            {
                "label": "产品特性",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "产品用户画像",
                "formType": "keywords",
                "isRequired": false,
                "placeholder": "比如高学历 精英 年轻人 女性"
            },
            {
                "label": "广告投放渠道",
                "options": [
                    "传统纸媒",
                    "电视",
                    "电梯/地铁/公交",
                    "互联网",
                    "户外广告",
                    "活动推广",
                    "其他"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "广告投放目的",
                "options": [
                    "扩大品牌曝光度",
                    "让更多人知道品牌",
                    "提高产品销量",
                    "树立更好的品牌形象"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "广告文案风格",
                "options": [
                    "正常",
                    "幽默",
                    "轻松",
                    "讽刺",
                    "严肃",
                    "喜悦",
                    "奔放",
                    "激励",
                    "厚重"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "formType": "input",
                "isRequired": true,
                "placeholder": "生成文案的大概字数，只填写数字即可"
            }
        ],
        "system_msg": "根据以下信息和要求：\n-产品名称\n-产品所属品类\n-产品定位\n-产品特性\n-产品用户画像\n-广告投放渠道\n-广告投放目的\n-广告文案风格要求\n-字数要求\n生成一个吸引人的广告营销文案",
        "user_msg": "产品名称：{产品名称}\n产品所属品类：{产品所属品类}\n产品定位：{产品定位}\n产品特性：{产品特性}\n产品用户画像：{产品用户画像}\n广告投放渠道：{广告投放渠道}\n广告投放目的：{广告投放目的}\n广告文案风格要求：{广告文案风格}\n字数要求：{字数要求}字左右",
        "origin_cat_id": 4,
        "top_cat_id": 1,
        "arrKey": "list64"
    },
    {
        "id": 67,
        "name": "SWOT分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64828e243cb7281849.png",
        "info": "替您的业务识别其优势、弱点、机会与威胁",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请填写您的产品和服务名称"
            },
            {
                "label": "产品或服务面向的客户群体",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "比如：高学历 女性 20-35岁"
            },
            {
                "label": "产品或服务简介",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品介绍、特性、优势"
            },
            {
                "label": "产品或服务的客单价",
                "formType": "input",
                "isRequired": true,
                "placeholder": "填写产品或服务的客单价"
            }
        ],
        "system_msg": "你是一个市场营销咨询专家，请根据我提供的：\n-产品或服务名称\n-产品或服务面向的客户群体\n-产品或服务简介\n-产品或服务的客单价\n生成详细的swot分析",
        "user_msg": "-产品或服务名称：{产品或服务名称}\n-产品或服务面向的客户群体：{产品或服务面向的客户群体}\n-产品或服务简介：{产品或服务简介}\n-产品或服务的客单价：{产品或服务的客单价}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list67"
    },
    {
        "id": 68,
        "name": "SCAMPER智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/648296262ee6366124.png",
        "info": "站在创新前沿，SCAMPER智能分析师(奔驰法)帮您冲破思维局限",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或者服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写产品或者服务的简单介绍"
            }
        ],
        "system_msg": "SCAMPER智能分析师是一款创新工具，采用SCAMPER模型理论，拥有替换、整合、调整、修改、应用、省略和翻转等七种创新策略，能够为你的产品设计、服务改进和问题解决提供全方位的创新分析支持。\n其智能化特性可以帮助你快速实现创新目标，提高工作效率，节约时间成本。\n请根据我给的产品或者服务介绍生成详细的SCAMPER智能分析",
        "user_msg": "产品或者服务介绍：{产品或者服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list68"
    },
    {
        "id": 69,
        "name": "5W2H智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/648297dcb05a163136.png",
        "info": "5W2H智能分析是一种营销工具，它可以帮助你在制定营销计划时进行全面深入的思考和分析",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "介绍产品的功能 用户群体 特性"
            }
        ],
        "system_msg": "根据我给的产品或服务介绍生成详细的5W2H智能分析报告。\n5W2H智能分析用于制定营销计划时进行全面深入的思考和分析。\n利用分析报告可以更好地了解目标受众、产品、市场和竞争对手。并利用这些信息来制定更加有针对性的营销策略，以吸引更多的潜在客户并提高销售业绩。",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list69"
    },
    {
        "id": 70,
        "name": "PESTLE智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829a2a9aaf590032.png",
        "info": "帮助用户评估其产品的政治、经济、社会、技术、法律和环境因素，以制定更有效的营销策略",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "根据我给出的产品或服务介绍，生成详细的PESTLE智能分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list70"
    },
    {
        "id": 71,
        "name": "3C战略三角模型",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829af2ed20036413.png",
        "info": "从公司自身 公司顾客和竞争对手做商业分析",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "你现在是3C战略三角模型分析工具，请根据我给的产品或服务介绍，帮我生成详细的分析报告、生成高质量的商业模型，提高我们企业的成功和竞争力。\n\n3C战略三角模型（3C's Strategic Triangle Model）即\n1. 公司自身（Corporation）。\n2. 公司顾客（Customer）。\n3. 竞争对手（Competitor）。",
        "user_msg": "产品或服务介绍：{产品或服务介绍}\n\n利用生成的报告我可以更好地掌握市场趋势，了解客户需求，制定可行的商业计划，提高企业的财务和经营效率。",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list71"
    },
    {
        "id": 72,
        "name": "麦肯锡7S智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829d3f5ee8839262.png",
        "info": "麦肯锡7S模型，包括结构(Structure)、制度(Systems)、风格(Style)、员工(Staff)、技能(Skills)、战略(Strategy)、共同价值观(Shared Values)",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，帮我生成一份麦肯锡7S模型分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list72"
    },
    {
        "id": 73,
        "name": "PDCA智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829ed35bcfa81783.png",
        "info": "自动分析计划、执行、检查、行动的各项指标，并快速为您提供个性化的改进建议",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，利用PDCA循环原则，给我生成一份详细的分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list73"
    },
    {
        "id": 74,
        "name": "SMART模型智能分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/64829fd7d649e76834.png",
        "info": "从明确性(specific)、可衡量性(measurable)、可达成性(attainable)、相关性(realistic)和时限性(time-based)等五个方面对目标进行设定的方法",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，帮我生成一份详细的SMART模型分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list74"
    },
    {
        "id": 75,
        "name": "波特五力分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a198a542980508.png",
        "info": "供应商的讨价还价能力、购买者的讨价还价能力、潜在竞争者进入的能力、替代品的替代能力、行业内竞争者现在的竞争能力",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，生成一份基于波特五力模型的详细分析报告，每个点都至少400字",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list75"
    },
    {
        "id": 76,
        "name": "价值创新计划ERRC分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a6321a14f64486.png",
        "info": "使用ERRC模型，即消除、减少、提升、创造四个步骤，帮助企业在市场中实现差异化优势",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，帮我生成一份详细的价值创新计划ERRC分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list76"
    },
    {
        "id": 77,
        "name": "安索夫矩阵分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a6ff647aa22395.png",
        "info": "以产品和市场作为两大基本面向，区别出四种产品/市场组合和相对应的营销策略",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，生成一份详细的安索夫矩阵分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list77"
    },
    {
        "id": 78,
        "name": "AIDA模型分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482a7c37987f68603.png",
        "info": "A为Attention，即引起注意；I为Interest，即诱发兴趣；D为Desire，即刺激欲望；最后一个字母A为Action，即促成购买",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品或服务介绍"
            }
        ],
        "system_msg": "请根据我给的产品或服务介绍，生成一份基于AIDA模型的详细的分析报告",
        "user_msg": "产品或服务介绍：{产品或服务介绍}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list78"
    },
    {
        "id": 79,
        "name": "百度知道回答",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482ac0f773b393572.png",
        "info": "根据百度知道里的问题生成答案",
        "cat_id": 23,
        "cat_id_arr": [
            23
        ],
        "user_form": [
            {
                "label": "问题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "输入百度知道里的问题"
            }
        ],
        "system_msg": "我来输入问题，你给出回答，用于发布在百度知道里",
        "user_msg": "{问题}",
        "origin_cat_id": 23,
        "top_cat_id": 16,
        "arrKey": "list79"
    },
    {
        "id": 81,
        "name": "差评转化",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230609/6482b602c3f3579803.png",
        "info": "应对客户差评，转化差评为好评",
        "cat_id": 27,
        "cat_id_arr": [
            27,
            75
        ],
        "user_form": [
            {
                "label": "客户给的差评",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "客户给的差评评论"
            },
            {
                "label": "回复给客户的语气风格",
                "options": [
                    "正常语气",
                    "卖萌博得同情",
                    "乞求让客户放过",
                    "幽默博客户开心"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "用什么语气回复客户"
            }
        ],
        "system_msg": "你扮演电商客服，现在我碰到了客户差评，请按照我给的要求，尽量让客户删除差评，然后给个好评\n-客户差评\n-回复给客户的语气风格",
        "user_msg": "客户给的差评：{客户给的差评}\n回复给客户的语气风格：{回复给客户的语气风格}",
        "origin_cat_id": 27,
        "top_cat_id": 25,
        "arrKey": "list81"
    },
    {
        "id": 82,
        "name": "营销策略",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483ea1b3928843815.png",
        "info": "创作营销策略方案",
        "cat_id": 3,
        "cat_id_arr": [
            3,
            38,
            34
        ],
        "user_form": [
            {
                "label": "产品及服务名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "产品及服务名称"
            },
            {
                "label": "产品及服务介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "产品及服务介绍"
            },
            {
                "label": "产品及服务品类行业",
                "formType": "input",
                "isRequired": true,
                "placeholder": "产品或服务所在的品类或者行业"
            },
            {
                "label": "面向的客户群体",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "比如：年轻女性 高学历"
            },
            {
                "label": "其他信息补充",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "比如产品的优劣势、特性等"
            },
            {
                "label": "营销方案需要包含的内容",
                "options": [
                    "营销战略制订",
                    "产品全市市场推广",
                    "营销团队建设",
                    "促销政策制定",
                    "专卖体系等特殊销售模式打造",
                    "终端销售业绩提升",
                    "样板市场打造",
                    "分销体系建立",
                    "渠道建设",
                    "直营体系建设",
                    "价格体系建设",
                    "招商策划",
                    "新产品上市策划",
                    "产品规划",
                    "市场定位",
                    "营销诊断",
                    "市场环境分析",
                    "消费心理分析",
                    "产品优势分析",
                    "营销方式和平台的选择"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "营销方案需要包含的内容"
            },
            {
                "label": "选择营销策略组合",
                "options": [
                    "4P’s营销策略组合",
                    "6P’s营销策略组合",
                    "11P’s营销策略组合"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择营销策略组合"
            }
        ],
        "system_msg": "-产品及服务名称\n-产品及服务介绍\n-产品及服务品类行业\n-面向的客户群体\n-选择营销策略组合\n-营销方案需要包含的内容\n-其他信息补充\n你是一个营销咨询专家，请根据我给的以上内容要求帮我写一个营销策略，需要详细能直接执行",
        "user_msg": "-产品及服务名称：{产品及服务名称}\n-产品及服务介绍：{产品及服务介绍}\n-产品及服务品类行业：{产品及服务品类行业}\n-面向的客户群体：{面向的客户群体}\n-选择营销策略组合：{选择营销策略组合}\n-营销方案需要包含的内容：{营销方案需要包含的内容}，每块内容都需要详细介绍\n-其他信息补充：{其他信息补充}",
        "origin_cat_id": 34,
        "top_cat_id": 1,
        "arrKey": "list82"
    },
    {
        "id": 83,
        "name": "营销策划方案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/6483f53b1b14448090.png",
        "info": "创作营销活动方案",
        "cat_id": 3,
        "cat_id_arr": [
            3,
            38,
            28,
            34,
            75
        ],
        "user_form": [
            {
                "label": "产品或服务名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "产品或服务名称"
            },
            {
                "label": "产品或服务介绍",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "产品或服务介绍"
            },
            {
                "label": "面向的客户群体",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "用关键词描述面向的客户群体"
            }
        ],
        "system_msg": "请根据下面信息，创作一篇营销策划方案。\n-产品或服务名称\n-产品或服务介绍\n-面向的客户群体\n\n方案需要非常详细并包含如下内容，针对以下每一项都必须有详细的分析和执行方案：\n1.市场分析和调研：对目标市场的行业、竞争对手、消费者需求等进行分析和调研，列出竞争对手、消费者详细需求和痛点，了解市场环境和竞争状况。\n2.目标市场和受众群体：明确目标市场和受众群体，包括年龄、性别、地域、收入、兴趣等特征，对受众群体进行详细的描述分析，为后续的营销活动提供依据。\n3.营销目标和策略：根据市场分析和目标受众，确定营销目标和策略，包括产品定位、价格策略、渠道管理、品牌推广等，每一项必须详细并且可执行。\n4.营销活动计划：制定具体的营销活动计划，包括推广活动、促销活动、网站推广、社交媒体营销、短视频营销、私域流量营销、电子邮件营销等，为实现营销目标提供具体方案。\n5.营销预算和投资回报：制定营销预算和投资回报计划，根据预算确定各项活动的费用和投入，同时评估营销活动的投资回报率和效果。\n6.营销执行和监控：根据营销计划，实施具体的营销活动，同时建立监控和反馈机制，及时调整和优化营销策略和活动。\n以上是营销策划方案的一些基本内容，其中各项内容需要根据实际情况进行具体规划和实施。",
        "user_msg": "-产品或服务名称：{产品或服务名称}\n-产品或服务介绍：{产品或服务介绍}\n-面向的客户群体：{面向的客户群体}\n\n",
        "origin_cat_id": 3,
        "top_cat_id": 1,
        "arrKey": "list83"
    },
    {
        "id": 84,
        "name": "竞品分析",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840305bb23f19290.png",
        "info": "生成竞品分析报告",
        "cat_id": 33,
        "cat_id_arr": [
            33
        ],
        "user_form": [
            {
                "label": "产品或服务名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "产品或服务名称"
            },
            {
                "label": "补充信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "比如竞品名称、产品优势、产品特性"
            }
        ],
        "system_msg": "请根据我给出的以下信息：\n-产品或服务名称\n-产品补充信息\n\n生成一份详细的竞品分析报告，要求报告中必须包含并符合以下内容：\n1、体验环境。\n2、市场状况（市场容量、竞争格局、市场占有率分布）。\n3、行业分析（历史变化、发展趋势）。\n4、需求分析（扮演用户、选择研究对象）。\n5、确定竞品（产品咨询收集、选择服务/目标用户相同或类似的产品）。\n6、竞品对比（多种分析方法）、针对每个竞品需要列出价格和swot。\n7、商业模式异同。\n8、业务/产品模式异同。\n9、运营及推广策略。\n10、归纳结论。\n11、用户体验五要素法。\n12、Base：目标用户是什么？目标用户的核心需求是什么？通过什么解决方案能够满足？同其他产品相比，解决方案有什么差异化和卖点？如何推广营销？市场效果如何？\n13、Solution：解决方案如何实现？还有多少空间？陌生用户进来如何使用？信息组织、交互如何？为什么要这么做？是否符合用户预期？配色、UI是否符合用户审美？用户会在那里困惑？用户打开产品的频率如何？用户是否会向他人推荐产品？\n14、四象限分析法：\n多在问题点罗列或安排下步计划时使用，可将自身产品的要素、特点、功能点、问题点根据实际情况和对比分析划归为四个象限区域，如急需改进区、竞品优势区、次要改进区、继续保持区。",
        "user_msg": "-产品或服务名称：{产品或服务名称}\n-产品补充信息：{补充信息}",
        "origin_cat_id": 33,
        "top_cat_id": 32,
        "arrKey": "list84"
    },
    {
        "id": 85,
        "name": "写新闻稿",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64840b08d7c8c22845.png",
        "info": "创作新闻 产品发布  功能上新等类型新闻稿",
        "cat_id": 36,
        "cat_id_arr": [
            36
        ],
        "user_form": [
            {
                "label": "内容要点",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "比如：相关关键词、简单概要"
            },
            {
                "label": "内容框架要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "生成的结果要包括哪些框架内容"
            },
            {
                "label": "内容要求补充信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "有哪些额外要求"
            },
            {
                "label": "新闻稿类型",
                "options": [
                    "新品发布",
                    "产品更新",
                    "营销活动",
                    "领导活动",
                    "开工开业",
                    "讲座会议等",
                    "其他类型"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择内容类型"
            },
            {
                "label": "新闻稿风格要求",
                "options": [
                    "正常",
                    "严肃",
                    "庄重",
                    "活泼",
                    "轻松",
                    "幽默",
                    "愤怒",
                    "讽刺",
                    "号召"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择新闻稿用什么风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "500字以下",
                    "500-1000字",
                    "1000-1500字",
                    "1500-2000字",
                    "2000字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "字数要求"
            }
        ],
        "system_msg": "请根据我给的以下信息和要求：\n-内容要点\n-内容框架要求\n-内容要求补充信息\n-新闻稿类型\n-新闻稿风格要求\n-字数要求\n\n生成一篇新闻稿和标题",
        "user_msg": "-内容要点：{内容要点}\n-内容框架要求：{内容框架要求}\n-内容要求补充信息：{内容要求补充信息}\n-新闻稿类型：{新闻稿类型}\n-新闻稿风格要求：{新闻稿风格要求}\n-字数要求：{字数要求}",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list85"
    },
    {
        "id": 88,
        "name": "写发言稿",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648415b0967a249471.png",
        "info": "自动生成发言稿 演讲稿",
        "cat_id": 36,
        "cat_id_arr": [
            36
        ],
        "user_form": [
            {
                "label": "发言稿内容概要",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请填写发言稿内容概要"
            },
            {
                "label": "发言稿使用场景",
                "options": [
                    "开幕词：指比较隆重的大型会议开始时所用的讲话稿",
                    "闭幕词：指较为大型的会议结束时，领导同志所做的要求性的讲话",
                    "会议报告：召开大中型会议时，有关领导代表一定的机关进行中心发言时所使用的文稿",
                    "动员发言：在部署重要工作或活动的会议上，有关领导所使用的用于鼓励人们积极开展此项工作或参加此项活动的文稿",
                    "总结性发言：指某一事项，或某一活动结束后、有关领导对其进行回顾、概括时所使用的文稿。",
                    "指示性发言：指有关领导对特定的机关和人员布置的工作、任务、指出希望和要求并规定某些指导原则时使用的文稿。",
                    "纪念性发言：指有关领导在追忆某一特殊的日子、事件或人物时，所使用的文稿。"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择发言稿用在什么场景"
            },
            {
                "label": "字数要求",
                "options": [
                    "500字以内",
                    "500-1000字",
                    "1000-1500字",
                    "1500-2000字",
                    "2000字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "字数要求"
            },
            {
                "label": "演讲稿语气风格",
                "options": [
                    "正常",
                    "严肃",
                    "庄重",
                    "崇敬",
                    "幽默",
                    "轻松",
                    "喜悦",
                    "急迫",
                    "激情"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择演讲稿语气风格"
            }
        ],
        "system_msg": "按照以下要求生成一篇演讲稿，信息及要求如下：\n-发言稿内容概要\n-发言稿使用场景\n-字数要求\n-演讲稿语气风格",
        "user_msg": "-发言稿内容概要：{发言稿内容概要}\n-发言稿使用场景：{发言稿使用场景}\n-字数要求：{字数要求}\n-演讲稿语气风格：{演讲稿语气风格}",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list88"
    },
    {
        "id": 89,
        "name": "总结报告",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/648454170d21043418.png",
        "info": "自动创作总结报告及汇报",
        "cat_id": 37,
        "cat_id_arr": [
            37
        ],
        "user_form": [
            {
                "label": "总结报告相关内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写与总结汇报相关的一些内容概要"
            },
            {
                "label": "总结报告的类型",
                "options": [
                    "思想总结报告",
                    "经济总结报告",
                    "地区总结报告",
                    "部门总结报告",
                    "单位总结报告",
                    "个人总结报告",
                    "月份总结报告",
                    "季度总结报告",
                    "年度总结报告",
                    "三年以上总结报告",
                    "综合性总结",
                    "专题性总结"
                ],
                "formType": "multiSelect",
                "isRequired": true,
                "placeholder": "总结报告的类型"
            },
            {
                "label": "字数要求",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入字数要求"
            }
        ],
        "system_msg": "请按照以下要求和信息生成一份总结报告。\n-总结报告相关内容\n-总结报告的类型\n-字数要求",
        "user_msg": "-总结报告相关内容：{总结报告相关内容}\n-总结报告的类型：{总结报告的类型}\n-字数要求：{字数要求}",
        "origin_cat_id": 37,
        "top_cat_id": 35,
        "arrKey": "list89"
    },
    {
        "id": 90,
        "name": "工作计划",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230610/64845694af7ee87003.png",
        "info": "自动创作各种工作计划",
        "cat_id": 37,
        "cat_id_arr": [
            37
        ],
        "user_form": [
            {
                "label": "工作计划大概内容",
                "formType": "textarea",
                "placeholder": "填写大概有哪些工作计划内容"
            },
            {
                "label": "多长时间的工作计划",
                "options": [
                    "周工作计划",
                    "月工作计划",
                    "季度工作计划",
                    "年度工作计划"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "选择工作计划时间长度"
            },
            {
                "label": "工作计划书写要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "提出你的具体要求"
            },
            {
                "label": "字数要求",
                "formType": "input",
                "isRequired": true,
                "placeholder": "需要生成多少字的工作计划"
            }
        ],
        "system_msg": "一个好的工作计划应该包括以下内容。\n1、工作内容：做什么（WHAT）。工作目标、任务。计划应规定出在一定时间内所完成的目标、任务和应达到要求。任务和要求应该具体明确，有的还要定出数量、质量和时间要求。\n2、工作方法：怎么做（HOW）。采取措施、策略。要明确何时实现目标和完成任务，就必须制定出相应的措施和办法，这是实现计划的保证。措施和方法主要指达到既定目标需要采取什么手段，动员哪些力量与资源，创造什么条件，排除哪些困难等。总之，要根据客观条件，统筹安排，将“怎么做”写得明确具体，切实可行。特别是针对工作总结中存在问题的分析，拟定解决问题的方法。\n3、工作分工：谁来做（WHO）。工作负责。这是指执行计划的工作程序和时间安排。每项任务，在完成过程中都有阶段性，而每个阶段又有许多环节，它们之间常常是互相交错的。因此，订计划必须胸有全局，妥善安排，哪些先干，哪些后干，应合理安排。而在实施当中，又有轻重缓急之分，哪是重点，哪是一般，也应该明确。在时间安排上，要有总的时限，又要有每个阶段的时间要求，以及人力、物力的安排。这样，使有关单位和人员知道在一定的时间内，一定的条件下，把工作做到什么程度，以便争取主动，有条不紊地协调进行。\n4、工作进度：什么时间做（WHEN）。完成期限。\n\n请按照以下信息帮我生成一份好的工作计划，信息及要求如下：\n-工作计划大概内容\n-多长时间的工作计划\n-工作计划书写要求\n-字数要求",
        "user_msg": "-工作计划大概内容：{工作计划大概内容}\n-多长时间的工作计划：{多长时间的工作计划}\n-工作计划书写要求：{工作计划书写要求}\n-字数要求：{字数要求}",
        "origin_cat_id": 37,
        "top_cat_id": 35,
        "arrKey": "list90"
    },
    {
        "id": 91,
        "name": "会议活动执行方案",
        "icon": "https://cos.kuaijingai.com/upload/work_ai/20230611/6485396589b5120637.png",
        "info": "会议型活动执行方案",
        "cat_id": 38,
        "cat_id_arr": [
            38
        ],
        "user_form": [
            {
                "label": "会议活动介绍",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写会议活动相关的信息"
            },
            {
                "label": "会议活动类型",
                "formType": "input",
                "isRequired": true,
                "placeholder": "比如：发布会、招商会、营销会等等"
            },
            {
                "label": "活动安排的城市",
                "formType": "input",
                "isRequired": true,
                "placeholder": "填写在哪里举办活动"
            }
        ],
        "system_msg": "请根据我给的会议活动介绍、会议活动类型和活动安排的城市，写一篇详细的会议活动执行方案，每一点都应该非常详细，拿过来就可以执行，内容应该包括：\n会场选址（预算、交通、硬件设施、服务水平、品牌匹配度等思考）、来宾接待（嘉宾邀请、发言准备、参与流程的沟通、起居接送、参 会提醒、座席安排、礼品准备的提前考虑）、氛围营造（沿途外围效果、舞美效果、前厅展示效果、签到效果、 来宾互动装置、留影区效果、服务人员安排等方案）、后勤保障（消防预案、医疗预案、安保预案、突发事件预案、天气预 案、交通预案、就餐预案、流程特殊情况预案等风险管理）、传播推广（事前预热、事中采访互动、视频及图文直播、事后曝光 长尾传播等的媒体安排、流量采购等方案）",
        "user_msg": "-会议活动介绍：{会议活动介绍}\n-会议活动类型：{会议活动类型}\n-活动安排的城市：{活动安排的城市}",
        "origin_cat_id": 38,
        "top_cat_id": 35,
        "arrKey": "list91"
    },
    {
        "id": 92,
        "name": "按摩椅",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230722/64bb53a5237f195615.png",
        "info": "可为不同品牌的【按摩椅】生成针对【特定人群】的卖点文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择输出语言",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称，示例：奥克斯"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容，示例：家用按摩椅 全身零重力全自动 多功能电动按摩椅沙发椅子 3D智能语音"
            },
            {
                "label": "目标人群",
                "options": [
                    "办公室职员",
                    "运动员",
                    "中老年人",
                    "外贸销售人员",
                    "家庭使用者"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您的目标人群",
                "defaultValue": "中老年人"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "patternIds": [],
                "newKeywords": "",
                "placeholder": "请输入产品的关键词",
                "defaultValue": [
                    "舒适性",
                    "健康",
                    "多功能性",
                    "便捷性"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "超短文案（60字内）"
            }
        ],
        "system_msg": "现在你是一个会写按摩椅商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，标题和内容中间请用符号-------进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名：{品牌}\n产品描述：{产品描述}\n目标人群：{目标人群}\n核心卖点：{核心卖点}\n字数要求：{字数要求}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list92"
    },
    {
        "id": 93,
        "name": "电视",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf4934f3d33938.png",
        "info": "可为不同品牌的家用电器生成针对特定人群的卖点文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "产品类型",
                "options": [
                    "液晶电视",
                    "激光电视",
                    "游戏电视",
                    "会议电视",
                    "艺术电视"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "平板电视"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "patternIds": [],
                "placeholder": "请输入产品的描述内容，如60寸超薄高清全面屏，活动只要3888元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "patternIds": [],
                "newKeywords": "",
                "defaultValue": [
                    "4K高清",
                    "超薄",
                    "特价"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "现在你是一个会写商品文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n产品类型：{产品类型}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list93"
    },
    {
        "id": 94,
        "name": "空调",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf754157812817.png",
        "info": "可为不同品牌的空调生成针对特定人群的卖点文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容，如一级能效1.5匹变频空调，目前活动价1288元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入产品的核心卖点",
                "defaultValue": [
                    "变频",
                    "冷暖两用",
                    "自动除菌",
                    "新一级能效",
                    "超省电",
                    "1.5匹"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择文案字数",
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的文案风格",
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list94"
    },
    {
        "id": 95,
        "name": "洗衣机",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf58ba80e33914.png",
        "info": "可为不同品牌的洗衣机生成卖点文案，为对应品牌的洗衣机做产品介绍",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择输出语言",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容，如10公斤全自动滚筒洗衣机洗烘一体机 热风除菌 智能烘干，目前活动价2588元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "除菌",
                    "全自动",
                    "烘干"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择文案字数",
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择文案风格",
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求。整篇文章请输出为{输出语言}",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list95"
    },
    {
        "id": 96,
        "name": "冰箱",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c0bc939fa9671513.png",
        "info": "可为不同品牌的冰箱生成卖点文案，为对应品牌的冰箱做产品介绍",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容，如双开门变频冰箱，一级能效，净味除菌，目前活动价2588元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "除菌",
                    "液晶显示屏",
                    "双开门"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请用{输出语言}创作，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list96"
    },
    {
        "id": 98,
        "name": "笔记本电脑",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c08a0170a5939782.png",
        "info": "生成各种品牌型号笔记本的测评、行情等营销文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容，如联想笔记本电脑小新pro16轻薄本 酷睿i5，目前活动价4588元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "defaultValue": [
                    "便携性",
                    "高配置",
                    "低能耗",
                    "超薄本",
                    "高性能"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list98"
    },
    {
        "id": 99,
        "name": "厨房小家电",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230726/64c08ceb74ff038011.png",
        "info": "厨房小家电包含电饭煲、微波炉、电磁炉、空气炸锅、咖啡机、榨汁机、破壁机、热水壶等，可为不同品牌的产品生成卖点文案和产品介绍",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "产品类型",
                "options": [
                    "电饭煲",
                    "微波炉",
                    "电磁炉",
                    "空气炸锅",
                    "咖啡机",
                    "榨汁机",
                    "破壁机",
                    "热水壶"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "电饭煲"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "家用",
                    "触控式",
                    "新品"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n产品类型：{产品类型}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list99"
    },
    {
        "id": 100,
        "name": "厨卫大家电",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9cf91b3f8612817.png",
        "info": "厨卫大家电包含油烟机、集成灶、消毒柜、洗碗机、电热水器等，可为不同品牌的产品生成卖点文案和产品介绍",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "产品类型",
                "options": [
                    "油烟机",
                    "集成灶",
                    "消毒柜",
                    "洗碗机",
                    "电热水器"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "油烟机"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入产品的描述内容"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "智能",
                    "一级能效"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n产品类型：{产品类型}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list100"
    },
    {
        "id": 101,
        "name": "耳机",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e3ed47cfc71513.png",
        "info": "生成多种品牌耳机的营销文案，文案内容可多细节展开描述",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：2023年全新款的降噪耳机，活动价108元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "入耳式",
                    "立体环绕",
                    "高效降噪"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list101"
    },
    {
        "id": 102,
        "name": "平板电脑",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32e69c2f8b31384.png",
        "info": "生成各种品牌型号平板电脑的测评、行情等营销文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，如Apple iPad Pro 11英寸平板电脑 蓝色，目前活动价6888元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "十核安卓",
                    "护眼",
                    "娱乐",
                    "超薄",
                    "二合一",
                    "超清4K"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list102"
    },
    {
        "id": 103,
        "name": "手机",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e66e0177b54520.png",
        "info": "生成各种品牌型号手机的测评、行情等营销文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，如iPhone 14 Pro Max 256GB 暗紫色 支持移动联通电信5G 官方正品，目前活动价9088元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "defaultValue": [
                    "双卡双待",
                    "超清护眼",
                    "超长待机",
                    "闪电快充",
                    "1亿像素",
                    "轻薄设计"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list103"
    },
    {
        "id": 104,
        "name": "投影仪",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e77c6eaa473566.png",
        "info": "生成多品牌投影仪的营销文案，可从清晰度、对比度等技术维度进行描述",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "标题\t输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：家用投影仪性价比之王，活动价2998元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "智能对焦",
                    "内置音响",
                    "环绕声场"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list104"
    },
    {
        "id": 105,
        "name": "相机",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e8534677270763.png",
        "info": "生成各种品牌型号数码相机的测评、行情等营销文案",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：新一代vlog相机，4K视频，超广角，大光圈，活动价3888元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "续航高手",
                    "小巧便携",
                    "口袋相机",
                    "4k画质",
                    "全画幅",
                    "数码相机"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list105"
    },
    {
        "id": 106,
        "name": "音响",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1e9463309424366.png",
        "info": "生成专业的音响营销文案，围绕音响的多个参数维度展开详细的描述",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：bose 550优质重低音，殿堂级的听觉享受，活动价108元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "重低音",
                    "声音透明",
                    "清晰"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list106"
    },
    {
        "id": 107,
        "name": "智能手表",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c1ea5c3704954520.png",
        "info": "可生成各品牌智能手表的营销文案，文案内容可从设计、性能等维度展开阐述",
        "cat_id": 42,
        "cat_id_arr": [
            42,
            77
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "型号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入商品型号"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：国产品牌的旗舰天花板，活动价999元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "持久续航",
                    "智能唤醒",
                    "多端联动"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案风格",
                "options": [
                    "促销活动",
                    "营销软文",
                    "专业介绍",
                    "评测风格"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "促销活动"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n型号：{型号}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案风格：{文案风格}",
        "origin_cat_id": 42,
        "top_cat_id": 40,
        "arrKey": "list107"
    },
    {
        "id": 108,
        "name": "唇膏",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c22e2a7198f73566.png",
        "info": "针对唇膏类产品，生成各种当红博主风格种草、测评文案，包括唇彩、唇膏、唇釉等",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品类型",
                "options": [
                    "唇膏",
                    "唇彩",
                    "唇釉",
                    "唇蜜",
                    "润唇膏",
                    "唇膜"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "唇膏"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "高级",
                    "磨砂质感",
                    "复古"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品类型：{产品类型}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list108"
    },
    {
        "id": 109,
        "name": "底妆",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c23be70ba7f87342.png",
        "info": "生成各品牌的粉饼、粉底、遮瑕等产品文案",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品类型",
                "options": [
                    "粉底",
                    "隔离霜",
                    "粉饼",
                    "素颜霜",
                    "干粉",
                    "定妆喷雾",
                    "底妆"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "粉底"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：妈生伪素颜，清透干净底妆，活动价188"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性",
                    "熬夜党"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "少女感",
                    "原装进口",
                    "丝滑遮瑕",
                    "控油",
                    "持久保湿",
                    "内透亮",
                    "不假白"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品类型：{产品类型}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list109"
    },
    {
        "id": 110,
        "name": "护发产品",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230727/64c23daa08e5270763.png",
        "info": "可生成各品牌的护发类产品，包括洗发水、发膜、护发素等",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            75,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品类型",
                "options": [
                    "洗发水",
                    "护发素",
                    "发膜",
                    "焗油膏",
                    "头皮护理"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "洗发水"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：无硅油洗发水，发丝根根分明，养护头皮，活动价28"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性",
                    "熬夜党",
                    "脱发人群"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "无硅油",
                    "天然成分",
                    "防脱发",
                    "无香型",
                    "养护头皮"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品类型：{产品类型}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list110"
    },
    {
        "id": 111,
        "name": "护肤精华",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32876e36d976502.png",
        "info": "生成与护肤精华类产品相关的测评、种草、促销类文案，适用于小红书、唯品会、淘宝等种草平台",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：小棕瓶2代，最新修复抗衰老成分，亲测效果显著"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性",
                    "熬夜党",
                    "脱发人群"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "抗衰",
                    "面部美白",
                    "提亮肤色"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list111"
    },
    {
        "id": 112,
        "name": "化妆水",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c329b83e6e576502.png",
        "info": "生成多类型化妆水的种草文案、测评文案等",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，如加班神器，天然成分爽肤水，有效去除暗黄"
            },
            {
                "label": "目标群体",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "女性"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "加班熬夜",
                    "去暗黄",
                    "美白",
                    "提亮"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list112"
    },
    {
        "id": 113,
        "name": "口红",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32b274100055097.png",
        "info": "可为不同场景下的口红生成卖点文案",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            75,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "色号",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入口红色号"
            },
            {
                "label": "口红类型",
                "options": [
                    "日常口红",
                    "持久口红",
                    "哑光口红",
                    "渐变口红",
                    "礼仪口红"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "日常口红"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：YSL圣罗兰小金条口红1966 2.2g 哑光礼盒，活动价288"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "自然",
                    "持久",
                    "保湿",
                    "百搭"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n色号：{色号}\n口红类型：{口红类型}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list113"
    },
    {
        "id": 114,
        "name": "面膜",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32c545513755097.png",
        "info": "围绕面膜产品，生成有真实体验感的测评种草文案，适用于小红书、唯品会、淘宝等种草平台",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：夏季晒后修复面霜，深层补水滋养，水嫩白皙"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性",
                    "熬夜党",
                    "脱发人群"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "美白",
                    "补水",
                    "夏季保养",
                    "晒后养护"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list114"
    },
    {
        "id": 115,
        "name": "面霜",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32d7b1b8b317114.png",
        "info": "生成与面霜类产品相关的测评、种草、促销类文案，适用于小红书、唯品会、淘宝等种草平台",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            75,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：欧莱雅补水面霜滋润不油腻，干皮救星"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性",
                    "熬夜党",
                    "脱发人群"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "修复",
                    "保养",
                    "保湿",
                    "水润"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内",
                    "文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活拨",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list115"
    },
    {
        "id": 116,
        "name": "香水",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c32f455fc3412642.png",
        "info": "可为不同场景下的香水，生成卖点文案",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "香水类型",
                "options": [
                    "中性香水",
                    "车载香水",
                    "男士香水",
                    "女士香水",
                    "儿童香水"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "中性香水"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，示例：祖玛珑蓝风铃七夕礼盒30ml 生日礼物送女友送男友，活动价588"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "香味持久",
                    "礼盒包装",
                    "清新香氛"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n香水类型：{香水类型}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list116"
    },
    {
        "id": 117,
        "name": "眼霜",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c332674c5be17114.png",
        "info": "专业眼霜营销文案生成模型，适用于小红书、知乎等平台的种草、测评文案",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容，抗皱祛黑眼圈功效甚好"
            },
            {
                "label": "目标群体",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "女性"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "抗初老",
                    "祛皱",
                    "眼圈"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list117"
    },
    {
        "id": 118,
        "name": "眼妆",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c3335d6e34954805.png",
        "info": "针对眼妆的需求，可生成眼影、眼线笔等眼妆产品的种草测品文案",
        "cat_id": 46,
        "cat_id_arr": [
            46,
            79
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入品牌名称"
            },
            {
                "label": "产品类型",
                "options": [
                    "眼影",
                    "眼妆",
                    "眼线笔",
                    "眉笔",
                    "卧蚕笔"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "眼影"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入产品的描述内容"
            },
            {
                "label": "目标群体",
                "options": [
                    "通用",
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "通用"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "温柔",
                    "少女感",
                    "提亮",
                    "防水",
                    "不飞粉"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "测评",
                    "清单",
                    "教程",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n品牌名称：{品牌名称}\n产品类型：{产品类型}\n产品描述：{产品描述}\n目标群体：{目标群体}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 46,
        "top_cat_id": 40,
        "arrKey": "list118"
    },
    {
        "id": 119,
        "name": "包包",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c334b64aa4a73356.png",
        "info": "可根据需求生成多种风格男包女包文案",
        "cat_id": 47,
        "cat_id_arr": [
            47,
            75
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "目标人群",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "女性"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如真牛皮男包商务风，双层拉链，限时活动199元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "大容量",
                    "牛皮"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n目标人群：{目标人群}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 47,
        "top_cat_id": 40,
        "arrKey": "list119"
    },
    {
        "id": 120,
        "name": "服装",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c33598591ca53010.png",
        "info": "可根据需求生成不同年龄多种风格的服装卖点文案",
        "cat_id": 47,
        "cat_id_arr": [
            47,
            75
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "目标人群",
                "options": [
                    "女装",
                    "男装",
                    "男童装",
                    "女童装",
                    "婴儿"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "女装"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请详细输入产品的描述内容，建议包含适合年龄、风格、面料等属性，如20-25岁甜美风格女装夏季连衣裙，波西米亚风，修身显瘦，桑蚕丝面料，限时活动99元",
                "defaultValue": ""
            },
            {
                "label": "适合季节",
                "options": [
                    "春季",
                    "夏季",
                    "秋季",
                    "冬季"
                ],
                "formType": "select",
                "isRequired": false,
                "placeholder": "",
                "defaultValue": "春季"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "吸汗",
                    "时尚",
                    "宽松",
                    "个性印花"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺",
                    "质疑",
                    "真诚",
                    "鼓励",
                    "亲切"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n目标人群：{目标人群}\n产品描述：{产品描述}\n适合季节：{适合季节}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 47,
        "top_cat_id": 40,
        "arrKey": "list120"
    },
    {
        "id": 121,
        "name": "鞋子",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c337127eb0d31384.png",
        "info": "可根据需求生成多种风格的鞋子卖点文案",
        "cat_id": 47,
        "cat_id_arr": [
            47,
            75
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "目标人群",
                "options": [
                    "女鞋",
                    "男鞋"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "女鞋"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如牛皮女鞋商务风格，限时活动99元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "防滑",
                    "透气",
                    "时尚"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n目标人群：{目标人群}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 47,
        "top_cat_id": 40,
        "arrKey": "list121"
    },
    {
        "id": 122,
        "name": "珠宝首饰",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230728/64c337e28bc8814025.png",
        "info": "可根据需求生成珠宝首饰文案，包括项链、戒指、耳环等",
        "cat_id": 47,
        "cat_id_arr": [
            47,
            75
        ],
        "user_form": [
            {
                "label": "输出语言",
                "options": [
                    "简体中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "简体中文"
            },
            {
                "label": "适合人群",
                "options": [
                    "女性",
                    "男性",
                    "通用"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "女性"
            },
            {
                "label": "产品描述",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请详细输入产品的描述内容，建议包含风格、面料等属性，如淡水珍珠项链S925银，时尚高贵气质，限时活动999元"
            },
            {
                "label": "核心卖点",
                "formType": "keywords",
                "isRequired": false,
                "newKeywords": "",
                "placeholder": "请输入核心卖点",
                "defaultValue": [
                    "S925",
                    "黄金",
                    "珍珠吊坠",
                    "镶钻"
                ],
                "newKeywordsAdding": false
            },
            {
                "label": "字数要求",
                "options": [
                    "超短文案（60字内）",
                    "短文案（60-150字内）",
                    "中文案（150-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "超短文案（60字内）"
            },
            {
                "label": "文案类型",
                "options": [
                    "种草",
                    "促销"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "种草"
            },
            {
                "label": "行文风格",
                "options": [
                    "严谨",
                    "接地气",
                    "活泼",
                    "文艺"
                ],
                "formType": "select",
                "isRequired": false,
                "defaultValue": "严谨"
            }
        ],
        "system_msg": "现在你是一个会写商品营销文案的高手，根据提供给你的指定信息，要求写一篇给定风格的商品文案，请将文章标题显示在第一行，并在标题前加上文字【标题】，内容前加上文字【内容】，标题和内容进行区分，文章内容长度请符合给定的字数要求",
        "user_msg": "输出语言：{输出语言}\n目标人群：{目标人群}\n产品描述：{产品描述}\n核心卖点：{核心卖点}\n字数要求：{字数要求}\n文案类型：{文案类型}\n行文风格：{行文风格}\n",
        "origin_cat_id": 47,
        "top_cat_id": 40,
        "arrKey": "list122"
    },
    {
        "id": 144,
        "name": "专业知识",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b7ed308822883.png",
        "info": "根据你的需求，帮你生成专业知识类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇专业知识类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list144"
    },
    {
        "id": 146,
        "name": "活动宣传",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b6f29e5971179.png",
        "info": "根据你的需求，帮你生成活动宣传类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇活动宣传类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list146"
    },
    {
        "id": 147,
        "name": "健康养生",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98ac24e21322883.png",
        "info": "根据你的需求，帮你生成健康养生类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇健康养生类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list147"
    },
    {
        "id": 148,
        "name": "旅行游玩",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98b9f9b2e971179.png",
        "info": "根据你的需求，帮你生成旅行游玩类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇旅行游玩类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list148"
    },
    {
        "id": 149,
        "name": "时尚穿搭",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98c569213e99424.png",
        "info": "根据你的需求，帮你生成时尚穿搭类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇时尚穿搭类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list149"
    },
    {
        "id": 150,
        "name": "幽默搞笑",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98e4d2e9c490535.png",
        "info": "根据你的需求，帮你生成幽默搞笑类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇幽默搞笑类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题。所创作的内容请进行美化排版，可以适当加上一些合理的emoji",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list150"
    },
    {
        "id": 151,
        "name": "情感文案",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98f1d3df5099424.png",
        "info": "根据你的需求，帮你生成情感文案类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇情感文案类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list151"
    },
    {
        "id": 152,
        "name": "教育文章",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d98feac556790535.png",
        "info": "根据你的需求，帮你生成教育文章类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            74,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇教育文章类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list152"
    },
    {
        "id": 153,
        "name": "美食菜谱",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d991c20c79f81083.png",
        "info": "根据你的需求，帮你生成美食菜谱类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇美食菜谱类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list153"
    },
    {
        "id": 154,
        "name": "党政公文",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d995bb7ffb881083.png",
        "info": "根据你的需求，帮你生成党政公文类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "input",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇党政公文类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list154"
    },
    {
        "id": 155,
        "name": "星座运势",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d9964d7152984474.png",
        "info": "根据你的需求，帮你生成星座运势类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇星座运势类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题。所创作的内容请进行美化排版，可以适当加上一些合理的emoji",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list155"
    },
    {
        "id": 156,
        "name": "母婴育儿",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230814/64d99711ccaf233938.png",
        "info": "根据你的需求，帮你生成母婴育儿类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67,
            81
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要的写作风格",
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇母婴育儿类的微信公众号文章。请根据给定的内容主题、内容要求、内容关键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list156"
    },
    {
        "id": 160,
        "name": "租房合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7609b6cf25268.png",
        "info": "一键生成租房合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的房屋租赁合同范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list160"
    },
    {
        "id": 161,
        "name": "劳动合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7665597282234.png",
        "info": "一键生成符合中国法律的劳动合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的劳动合同范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list161"
    },
    {
        "id": 162,
        "name": "承包合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba76be5bbf75231.png",
        "info": "一键生成符合中国法律的承包合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list162"
    },
    {
        "id": 163,
        "name": "代理合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba772071ba62433.png",
        "info": "一键生成符合中国法律的代理合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list163"
    },
    {
        "id": 164,
        "name": "采购合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7784611230868.png",
        "info": "一键生成采购合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list164"
    },
    {
        "id": 165,
        "name": "服务合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba77d3631057294.png",
        "info": "一键生成服务合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list165"
    },
    {
        "id": 166,
        "name": "股权合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba782d4c2e94020.png",
        "info": "一键生成股权合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list166"
    },
    {
        "id": 167,
        "name": "出售合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba787b177c54270.png",
        "info": "一键生成出售合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list167"
    },
    {
        "id": 168,
        "name": "工程合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba78f6f77567920.png",
        "info": "一键生成工程合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list168"
    },
    {
        "id": 169,
        "name": "房屋出售合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7946a26a56912.png",
        "info": "一键生成房屋出售合同",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{所属二级分类}范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list169"
    },
    {
        "id": 170,
        "name": "二手车合同",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650ba7b27c39e57218.png",
        "info": "适合二手车出售或出租",
        "cat_id": 83,
        "cat_id_arr": [
            83
        ],
        "user_form": [
            {
                "label": "合同类型",
                "options": [
                    "二手车出售",
                    "二手车出租"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "二手车出售"
            },
            {
                "label": "重点说明",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请描述你对合同的其他要求，若无则不填写"
            }
        ],
        "system_msg": "你是一个写合同的高手，请根据我提供的信息，帮我写一个符合中国法律的{合同类型}合同范本。合同内如果需要填写的地方，请用XX替代。",
        "user_msg": "合同类型：{合同类型}\n重点说明：{重点说明}",
        "origin_cat_id": 83,
        "top_cat_id": 82,
        "arrKey": "list170"
    },
    {
        "id": 172,
        "name": "科技文章",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650baa04b39b797288.png",
        "info": "根据你的需求，帮你生成科技类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": false
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇科技\n类的微信公众号文章。请根据给定的内容主题、内容要求、内容关\n键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文\n章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n内容关键词：{内容关键词}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list172"
    },
    {
        "id": 173,
        "name": "汽车讲解",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650baa2e6749699813.png",
        "info": "根据你的需求，帮你生成汽车类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "汽车品牌和型号",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入汽车品牌和型号"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇汽车介绍类的微信公众号文章。请根据给定的汽车品牌和型号、内容要求、写作风格、字数要求，帮我写一篇给定风格的微信公众号文章，并生成对应标题",
        "user_msg": "汽车品牌和型号：{汽车品牌和型号}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list173"
    },
    {
        "id": 174,
        "name": "情感笔记",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bac1db119034310.png",
        "info": "生成情感类小红书笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "情感笔记类型",
                "options": [
                    "幸福",
                    "悲伤",
                    "喜悦",
                    "厌恶",
                    "爱情",
                    "正能量",
                    "恐惧",
                    "愤怒"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你想要的情感类型"
            },
            {
                "label": "内容主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的内容主题或要求"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇{情感笔记类型}类型的笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "情感笔记类型：{情感笔记类型}\n内容主题：{内容主题}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list174"
    },
    {
        "id": 175,
        "name": "养生健康",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650badabc744930473.png",
        "info": "帮你生成养身类爆款笔记内容",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您要写的内容主题"
            },
            {
                "label": "针对人群性别",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择性别"
            },
            {
                "label": "针对人群年龄",
                "options": [
                    "少儿",
                    "青少年",
                    "青年",
                    "中年",
                    "老年"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "写作风格",
                "options": [
                    "严肃",
                    "真诚",
                    "讽刺",
                    "激动",
                    "质疑",
                    "愉快"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择您想要的写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择字数要求",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇养生健康类笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "内容主题：{内容主题}\n针对人群性别：{针对人群性别}\n针对人群年龄：{针对人群年龄}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list175"
    },
    {
        "id": 176,
        "name": "生活记录",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb237294d980316.png",
        "info": "帮你生成各类生活记录类型的笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "生活类型",
                "options": [
                    "品质生活",
                    "校园生活",
                    "接地气生活",
                    "中外生活",
                    "颓废生活",
                    "日常生活",
                    "工作生活"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入内容主题"
            },
            {
                "label": "写作风格",
                "options": [
                    "愉快",
                    "真诚",
                    "严肃",
                    "讽刺",
                    "激动",
                    "质疑",
                    "鼓励",
                    "热情",
                    "傲慢"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-400字）",
                    "长文案（400-800字）"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇生活类型的笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和生成对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "生活类型：{生活类型}\n内容主题：{内容主题}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list176"
    },
    {
        "id": 177,
        "name": "母婴育儿",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb431b6a0f28153.png",
        "info": "生成育儿、早教、孕产类笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "笔记类型",
                "options": [
                    "育儿经验",
                    "妈妈经验",
                    "早教知识",
                    "孕产经验"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": ""
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入内容主题"
            },
            {
                "label": "写作风格",
                "options": [
                    "愉快",
                    "真诚",
                    "严肃",
                    "激动",
                    "质疑",
                    "热情",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇{笔记类型}类型的笔记，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和生成对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "笔记类型：{笔记类型}\n内容主题：{内容主题}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list177"
    },
    {
        "id": 178,
        "name": "教育笔记",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650bb618eaa6634831.png",
        "info": "生成教育类型笔记内容",
        "cat_id": 65,
        "cat_id_arr": [
            65,
            74
        ],
        "user_form": [
            {
                "label": "笔记类型",
                "options": [
                    "家庭教育",
                    "K12教育",
                    "幼儿教育",
                    "大学教育",
                    "留学教育",
                    "语言教育"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择想要创作的类型"
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入内容主题"
            },
            {
                "label": "写作风格",
                "options": [
                    "愉快",
                    "真诚",
                    "严肃",
                    "激动",
                    "鼓励",
                    "讽刺"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-400字）",
                    "长文案（400-800字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇教育笔记内容，笔记类型是{笔记类型}，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和生成对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "笔记类型：{笔记类型}\n内容主题：{内容主题}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list178"
    },
    {
        "id": 179,
        "name": "小红书账号简介",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230921/650c10f5f049a91570.png",
        "info": "帮你创作小红书账号的简介内容",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "账号类目",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入您小红书账号所经营的类目，如：时尚饰品"
            },
            {
                "label": "特别要求",
                "formType": "input",
                "isRequired": false,
                "placeholder": "如果有其他要求可以在这里输入"
            },
            {
                "label": "简介风格",
                "options": [
                    "真诚",
                    "幽默",
                    "官方",
                    "正式"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你想要的简介风格"
            }
        ],
        "system_msg": "我是一名小红书博主，我的小红书账号主要是经营{账号类目}，请你帮我创作一条小红书账号的简介内容，内容风格请采用{简介风格}风格。注意事项：简介内容请不要超过100个字符，并且请采用分段格式",
        "user_msg": "账号类目：{账号类目}\n特别要求：{特别要求}\n简介风格：{账号类目}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list179"
    },
    {
        "id": 180,
        "name": "母婴辅食",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d8a93db70d74689.png",
        "info": "生成母婴辅食类爆款笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "博主身份",
                "options": [
                    "宝妈",
                    "孕妈",
                    "奶爸",
                    "宝宝父母",
                    "营养师",
                    "儿科医生",
                    "产科医生",
                    "儿科护士"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你的身份"
            },
            {
                "label": "产品",
                "formType": "input",
                "isRequired": true,
                "newKeywords": "",
                "placeholder": "请输入产品，如：米粉",
                "defaultValue": "",
                "newKeywordsAdding": false,
                "addDefaultTagVisible": false
            },
            {
                "label": "品牌名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入产品的品牌名"
            },
            {
                "label": "内容类型偏好",
                "options": [
                    "好物种草",
                    "生活记录",
                    "创意创造"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择内容偏好"
            },
            {
                "label": "写作风格",
                "options": [
                    "严肃",
                    "真诚",
                    "讽刺",
                    "激动",
                    "质疑",
                    "愉快"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你的身份是一名{博主身份}小红书博主，请根据我所提供的产品信息和创作要求，帮我写一篇{内容类型偏好}类小红书爆款笔记，请参考小红书爆款笔记逻辑帮我创作内容和对应标题，标题字符控制在20-25字。请在内容中合理的加入emoji",
        "user_msg": "产品：{产品}\n品牌名称：{品牌名称}\n内容类型偏好：{内容类型偏好}\n博主身份：{博主身份}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list180"
    },
    {
        "id": 181,
        "name": "护肤防晒产品",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d8e91b677852879.png",
        "info": "帮你生成护肤防晒类产品笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "博主身份",
                "options": [
                    "青春少女",
                    "熟龄女性",
                    "学生党",
                    "精致男性",
                    "皮肤科医生",
                    "护肤品体验官"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择你的身份"
            },
            {
                "label": "产品品牌",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入产品品牌名称"
            },
            {
                "label": "产品名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入产品名称，如水动力洁面乳"
            },
            {
                "label": "内容类型偏好",
                "options": [
                    "好物种草",
                    "生活记录",
                    "测评分享"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择内容偏好"
            },
            {
                "label": "目标群体",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择目标群体"
            },
            {
                "label": "写作风格",
                "options": [
                    "严肃",
                    "真诚",
                    "讽刺",
                    "激动",
                    "质疑",
                    "愉快"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "现在你的身份是一名{博主身份}小红书博主，请根据我所提供的产品信息和创作要求，帮我写一篇{内容类型偏好}类小红书爆款笔记，笔记内容针对的目标人群是{目标群体}，请参考小红书爆款笔记逻辑帮我创作内容和对应标题，标题字符控制在20-25字，在文中请适当加入emoji表情",
        "user_msg": "产品品牌：{产品品牌}\n产品名称：{产品名称}\n内容类型偏好：{内容类型偏好}\n目标群体：{目标群体}\n博主身份：{博主身份}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list181"
    },
    {
        "id": 182,
        "name": "护肤防晒技巧",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230922/650d9664117a674689.png",
        "info": "帮你生成护肤防晒技巧知识类笔记",
        "cat_id": 65,
        "cat_id_arr": [
            65
        ],
        "user_form": [
            {
                "label": "目标群体",
                "options": [
                    "女性",
                    "男性"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择目标群体"
            },
            {
                "label": "主题偏好",
                "options": [
                    "护肤",
                    "防晒",
                    "保养"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择主题偏好"
            },
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入内容主题，如：军训期间如何保护皮肤"
            },
            {
                "label": "写作风格",
                "options": [
                    "严肃",
                    "真诚",
                    "讽刺",
                    "激动",
                    "质疑",
                    "愉快"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文案（80-150字）",
                    "中文案（200-500字）",
                    "长文案（500-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的小红书爆款文案专家，现在我需要写一篇{主题偏好}类笔记内容，请按照小红书笔记的爆款逻辑结构，根据我所提供的信息，帮我创作一篇笔记和生成对应标题。请在文中合理的运用emoji符号。标题字符请控制在20-25字",
        "user_msg": "目标群体：{目标群体}\n主题偏好：{主题偏好}\n内容主题：{内容主题}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 65,
        "top_cat_id": 64,
        "arrKey": "list182"
    },
    {
        "id": 183,
        "name": "PPT大纲",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231018/652fc6c318a9837400.png",
        "info": "根据你的主题帮你生成对应的PPT大纲内容",
        "cat_id": 36,
        "cat_id_arr": [
            36
        ],
        "user_form": [
            {
                "label": "PPT主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的PPT大纲主题内容"
            },
            {
                "label": "PPT内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请描述你的PPT主要内容"
            }
        ],
        "system_msg": "我需要写一份PPT大纲内容，请你根据我的PPT主题和PPT内容的要求，帮我只做一份相应的PPT大纲内容",
        "user_msg": "PPT主题：{PPT主题}\nPPT内容：{PPT内容}",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list183"
    },
    {
        "id": 184,
        "name": "知乎测评文章",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231018/652fc86d5361511803.png",
        "info": "一键生成知乎测评类文章",
        "cat_id": 20,
        "cat_id_arr": [
            20
        ],
        "user_form": [
            {
                "label": "主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入主题，如：华为Mate60Pro为什么像素这么高？"
            },
            {
                "label": "作者身份",
                "options": [
                    "美食专家",
                    "经济学家",
                    "旅行专家",
                    "金融专家",
                    "科技大佬",
                    "母婴专家",
                    "职场专家",
                    "汽车专家",
                    "人文学者",
                    "社会科学专家",
                    "时尚达人",
                    "数码专家",
                    "体育专家",
                    "心理学专家",
                    "艺术大师",
                    "音乐大师",
                    "影视爱好者",
                    "游戏达人",
                    "娱乐专家",
                    "运动健身教练",
                    "动物专家",
                    "自然科学学者",
                    "时尚博主",
                    "职场专家"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "科技大佬"
            },
            {
                "label": "文章口吻",
                "options": [
                    "新闻稿",
                    "消费者",
                    "讽刺",
                    "搞笑",
                    "口语化",
                    "真诚",
                    "惊喜"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "真诚"
            },
            {
                "label": "大概内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请描述你文章的大概内容"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "100-300字",
                    "400-600字",
                    "700-1000字"
                ],
                "formType": "select",
                "isRequired": true
            }
        ],
        "system_msg": "我需要写一篇知乎平台的内容，你是一位{作者身份}，请根据我的主题和大楷内容，帮我写一篇文章口吻是{文章口吻}的知乎文章，字数要求：{字数要求}",
        "user_msg": "主题：{主题}\n大概内容：{大概内容}\n",
        "origin_cat_id": 20,
        "top_cat_id": 16,
        "arrKey": "list184"
    },
    {
        "id": 185,
        "name": "影视解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530a6df50e4477504.png",
        "info": "生成各类影视解说文案",
        "cat_id": 30,
        "cat_id_arr": [
            30
        ],
        "user_form": [
            {
                "label": "影视节目名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视节目的名称"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "视频时长",
                "options": [
                    "短视频（30-120秒）",
                    "中视频（120-300秒）",
                    "长视频（300-600秒）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "短视频（30-120秒）"
            }
        ],
        "system_msg": "你是一名抖音影视解说博主，擅长写各类影视解说文案，现在我需要写一篇影视节目是{创作类型}的文案，我需要创作{视频时长}秒的视频文案，每30秒大楷70个字",
        "user_msg": "影视节目名称：{影视节目名称}\n内容要求：{内容要求}\n制作的视频时长：{视频时长}",
        "origin_cat_id": 30,
        "top_cat_id": 29,
        "arrKey": "list185"
    },
    {
        "id": 186,
        "name": "影视点评/解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231019/6530a8e810aa925928.png",
        "info": "生成影视点评类或解说类文章",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "创作类型",
                "options": [
                    "解说类",
                    "点评类"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择创作类型，解说类或点评类"
            },
            {
                "label": "影视节目名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视节目的名称"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入您的内容要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "真诚",
                    "质疑",
                    "热情",
                    "鼓励"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名专业的微信公众号写作专家，现在我需要写一篇影视节目是{创作类型}的微信公众号文章，创作类型是{创作类型}，请你根据我所提供的信息，帮我创作一篇文章内容和对应标题",
        "user_msg": "创作类型：{创作类型}\n影视节目名称：{影视节目名称}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list186"
    },
    {
        "id": 192,
        "name": "影视点评",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/6579102b5392368311.jpg",
        "info": "生成任意影视剧点评文案",
        "cat_id": 87,
        "cat_id_arr": [
            87
        ],
        "user_form": [
            {
                "label": "影视剧名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入影视剧的名称"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的内容要求，如第几集或其他要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "真诚",
                    "质疑",
                    "热情",
                    "鼓励",
                    "建议",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）",
                    "超长文章（1000-1600字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名拥有百万粉丝的自媒体写作博主，专门写影视剧点评文章，现在我需要写一篇关于影视剧名称：{影视剧名称}的点评文章，我将用于发布在自媒体平台里。请你根据我所提供的信息，帮我创作一篇点评文章内容和对应标题",
        "user_msg": "影视剧名称：{影视剧名称}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 87,
        "top_cat_id": 86,
        "arrKey": "list192"
    },
    {
        "id": 194,
        "name": "书籍点评",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/657914a1f265d65821.png",
        "info": "生成任意书籍点评文案",
        "cat_id": 88,
        "cat_id_arr": [
            88
        ],
        "user_form": [
            {
                "label": "书籍名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入书籍的名称，如：红楼梦"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的内容要求，如：第几章节等要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "真诚",
                    "质疑",
                    "热情",
                    "鼓励",
                    "建议",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）",
                    "超长文章（1000-1600字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一名拥有百万粉丝的自媒体写作博主，专门写书籍点评文章，现在我需要写一篇关于书籍名称：{书籍名称}的点评文章，我将用于发布在自媒体平台里。请你根据我所提供的信息，帮我创作一篇点评文章内容和对应标题",
        "user_msg": "书籍名称：{书籍名称}\n内容要求：{内容要求}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 88,
        "top_cat_id": 86,
        "arrKey": "list194"
    },
    {
        "id": 195,
        "name": "书籍解说",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/6579165e5cb7976733.jpg",
        "info": "生成任意书籍解说文案",
        "cat_id": 88,
        "cat_id_arr": [
            88
        ],
        "user_form": [
            {
                "label": "书籍名称",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入书籍的名称，如：红楼梦"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的内容要求，如：第几章节或其他创作要求"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）",
                    "超长文章（1000-1600字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位卓越的书籍文案写作高手，具有独特的创意和感知力。你需要以生动的语言、简洁的文字，将《{书籍名称}》的剧情表达得深入人心，引发读者的情感共鸣，让观众沉浸在这剧情中。创作结果请满足我的内容要求和字数要求",
        "user_msg": "书籍名称：{书籍名称}\n内容要求：{内容要求}\n字数要求：{字数要求}",
        "origin_cat_id": 88,
        "top_cat_id": 86,
        "arrKey": "list195"
    },
    {
        "id": 196,
        "name": "书籍推荐",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65791cc67e45122025.jpg",
        "info": "为你挑选适合的书，并给出推荐理由",
        "cat_id": 88,
        "cat_id_arr": [
            88
        ],
        "user_form": [
            {
                "label": "国内外书籍",
                "options": [
                    "国内书籍",
                    "国外书籍"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "国内书籍"
            },
            {
                "label": "观看人群",
                "options": [
                    "儿童",
                    "青年人",
                    "老年人"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "青年人"
            },
            {
                "label": "书籍类型",
                "options": [
                    "文艺",
                    "励志",
                    "人文社科",
                    "生活",
                    "经营",
                    "科技",
                    "教育",
                    "童书",
                    "漫画",
                    "小说"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "文艺"
            },
            {
                "label": "推荐书籍数量",
                "options": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择为你推荐的书籍数量"
            },
            {
                "label": "其他要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入你的其他要求"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "真诚",
                    "质疑",
                    "热情",
                    "鼓励",
                    "建议",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位书籍推荐专家，请根据我提供的信息，为我推荐{推荐书籍数量}本{国内外书籍}，书籍类型是{书籍类型}，并给出对应的推荐理由，请满足我给予的写作风格和字数要求，请帮我创作对应的内容和标题，我将发布到自媒体平台里",
        "user_msg": "推荐观看的指定人群：{观看人群}\n推荐的书籍类型为：{书籍类型}\n其他要求：{其他要求}\n文章的写作风格：{写作风格}\n文章的字数要求：{字数要求}",
        "origin_cat_id": 88,
        "top_cat_id": 86,
        "arrKey": "list196"
    },
    {
        "id": 197,
        "name": "影视推荐",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20231213/65791fd7bcf1945021.jpg",
        "info": "为你推荐适合的影视剧，并给出推荐理由",
        "cat_id": 87,
        "cat_id_arr": [
            87
        ],
        "user_form": [
            {
                "label": "影视分类",
                "options": [
                    "电影",
                    "电视剧",
                    "综艺节目"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "内容类型",
                "options": [
                    "不限类型",
                    "喜剧",
                    "爱情",
                    "悬疑",
                    "动画",
                    "武侠",
                    "古装",
                    "家庭",
                    "犯罪",
                    "科幻",
                    "恐怖",
                    "历史",
                    "战争",
                    "动作",
                    "冒险",
                    "传记",
                    "剧情",
                    "奇幻",
                    "惊悚",
                    "灾难"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "不限类型"
            },
            {
                "label": "地区",
                "options": [
                    "不限地区",
                    "中国大陆",
                    "欧美",
                    "国外",
                    "韩国",
                    "日本",
                    "中国香港",
                    "美国",
                    "英国",
                    "泰国",
                    "中国台湾",
                    "意大利",
                    "法国",
                    "德国",
                    "俄罗斯",
                    "印度"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "中国大陆"
            },
            {
                "label": "年代",
                "options": [
                    "2023年",
                    "2022年",
                    "2021年",
                    "2020年",
                    "2019年",
                    "2010年代",
                    "2000年代",
                    "90年代",
                    "80年代",
                    "70年代",
                    "60年代"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "2023年"
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "讽刺",
                    "幽默",
                    "真诚",
                    "质疑",
                    "热情",
                    "鼓励",
                    "建议",
                    "崇敬"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正常"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位影视剧推荐专家，请根据我提供的信息，为我推荐对应的{影视分类}，并写出影视内容总结和给出对应的推荐理由，请满足我给予的写作风格和字数要求，帮我创作对应的内容和标题，我将发布到自媒体平台里",
        "user_msg": "影视剧内容类型：{内容类型}\n影视剧出品地区：{地区}\n拍摄年份：{年代}\n文章写作风格：{写作风格}\n文章字数要求：{字数要求}",
        "origin_cat_id": 87,
        "top_cat_id": 86,
        "arrKey": "list197"
    },
    {
        "id": 202,
        "name": "文献综述",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/659809718a83f96608.png",
        "info": "文献总结评价",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "研究领域和主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "描述您的研究领域和主题"
            },
            {
                "label": "研究问题或目标",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "指明您研究的主要问题或目标"
            },
            {
                "label": "关键字",
                "formType": "keywords",
                "isRequired": true,
                "placeholder": "提供与您研究相关的关键字"
            },
            {
                "label": "文献类型",
                "options": [
                    "学术期刊",
                    "学术会议",
                    "书籍",
                    "技术报告",
                    "综述文章",
                    "专利",
                    "学位论文",
                    "白皮书",
                    "电子文献"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "学位论文"
            },
            {
                "label": "其他要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请填写其他要求或信息，若无则留空"
            },
            {
                "label": "创作语言",
                "options": [
                    "中文",
                    "英文"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "中文"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我写一篇不少于3000字的文献综述，输出结果的语言需要是{语言}，",
        "user_msg": "信息如下：\n研究领域和主题：{研究领域和主题}\n研究问题或目标：{研究问题或目标}\n关键字：{关键字}\n文献类型：{文献类型}\n其他要求：{其他要求}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list202"
    },
    {
        "id": 203,
        "name": "论文致谢",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980c77b47e249978.png",
        "info": "专业论文致谢，点睛之笔",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "指定感谢对象",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "填写你需要感谢的具体对象"
            },
            {
                "label": "具体贡献",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "列出每个对象的具体贡献"
            },
            {
                "label": "写作风格",
                "options": [
                    "正式风格",
                    "亲切感激",
                    "专业语气",
                    "表达情感",
                    "简练明了",
                    "幽默轻松"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "正式风格"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "200-400字",
                    "400-600字",
                    "600-800字",
                    "800-1000字",
                    "1000字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我写一篇论文致谢内容，字数要求{字数要求}，请严格遵守字数要求。",
        "user_msg": "指定感谢对象：{指定感谢对象}\n具体贡献：{具体贡献}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list203"
    },
    {
        "id": 204,
        "name": "论文研究内容扩写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240105/65980f97e0abd27588.png",
        "info": "帮你扩写论文的研究内容",
        "cat_id": 91,
        "cat_id_arr": [
            74,
            91
        ],
        "user_form": [
            {
                "label": "研究内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的研究内容"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的研究内容，帮我在原有的内容上进行扩写",
        "user_msg": "研究内容：{研究内容}",
        "origin_cat_id": 74,
        "top_cat_id": 90,
        "arrKey": "list204"
    },
    {
        "id": 206,
        "name": "论文改写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659903d72827460299.png",
        "info": "改写润色，妙笔生花，完美不同",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "原文内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请完整输入你的原文内容"
            },
            {
                "label": "改写要求",
                "options": [
                    "科学实证风格",
                    "理论思辨风格",
                    "批判性写作风格",
                    "历史研究风格",
                    "案例研究风格",
                    "叙事性写作风格",
                    "语法和结构调整",
                    "简化和澄清",
                    "提高表达效果",
                    "缩短冗余内容"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "科学实证风格"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的原论文内容，在原内容的基础上按照改写要求，帮我重新改写内容",
        "user_msg": "原文内容：{原文内容}\n改写要求：{改写要求}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list206"
    },
    {
        "id": 207,
        "name": "实验报告",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659904a86e20882368.png",
        "info": "实验过程的整理呈现和科学分析",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "实验题目",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入清晰准确的实验题目"
            },
            {
                "label": "辅助信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "建议输入创作辅助信息，如：实验目的、实验原理、实验材料、程序方法、结果数据、分析观点等"
            }
        ],
        "system_msg": "你是一位精通各行业的科学家，现在请根据我提供的信息，帮我写一份实验报告，字数不少于1000字",
        "user_msg": "实验题目：{实验题目}\n辅助信息：{辅助信息}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list207"
    },
    {
        "id": 208,
        "name": "公文写作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659907c5f020582147.png",
        "info": "专业公文写作，格式规范，逻辑严谨",
        "cat_id": 36,
        "cat_id_arr": [
            74,
            36
        ],
        "user_form": [
            {
                "label": "公文主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入清晰准确的公文主题"
            },
            {
                "label": "辅助信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "建议输入相关内容信息，如：发文对象、发文背景、关键词、核心内容等"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "200-400字",
                    "400-600字",
                    "600-1000字",
                    "1000-2000字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位专业的公文写作专家，会写各种类型的公文内容，现在请根据我提供的信息，帮我写一篇{字数要求}的公文内容",
        "user_msg": "公文主题：{公文主题}\n辅助信息：{辅助信息}",
        "origin_cat_id": 74,
        "top_cat_id": 35,
        "arrKey": "list208"
    },
    {
        "id": 209,
        "name": "申请书",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/6599091ee5c2341795.png",
        "info": "一键撰写各类申请书",
        "cat_id": 36,
        "cat_id_arr": [
            36
        ],
        "user_form": [
            {
                "label": "主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请准确输入申请主题"
            },
            {
                "label": "申请事项",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的申请事项"
            },
            {
                "label": "申请原因",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入您的申请原因"
            },
            {
                "label": "辅助信息",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "请输入其他辅助信息，如个人简介、个人优势等"
            },
            {
                "label": "申请人",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请准确输入申请人信息"
            }
        ],
        "system_msg": "你是一位专业的写作专家，会写各种类型的申请书，现在请根据我提供的信息，帮我写一篇标准格式的申请书内容",
        "user_msg": "主题：{主题}\n申请事项：{申请事项}\n申请原因：{申请原因}\n申请人：{申请人}\n辅助信息：{辅助信息}\n",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list209"
    },
    {
        "id": 210,
        "name": "通知公告",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65990a77aca9a87257.png",
        "info": "帮你写各类通知公告内容",
        "cat_id": 36,
        "cat_id_arr": [
            36
        ],
        "user_form": [
            {
                "label": "主题",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请准确输入你的内容主题"
            },
            {
                "label": "通知对象",
                "formType": "textarea",
                "isRequired": false
            },
            {
                "label": "通知内容概述",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入通知内容信息，如：时间、地点、事件等"
            }
        ],
        "system_msg": "你是一位专业的写作专家，会写各种类型的内容，现在请根据我提供的信息，帮我写一篇标准格式的通知公告内容",
        "user_msg": "主题：{主题}\n通知对象：{通知对象}\n通知内容概述：{通知内容概述}",
        "origin_cat_id": 36,
        "top_cat_id": 35,
        "arrKey": "list210"
    },
    {
        "id": 211,
        "name": "工作日报/月报/年报",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991373d176212636.png",
        "info": "根据需求快速创作工作日报、月报、年报",
        "cat_id": 37,
        "cat_id_arr": [
            37
        ],
        "user_form": [
            {
                "label": "类型",
                "options": [
                    "日报",
                    "周报",
                    "月报",
                    "年报"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "日报"
            },
            {
                "label": "您的职位",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你的职位，如：产品经理"
            },
            {
                "label": "工作内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请概述您的工作内容"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "100-300字",
                    "300-500字",
                    "500-800字",
                    "800字以上"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位写作高手，请根据我的职位和工作内容，帮我写一篇工作{总结类型}，字数要求：{字数要求}",
        "user_msg": "我的职位：{您的职位}\n工作内容：{工作内容}",
        "origin_cat_id": 37,
        "top_cat_id": 35,
        "arrKey": "list211"
    },
    {
        "id": 212,
        "name": "培训总结",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659914d4d7b8396308.png",
        "info": "快速创作培训总结报告",
        "cat_id": 37,
        "cat_id_arr": [
            37
        ],
        "user_form": [
            {
                "label": "培训内容",
                "formType": "textarea",
                "placeholder": "请输入培训内容，如：培训地点、培训内容、培训感受等"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "200-400字",
                    "400-600字",
                    "600-900字",
                    "900-1300字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位写作高手，请根据我提供的培训内容，帮我写一篇培训总结报告，字数要求：{字数要求}",
        "user_msg": "培训内容：{培训内容}\n字数要求：{字数要求}",
        "origin_cat_id": 37,
        "top_cat_id": 35,
        "arrKey": "list212"
    },
    {
        "id": 213,
        "name": "邀请函",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/659915db0e98385161.png",
        "info": "快速创作符合你要求的邀请函",
        "cat_id": 38,
        "cat_id_arr": [
            38
        ],
        "user_form": [
            {
                "label": "邀请者",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入邀请者信息"
            },
            {
                "label": "受邀者",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入受邀者信息"
            },
            {
                "label": "活动内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入活动内容信息"
            },
            {
                "label": "活动地点",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入活动地点信息"
            },
            {
                "label": "活动时间",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入活动时间信息"
            }
        ],
        "system_msg": "你是一位写作高手，请根据我提供的邀请函信息，帮我写一篇标准格式的邀请函",
        "user_msg": "邀请函信息：\n邀请者：{邀请者}\n受邀者：{受邀者}\n活动内容：{活动内容}\n活动地点：{活动地点}\n活动时间：{活动时间}",
        "origin_cat_id": 38,
        "top_cat_id": 35,
        "arrKey": "list213"
    },
    {
        "id": 214,
        "name": "论文研究方法扩写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991a8a0485b80906.png",
        "info": "帮你扩写论文的研究方法",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "基础信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的研究方法信息，如：研究方法的选择、实验设计、研究方法解释和讨论等信息"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的论文信息，帮我在原有的内容上进行扩写论文的研究方法。请按照标准的论文研究方法结构帮我创作。",
        "user_msg": "论文信息：{基础信息}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list214"
    },
    {
        "id": 215,
        "name": "论文研究结果扩写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240106/65991bd52ee1c24025.png",
        "info": "一键创作论文研究结果",
        "cat_id": 91,
        "cat_id_arr": [
            91,
            74
        ],
        "user_form": [
            {
                "label": "研究结果信息",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "建议输入研究的描述内容、结果、推论、建议等信息，输入越详细，效果越好"
            }
        ],
        "system_msg": "你是一位专业的论文导师，现在请根据我提供的信息，帮我在原有的内容上进行扩写论文研究结果",
        "user_msg": "研究结果信息：{研究结果信息}",
        "origin_cat_id": 91,
        "top_cat_id": 90,
        "arrKey": "list215"
    },
    {
        "id": 217,
        "name": "楼市资讯",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240327/66038821ec39263362.png",
        "info": "根据你的需求，帮你生成知识科普类公众号文章",
        "cat_id": 67,
        "cat_id_arr": [
            67
        ],
        "user_form": [
            {
                "label": "内容主题",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入你要创作的内容主题"
            },
            {
                "label": "内容要求",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入你的内容要求"
            },
            {
                "label": "内容关键词",
                "formType": "keywords",
                "isRequired": false
            },
            {
                "label": "写作风格",
                "options": [
                    "正常",
                    "亲切",
                    "严谨",
                    "讽刺",
                    "质疑",
                    "热情",
                    "真诚",
                    "鼓励",
                    "幽默"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择写作风格",
                "defaultValue": "严谨"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入自动判断",
                    "短文章（150-300字）",
                    "中文章（300-600字）",
                    "长文章（600字-1000字）"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "",
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "现在你是一个会写微信公众号文章的高手，请帮我写一篇关于楼市房产\n类的微信公众号文章。请根据给定的内容主题、内容要求、内容关\n键词、写作风格、字数要求，帮我写一篇给定风格的微信公众号文\n章，并生成对应标题",
        "user_msg": "内容主题：{内容主题}\n内容要求：{内容要求}\n内容关键词：{内容关键词}\n写作风格：{写作风格}\n字数要求：{字数要求}",
        "origin_cat_id": 67,
        "top_cat_id": 66,
        "arrKey": "list217"
    },
    {
        "id": 221,
        "name": "内容润色改写",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab45c207df82927.png",
        "info": "输入小说原文内容，自动改写润色",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "原文内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入原文内容，不超过1000字符"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "鉴宝",
                    "脑洞",
                    "甜宠",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "恐怖",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择小说类型"
            },
            {
                "label": "字数要求",
                "options": [
                    "200-400字",
                    "400-600字",
                    "600-1000字",
                    "根据当前输入自动判断"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入自动判断"
            }
        ],
        "system_msg": "你是一位优秀的{小说类型}类型小说写作高手，请根据我提供的小说原文内容和要求帮我进行润色，请不要修改原内容本意，创作字数请满足给定的字数要求",
        "user_msg": "原文内容：{原文内容}\n小说类型：{小说类型}\n字数要求：{字数要求}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list221"
    },
    {
        "id": 222,
        "name": "书名创作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab90b0769620685.png",
        "info": "根据你输入的内容，自动创作书名",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入小说内容，最多不超过1000字"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "鉴宝",
                    "甜宠",
                    "脑洞",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "恐怖",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择小说类型"
            }
        ],
        "system_msg": "你是一位优秀的小说写作高手，请根据我提供的小说内容和小说类型，帮我生成10个小说标题，标题的字数不超过15个中文汉字\n",
        "user_msg": "小说内容：{小说内容}\n小说类型：{小说类型}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list222"
    },
    {
        "id": 223,
        "name": "别名创作",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664ab9f8718ec48639.png",
        "info": "根据你的小说标题，创作对应小说别名",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说书名",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入小说原来的书名"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "鉴宝",
                    "甜宠",
                    "脑洞",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "恐怖",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true,
                "placeholder": "请选择小说类型"
            }
        ],
        "system_msg": "你是一位优秀的小说写作高手，请根据我提供的小说书名和小说类型，帮我生成15个对应的小说标题，标题的字数不超过15个中文汉字\n",
        "user_msg": "小说书名：{小说书名}\n小说类型：{小说类型}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list223"
    },
    {
        "id": 224,
        "name": "吸睛开头",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664af819a802a55598.png",
        "info": "帮你创作推文视频吸睛开头（200字内）",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入小说开头内容，最多200字"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "恐怖",
                    "甜宠",
                    "脑洞",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "鉴宝",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "言情"
            },
            {
                "label": "创作风格",
                "options": [
                    "激情",
                    "悬疑",
                    "讽刺",
                    "深沉",
                    "喜悦",
                    "说服",
                    "傲慢",
                    "惊喜"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "激情"
            }
        ],
        "system_msg": "根据我给的内容和要求，创作一篇小说短视频的开头文案，要求如下：\n1、需要非常吸引人，能让观众继续看下去\n2、用创作出来的文案不超过200字，\n3、按照我给的创作风格生成对应语气和风格的文案\n4、创作的内容请不要偏离我原文的本意",
        "user_msg": "小说内容：{小说内容}\n小说类型：{小说类型}\n创作风格：{创作风格}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list224"
    },
    {
        "id": 225,
        "name": "内容概括",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664afb7f0bc9563022.png",
        "info": "根据你输入的小说内容，智能概括精华内容",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入小说原文内容，最多1000字"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "恐怖",
                    "甜宠",
                    "脑洞",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "鉴宝",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "言情"
            },
            {
                "label": "字数要求",
                "options": [
                    "根据当前输入，自动判断",
                    "100-300字",
                    "400-600字",
                    "700-1000字"
                ],
                "formType": "select",
                "isRequired": true,
                "defaultValue": "根据当前输入，自动判断"
            }
        ],
        "system_msg": "你是一位优秀的小说写作高手，请根据我提供的小说内容和小说类型，帮我概括成精华内容，请不要修改原内容本意。内容创作字数，请满足我给定的字数要求",
        "user_msg": "小说内容：{小说内容}\n小说类型：{小说类型}\n字数要求：{字数要求}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list225"
    },
    {
        "id": 226,
        "name": "短视频标题",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664afce43813887542.png",
        "info": "生成短视频标题文案",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说书名",
                "formType": "input",
                "isRequired": true,
                "placeholder": "请输入小说书名"
            },
            {
                "label": "小说类型",
                "options": [
                    "言情",
                    "都市",
                    "现代",
                    "赘婿",
                    "恐怖",
                    "甜宠",
                    "脑洞",
                    "武侠",
                    "科幻",
                    "穿越",
                    "悬疑",
                    "历史",
                    "奇幻",
                    "鉴宝",
                    "传记"
                ],
                "formType": "select",
                "isRequired": true
            },
            {
                "label": "其他要求",
                "formType": "textarea",
                "isRequired": false,
                "placeholder": "若有其他要求，请直接输入"
            }
        ],
        "system_msg": "你是一位拥有百万粉丝的小说账号抖音博主，你的账号主要是发布小说内容。现在请你根据我提供小说书名和对应的小说类型，帮我创作5条吸引人的抖音短视频的标题。",
        "user_msg": "小说书名：{小说书名}\n小说类型：{小说类型}\n要求：{其他要求}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list226"
    },
    {
        "id": 227,
        "name": "小说内容绘画提示词",
        "icon": "https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20240520/664b005f97a9c99100.png",
        "info": "根据你输入的信息，创作对应画面的AI绘画提示词",
        "cat_id": 95,
        "cat_id_arr": [
            95
        ],
        "user_form": [
            {
                "label": "小说内容",
                "formType": "textarea",
                "isRequired": true,
                "placeholder": "请输入小说原文内容"
            }
        ],
        "system_msg": "你是一位优秀的AI绘画提示词专家，请根据我提供的小说内容帮我生成对应的AI绘画描述词，我将会用你提供的AI绘画描述词去AI绘画工具中生成对应的图片。注意：1.如果我输入的内容较长，你可以生成多个AI绘画描述词，并在开头注明序号；2.每个绘画描述词都必须围绕小说动漫风格进行创作；3.请按照Stable Diffusion提示词的标准写法进行创作\n\n",
        "user_msg": "内容：{小说内容}",
        "origin_cat_id": 95,
        "top_cat_id": 94,
        "arrKey": "list227"
    }
]

export function main() {
    let data = [];
    let l1 = aaa(a1, 58)
    let l2 = aaa(a2, 59)
    let l3 = aaa(a3, 60)
    let l4 = aaa(a4, 61)
    let l5 = aaa(a5, 62)
    let l6 = aaa(a6, 63)
    let l7 = aaa(a7, 65)
    let l8 = aaa(a8, 66)
    let l9 = aaa(a9, 67)
    let l10 = aaa(a10, 68)
    let l11 = aaa(a11, 69)
    let l12 = aaa(a12, 70)
    let l13 = aaa(a13, 71)
    data.push(...l1, ...l2, ...l3, ...l4, ...l5, ...l6, ...l7, ...l8, ...l9, ...l10, ...l11, ...l12, ...l13)
    exportData(data)
}

export function mainApp(){
    let l = [];
    b.forEach(item => {
        let aa = {}
        aa.icon = item.icon
        aa.info = item.info
        aa.name = item.name
        aa.system_msg = item.system_msg
        aa.user_msg = item.user_msg
        let pc = []
        item.user_form.forEach(j => {
            let aaa = {}
            aaa.id = j.label
            aaa.label = j.label
            aaa.type = j.formType
            if (aaa.type == 'keywords'){
                aaa.type = 'input'
            }
            aaa.isRequire = j.isRequired ? '1':'0'
            if (j.options && j.options.length){
                aaa.selectContent = []
                aaa.selectContent.push(...j.options)
            }
            aaa.prompt = j.placeholder
            pc.push(aaa)
        })
        aa.param = JSON.stringify(pc)
        l.push(aa)
    })
    exportData(l)
}

export function exportData(data){
    const XLSX = require('xlsx');
// 将对象数组转换为工作表
    const worksheet = XLSX.utils.json_to_sheet(data);
// 创建工作簿并添加工作表
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
// 写入文件
    XLSX.writeFile(workbook, 'data.xlsx');
}

export function aaa(a, pid) {
    let l = [];
    a.forEach(item => {
        item.app_list.forEach(j => {
            let aa = {}
            aa.menuName = item.cat_name
            aa.name = j.name
            l.push(aa)
        })
    })
    return l;
}
