<template>
  <div class="chat">
    <div class="chat-create-tool">
      <div class="chat-create-tool-index"  v-if="!showTalk">
        <el-scrollbar style="height: 100%;width: 100%;" >
          <div v-for="(item,i) in list" :key="i">
            <div class="tool-title">
              {{ item.label }}
            </div>
            <div class="tool-list">
              <el-row>
                <el-col :span="4" v-for="(c,j) in item.children" :key="100 + j">
                  <el-tooltip effect="dark" placement="top" :open-delay="500" :content="c.info">
                    <div class="list-item" @click="handleClickToolItem(c)">
                      <div class="list-item-icon">
                        <el-image style="width: 100%;height: 100%" fit="cover" :src="c.icon"></el-image>
                      </div>
                      <div class="list-item-content">
                        <div class="content-name">
                          {{ c.label }}
                        </div>
                        <div class="content-introduce">
                          {{ c.info }}
                        </div>
                      </div>
                    </div>
                  </el-tooltip>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="chat-create-tool-talk" v-if="showTalk">
        <div class="talk-title">
          <div class="title-icon" @click="showTalk = false">
            <svg-icon icon-class="back"/>
          </div>
          <div class="title-name">
            {{ talkTitle }}
          </div>
        </div>
        <div class="talk-content">
          <talk-ai-input ref="talkAiInput"></talk-ai-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {treeListAiCreateTool} from "@/api/chat/aiCreateTool";
import TalkAiInput from "@/views/talk/component/talk-ai-input";

export default {
  name: "talk-create-tool",
  components: {TalkAiInput},
  data() {
    return {
      showTalk: false,
      list: [],
      sourceList: [],
      talkTitle: "",
      c: undefined,
    }
  },
  watch: {
    $route(val1,val2){
      this.c = val1.query.c
    },
    c(val1,val2){
      this.filterList(val1)
    }
  },
  created() {
    this.c = this.$route.query.c
    this.getList(this.c);
  },
  mounted() {
    let a = this.$route.name
    if (this.c){
      a = a + "-c" + this.c;
    }
    this.$emit("talkChild", a);
    // 由于tooltip滚动是会造成滚动条闪烁，将body 添加 overflow=hidden 可解决
    document.body.style.overflow = 'hidden'
  },
  methods: {
    getList(val) {
      treeListAiCreateTool({}).then(res => {
        this.list = res.data;
        this.sourceList = res.data;
        if (val){
          this.filterList(val)
        }
      })

    },
    handleClickToolItem(item) {
      this.talkTitle = item.label;
      this.showTalk = true;
      this.$nextTick(() => {
        this.$refs.talkAiInput.showMarkDownPrologue(item.userMsg);
        this.$refs.talkAiInput.setTemplate("chatAiCreateToolId", item.id)
      })
    },
    filterList(val){
      if (!val){
        this.list = this.sourceList
        return
      }
      this.list = this.sourceList.filter(item => item.id == val)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
