import request from "@/api/request";
import website from "@/config/website";


export function getTalkModel() {
    return request({
        url: `/api/${website.apiRequestHead}/chat/model/open/getTalkModel`,
        method: 'get'
    })
}
