import request from "@/api/request";
import website from "@/config/website";

/**
 * 树状模板分组数据
 */
export function treeListAiTalkPromptTemplateGroup(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiTalkPromptTemplateGroup/open/getTreeList`,
        method: 'post',
        data: {}
    })
}

/**
 * 获取整个节点的树状数据
 */
export function treeListAllElementByPid(pid){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiTalkPromptTemplateGroup/open/getElementByPid/${pid}`,
        method: 'get',
    })
}
