import request from "@/api/request";
import website from "@/config/website";

export function getHotTags(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/open/getHotTags`,
        method: 'get'
    })
}

export function showImage(data){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/open/show`,
        method: 'post',
        data: data
    })
}

export function updateTags(data){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/updateTags`,
        method: 'post',
        data: data
    })
}

export function text2img(data){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/text2img`,
        method: 'post',
        data: data
    })
}

export function img2img(data){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/img2img`,
        method: 'post',
        data: data
    })
}

export function listByLogin(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/listByLogin`,
        method: 'get'
    })
}

export function listByStar(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/listByStar`,
        method: 'get'
    })
}

export function listAndStar(){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/listAndStar`,
        method: 'get'
    })
}

export function getAiDrawingImageById(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/${id}`,
        method: 'get'
    })
}

export function releaseImage(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImage/release/${id}`,
        method: 'get'
    })
}

export function setStar(id){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingImageStar/setStar/${id}`,
        method: 'get'
    })
}
