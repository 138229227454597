import request from "@/api/request";
import website from "@/config/website";

export function treeListAiCreateTool(data){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiCreateTool/open/getTreeList`,
        method: 'post',
        data: data
    })
}
