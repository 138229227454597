import request from "@/api/request";
import website from "@/config/website";

/**
 * 获取整个节点的树状数据
 */
export function treeListAllElementByPid(pid){
    return request({
        url: `/api/${website.apiRequestHead}/chat/aiDrawingPrompt/open/getElementByPid/${pid}`,
        method: 'get',
    })
}
