<template>
  <div class="chat" style="background: #f0f2f5">
    <div class="chat-talk-wrap">
      <div class="chat-talk-core-wrap">
        <el-scrollbar  style="height: 100%;"
                       v-if="!showTalkAi"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="#f0f2f5">
          <div class="hot-question-list-warp">
            <div class="hot-question-list">
              <div class="list-title">
                <div>
                  <svg-icon icon-class="hotQuestion" /> 热门提问
                </div>
                <el-button class="refresh-btn" type="text" icon="el-icon-refresh" @click="getHotQuestionList">{{' 换一批'}}</el-button>
              </div>
              <div class="list-content">
                <div class="list-content-column" v-for="(item,i) in hotQuestionList" :key="i">
                  <div class="column-title">
                    <el-image :src="item.icon" class="column-icon"></el-image>
                    <span>{{item.name}}</span>
                  </div>
                  <div v-if="item.contentList && item.contentList.length">
                    <div class="column-card" v-for="(c,i) in item.contentList" :key="i" @click="talkQuestion = c.content">
                      <div class="card-content">
                        {{c.content}}
                      </div>
                    </div>
                  </div>
                  <div class="column-card" v-if="!item.contentList || !item.contentList.length">
                    <div class="card-content">
                      暂无
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div style="height: calc(100% - 171px);" v-if="showTalkAi" >
          <talkAi ref="talkAi" :chatListId="chatListId" @sendEnd="sendEnd"></talkAi>
        </div>
        <div class="chat-talk-footer">
          <div class="footer-tools" >
            <div>
              <el-button class="tools-btn ant-btn ant-btn-primary" @click="handleClickCreateNewChat"><i class="el-icon-plus" /> 新建对话</el-button>
              <el-button class="tools-btn ant-btn ant-btn-primary ant-btn-background-ghost" @click="handleClickPrompt"><svg-icon icon-class="prompt"/> 提示词</el-button>
              <el-button class="tools-btn ant-btn ant-btn-primary ant-btn-background-ghost" @click="handleClickHistory"><svg-icon icon-class="tabulation" /> 对话记录</el-button>
              <el-dropdown style="margin-left: 10px" @command="recommendChange" trigger="click">
                <el-button class="tools-btn ant-btn ant-btn-primary ant-btn-background-ghost" style="width: unset">
                  {{`聊天模型: ${modelValue} `}}<svg-icon icon-class="up" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-for="(item, index) in modelList"
                      :key="index"
                      :command="item"
                  >{{ item.showName }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="footer-input-warp">
            <div class="footer-input">
              <el-input type="textarea" placeholder="有问题尽管问我... " v-model="talkQuestion" :rows="4"></el-input>
            </div>
            <el-button class="footer-submit-button ant-btn ant-btn-primary" :disabled="!talkQuestion" @click="send" :loading="sendLoading">{{sendLoading ? '发送中' :'发 送'}}</el-button>
          </div>
          <el-tooltip class="footer-tooltip"
                      effect="dark"
                      placement="top">
            <div slot="content" style="font-size: 14px">
              该服务处于前沿探索阶段，您应当合<br/>
              法合规使用本服务，并承担由此产生<br/>
              的所有责任。我们对您的使用不做保<br/>
              证且不承担任何责任。
            </div>
            <div><i class="el-icon-warning-outline"/>免责声明</div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <el-dialog class="chat-talk-index-prompt"
               center
               :modal="false"
               :append-to-body="true"
               :close-on-click-modal="true"
               title="提示词模板"
               :visible.sync="openPrompt"
               width="60%">
      <div class="chat">
        <div class="left" >
          <el-scrollbar style="height: 100%">
            <el-menu class="left-menu" :default-active="'0'" >
              <div v-for="(j,i) in navMenuList" :key="i">
                <el-menu-item v-if="!j.children" :index="''+j.id" @click="handleClickNavMenu(j.id)">{{j.label}}</el-menu-item>
                <el-submenu v-if="j.children" :index="''+j.id" >
                  <template slot="title">{{j.label}}</template>
                  <el-menu-item :index="''+k.id"
                                v-for="(k) in j.children"
                                :key="k.id"
                                @click="handleClickNavMenu(k.id)">
                    {{k.label}}
                  </el-menu-item>
                </el-submenu>
              </div>
            </el-menu>
          </el-scrollbar>
        </div>
        <div class="right">
          <div class="right-wrap">
            <div class="right-wrap-left">
              <div>
                <el-input v-model="search" placeholder="请输入提示词" @input="getTemplateList(null)"></el-input>
              </div>
              <el-scrollbar style="height: calc(100% - 40px);margin-top: 20px;width: calc(100% + 10px);">
                <div class="list-wrap" v-infinite-scroll="getCatchTemplateList" :infinite-scroll-disabled="isDisabledScroll">
                  <div class="list-item" v-for="item in catchTemplateList" :key="item.id" @click="handleClickTemplate(item)">
                    <div class="list-item-title">{{item.title}}</div>
                    <div class="list-item-content">{{item.content}}</div>
                    <div class="list-item-use-num">
                      <svg-icon icon-class="hotTemplate"></svg-icon>
                      <div>{{parseInt(item.useNum) >= 100 ? item.useNum : '小于100' }}</div>
                    </div>
                  </div>
                  <div class="tip" v-if="isDisabledScroll">没有更多了...</div>
                </div>
              </el-scrollbar>
            </div>
            <div class="right-wrap-right">
              <div class="right-input">
                <el-input type="textarea" v-model="templateContent" placeholder="请选择提示词" ></el-input>
              </div>
              <div class="right-btn">
                <el-button class="ant-btn use-btn" @click="handleUseTemplate"><svg-icon icon-class="useTemplate"/> 使 用</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="chat-talk-index-history"
               center
               :modal="false"
               :close-on-click-modal="true"
               title="对话记录"
               width="40%"
               :visible.sync="openHistory"
               :append-to-body="true">
      <div class="content"
           v-loading="loadingHistory"
           element-loading-spinner="el-icon-loading"
           element-loading-background="#fff">
        <el-scrollbar style="height: 100%;width: calc(100% + 10px)">
          <div class="list-wrap"
               v-infinite-scroll="getCatchChatList"
               :infinite-scroll-disabled="isDisabledScroll">
            <div class="list-item-card" v-for="(item,i) in catchChatList" :key="i" @click="handleClickHistoryMessage(item)">
              <div class="item-title" >
                {{item.chatContent}}
              </div>
              <svg-icon class-name="item-icon" icon-class="delLinear" @click.stop="handleDelChatList(item)"/>
            </div>
          </div>
        </el-scrollbar>
      </div>

    </el-dialog>
    <el-dialog class="chat-replace-dialog"
               top="40vh"
               :append-to-body="true"
               :show-close="false"
               title="请替换关键词"
               :visible.sync="openReplaceParam"
               width="50%">
      <div class="replace-body-wrap">
        <el-row class="replace-body-row" :gutter="20">
          <el-col class="replace-body-col" :span="8" v-for="(item,i) in replaceParamList" :key="i">
            <div>{{item.key}}</div>
            <el-input v-model="item.value" :placeholder="'请输入内容替换' + item.key"></el-input>
          </el-col>
        </el-row>
        <svg-icon class-name="replace-body-icon" icon-class="replaceSend" @click="handleSendReplace()"/>
      </div>
    </el-dialog>
  </div>
</template>

<script >
import {listAiTalkHotQuestionContent} from "@/api/chat/aiTalkHotQuestionContent";
import {treeListAllElementByPid} from "@/api/chat/aiTalkPromptTemplate";
import {delChatListById, getChatListByUser} from "@/api/chat/aiTalk";
import talkAi from "@/views/talk/component/talk-ai";
import {getAllModelApi} from "@/api/system";
import {mapActions, mapGetters} from "vuex";
import {updataSettingDataApi} from "@/api/user";
import {getTalkModel} from "@/api/chat/model";

export default {
  name: "talk-index",
  components:{
    talkAi,
  },
  computed: {
    ...mapGetters(['userInfo', 'currMqttMsg', 'reconMqttTime', 'sysConfig', 'isLogin', 'settingObj']),
  },
  data(){
    return{
      hotQuestionList: [],
      loading: false,
      talkQuestion: undefined,
      allTemplateList: [],
      templateList: [],
      catchTemplateList: [],
      replaceParamList: [],
      chatList:[],
      catchChatList: [],
      pageNum: 1,
      pageSize: 10,
      historyPageNum: 1,
      historyPageSize: 15,
      navMenuList: [],
      search: undefined,
      templateContent: undefined,
      isDisabledScroll: false,
      selectNavMenu: 0,
      openPrompt: false,
      openReplaceParam: false,
      openHistory: false,
      loadingHistory: false,
      showTalkAi: false,
      chatListId: undefined,
      modelValue: undefined,
      modelList: [],
      sendLoading: false,
    }
  },
  created() {
    this.$emit('talkChild','talk-index');
    this.getHotQuestionList();
    this.getAllElementByPid(0);
    this.getChatModel();
    if (this.isLogin){
      this.getChatList();
    }
  },
  methods:{
    ...mapActions(['getSettingDataActions', 'getUserInfoActions']),
    getChatList(){
      this.loadingHistory = true;
      this.historyPageNum = 1;
      this.catchChatList = [];
      getChatListByUser().then(res => {
        this.chatList = res.data
        this.getCatchChatList()
      }).catch(() => {
        this.loadingHistory = false;
      })
    },
    getCatchChatList(){
      let a = [];
      a.push(...this.chatList);
      let start = (this.historyPageNum++ - 1) * this.historyPageSize;
      this.catchChatList.push(...a.splice(start,this.historyPageSize));
      this.loadingHistory = false;
    },
    getHotQuestionList(){
      this.loading = true;
      listAiTalkHotQuestionContent().then(res => {
        this.hotQuestionList = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    send(){
      this.sendLoading = true;
      this.showTalkAi = true;
      this.$nextTick(() => {
        this.$refs.talkAi.sendMessage(this.talkQuestion)
      })
    },
    sendEnd(){
      this.sendLoading = false;
      this.talkQuestion = null;
      this.getUserInfoActions();
    },
    handleClickCreateNewChat(){
      this.showTalkAi = false;
      this.chatListId = null;
    },
    handleClickPrompt(){
      this.openPrompt = true;
    },
    handleClickHistory(){
      this.getChatList()
      this.openHistory = true;
    },
    getAllElementByPid(pid){
      treeListAllElementByPid(pid).then(res => {
        this.treeList = res.data;
        this.getAllTemplateList();
        this.getNavMenuList()
      })
    },
    getAllTemplateList(){
      this.allTemplateList = [];
      this.treeList[0].children.forEach(i => {
        i.children.forEach(j => {
          if (j.templateList && j.templateList.length){
            j.templateList.forEach(k => {
              k.templatePId = j.id
              this.allTemplateList.push(k)
            })
          }
        })
      })
      this.getTemplateList()
    },
    getTemplateList(){
      let id = this.selectNavMenu
      let title = this.search;
      this.templateList = [];
      this.catchTemplateList = [];
      this.pageNum = 1;
      if (title || id){
        let a = this.allTemplateList.filter(item => {
          return (title ? item.title.includes(title) : 1) && (id ? item.templatePId === id : 1)
        });
        this.templateList.push(...a)
      }
      if (!title && !id){
        this.templateList.push(...this.allTemplateList)
      }
      this.getCatchTemplateList()
    },
    getCatchTemplateList(){
      if ((this.pageNum - 1) * this.pageSize >= this.templateList.length){
        this.isDisabledScroll = true;
        return
      }
      let a = [];
      a.push(...this.templateList);
      let start = (this.pageNum++ - 1) * this.pageSize
      this.isDisabledScroll = a.length < start + this.pageSize;
      this.catchTemplateList.push(...a.splice(start,this.pageSize))
    },
    getNavMenuList(){
      this.navMenuList = [];
      let a = this.treeList[0].children
      this.navMenuList.push({
        id: 0,
        label: '全部'
      })
      this.navMenuList.push(...a)
    },
    handleClickNavMenu(id){
      this.selectNavMenu = id;
      this.getTemplateList()
    },
    handleClickTemplate(item){
      this.templateContent = null;
      this.replaceParamList = [];
      const regex = /\{.*?\}/g;
      this.templateContent = item.content;
      let match = item.content.match(regex);
      if (match && match.length){
        match.forEach(j => {
          this.replaceParamList.push({
            key: j,
            value: ''
          })
        })
        this.openReplaceParam = true;
        return
      }
      this.handleSendReplace();
    },
    handleSendReplace(){
      if (this.replaceParamList && this.replaceParamList.length){
        this.replaceParamList.forEach(item => {
          this.templateContent = this.templateContent.replace(item.key,item.value)
        })
      }
      this.openReplaceParam = false;
    },
    handleUseTemplate(){
      if (!this.templateContent){
        this.$message.warning("请选择要使用的模板!")
        return
      }
      this.talkQuestion = this.templateContent;
      this.templateContent = null;
      this.openPrompt = false;
    },
    handleDelChatList(item){
      this.loadingHistory = true;
      delChatListById(item.id).then(res => {
        this.$message.success("删除成功")
        this.getChatList();
      }).finally(() => {
        this.loadingHistory = false
      })
    },
    handleClickHistoryMessage(item){
      this.chatListId = item.id
      this.showTalkAi = true
      this.openHistory = false
      this.$nextTick(() => {
        this.$refs.talkAi.listId = this.chatListId
      })
    },
    // 获取模型
    getChatModel() {
      getTalkModel().then(res => {
        this.modelList = res.data;
        if (this.isLogin){
          this.getSettingDataActions()
          let a = this.modelList.filter(item => item.model == this.settingObj.ai_model)
          if (a && a.length){
            this.modelValue = a[0].showName;
          }
        }
      })
    },
    recommendChange(item) {
      if (!this.isLogin) {
        return this.$router.push({ name: 'login' })
      }
      if (item.showName === this.modelValue){
        return
      }
      if (item.consumeFuel !== 0 && this.userInfo.rl_cou <= 0) {
        this.$message.warning("燃料不足!")
        return false
      }
      this.$showLoading({
        text: '更换中',
      })
      updataSettingDataApi({
        aiModel: item.model,
      }).then((res) => {
        this.modelValue = item.showName
        this.getSettingDataActions()
        this.$hideLoading({
          message: '更换成功',
          type: 'success',
        })
      }).catch(() => {
        this.$hideLoading({
          message: '更换失败',
          type: 'error',
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
